import {
  Box,
  makeStyles,
  Theme,
  Typography,
  Slide,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import BasicModal from 'components/ui/Modal';
import dayjs from 'dayjs';
import React from 'react';
import MedicationModal from './MedicationModal';
import DrugFile from './DrugFile';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
    padding: '1rem',
    minHeight: '100px',
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4,0,0.2,1)',
    transitionDuration: '0.3s',
    '&:hover': {
      border: '1px solid #425364',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
    color: '#494B5A',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'blue',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#494B5A',
  },
  date: {
    fontSize: '14px',
    color: '#8E96A4',
  },
  saveBtnSlide: {
    textTransform: 'capitalize',
    color: 'black',
    background: '#D6EEFF',
    border: '1px solid #EAECF0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalWrapper: {
    display: 'flex',
    gap: '10px',
    position: 'fixed',
    top: '10%',
    left: '10%',
    zIndex: 1300, // Ensures both modals stay on top
  },
  secondModal: {
    position: 'absolute',
    left: 'calc(100% + 10px)', // Positions next to first modal
    top: 0,
    background: '#fff',
    width: '50vw',
    height: '100%',
    boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 1200,
  },
}));

const Medications = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isLarge = useMediaQuery(theme.breakpoints.only('lg'), {
    defaultMatches: true,
  });
  const medicationsArray = [
    {
      name: 'Ezy dose cold seal crd 62 dose miscellaneous',
      date: new Date(),
    },
    { name: 'PredniSONE oral tablet 5mg', date: new Date() },
    { name: 'AmLODIPine besylate oral tablet 10 mg', date: new Date() },
  ];
  const [open, setOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Box position="relative">
      <Box className={classes.root} onClick={() => setOpen(true)}>
        <Typography className={classes.title}>Medications (3)</Typography>
        <Box mt={2}>
          {medicationsArray?.map((medication) => (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
              mb={2}
            >
              <Box className={classes.circle} />
              <Typography className={classes.text}>
                {medication?.name}
              </Typography>
              <Typography className={classes.date}>
                {dayjs(medication?.date).format('MM-DD-YYYY')}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <BasicModal
        open={open}
        handleClose={() => {
          setOpen(false);
          setIsExpanded(false);
        }}
        onClose={() => {
          setOpen(false);
          setIsExpanded(false);
        }}
        isRegularModal
        isSlide
        divider
        title={'Medications'}
        titlePadding={'2rem'}
      >
        <>
          {isExpanded && (
            <Box
              position="absolute"
              right={isLarge ? '58vw' : '48vw'}
              top={0}
              py={'1.2rem'}
            >
              <CancelIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsExpanded(false)}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              width: isExpanded
                ? isLarge
                  ? '100vw'
                  : '80vw'
                : isLarge
                ? '57vw'
                : '47vw',
            }}
            style={{
              transition: 'width 0.3s ease',
            }}
          >
            <Box
              sx={{
                width: isExpanded ? (isLarge ? '43vw' : '33vw') : '0',
                overflow: 'hidden',
                borderRight: isExpanded ? '1px solid #EAECF0' : 'none',
                position: 'relative',
                height: '100vh',
                // backgroundColor: '#fff',
              }}
              style={{
                transition: 'width 0.3s ease',
              }}
            >
              {isExpanded && (
                <DrugFile {...props} handleExpand={setIsExpanded} />
              )}
            </Box>

            <Box
              sx={{ width: isLarge ? '57vw' : '47vw', flexShrink: 0 }}
            >
              <MedicationModal handleExpand={setIsExpanded} />
            </Box>
          </Box>
        </>
      </BasicModal>
    </Box>
  );
};

export default Medications;
