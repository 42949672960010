import React, { ReactElement, useEffect } from 'react';
import AutoCompleteAdd, { Option } from 'components/ui/Inputs/AutoCompleteAdd';
import {
  Avatar,
  Box,
  BoxProps,
  Hidden,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { displaySelectedFirst } from 'components/ui/AssignedTo/utils';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import useEditPatient from '@eggmed/graphql-client/operations/patientOperations/useEditPatient';
import { log } from 'config/log';

interface SearchBarProps extends BoxProps {
  values: Option[];
  options: Option[];
  setValues: (value: Option[]) => void;
  filterOptions?: (
    options: Option[],
    params: { inputValue: string; getOptionLabel: (option: Option) => string }
  ) => Option[];
  placeholder?: string;
  optionRendrerOptions?: {
    handleClick?: () => void;
  };
  icon?: React.ReactElement;
  noOptionsText?: string;
  searchValue?: string;
}

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 400,
    fontSize: '15px',
    color: 'dark',
  },
  email: {
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  searchBar: {
    display: 'flex',
    transition: 'width 0.3s ease',
  },
  expanded: {
    width: '350px',
  },
  collapsed: {
    width: '300px',
  },
  expandedMenu: {
    // border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    zIndex: 100,
    fontSize: 13,
    color: '#586069',
    transition:
      'opacity 120ms ease-in-out 0s, scale 240ms cubic-bezier(0.18, 1.4, 0.4, 1) 0s',
    width: '300px',
    visibility: 'visible',
    opacity: 1,
    scale: 1,
    transformOrigin: '100%',
  },
  collapsedMenu: {
    visibility: 'hidden',
    opacity: 0,
    scale: 0.85,
  },
}));

enum searchType {
  name = 'name',
  email = 'email',
}

function SearchInput({
  values,
  options,
  setValues,
  filterOptions,
  placeholder,
  icon,
  optionRendrerOptions,
  searchValue,
  ...props
}: SearchBarProps) {
  const { updatePatient, loading: loadingPatient } = useEditPatient();

  const history = useHistory();
  const classes = useStyles();
  const [isActive, setIsActive] = React.useState(false);
  const [menuActive, setMenuActive] = React.useState(false);
  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        setMenuActive(true);
      }, 300);
    } else {
      setMenuActive(false);
    }
  }, [isActive]);
  const highlightSearchValue = (text, highlight, type) => {
    const escapedHighlight = highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
      <Typography
        className={type === searchType.name ? classes.name : classes.email}
      >
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <b style={{ fontWeight: 700, color: 'black' }} key={index}>
              {part}
            </b>
          ) : (
            part
          )
        )}
      </Typography>
    );
  };
  return (
    <Box
      display="flex"
      className={clsx(
        classes.searchBar,
        isActive ? classes.expanded : classes.collapsed
      )}
      flexDirection="column"
    >
      <AutoCompleteAdd
        {...props}
        icon={icon}
        isSearch={true}
        multiple={false}
        placeholder={placeholder}
        data-cy="autocomplete-add"
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        groupBy={(option: Option) => option.type}
        filterOptions={filterOptions && filterOptions}
        menuClassName={
          menuActive ? classes.expandedMenu : classes.collapsedMenu
        }
        options={
          typeof values === 'object'
            ? [...options].sort()
            : [...options].sort((firstValue, secondValue) =>
                displaySelectedFirst({
                  firstValue,
                  secondValue,
                  values,
                  options,
                })
              )
        }
        renderOption={(option, { selected }) => {
          const { name, email } = option;
          return (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '1rem' }}
              overflow="hidden"
              onClick={async () => {
                history.push(`/doctor/patient/${option.id}`);
                await updatePatient(
                  {
                    EditPatientInput: {
                      updatedAt: new Date(),
                      email: option?.email,
                      firstname: option?.firstname,
                      lastname: option?.lastname,
                    },
                    picture: undefined,
                    patientId: option?._id,
                  },
                  (e) => log('error', e)
                );
              }}
            >
              <Avatar src={option.picture}>{option.name[0]}</Avatar>
              <Box>
                {highlightSearchValue(name, searchValue, searchType.name)}
                {highlightSearchValue(email, searchValue, searchType.email)}
              </Box>
            </Box>
          );
        }}
        values={values}
        setValues={setValues}
      />
    </Box>
  );
}

export default SearchInput;
