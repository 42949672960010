import { Chip, ChipProps, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
export const color = {
  high: 'red',
  completed: 'rgb(45, 199, 109)',
  Done: 'rgb(45, 199, 109)',
  overdue: 'rgb(254, 68, 63)',
  'To do': 'rgb(254, 68, 63)',
  low: 'rgb(208, 213, 221)',
  default: 'rgb(247, 147, 30)',
};
const useStyles = makeStyles(() => ({
  chip: {
    color: 'black',
    fontSize: '14px',
    borderRadius: '5px',
    fontWeight: 500,
    backgroundColor: 'transparent',
    border: '1px solid #D0D5DD',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  pointChip: {
    backgroundColor: ({ priority }: IPriorityChipProps) =>
      color[priority] ?? color.default,
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '18px',
    fontFamily: "'Inter', sans-serif",
  },
}));

interface IPriorityChipProps extends ChipProps {
  priority: 'low' | string;
}
export default function PriorityChip({
  priority,
  className,
  ...props
}: IPriorityChipProps): JSX.Element {
  const classes = useStyles({ priority });
  const { t } = useTranslation();
  const label =
    priority === 'high'
      ? t('High')
      : priority === null
      ? t('Low')
      : t(priority);
  return (
    <Chip
      className={clsx(className, classes.chip)}
      label={
        <span className={classes.label}>
          <span className={classes.pointChip} />
          {label}
        </span>
      }
      {...props}
    />
  );
}
