import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
// import i18next from 'i18next';

dayjs.extend(utc);

// const Language = i18next.language || 'en'
const language = localStorage.getItem('i18nextLng') || 'en';
dayjs.locale(language === 'fr' ? 'fr' : 'en');

const removeTimeZoneFromDate = (dateString: string) => {
  if (dateString.endsWith('Z')) {
    return dateString;
  }
  // Extract everything before the timezone offset
  const dateWithoutTZ = dateString.replace(/([+-]\d{2}:\d{2})$/, '');
  // Append Z to treat it as UTC without conversion
  const dateStringWithoutTimeZone = dateWithoutTZ + 'Z';
  return dateStringWithoutTimeZone;
};

export function toDayjs(date: string | Date) {
  return dayjs(date).utc();
}

export function calculateAge(dateOnMS: string) {
  const age = dayjs().diff(new Date(parseInt(dateOnMS, 10)), 'year');
  return age === 0 || age == undefined || age == null || isNaN(age) ? '-' : age;
}
export function hourFormat(_isodate: string | Date): string {
  return dayjs(_isodate).utc().format('HH:mm A');
}

export function dayjsFormat(date: string | Date, format: string, isUtc = true) {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;

  return isUtc
    ? dayjs(_date).utc().format(format)
    : dayjs(_date).format(format);
}

export function newDate(date: string) {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  return dayjs(_date).utc().toDate();
}

export function getOffset(date: string | Date) {
  const _offset = dayjs(date).utcOffset();
  return _offset;
}

export function dateFormatDependancy(date: string, format: string) {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  if (format === '12-hour format') {
    return dayjs(_date).utc().format('h:mm A');
  }
  return dayjs(_date).utc().format('HH:mm');
}

export function convertMsToDate(dateOnMS: string) {
  const date = new Date(parseInt(dateOnMS, 10));
  const result = dayjs(date).utc().format('DD/MM/YYYY');
  return result;
}
export function convertMsToDateMonth(dateOnMS: string) {
  const date = new Date(parseInt(dateOnMS, 10));
  const result = dayjs(date).utc().format('MMMM YYYY');
  return result;
}

export function convertDateLikeMeet(dateNow: number) {
  const minutes = new Date(dateNow).getMinutes();
  if (minutes < 30) return new Date(new Date(dateNow).setMinutes(30));
  return new Date(
    new Date(new Date(dateNow).setMinutes(0)).setHours(
      new Date().getHours() + 1
    )
  );
}
export function stringToDate(date: string) {
  return new Date(parseInt(date, 10));
}

export function convertTime(
  dateWithOutTime: string,
  time: string,
  timeZone?: string,
  isUtc?: boolean
) {
  const [hours, minutes] = time.split(':').map((num) => parseInt(num, 10));

  let dateTime = dayjs(dateWithOutTime)
    .utc()
    .set('hour', hours)
    .set('minute', minutes);

  const convertedDate = dateTime.toDate().toISOString();

  return convertedDate;
}

export function convertDate(date: Date, timeZone?: string) {
  let _date = dayjs(date);

  const timeZoneFormatted = _date.utc().format('YYYY-MM-DD HH:mm:ss Z');
  return timeZoneFormatted;
}

export function formatDate(date: string): string {
  return dayjs(date).utc().format('DD/MM/YYYY');
}
export function formatDatesFullMonth(date: string): string {
  return dayjs(date).utc().format('MMMM DD, YYYY');
}
export function formatDateMessage(date: string): string {
  const now = dayjs();
  const inputDate = dayjs(date);
  const today = now.startOf('day');
  const yesterday = today.subtract(1, 'day');
  const diffDays = today.diff(inputDate.startOf('day'), 'day');

  if (inputDate.isSame(today, 'day')) {
    return dayjsFormat(new Date(date), 'HH:mm');
  } else if (inputDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else if (diffDays < 7) {
    return dayjsFormat(new Date(date), 'dddd');
  } else {
    return dayjsFormat(new Date(date), 'DD/MM/YY');
  }
}
export function fromNow(date: string): string {
  dayjs.extend(relativeTime);
  return dayjs(date).fromNow();
}

export function formatToFullDate(date: string): string {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  return dayjs(_date).utc().format('MMMM D, YYYY h:mm A');
}
export function formatDates(date: string): string {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  return dayjs(_date).utc().format('MMM DD, YYYY');
}
export function formatTime(date: string, doctor: { timeZone: string }): string {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  return dayjs(_date).utc().format('h:mm A');
}
export function formatFullDate(date: string): string {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  return dayjs(_date).utc().format('h:mm A');
}
export function formatToFullDateWithoutTime(date: string): string {
  const _date = typeof date === 'string' ? removeTimeZoneFromDate(date) : date;
  return dayjs(_date).utc().format('MMMM D, YYYY');
}

export function getHourAndMinutesFromDate(
  dateOnMS: string,
  isUtc = true
): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return isUtc
    ? dayjs(date)
        .utc()
        .format(language === 'fr' ? 'HH:mm' : 'h:mm A')
    : dayjs(date).format(language === 'fr' ? 'HH:mm' : 'h:mm A');
}
export function getFullTimeFromDate(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return dayjs(date).utc().format('h:mm:ss');
}
export function getMonthAndDayNumber(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return dayjs(date).utc().format('MMM D');
}
export function getDayLetter(dateOnMS: string): string {
  const date = new Date(parseInt(dateOnMS, 10));
  return dayjs(date).utc().format('dddd');
}

export function formatToMonthAndYear(dateOnMS: string): string {
  return dayjs(new Date(dateOnMS)).utc().format('MMMM YYYY');
}
export function formatMonthYear(dateOnMS: string): string {
  return dayjs(new Date(dateOnMS)).utc().format('MMMM, YYYY');
}
export function getTimeDate(
  _date: { startDate: string; endDate: string },
  isTwelveFormat = false
) {
  return isTwelveFormat
    ? [
        dayjsFormat(_date?.startDate, 'h:mm A'),
        dayjsFormat(_date?.endDate, 'h:mm A'),
      ]
    : [
        dayjsFormat(_date?.startDate, 'HH:mm'),
        dayjsFormat(_date?.endDate, 'HH:mm'),
      ];
}
export function addStart(_date: { startDate?: string; endDate?: string }) {
  return dayjsFormat(_date?.startDate, 'HH:mm');
}
export function sortDays(days) {
  const map = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };
  return days.sort((a, b) => {
    return map[a] - map[b];
  });
}

export function differenceBetweenTwoDatesByHour(
  date1: string,
  date2: string
): string {
  const diff = dayjs(date2).diff(dayjs(date1), 'hour', true);
  const diffHours = Math.floor(diff);
  const diffMinutes = Math.round((diff - diffHours) * 60);
  const hours = `${diffHours} hour${diffHours > 1 ? 's' : ''}`;
  const minutes = `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''}`;
  return `${diffHours ? hours : ''} ${diffMinutes ? minutes : ''}`;
}
export function convertdateNumberToMonth(date: string) {
  switch (date) {
    case '01':
      return 'Jan';
    case '02':
      return 'Feb';
    case '03':
      return 'Mar';
    case '04':
      return 'Apr';
    case '05':
      return 'May';
    case '06':
      return 'Jun';
    case '07':
      return 'Jul';
    case '08':
      return 'Aug';
    case '09':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dec';
    default:
      return date;
  }
}
export function convertdateNumberToWeek(date: string) {
  switch (date) {
    case '1':
      return 'Mon';
    case '2':
      return 'Tue';
    case '3':
      return 'Wed';
    case '4':
      return 'Thu';
    case '5':
      return 'Fri';
    case '6':
      return 'Sat';
    case '7':
      return 'Sun';
    default:
      return date;
  }
}
export function dateIsExpired(date: string | Date) {
  return dayjs(date).isBefore(dayjs());
}
export function getTimeDifference(inputString: string) {
  if (inputString.includes(' - ')) {
    const times = inputString.split(' - ');

    if (times.length !== 2) {
      console.log(
        'Invalid input format. Please use "start time - end time" format.'
      );
    }

    const startTime = times[0];
    const endTime = times[1];

    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const totalStartMinutes = startHours * 60 + startMinutes;
    const totalEndMinutes = endHours * 60 + endMinutes;

    const timeDifference = totalEndMinutes - totalStartMinutes;

    return timeDifference;
  }
  return inputString;
}
export function getDayName(dateString: number | string | Date) {
  const date = dayjs(dateString);
  return date.format('dddd').toLowerCase();
}

export function combineDates(date1: Date, date2: Date) {
  const year = date1.getFullYear();
  const month = date1.getMonth();
  const day = date1.getDate();
  const hours = date2.getHours();
  const minutes = date2.getMinutes();
  const seconds = date2.getSeconds();
  return new Date(year, month, day, hours, minutes, seconds);
}
export function generateTextFromAppointment(appointment) {
  if (!!!appointment.endOn && !!!appointment.occurrence) {
    return null;
  }

  let text = '';
  if (appointment.numberOfRepeat === 1) {
    if (appointment.repeatEvery === 'day') {
      text = 'Daily';
    } else if (appointment.repeatEvery === 'week') {
      text = 'Weekly';
    } else if (appointment.repeatEvery === 'month') {
      text = 'Monthly';
    }
  } else {
    text =
      'Every ' +
      appointment.numberOfRepeat +
      ' ' +
      appointment.repeatEvery +
      's';
  }
  if (
    appointment.repeatOn &&
    appointment.repeatOn.length === 7 &&
    appointment.repeatEvery !== 'day'
  ) {
    text += ' Daily';
  } else if (appointment.repeatOn.length == 1) {
    text += ` on ${
      appointment.repeatOn[0].charAt(0).toUpperCase() +
      appointment.repeatOn[0].slice(1)
    } `;
  } else if (
    appointment.repeatOn &&
    appointment.repeatOn.length > 1 &&
    appointment.repeatEvery !== 'day'
  ) {
    text += ` on ${appointment.repeatOn
      .map((day) => day.charAt(0).toUpperCase() + day.slice(1))
      .slice(0, -1)
      .join(', ')} and ${
      appointment.repeatOn.slice(-1)[0].charAt(0).toUpperCase() +
      appointment.repeatOn.slice(-1)[0].slice(1)
    }`;
  }

  if (appointment.occurence) {
    text += ', until ' + appointment.occurence + ' occurrences';
  } else if (appointment.endOn) {
    text += ', until ' + dayjs(appointment.endOn).format('MMMM DD, YYYY');
  }
  return text;
}

export function transformArrayTimeZone(appointments, timeZone) {
  return appointments?.map((appointment) => {
    return {
      ...appointment,
      startDate: dayjs(
        dayjs(appointment?.startDate).format('YYYY-MM-DDTHH:mm:ss')
      )
        .toDate()
        .getTime(),
      endDate: dayjs(dayjs(appointment?.endDate).format('YYYY-MM-DDTHH:mm:ss'))
        .toDate()
        .getTime(),
    };
  });
}
export function transformObjectTimeZone(appointment, timeZone) {
  return {
    ...appointment,
    startDate: newDate(
      dayjsFormat(appointment?.startDate, 'YYYY-MM-DDTHH:mm:ss')
    ).getTime(),
    endDate: newDate(
      dayjsFormat(appointment?.endDate, 'YYYY-MM-DDTHH:mm:ss')
    ).getTime(),
  };
}
export function convertObjTimes(obj: any) {
  return {
    ...obj,
    startDate: dayjsFormat(obj.startDate, 'YYYY-MM-DDTHH:mm:ss'),
    endDate: dayjsFormat(obj.endDate, 'YYYY-MM-DDTHH:mm:ss'),
  };
}
export const formatInboxDate = (
  date: Date | null | undefined,
  americanFormat: boolean = false
) => {
  const currentDate = dayjs();
  const inputDate = dayjs(date);

  if (inputDate.isSame(currentDate, 'day')) {
    return dayjsFormat(new Date(date), 'HH:mm');
  }

  if (inputDate.isSame(currentDate, 'week')) {
    return dayjsFormat(new Date(date), 'dddd');
  }

  return !americanFormat
    ? dayjsFormat(new Date(date), 'D/M/YY')
    : dayjsFormat(new Date(date), 'M/D/YY');
};
