import React from 'react';
import { createRoot } from 'react-dom/client';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
  edit: boolean;
}

export default class Address {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private root: any | null;

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 12.5C13.6569 12.5 15 11.1569 15 9.5C15 7.84315 13.6569 6.5 12 6.5C10.3431 6.5 9 7.84315 9 9.5C9 11.1569 10.3431 12.5 12 12.5Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 22C14 18 20 15.4183 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 15.4183 10 18 12 22Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      title: 'Address',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? false;
    this.root = null; // Store the root for React 18 rendering

    const defaultQuestion = {
      question: 'Your address',
      type: 'Address',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      const updatedQuestions = newQuestionsOrUpdater(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };

  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };

  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };

  private updateDom() {
    if (this.nodes.holder) {
      if (!this.root) {
        this.root = createRoot(this.nodes.holder);
      }
      this.root.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          changeQuestion={this.changeQuestion.bind(this)}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />
      );
    }
  }

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    this.root = createRoot(rootNode);
    this.root.render(
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion.bind(this)}
        question={this.data.question}
        isNotes={false}
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }

  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
