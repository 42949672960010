import { useQuery, gql } from '@apollo/client';

export default function useGetInsurances(patient) {
  const { data, loading, error } = useQuery(GET_INSURANCES, {
    variables: {
      patient,
    },
  });

  return { data, loading, error };
}

export const GET_INSURANCES = gql`
  query patientInsurance($patient: ID) {
    patientInsurance(patient: $patient) {
      _id
      patient {
        firstname
        lastname
      }
      frontPicture
      backPicture
      relationShipType
      firstname
      middlename
      lastname
      sex
      birthday
      phone
      address
      city
      state
      zipCode
      country
      insuranceType
      payer {
        _id
        payer_name
        payerid
      }
      memberID
      groupID
      planID
      copay
      deductible
      startDate
      endDate
    }
  }
`;
