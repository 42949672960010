import { Box, Grid } from '@material-ui/core';
import React from 'react';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import CptComponent from 'pages/AdminPage/Payment/cptComponent';
import { useForm } from 'react-hook-form';
import EditButtons from 'components/EditButtons';

const NewPrescription = ({ handleExpand }) => {
  const { register, control, setValue, errors, handleSubmit } = useForm();
  const commonProps = { register, control, errors };
  function submit(data) {}
  return (
    <Box px="2rem" mt={2}>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <CptComponent
              control={control}
              options={[]}
              name="PrescribedName"
              label="Prescriber name"
              setValue={setValue}
              errors={errors}
              loading={false}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Prescribed location'}
              {...commonProps}
              name="location"
              type="select"
              options={['']}
            />{' '}
          </Grid>
        </Grid>
        <CptComponent
          control={control}
          options={[]}
          name="drugName"
          label="Drug name"
          setValue={setValue}
          errors={errors}
          loading={false}
        />
        <InputBlock
          label={'Directions'}
          {...commonProps}
          name="directions"
          type="text"
        />
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Quantity'}
              {...commonProps}
              name="quantity"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Unit'}
              {...commonProps}
              name="unit"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Refill'}
              {...commonProps}
              name="unit"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Days of supply'}
              {...commonProps}
              name="unit"
              type="text"
            />
          </Grid>
        </Grid>
        <InputBlock
          label={'Effective on'}
          {...commonProps}
          name="EffectiveOn"
          type="date"
        />
        <InputBlock
          label={'Problem'}
          {...commonProps}
          name="problem"
          type="select"
          options={['']}
        />
        <InputBlock
          label={'Note to pharmacy'}
          {...commonProps}
          name="note"
          type="area"
        />
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          position="absolute"
          bottom={60}
          right={3}
          borderTop={'0.5px solid #EAECF0'}
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <EditButtons
            editable
            isPatient={false}
            type="submit"
            loading={false}
            handleOpen={(e) => {
              e.preventDefault();
              handleExpand(false);
            }}
            submitText={'Save'}
            editMode={false}
            handleDelete={null}
            px={'2rem'}
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
};

export default NewPrescription;
