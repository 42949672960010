import { gql, useMutation } from '@apollo/client';
import { GET_FINANCES } from './useGetFinances';

export const CREATE_CLAIM = gql`
  mutation createClaim($claimInput: ClaimInput) {
    createClaim(claimInput: $claimInput) {
      _id
    }
  }
`;

export const UPDATE_CLAIM = gql`
  mutation updateClaim($id: ID, $claimInput: ClaimInput) {
    updateClaim(id: $id, claimInput: $claimInput) {
      _id
    }
  }
`;
export const SUBMIT_CLAIM = gql`
  mutation submitClaim($claim: [claimSubmittion]) {
    submitClaim(claim: $claim)
  }
`;
export const DELETE_CLAIM = gql`
  mutation deleteClaim($id: ID) {
    deleteClaim(id: $id)
  }
`;

export function useUpdateClaim() {
  const [updateClaim, { data, error, loading }] = useMutation(UPDATE_CLAIM);

  return { updateClaim, data, error, loading };
}
export function useDeleteClaim() {
  const [deleteClaim, { data, error, loading }] = useMutation(DELETE_CLAIM, {
    refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL],
  });

  return { deleteClaim, data, error, loading };
}
export function useSubmitClaim() {
  const [submitClaim, { data, error, loading }] = useMutation(SUBMIT_CLAIM, {
    refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL],
  });

  return { submitClaim, data, error, loading };
}

export default function useCreateClaim() {
  const [createClaim, { data, error, loading }] = useMutation(CREATE_CLAIM, {
    refetchQueries: [GET_FINANCES, SESSION_BY_PERIOD_BILL],
  });

  return { createClaim, data, error, loading };
}

export const SESSION_BY_PERIOD_BILL = gql`
  query sessionsByPeriodBills($patient: ID, $startDate: Date, $endDate: Date) {
    sessionsByPeriodBills(
      patient: $patient
      startDate: $startDate
      endDate: $endDate
    ) {
      _id
      startDate
      endDate
      locationLink
      sessionType {
        session
        rate
        duration
        currency
        color
        location
        isPrepayment
      }
      isPaid
    }
  }
`;
