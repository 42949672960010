import { gql, useMutation } from '@apollo/client';

const REMOVE_UNREAD_MESSAGES = gql`
  mutation removeUnreadMessages($threadConversationID: ID) {
    removeUnreadMessages(threadConversationID: $threadConversationID)
  }
`;

const useRemoveUnreadThreadMessages = () => {
  const [removeUnreadMessages] = useMutation(REMOVE_UNREAD_MESSAGES);

  async function handleRemoveUnreadMessages(threadId: string) {
    try {
      return await removeUnreadMessages({
        variables: { threadConversationID: threadId },
      });
    } catch (e) {
      return e;
    }
  }

  return handleRemoveUnreadMessages;
};

export { REMOVE_UNREAD_MESSAGES };
export default useRemoveUnreadThreadMessages;
