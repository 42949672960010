import { Box, Grid } from '@material-ui/core';
import CptComponent from 'pages/AdminPage/Payment/cptComponent';
import React from 'react';
import { useForm } from 'react-hook-form';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import EditButtons from 'components/EditButtons';

const AddVitalsModal = ({ handleExpand }) => {
  const { register, control, setValue, errors, handleSubmit } = useForm();
  const commonProps = { register, control, errors };
  function submit(data) {}
  return (
    <Box px="2rem" mt={2} overflow="scroll" maxHeight={'85vh'}>
      <form onSubmit={handleSubmit(submit)}>
        <InputBlock
          label={'Temperature (°F)'}
          {...commonProps}
          name="temperature"
          type="number"
        />
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Blood Pressure ( mmHg )'}
              {...commonProps}
              name="blood_pressure"
              type="number"
              placeholder="Systolic"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={''}
              {...commonProps}
              name="blood_pressure_2"
              type="number"
              placeholder="Diastolic"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Height ( ft/I )'}
              {...commonProps}
              name="height_1"
              type="number"
              placeholder="Feet"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={''}
              {...commonProps}
              name="height_2"
              type="number"
              placeholder="Inches"
            />
          </Grid>
        </Grid>
        <InputBlock
          label={'Weight (lbs)'}
          {...commonProps}
          name="weight"
          type="number"
        />
        <InputBlock
          label={'BMI (kg/m²)'}
          {...commonProps}
          name="bmi"
          type="number"
        />
        <InputBlock
          label={'Head Circumference (cm)'}
          {...commonProps}
          name="head_circumference"
          type="number"
        />
        <InputBlock
          label={`Waist Circumference (cm)`}
          {...commonProps}
          name="weight_circumference"
          type="number"
        />
        <InputBlock
          label={`Heart Rate (BPM)`}
          {...commonProps}
          name="heart_rate"
          type="number"
        />
        <InputBlock
          label={`Respiratory Rate (BPM)`}
          {...commonProps}
          name="respiratory_rate"
          type="number"
        />
        <InputBlock
          label={`Oxygen Saturation (SpO2)`}
          {...commonProps}
          name="oxygen_saturation"
          type="number"
        />
        <InputBlock
          label={`Pain Scale (0-10)`}
          {...commonProps}
          name="pain_scale"
          type="number"
        />
        <InputBlock
          label={`Peak Flow Measurement (L/min)`}
          {...commonProps}
          name="peak_flow"
          type="number"
        />
        <InputBlock
          label={`Blood Glucose Level (mg/dL)`}
          {...commonProps}
          name="blood_glucose"
          type="number"
        />
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          position="absolute"
          bottom={60}
          right={3}
          borderTop={'0.5px solid #EAECF0'}
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <EditButtons
            editable
            isPatient={false}
            type="submit"
            loading={false}
            handleOpen={(e) => {
              e.preventDefault();
              handleExpand(false);
            }}
            submitText={'Save'}
            editMode={false}
            handleDelete={null}
            px={'2rem'}
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddVitalsModal;
