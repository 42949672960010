import {
  Box,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Checkbox,
} from '@material-ui/core';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { IsDefaultComponent } from 'pages/AdminPage/Payment/IsDefaultComponent';
import { convertObjectsFormat } from 'pages/AdminPage/Payment/RatesModal';
import CptComponent from 'pages/AdminPage/Payment/cptComponent';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import { gql, useQuery } from '@apollo/client';
import Text from 'Text';

export const DIAGNOSIS_CODES = gql`
  query diagnosisCodes {
    diagnosisCodes {
      code
      description
    }
  }
`;
const useStyles = makeStyles<Theme>((theme) => ({
  draftNotes: {
    height: theme.typography.pxToRem(410),
    minHeight: theme.typography.pxToRem(410),
  },
}));

const ConditionModal = () => {
  const classes = useStyles();
  const { control, register, errors, watch, setValue } = useFormContext();
  const commonProps = { control, register, errors };
  const { data: diagnosisCode, loading } = useQuery(DIAGNOSIS_CODES);
  return (
    <Box px={'2.1rem'}>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} md={3}>
          <CptComponent
            control={control}
            name="diagnosisCode"
            loading={loading}
            options={convertObjectsFormat(diagnosisCode?.diagnosisCodes)}
            label="Diagnosis Code"
            isLimited
            setValue={setValue}
            errors={errors}
          />
        </Grid>
        <Grid item xl={9} lg={9} md={9}>
          <InputBlock
            label={'Description'}
            {...commonProps}
            name="description"
            type="text"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Started on'}
            {...commonProps}
            name="dateAdded"
            type="date"
            isAddedComponent={
              <Box display="flex" alignItems="center">
                <Controller
                  name={'type'}
                  control={control}
                  render={({ onChange, value, ref }) => {
                    return (
                      <Checkbox
                        size="medium"
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                        }}
                        checkedIcon={<CheckedIcon width={16} height={16} />}
                        icon={<UncheckedIcon width={16} height={16} />}
                        style={{ backgroundColor: 'transparent' }}
                      />
                    );
                  }}
                />
                <Text i18nKey="Current">Current</Text>
              </Box>
            }
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <Box
            style={{
              opacity: watch()?.type || watch()?.type === 'Current' ? 0.5 : 1,
            }}
            mt={0.65}
          >
            <InputBlock
              label={'Ended on'}
              {...commonProps}
              name="dateEnded"
              type="date"
              disabled={watch()?.type}
            />
          </Box>
        </Grid>
        <Grid
          container
          xl={12}
          lg={12}
          style={{ gap: '15px', marginInline: '0.5rem' }}
        >
          <IsDefaultComponent
            title={'Set as primary diagnosis'}
            description={
              'The primary diagnosis that will be used for processing insurance claims'
            }
            name="isPrimary"
            {...commonProps}
            type={'switch'}
            // isDefault={currentRow?.isDefault}
          />

          <IsDefaultComponent
            title={'Display on profile'}
            description={
              'This diagnosis will be displayed on your client’s profile'
            }
            name="isDisplayed"
            {...commonProps}
            type={'switch'}
            // isDefault={currentRow?.isDefault}
          />
        </Grid>
        <Grid item xl={12} lg={12}>
          <InputBlock
            label={'Notes'}
            {...commonProps}
            name="treatment"
            type="draftjs"
            isBigger={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConditionModal;
