import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './index';
import ClaimTableHeader from './ClaimTableHeader';
import ClaimTableCell from './ClaimTableCell';

const ClaimTableDetails = ({ commonProps, sessionLength }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      boxSizing="border-box"
      width="100%"
      px={1}
      style={{
        overflowX: 'auto',
      }}
    >
      <Box
        boxSizing="border-box"
        width="100%"
        borderLeft="1px solid #D0D5DD"
        borderTop="1px solid #D0D5DD"
      >
        <ClaimTableHeader />
        {Array.from({ length: sessionLength }).map((_, index) => (
          <ClaimTableCell commonProps={commonProps} index={index + 1} />
        ))}
      </Box>
    </Box>
  );
};

export default ClaimTableDetails;
