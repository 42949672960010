import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import { TranslationKeys } from 'shared/interfaces/i18';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>((theme) => ({
  text: {
    fontFamily: 'Inter, sans-serif',
  },
}));

const Text = ({
  i18nKey,
  count = null,
  children,
  className,
  style,
  onClick,
}: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const label =
    t(`${i18nKey as TranslationKeys}`) === i18nKey
      ? children
      : t(`${i18nKey as TranslationKeys}`);
  return (
    <Typography
      className={clsx(classes.text, className)}
      style={{ ...style, textTransform: 'none' }}
      onClick={onClick}
    >
      {label}
    </Typography>
  );
};

export default Text;
