import React, { ReactElement } from 'react';
import {
  Grid,
  Box,
  FormControl,
  Typography,
  Checkbox,
} from '@material-ui/core';
import ScrollBar from 'components/ui/ScrollBar';
import InputBlock from '../../StaffProfile/editModalTabs/InputBlock';
import { IDoctor } from '../PersonalInformation';
import { Controller, FieldErrors, Control, Ref } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import useStyles from '../styles';
import Text from 'Text';

interface IAddressForm {
  register?: (ref?: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  doctor: IDoctor;
  error?: string;
}

const Address = ({
  register,
  control,
  errors,
  doctor,
  error,
}: IAddressForm): ReactElement => {
  const classes = useStyles({ isPatient: false });
  const defaultValues = { label: doctor?.country };
  const commonProps = { register, control, errors };
  const { showPhone, showAdress, showEmail } = doctor || {};
  return (
    <ScrollBar sx={{ flex: 1 }}>
      <Box style={{ paddingInline: '2rem', boxSizing: 'border-box' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text i18nKey="phoneNumber" className={classes.label}>
                  Phone number
                </Text>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    inputRef={register}
                    name="showPhone"
                    size="medium"
                    defaultChecked={showPhone}
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                    disabled
                  />
                  <Text i18nKey="visibleOnProfile" className={classes.label}>
                    Visible on profile
                  </Text>
                </Box>
              </Box>
              <InputBlock name="phone" type="phone" {...commonProps} />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text i18nKey="email" className={classes.label}>
                  Email
                </Text>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Checkbox
                    inputRef={register}
                    name="showEmail"
                    size="medium"
                    defaultChecked={showEmail}
                    checkedIcon={<CheckedIcon />}
                    icon={<UncheckedIcon />}
                    disabled
                  />
                  <Text i18nKey="visibleOnProfile" className={classes.label}>
                    Visible on profile
                  </Text>
                </Box>
              </Box>
              <InputBlock
                name="email"
                error={error}
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt="2rem"
              width="100%"
            >
              <Text i18nKey="address" className={classes.experienceTitle}>
                Address
              </Text>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Checkbox
                  inputRef={register}
                  name="showAdress"
                  size="medium"
                  defaultChecked={showAdress}
                  checkedIcon={<CheckedIcon />}
                  icon={<UncheckedIcon />}
                  disabled
                />
                <Text i18nKey="visibleOnProfile" className={classes.label}>
                  Visible on profile
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%' }}>
              <InputBlock
                name="country"
                label="Country"
                type="country"
                defaultValue={defaultValues}
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Street"
                name="address"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="Apt, suite (optional)"
                name="suite"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="City"
                name="city"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginTop: '25px' }}>
              <InputBlock
                label="State"
                name="state"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
            <FormControl style={{ width: '100%', marginBlock: '25px' }}>
              <InputBlock
                label="Zip code"
                name="zipcode"
                type="text"
                {...commonProps}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </ScrollBar>
  );
};
export default Address;
