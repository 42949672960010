import React from 'react';
import { ReactComponent as DuplicateIcon } from 'assets/templateSettings/duplicateIcon.svg';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Input from 'components/ui/Inputs';
import useCreateDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreateDoctorTemplate';
import { DOCTOR_TEMPLATES } from 'pages/PatientActivities/Questionaires/Questionaires';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useSnackbar } from 'hooks/useSnackbar';
import Text from 'Text';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    paddingInline: '2rem',
    boxSizing: 'border-box',
    marginTop: '-0.5rem',
  },
  container: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: '1rem',
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1rem',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    textAlign: 'left',
    color: '#101828',
  },
  body: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#475467',
  },
  buttonBox: {
    height: '4.25rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: '2rem',
  },
  button: {
    height: theme.typography.pxToRem(44),
    paddingInline: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(8),
    textTransform: 'none',
  },
  cancelButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#344054',
    backgroundColor: '#FFFFFF',
    marginRight: '1rem',
    border: '1px solid #D0D5DD',
    '&:hover': {
      backgroundColor: '#F7F8FA',
    },
  },
  saveButton: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    color: '#FFFFFF',
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    '&:hover': {
      backgroundColor: '#0154CB',
    },
  },
}));

function OngoingSessionModal({
  row,
  handleClose,
}: {
  row: any;
  handleClose: () => void;
}) {
  const [handleCreateDoctorTemplate, { loading }] = useCreateDoctorTemplate();
  const classes = useStyles();
  const [newTitle, setNewTitle] = React.useState(row?.title + '(1)');
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();
  async function handleSave() {
    const currentRow = removeTypename(row);
    const { _id, ...templates } = currentRow || {};
    await handleCreateDoctorTemplate({
      variables: {
        doctorTemplateInput: {
          ...templates,
          title: newTitle,
          doctor: templates?.doctor?._id,
          tags:
            templates?.tags?.length > 0
              ? templates?.tags?.map((el) => el?._id)
              : [],
        },
      },
      refetchQueries: [DOCTOR_TEMPLATES, DOCTOR_TEMPLATES_BY_FORM],
    });
    triggerSnack('Template successfully duplicated');
    handleClose();
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <DuplicateIcon />
        <Box className={classes.textBox}>
          <Text i18nKey="Duplicate template" className={classes.title}>
            Duplicate template
          </Text>
        </Box>
      </Box>
      <Input
        type="text"
        variant="filled"
        value={newTitle}
        onChange={(e) => setNewTitle(e.target.value)}
      />
      <Box className={classes.buttonBox}>
        <Button
          className={clsx(classes.button, classes.cancelButton)}
          onClick={handleClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          className={clsx(classes.button, classes.saveButton)}
          onClick={() => handleSave()}
        >
          {loading ? <ProgressLoader width={20} height={20} /> : t('Save')}
        </Button>
      </Box>
    </Box>
  );
}

export default OngoingSessionModal;
export function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  } else if (typeof obj === 'object' && obj !== null) {
    const { __typename, ...rest } = obj; // remove __typename from current level
    return Object.keys(rest).reduce((acc, key) => {
      acc[key] = removeTypename(rest[key]); // recursively apply to nested objects
      return acc;
    }, {});
  }
  return obj; // return primitive values as is
}
