import { Box } from '@material-ui/core';
import CustomRadioGroup from 'pages/DoctorFinancePage/claim/CustomRadioGroup';
import React from 'react';
import { useForm } from 'react-hook-form';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import CptComponent from 'pages/AdminPage/Payment/cptComponent';
import EditButtons from 'components/EditButtons';

const LabTab = (props) => {
  const { register, control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {
      type: 0,
    },
  });
  const commonProps = { register, control, errors };
  function onSubmit() {}
  return (
    <Box px="2rem" mt={2}>
      <form onSubmit={handleSubmit(onsubmit)}>
        <Box display="flex" alignItems="center">
          <CustomRadioGroup
            name={'type'}
            control={commonProps.control}
            options={[
              { name: 'Electronic', value: 0 },
              { name: 'Manual', value: 1 },
            ]}
            ariaLabel={'type'}
            style={{ gap: '5px' }}
          />
        </Box>
        <Box mt={1}>
          <InputBlock
            label={'Ordering Provider'}
            {...commonProps}
            name="orderingProvider"
            type="select"
            options={['']}
          />
          <InputBlock
            label={'Service Location'}
            {...commonProps}
            name="serviceLocation"
            type="select"
            options={['']}
          />
          <InputBlock
            label={'Select Lab Facility'}
            {...commonProps}
            name="labFacility"
            type="select"
            options={['']}
          />
          <CptComponent
            control={control}
            options={[]}
            name="labOrder"
            label="Lab Order"
            setValue={setValue}
            errors={errors}
            loading={false}
          />
          <InputBlock
            label={'Diagnosis'}
            {...commonProps}
            name="diagnosis"
            type="select"
            options={['']}
          />
          <InputBlock
            label={'Select Encounter'}
            {...commonProps}
            name="encounter"
            type="select"
            options={['']}
          />
          <CptComponent
            control={control}
            options={[]}
            name="assignTo"
            label="Assign to"
            setValue={setValue}
            errors={errors}
            loading={false}
          />
          <InputBlock
            label={'Comment'}
            {...commonProps}
            name="comment"
            type="area"
          />
        </Box>
        <Box
          height="76px"
          display="flex"
          alignItems="center"
          width="100%"
          position="absolute"
          bottom={60}
          right={3}
          borderTop={'0.5px solid #EAECF0'}
          style={{
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
          }}
        >
          <EditButtons
            editable
            isPatient={false}
            type="submit"
            loading={false}
            handleOpen={(e) => {
              e.preventDefault();
              props.handleExpand(false);
            }}
            submitText={'Save'}
            editMode={false}
            handleDelete={null}
            px={'2rem'}
            isSlide
          />
        </Box>
      </form>
    </Box>
  );
};

export default LabTab;
