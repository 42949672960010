import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { useStyles } from './index';

const ClaimTableCell = ({ commonProps, index }) => {
  const classes = useStyles();

  return (
    <Box boxSizing="border-box" width="100%" borderBottom="1px solid #D0D5DD">
      <Box
        display="flex"
        flexWrap="no-wrap"
        boxSizing="border-box"
        width="100%"
      >
        <Box
          style={{ flexBasis: '15%', minWidth: '290px', gap: '5px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          boxSizing="border-box"
          className={classes.cell}
        >
          <Box width="100%" flex={1}>
            <InputBlock
              label={''}
              {...commonProps}
              name={`fromdate${index + 1}`}
              type="date"
            />
          </Box>
          <Box width="100%" flex={1}>
            <InputBlock
              label={''}
              {...commonProps}
              name={`todate${index + 1}`}
              type="date"
            />
          </Box>
        </Box>
        <Box
          style={{
            flexBasis: '7%',
            minWidth: '90px',
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.cell}
        >
          <Box width="100%">
            <InputBlock
              label={''}
              {...commonProps}
              name={`place${index + 1}`}
              type="select"
              options={['']}
            />
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '5%', minWidth: '65px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.cell}
        >
          <InputBlock
            label={''}
            {...commonProps}
            name={`EMG${index + 1}`}
            type="checkbox"
            defaultValue={false}
          />
        </Box>
        <Box
          style={{ flexBasis: '24%', minWidth: '345px' }}
          display="flex"
          flexDirection="column"
          className={classes.cell}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            boxSizing="border-box"
            style={{ gap: '5px' }}
            mt={1}
          >
            <Box
              display="flex"
              alignItems="stretch"
              justifyContent="center"
              style={{ gap: '5px' }}
              mt={1}
            >
              <Box
                width="100%"
                minWidth="56.5%"
                flex={1}
                px={1}
                boxSizing="border-box"
              >
                <Box width="100%" flex={1} boxSizing="border-box">
                  <InputBlock
                    label="Procedure code"
                    {...commonProps}
                    name={`procedureCode${index + 1}`}
                    type="text"
                  />
                  <InputBlock
                    label="Description"
                    {...commonProps}
                    name={`description${index + 1}`}
                    type="text"
                  />
                </Box>
              </Box>

              <Box
                width="100%"
                flex={1}
                boxSizing="border-box"
                overflow="hidden"
                px={1}
              >
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mt="20px"
                >
                  <Box width="24%" overflow="hidden">
                    <InputBlock
                      {...commonProps}
                      name={`modifier-${index + 1}-1`}
                      type="text"
                      inputProps={{
                        style: {
                          paddingInline: '4px',
                        },
                        maxLength: 2,
                      }}
                    />
                  </Box>
                  <Box width="24%">
                    <InputBlock
                      {...commonProps}
                      name={`modifier-${index + 1}-2`}
                      type="text"
                    />
                  </Box>
                  <Box width="24%">
                    <InputBlock
                      {...commonProps}
                      name={`modifier-${index + 1}-3`}
                      type="text"
                    />
                  </Box>
                  <Box width="24%">
                    <InputBlock
                      {...commonProps}
                      name={`modifier-${index + 1}-4`}
                      type="text"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '12.5%', minWidth: '12.5%' }}
          className={classes.cell}
        >
          <Box
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Box display="flex" alignItems="center" flexWrap={'no-wrap'}>
              <InputBlock
                {...commonProps}
                name={`Diagnosis-A-${index + 1}`}
                type="checkbox"
                defaultValue={false}
              />
              <Typography className={classes.checkboxText}>A</Typography>
            </Box>
            <Box display="flex" alignItems="center" flexWrap={'no-wrap'}>
              <InputBlock
                {...commonProps}
                name={`Diagnosis-B-${index + 1}`}
                type="checkbox"
                defaultValue={false}
              />
              <Typography className={classes.checkboxText}>B</Typography>
            </Box>
            <Box display="flex" alignItems="center" flexWrap={'no-wrap'}>
              <InputBlock
                {...commonProps}
                name={`Diagnosis-C-${index + 1}`}
                type="checkbox"
                defaultValue={false}
              />
              <Typography className={classes.checkboxText}>C</Typography>
            </Box>
            <Box display="flex" alignItems="center" flexWrap={'no-wrap'}>
              <InputBlock
                {...commonProps}
                name={`Diagnosis-D-${index + 1}`}
                type="checkbox"
                defaultValue={false}
              />
              <Typography className={classes.checkboxText}>D</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '6.2%', minWidth: '100px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.cell}
        >
          <InputBlock
            {...commonProps}
            name={`Charges${index + 1}`}
            type="number"
          />
        </Box>
        <Box
          style={{ flexBasis: '4.1%', minWidth: '70px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.cell}
        >
          <InputBlock
            {...commonProps}
            name={`units${index + 1}`}
            defaultValue={1}
            type="number"
          />
        </Box>
        <Box
          style={{ flexBasis: '4.9%', minWidth: '85px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.cell}
        >
          <InputBlock {...commonProps} name={`epsdt${index + 1}`} type="text" />
        </Box>
        <Box
          style={{ flexBasis: '20.7%', minWidth: '20.7%' }}
          className={classes.cell}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ gap: '1rem' }}
          >
            <Box width="100%" flex={1}>
              <InputBlock
                label={'Last name'}
                {...commonProps}
                name={`lastname${index + 1}`}
                type="text"
              />
            </Box>
            <Box width="100%" flex={1}>
              <InputBlock
                label={'First name'}
                {...commonProps}
                name={`firstname${index + 1}`}
                type="text"
              />
            </Box>
          </Box>
          <InputBlock
            label={'NPI'}
            {...commonProps}
            name={`npi${index + 1}`}
            type="text"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimTableCell;
