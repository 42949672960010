import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
    padding: '1rem',
    minHeight: '100px',
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4,0,0.2,1)',
    transitionDuration: '0.3s',
    '&:hover': {
      border: '1px solid #425364',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: '#494B5A',
    marginBottom: '0.25rem',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'blue',
  },
  circleRed: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'red',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#494B5A',
  },
  date: {
    fontSize: '14px',
    color: '#8E96A4',
  },
}));

const Allergies = () => {
  const classes = useStyles();
  const allergies = [
    {
      name: 'Cephalosporings',
      date: new Date(),
    },
    { name: 'Peanut oil', date: new Date() },
  ];
  return (
    <Box className={classes.root} style={{ cursor: 'not-allowed' }}>
      <Typography className={classes.title}>Allergies (2)</Typography>
      <Box mt={2}>
        {allergies?.map((medication) => (
          <Box
            display="flex"
            alignItems="center"
            style={{ gap: '10px' }}
            mb={2}
          >
            <Box className={classes.circleRed} />
            <Typography className={classes.text}>{medication?.name}</Typography>
            <Typography className={classes.date}>
              {dayjs(medication?.date).format('MM-DD-YYYY')}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Allergies;
