import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dayjs from 'dayjs';
import { FormInput } from './FormInput';
import { makeStyles } from '@material-ui/core';
import { Controller, Control } from 'react-hook-form';
import { dayjsFormat } from 'utils';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',
    '& div.react-datepicker-wrapper': {
      width: '100%',
      zIndex: 1000000000000000,
    },
    '& .react-datepicker-popper': {
      zIndex: 50000000000000,
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: '#39C38D',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__header': {
      backgroundColor: '#ffffff',
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      color: '#425364',
      fontWeight: 500,
    },
    '& .react-datepicker__day--outside-month': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__day[aria-disabled="true"]': {
      color: '#949494',
      fontWeight: 500,
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white !important',
    },
    '& .react-datepicker__day--range-end': {
      color: '#425364 !important',
      fontWeight: 'bold',
      borderRadius: '30px',
    },
    '& .react-datepicker__day--in-range': {
      backgroundColor: '#082CAF !important',
      color: 'rgba(255,255,255,0.8) !important',
      borderRadius: '30px',
    },
  },
  error: {
    color: 'red',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.03333em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  nextButton: {
    border: '1px solid #DEE2EC',
    color: '#9CB2CD',
    backgroundColor: 'white',
    width: '20.7px',
    height: '20.7px',
    fontSize: '16px',
    borderRadius: '10px',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function CustomDateRange({
  name,
  control,
  defaultValue,
  ...props
}: any): JSX.Element {
  const dateFormat = 'DD/MM/YYYY';
  const classes = useStyles();
  const getDateValue = (value: string[]) => {
    if (value && value[0] && value[1]) {
      if (
        dayjsFormat(value[0], dateFormat) !== dayjsFormat(value[1], dateFormat)
      ) {
        return `${dayjsFormat(value[0], dateFormat)} - ${dayjsFormat(
          value[1],
          dateFormat
        )}`;
      } else {
        return `${dayjsFormat(value[0], dateFormat)}`;
      }
    } else {
      return `${dayjsFormat(value[0], dateFormat)}`;
    }
  };
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      {...props}
      render={({ onChange, onBlur, value }) => {
        let date = getDateValue(value);
        return (
          <div className={classes.datePicker}>
            <DatePicker
              onBlur={onBlur}
              startDate={value && value[0]}
              endDate={value && value[1]}
              selectsRange
              closeOnScroll
              selected={value && value[0]}
              onChange={onChange}
              shouldCloseOnSelect={false}
              dateFormat={date}
              customInput={
                <FormInput
                  fullWidth
                  type="text"
                  data-cy="date-input"
                  variant="filled"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              }
            />
          </div>
        );
      }}
    />
  );
}

export default CustomDateRange;
