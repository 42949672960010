import { Box } from '@material-ui/core';
import React from 'react';
import Medications from './Medications';
import Vitals from './Vitals';
import LabsImaging from './LabsImaging';

const LeftSheet = (props) => {
  return (
    <Box display="flex" flexDirection="column" style={{ gap: '20px' }}>
      <Medications {...props} />
      <Vitals {...props} />
      <LabsImaging {...props} />
    </Box>
  );
};

export default LeftSheet;
