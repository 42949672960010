import React from 'react';
import SchedulePageProvider from 'pages/SchedulePage/state/SchedulePageProvider';
import PatientSchedule from './PatientSchedule';
import InboxPageProvider from 'pages/InboxPage/state/Provider';

const PatientAppointment = () => {
  return (
    <SchedulePageProvider>
      <InboxPageProvider isOnly={false}>
        <PatientSchedule />
      </InboxPageProvider>
    </SchedulePageProvider>
  );
};

export default PatientAppointment;
