import React from 'react';
import { createRoot } from 'react-dom/client';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
  edit: boolean;
}

export default class LineaireScale {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private root: any | null;

  static get toolbox() {
    return {
      icon: `<svg width="21" height="6" viewBox="0 0 21 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66667 5.66732C1.19391 5.66732 0 4.47341 0 3.00065C0 1.52789 1.19391 0.333984 2.66667 0.333984C3.96853 0.333984 5.0525 1.26689 5.28653 2.50065H15.0468C15.2808 1.26689 16.3648 0.333984 17.6667 0.333984C19.1394 0.333984 20.3333 1.52789 20.3333 3.00065C20.3333 4.47341 19.1394 5.66732 17.6667 5.66732C16.3648 5.66732 15.2808 4.73442 15.0468 3.50065H5.28653C5.0525 4.73442 3.96853 5.66732 2.66667 5.66732Z" fill="#475467"/>
      <path d="M12.666 3C12.666 4.38071 11.5467 5.5 10.166 5.5C8.7853 5.5 7.66602 4.38071 7.66602 3C7.66602 1.61929 8.7853 0.5 10.166 0.5C11.5467 0.5 12.666 1.61929 12.666 3Z" fill="#475467"/>
      </svg>                 
      `,
      title: 'Lineaire scale',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? false;
    this.root = null;

    const defaultQuestion = {
      question: 'Custom question',
      type: 'Linear scale',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [0, 5],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };
  private updateDom() {
    if (this.nodes.holder) {
      if (!this.root) {
        this.root = createRoot(this.nodes.holder);
      }
      this.root.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          changeQuestion={this.changeQuestion.bind(this)}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />
      );
    }
  }
  // private setQuestionssss = (
  //   updateFn: (questions: Array<any>) => Array<any>
  // ) => {
  //   // Check if the input is a function
  //   if (typeof updateFn === 'function') {
  //     // Call the function with the current questions to get the updated questions
  //     const updatedQuestions = updateFn(this.data.questions);
  //     console.log(updatedQuestions, 'updatedQuestions');
  //     // Update the data with the new questions array
  //     this.data = {
  //       ...this.data,
  //       questions: updatedQuestions,
  //     };

  //     console.log(updatedQuestions, 'updated questions');
  //   } else {
  //     console.error('Expected a function that returns an array of questions');
  //   }
  // };

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    this.root = createRoot(rootNode);
    this.root.render(
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion.bind(this)}
        question={this.data.question}
        isNotes={false}
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
