import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { ReactComponent as Left } from 'assets/Schedule/new/left-icon.svg';
import { ReactComponent as Right } from 'assets/Schedule/new/right-icon.svg';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  Box,
  IconButton,
  makeStyles,
  Typography,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button';
import { useQuery } from '@apollo/client';
import useHighlitedTimes from '@eggmed/graphql-client/operations/appointmentsOperations/useHighlitedTimes';
import ProgressLoader from 'components/ui/ProgressLoader';

Dayjs.extend(weekday);
Dayjs.extend(utc);
Dayjs.extend(timezone);
const useStyles = makeStyles<Theme>((theme) => ({
  datePicker: {
    position: 'relative',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
    '& .react-datepicker__day--highlighted-custom-1': {
      backgroundColor: ({ isSession }: { isSession: boolean }) =>
        isSession ? '#D1E9FF' : 'white',
      color: '#0265DC !important',
      fontWeight: ({ isSession }: { isSession: boolean }) => isSession && 700,
      borderRadius: ({ isSession }: { isSession: boolean }) =>
        isSession && '50%',
    },
    '& .react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted':
      {
        backgroundColor: ({ isSession }: { isSession: boolean }) =>
          isSession ? '#D1E9FF' : 'white',
        color: '#0265DC !important',
        borderRadius: '50%',
        fontWeight: ({ isSession }: { isSession: boolean }) => isSession && 700,
      },
    '& div.react-datepicker': {
      border: '0px solid white',
      width: '100%',
      backgroundColor: ({
        changeColor,
        isSession,
      }: {
        changeColor: boolean;
        isSession: boolean;
      }) => (changeColor ? '#082CAF' : isSession ? '#F2F4F7' : 'white'),
    },
    '& div.react-datepicker-wrapper': {
      width: '100%',
    },
    '& .react-datepicker__day--in-range': {
      backgroundColor: ({ color }: { color: string }) =>
        color ?? '#39C38D !important',
      color: 'rgba(255,255,255,0.8) !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day--range-start:hover, & .react-datepicker__day--range-end:hover, & .react-datepicker__day--in-range:hover, & .react-datepicker__day--in-selecting-range:hover':
      {
        backgroundColor: ({ color }: { color: string }) =>
          color ?? '#39C38D !important',
        borderRadius: '30px',
        cursor: 'pointer', // Add this to indicate the element is clickable
      },
    '& .react-datepicker__day--range-end': {
      color: 'white !important',
      fontWeight: 'bold',
    },
    '& .react-datepicker__day--in-selecting-range:hover': {
      color: 'rgba(255,255,255,0.8) !important',
    },
    '& .react-datepicker-popper': {},
    '& .react-datepicker__day--selected': {
      backgroundColor: ({ color }: { color: string }) =>
        color ?? '#39C38D !important',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      fontFamily: 'Inter, sans-serif',
      color: ({ isSession }: { isSession: boolean }) =>
        isSession ? '#4F4F4F' : '70757a',
      padding: '8px',
      [theme.breakpoints.only('lg')]: {
        padding: theme.typography.pxToRem(6),
      },
      [theme.breakpoints.only('md')]: {
        padding: theme.typography.pxToRem(2),
      },
    },
    '& .react-datepicker__header': {
      backgroundColor: ({
        changeColor,
        isSession,
      }: {
        changeColor: boolean;
        isSession: boolean;
      }) => (changeColor ? '#082CAF' : isSession ? '#F2F4F7' : 'white'),
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      fontFamily: 'Inter, sans-serif',
      color: ({ isSession }: { isSession: boolean }) => isSession && '#BCC6D3',
      padding: '4px',
      outline: '0px solid white',
    },
    '& .react-datepicker__week': {
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1rem',
    },
    '& .react-datepicker__month-container': {
      width: '100%',
    },
    '& .react-datepicker__day-names': {
      display: 'flex',
      fontWeight: 500,
      fontSize: '14px',
      justifyContent: 'space-between',
    },
    '& .react-datepicker__day--outside-month': {
      fontFamily: 'Inter, sans-serif',
      color: ({ isSession }: { isSession: boolean }) =>
        isSession ? 'transparent !important' : '#70757a',
      backgroundColor: 'transparent !important',
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'rgb(31, 97, 220) !important',
      color: 'white !important',
      borderRadius: '50%',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  nextButton: {
    border: '1px solid #DEE2EC',
    color: '#425364',
    backgroundColor: ({ changeColor }: { changeColor: boolean }) =>
      changeColor ? '#082CAF' : 'white',
    width: '20.7px',
    height: '20.7px',
    fontSize: '16px',
    borderRadius: '10px',
    float: 'right',
  },
  nextButtonSession: {
    color: '#425364',
    width: '18px',
    height: '18px',
    fontSize: '16px',
    borderRadius: '8px',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  DateText: {
    color: ({
      changeColor,
      isSession,
    }: {
      changeColor: boolean;
      isSession: boolean;
    }) =>
      changeColor
        ? 'rgba(255, 255, 255, 0.5)'
        : isSession
        ? '#4F4F4F'
        : '#70757a',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
  },
  HeaderElement: {
    padding: '5px',
    paddingLeft: '30px',
    paddingRight: '30px',
    fontWeight: 400,
    fontSize: '1.2rem',
    cursor: 'pointer',
  },
  loaderOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
  },
  root: {
    width: '18px',
    height: '18px',
    maxWidth: '18px',
    minWidth: '18px',
  },
}));

interface IDatePickerSession {
  margin?: boolean;
  changeColor?: boolean;
  color?: string;
  selectsRange?: boolean;
  startDate: Date;
  endDate: Date | null;
  setStartDate: (startDate: Date) => void;
  setEndDate: (endDate: Date | null) => void;
  setIsChanged: (changed: boolean) => void;
  setIsAvailable?: (changed: boolean) => void;
  minDate?: Date;
  isSession?: boolean;
  feedMode?: boolean;
  doctorId?: string;
}

export default function DatePickerSession({
  doctorId,
  feedMode = false,
  isSession = false,
  margin,
  changeColor,
  color,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  setIsChanged,
  minDate,
}: IDatePickerSession): JSX.Element {
  const [currentMonth, setCurrentMonth] = useState(Dayjs());
  const [monthName, setMonthName] = useState(currentMonth.format('MMMM'));
  const [year, setYear] = useState(currentMonth.year());

  const { data, loading, error } = useHighlitedTimes({
    doctor: doctorId,
    monthName,
    year,
  });

  const highlightedDates = useMemo(
    () =>
      data?.highlightedTimes?.map((timestamp) => {
        return Dayjs.utc(timestamp).toDate();
      }) || [],
    [data]
  );

  const classes = useStyles({ changeColor, color, isSession });
  const { t } = useTranslation();

  const onChange = (dates: [Date, Date | null]) => {
    const [start] = dates;
    if (isSession && start) {
      setStartDate(start);
      setEndDate(start);
    }
    setIsChanged(true);
  };

  const months = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];

  const handleMonthChange = (newMonth: Date) => {
    const newYear = Dayjs(newMonth).year();

    if (newYear !== year) {
      handleYearChange(newMonth);
    }
    const newDay = Dayjs(newMonth).date();
    setCurrentMonth(Dayjs(newMonth));
    setMonthName(Dayjs(newMonth).format('MMMM'));

    if (startDate) {
      const newStartDate = Dayjs(newMonth).date(newDay);
      setStartDate(newStartDate.toDate());
      setEndDate(newStartDate.toDate());
    }
  };

  const handleYearChange = (newDate: Date) => {
    const newYear = Dayjs(newDate).year();
    if (newYear !== year) {
      setYear(newYear);
    }
  };

  return (
    <Box className={classes.datePicker}>
      {loading && (
        <Box className={classes.loaderOverlay}>
          <ProgressLoader width={60} height={60} />
        </Box>
      )}
      <DatePicker
        highlightDates={highlightedDates as any}
        inline
        selected={startDate}
        onChange={feedMode ? undefined : onChange}
        startDate={startDate}
        endDate={isSession ? startDate : endDate}
        selectsRange
        minDate={minDate}
        filterDate={
          !feedMode
            ? (date) => {
                if (
                  Array.isArray(highlightedDates) &&
                  highlightedDates.length > 0
                ) {
                  return true;
                } else {
                  return true;
                }
              }
            : () => {
                return false;
              }
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }: any) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            width="100%"
          >
            <Box
              style={{
                marginBottom: '10px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  decreaseMonth();
                  handleMonthChange(Dayjs(date).subtract(1, 'month').toDate());
                }}
                disabled={prevMonthButtonDisabled}
                classes={{ root: classes.root }}
              >
                <Box className={classes.nextButtonSession}>
                  <Right />
                </Box>
              </Button>

              <Typography className={classes.DateText}>{`${
                months[Dayjs(date).get('month')]
              } ${Dayjs(date).get('year')}`}</Typography>

              <Button
                onClick={(e) => {
                  e.preventDefault();
                  increaseMonth();
                  handleMonthChange(Dayjs(date).add(1, 'month').toDate());
                }}
                classes={{ root: classes.root }}
                disabled={nextMonthButtonDisabled}
              >
                <Box className={classes.nextButtonSession}>
                  <Left />
                </Box>
              </Button>
            </Box>
          </Box>
        )}
        disabled={feedMode}
      />
    </Box>
  );
}
