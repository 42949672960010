import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';
import clsx from 'clsx';
import SignatureResponse from 'components/Editorjs/Responses/Signature';
import { useStyles } from './index';
import CustomRadioGroup from './CustomRadioGroup';
import Text from 'Text';

const ClaimBasicInfo = ({ commonProps }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <>
      <Grid item xl={8} lg={8}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="1. Payer" className={classes.label}>
            1. Payer
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} direction="row">
            <InputBlock
              label={'Payer ID'}
              {...commonProps}
              name="payerID"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Payer name'}
              {...commonProps}
              name="payerName"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Address'}
              {...commonProps}
              name="address"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'City'}
              {...commonProps}
              name="city"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'State'}
              {...commonProps}
              name="state"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock label={'ZIP'} {...commonProps} name="zip" type="text" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="1.a Insured member ID" className={classes.label}>
            1.a Insured member ID
          </Text>
        </Box>
        <Grid item xl={12} lg={12}>
          <InputBlock
            label={'Member ID'}
            {...commonProps}
            name="memberID"
            type="text"
          />
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="2. Patient name" className={classes.label}>
            2. Patient name
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'First name'}
              {...commonProps}
              name="firstname"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Middle name'}
              {...commonProps}
              name="middlename"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Last name'}
              {...commonProps}
              name="lastname"
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="3. Patient info" className={classes.label}>
            3. Patient info
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Date of birth'}
              {...commonProps}
              name="birthDate"
              type="date"
            />
          </Grid>
          <Grid item xl={8} lg={8}>
            <Box mb={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.margin}
              >
                <Text i18nKey="Sex" className={clsx(classes.textTitle)}>
                  Sex
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <CustomRadioGroup
                  name={'gender'}
                  control={commonProps.control}
                  options={[
                    { name: 'Male', value: 0 },
                    { name: 'Female', value: 1 },
                  ]}
                  style={{ gap: '10px' }}
                  ariaLabel={'gender'}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="4. Insured name" className={classes.label}>
            4. Insured name
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'First name'}
              {...commonProps}
              name="insuranceName"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Middle name'}
              {...commonProps}
              name="insuranceMiddle"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Last name'}
              {...commonProps}
              name="insuranceLast"
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="5. Patient address" className={classes.label}>
            5. Patient address
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 1'}
              {...commonProps}
              name="address1"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 2'}
              {...commonProps}
              name="address2"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'City'}
              {...commonProps}
              name="city"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'State'}
              {...commonProps}
              name="state"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'ZIP code'}
              {...commonProps}
              name="zipcode"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Phone number (included area code)'}
              {...commonProps}
              name="phone"
              type="phone"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="6. Patient relationship to insured"
            className={classes.label}
          >
            6. Patient relationship to insured
          </Text>
        </Box>
        <Box display="flex" alignItems="center" mt={-1}>
          <CustomRadioGroup
            name={'relationship'}
            control={commonProps.control}
            options={[
              { name: 'Self', value: 0 },
              { name: 'Spouce', value: 1 },
              { name: 'Child', value: 2 },
              { name: 'Other', value: 3 },
            ]}
            style={{ gap: '10px' }}
            ariaLabel={'relationship'}
          />
        </Box>
        <Box className={classes.labelRoot} p={1} mt={2}>
          <Text i18nKey="8. Reserved for NUCC use" className={classes.label}>
            8. Reserved for NUCC use
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={'#F9FAFB'}
          height="45%"
        >
          <Text i18nKey="Reserved for NUCC">Reserved for NUCC</Text>
        </Box>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="7. Insured address" className={classes.label}>
            7. Insured address
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 1'}
              {...commonProps}
              name="Insuredaddress1"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 2'}
              {...commonProps}
              name="Insuredaddress2"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'City'}
              {...commonProps}
              name="Insuredcity"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'State'}
              {...commonProps}
              name="Insuredstate"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'ZIP code'}
              {...commonProps}
              name="Insuredzipcode"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Phone number (included area code)'}
              {...commonProps}
              name="Insuredphone"
              type="phone"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="9. Other insured" className={classes.label}>
            9. Other insured
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'First name'}
              {...commonProps}
              name="firstnameOther"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Last name'}
              {...commonProps}
              name="lastnameOther"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock label={'M.I.'} {...commonProps} name="mi" type="text" />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Relationship'}
              {...commonProps}
              name="relationshipother"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'a1. Policy/Group ID'}
              {...commonProps}
              name="policy"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'a2. Insured ID'}
              {...commonProps}
              name="insuredID"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'a3. Indicator code'}
              {...commonProps}
              name="indicator"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'b. Insurance plan name/program name'}
              {...commonProps}
              name="planname"
              type="text"
            />
          </Grid>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'c. Insurance type code'}
              {...commonProps}
              name="insurancetype"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'d. Res sequence'}
              {...commonProps}
              name="ressequence"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'e. Payer ID'}
              {...commonProps}
              name="payerIDReserved"
              type="text"
              disabled
              placeholder="Reserved for NUCC"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="10. Is patient condition related to"
            className={classes.label}
          >
            10. Is patient condition related to
          </Text>
        </Box>
        <Box mb={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.margin}
          >
            <Text
              i18nKey="a. Employment? (current or previous)"
              className={clsx(classes.textTitle)}
            >
              a. Employment? (current or previous)
            </Text>
          </Box>
          <Grid item xl={4} lg={4}>
            <Box display="flex" alignItems="center">
              <CustomRadioGroup
                name={'employment'}
                control={commonProps.control}
                options={[
                  { name: 'Yes', value: 0 },
                  { name: 'No', value: 1 },
                ]}
                style={{ gap: '15px' }}
                ariaLabel={'employment'}
              />
            </Box>
          </Grid>
          <Box mt={4.5}>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.margin}
                >
                  <Text
                    i18nKey="b. Auto accident"
                    className={clsx(classes.textTitle)}
                  >
                    b. Auto accident{' '}
                  </Text>
                </Box>
                <Box display="flex" alignItems="center">
                  <CustomRadioGroup
                    name={'accident'}
                    control={commonProps.control}
                    options={[
                      { name: 'Yes', value: 0 },
                      { name: 'No', value: 1 },
                    ]}
                    style={{ gap: '15px' }}
                    ariaLabel={'accident'}
                  />
                </Box>
              </Grid>
              <Grid item xl={8} lg={8}>
                <InputBlock
                  label={'Place (state)'}
                  {...commonProps}
                  name="place"
                  type="text"
                />
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid item xl={4} lg={4}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.margin}
              >
                <Text
                  i18nKey="c. Other accident"
                  className={clsx(classes.textTitle)}
                >
                  c. Other accident{' '}
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <CustomRadioGroup
                  name={'otheraccident'}
                  control={commonProps.control}
                  options={[
                    { name: 'Yes', value: 0 },
                    { name: 'No', value: 1 },
                  ]}
                  style={{ gap: '15px' }}
                  ariaLabel={'otheraccident'}
                />
              </Box>
            </Grid>
          </Box>
          <Box mt={3.7}>
            <Grid item xl={12} lg={12}>
              <InputBlock
                label={'d. Claim codes (designated by NUCC)'}
                {...commonProps}
                name="claimcode"
                type="text"
              />
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box
          style={{ whiteSpace: 'nowrap' }}
          className={classes.labelRoot}
          p={1}
        >
          <Text
            i18nKey="11. Insured policy group or Feca number"
            className={classes.label}
          >
            11. Insured policy group or Feca number
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'Last Policy/Group ID'}
              {...commonProps}
              name="lastPolicy"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'a. Insured date of birth'}
              {...commonProps}
              name="insuredDate"
              type="date"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <Box mb={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.margin}
              >
                <Text i18nKey="Sex" className={clsx(classes.textTitle)}>
                  Sex
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <CustomRadioGroup
                  name={'sexPolicy'}
                  control={commonProps.control}
                  options={[
                    { name: 'Yes', value: 0 },
                    { name: 'No', value: 1 },
                  ]}
                  ariaLabel={'sexPolicy'}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'b. Other claim ID (designated by NUCC)'}
              {...commonProps}
              name="otherClaimID"
              type="text"
              disabled
              placeholder="Designated by NUCC"
              InputProps={{
                style: { backgroundColor: '#dee2e6' },
              }}
            />
          </Grid>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'c. Insurance plan name or program name'}
              {...commonProps}
              name="insurancePlan"
              type="text"
            />
          </Grid>
          <Grid item xl={12} lg={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={classes.margin}
            >
              <Text
                i18nKey="d. Is there another health benefit plan?"
                className={clsx(classes.textTitle)}
              >
                d. Is there another health benefit plan?{' '}
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <CustomRadioGroup
                name={'anotherHealth'}
                control={commonProps.control}
                options={[
                  { name: 'Yes', value: 0 },
                  { name: 'No', value: 1 },
                ]}
                ariaLabel={'anotherHealth'}
              />
              <Text
                i18nKey="If yes, complete items 9, 9a and 9b."
                className={classes.note}
              >
                If yes, complete items 9, 9a and 9b.
              </Text>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimBasicInfo;
