import React, { ReactElement, useEffect, useTransition } from 'react';
import Autocomplete, {
  createFilterOptions,
  AutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import { FormInput } from './FormInput';
import { useTranslation } from 'react-i18next';

// Get option label
export const filter = createFilterOptions<any>();
// interface FilmOptionType {
//   inputValue?: string;
//   title: string;
//   year?: number;
// }
interface IAutoCompleteAddNewProps
  extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
  getOptionLabel: (option: any) => any;
  errors?: boolean;
  placeholder?: string;
}

export default function AutoCompleteAddNew({
  errors,
  value,
  placeholder,
  ...props
}: IAutoCompleteAddNewProps): ReactElement {
  const [key, setValueKey] = React.useState('autocomplete');
  useEffect(() => {
    setValueKey(value);
  }, [value]);
  const { t } = useTranslation();

  return (
    <Autocomplete
      fullWidth
      // autoHighlight
      // autoSelect
      key={key}
      // selectOnFocus
      // clearOnBlur
      handleHomeEndKeys
      value={value}
      id="free-solo-with-text-demo"
      // style={{ width: 300 }}
      {...props}
      renderInput={(params) => (
        <FormInput
          {...params}
          variant="filled"
          error={!!errors}
          placeholder={placeholder || t('Search')}
        />
      )}
    />
  );
}
