import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useProvider } from 'pages/InboxPage/state/Provider';
import useStyles from './style';
import MessagesList from './MessagesList';
import FileList from './FileList';
import InputMessage from './InputMessage';
import EmptyMessageIllustration from './EmptyMessageIllustration';
import useHeightObserver from 'hooks/useHeightObserver';
interface Props {
  patient?: boolean;
  setMessageHidden?: (value: boolean) => void;
  doctorPatientTab?: boolean;
}

const Message = ({
  patient,
  setMessageHidden,
  doctorPatientTab = false,
}: Props) => {
  const [height, setHeight] = useState<number>();
  const classes = useStyles({ patient, height });
  const [filesHidden, setFilesHidden] = React.useState(true);
  const { isEmptyConversationList, messagesData, listFiLes, selected } =
    useProvider();
  const isSelected = !isEmptyConversationList && !!selected;

  const observedElementRef = useHeightObserver((height) => {
    setHeight(height);
  });

  return (
    <Box
      className={classes.fullHeight}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      boxSizing="border-box"
      width="100%"
      style={{
        gap: doctorPatientTab ? '0rem' : '1rem',
        borderRadius: doctorPatientTab ? '0rem' : '12px',
      }}
    >
      <Box
        flex={1}
        // className={filesHidden ? classes.messageListContainer : classes.hidden}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={classes.fullHeight}
          style={{
            backgroundColor: 'white',
            borderRadius: doctorPatientTab ? '0px' : '12px',

            border: doctorPatientTab ? '1px solid #EAECF0' : 'none',
          }}
        >
          <Box className={classes.msgContentWrapper}>
            {isEmptyConversationList && (
              <EmptyMessageIllustration isEmpty={isEmptyConversationList} />
            )}
            {messagesData && !isEmptyConversationList && selected ? (
              <MessagesList
                doctorPatientTab={doctorPatientTab}
                data={messagesData}
                patient={patient}
                setFilesHidden={setFilesHidden}
              />
            ) : (
              <EmptyMessageIllustration isEmpty={isEmptyConversationList} />
            )}
          </Box>
          <div ref={observedElementRef} className={classes.msgInputWrapper}>
            {isSelected && <InputMessage />}
          </div>
        </Box>
      </Box>
      {isSelected && !filesHidden && (
        <Box
          style={{
            backgroundColor: 'white',
            borderRadius: doctorPatientTab ? '0px' : '12px',

            border: doctorPatientTab ? '1px solid #EAECF0' : 'none',
            width: '30%',
          }}
        >
          <FileList
            doctorPatientTab={doctorPatientTab}
            data={listFiLes}
            setFilesHidden={setFilesHidden}
          />
        </Box>
      )}
    </Box>
  );
};
export default Message;
