import { Avatar, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Tabs from 'components/ui/Tabs';
import React from 'react';

import LabTab from './LabTab';

const useStyles = makeStyles<Theme>((theme) => ({
  infoContainer: {
    background: 'rgb(249 249 249)',
    borderBottom: '1px solid #e5e7eb',
  },
  avatar: {
    width: 40,
    height: 40,
    textTransform: 'capitalize',
  },
  tabForm: {
    width: '70%',
    paddingLeft: '0.5rem',
    [theme.breakpoints.only('lg')]: {
      width: '80%',
    },
  },
  name: {
    color: '#494b5A',
    fontSize: '16px',
    fontWeight: 600,
  },
  birth: {
    color: '#494b5A',
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.only('lg')]: {
      fontSize: '12px',
    },
  },
}));
const AddLabsImagingModal = (props) => {
  const classes = useStyles();
  const {
    picture,
    firstname,
    gender,
    lastname,
    birthday,
    address,
    city,
    country,
  } = props?.data?.patient || {};
  const [customValue, setCustomValue] = React.useState(0);
  return (
    <Box>
      <Box mt={2} width="100%" overflow={'scroll'} maxHeight={'85vh'}>
        <Tabs
          tabsMode="other"
          tabClassHeader={classes.tabForm}
          tabsBar={['Lab order', 'Image order']}
          tabsContent={[
            <LabTab handleExpand={props.handleExpand} type="lab" />,
            <LabTab handleExpand={props.handleExpand} type="image" />,
          ]}
          customValue={customValue}
          setCustomValue={setCustomValue}
          scrollable={false}
        />
      </Box>
    </Box>
  );
};

export default AddLabsImagingModal;
