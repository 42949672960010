import React, { ReactElement, useState } from 'react';
import { Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import MeetingContext from 'contexts/MeetingContext';
import AuthContext from 'contexts/AuthContext';
import history from 'config/routerHistory';
import './App.css';
import RoutesGenerator from './components/RootGenerator';
import routes from './routes';
import AccessDenied from './AccesDenies';
import { ModalProvider } from './hooks/useModal';
import ToastProvider from 'hooks/useToast';
import SnackProvider from 'hooks/useSnackbar';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { config } from 'config';
import { TourGuide } from './components/TourGuide';
import EditorContextProvider from 'components/Editorjs/EditorContextProvider';

const stripePromise = loadStripe(config.PUBLICHABLE_KEY);

function App(): ReactElement {
  const [auth, setAuth] = useState(!!localStorage.getItem('token'));

  if (
    !auth &&
    process.env.NODE_ENV !== 'production' &&
    localStorage.getItem('temporaireAuth') !== 'true'
  )
    return <AccessDenied setAuth={setAuth} />;

  toast.configure();

  return (
    <Router history={history}>
      <ModalProvider>
        <AuthContext>
          <Elements stripe={stripePromise}>
            <MeetingContext>
              <ToastProvider>
                <SnackProvider>
                  <TourGuide history={history} />
                  <EditorContextProvider>
                    <RoutesGenerator routes={routes} />
                  </EditorContextProvider>
                </SnackProvider>
              </ToastProvider>
            </MeetingContext>
          </Elements>
        </AuthContext>
      </ModalProvider>
      <ToastContainer />
    </Router>
  );
}

export default App;
