import React, { useState } from 'react';
import { ConflictComponent } from './ConflictComponent';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import { ReactComponent as BackIcon } from 'assets/conflicts/back.svg';
import Pagination from 'components/ui/Pagination';
import ScrollBar from 'components/ui/ScrollBar';
import Skeleton from '../../../../components/ui/Skeleton';
import {
  calculatePaginationTabs,
  getLimitData,
} from 'components/ui/Table/tableutils';
import { IConflict } from './types';
import Modals from 'pages/InboxPage/modals';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';

type IConflictModal = {
  onClose: () => void;
  data: IConflict[][];
  isBack?: boolean;
};
export const ConflictModal = ({
  onClose,
  data,
  isBack = true,
}: IConflictModal) => {
  const classes = useStyles();
  const { patientAdded, page, setPage, limit, conflictsCount, loading } =
    useProvider();
  const handlePaginationChange = (e: any, pageNumber: number) => {
    e.stopPropagation();
    setPage(pageNumber);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {isBack && <BackIcon className={classes.back} onClick={onClose} />}
      </Box>
      <ScrollBar className={classes.scroller}>
        <Box className={classes.conflicts}>
          {loading &&
            [0, 1, 2].map((el) => (
              <Box
                display="flex"
                width="100%"
                height={'100%'}
                flexDirection={'column'}
              >
                <Box
                  display="flex"
                  justifyContent={'space-around'}
                  width="100%"
                  height={'100%'}
                  style={{
                    gap: '40px',
                  }}
                >
                  <Skeleton loading={loading}>
                    <Box
                      className={classes.conflictCard}
                      width="20vw"
                      height={'15vh'}
                    />
                  </Skeleton>
                  <Skeleton loading={loading}>
                    <Box
                      className={classes.conflictCard}
                      width="20vw"
                      height={'15vh'}
                    />
                  </Skeleton>
                </Box>
              </Box>
            ))}
          {!loading &&
            data?.map((el, index) => (
              <ConflictComponent
                key={index}
                conflictedEvents={el}
                onClose={onClose}
              />
            ))}
        </Box>
      </ScrollBar>
      <Box className={classes.paginationContainer}>
        <Pagination
          count={conflictsCount ? Math.ceil(conflictsCount / limit) : 1}
          page={page}
          onChange={handlePaginationChange}
          variant="outlined"
          shape="rounded"
        />
      </Box>
      <Modals
        subject=""
        contact={[
          {
            firstname: patientAdded?.firstname,
            lastname: patientAdded?.lastname,
            middlename: patientAdded?.middlename,
            _id: patientAdded?._id,
            email: patientAdded?.email,
            picture: patientAdded?.picture,
          },
        ]}
      />
    </Box>
  );
};
