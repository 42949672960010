import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactComponent as DownloadSummary } from 'assets/downloadSummary.svg';
import Text from 'Text';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

function LoadingSummary({
  loading,
  text = 'Your summary is downloading',
  isStyled = false,
}: {
  loading: boolean;
  text?: string;
  isStyled?: boolean;
}) {
  const classes = useStyles({ isStyled });
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    let progressValue = 0;

    const interval = setInterval(() => {
      progressValue += 1;
      if (progressValue > 100) {
        clearInterval(interval);
      } else {
        setProgress(progressValue);
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (!loading) {
      setProgress(100);
    }
  }, [loading]);
  return (
    <Box className={classes.loadingContainer}>
      <DownloadSummary />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Text
          i18nKey={text}
          style={{
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 600,
            color: '#182230',
          }}
        >
          {text}
        </Text>
        <LinearProgress
          classes={{
            root: classes.loaderRoot,
            barColorPrimary: classes.barColorPrimary,
          }}
          variant={'determinate'}
          value={progress}
        />
      </Box>
    </Box>
  );
}

export default LoadingSummary;
