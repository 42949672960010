import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';
import clsx from 'clsx';
import SignatureResponse from 'components/Editorjs/Responses/Signature';
import { useStyles } from './index';
import CustomRadioGroup from './CustomRadioGroup';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import dayjs from 'dayjs';
import Text from 'Text';

const ClaimIntermediaire = ({ commonProps, editMode }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { doctor } = useAuth();
  const history = useHistory();
  return (
    <>
      <Grid item xl={6} lg={6}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="12. Patient or authorized person’s signature"
            className={classes.label}
          >
            12. Patient or authorized person’s signature
          </Text>
        </Box>
        <Text
          i18nKey="Please read back of form before completing and signing this form"
          className={classes.description}
        >
          Please read back of form before completing and signing this form
        </Text>
        <Box mt={4}>
          <Text i18nKey="authorizeInfo" className={classes.paragraph}>
            I authorize the release of any medical or other information
            necessary to process this claim. I also request payment of
            government benefits either to myself or to the party who accepts
            assignment below.
          </Text>
        </Box>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Text i18nKey="ELECTRONICALLY SIGNED" className={classes.signLabel}>
            ELECTRONICALLY SIGNED
          </Text>
          <InputBlock
            label={'Date'}
            {...commonProps}
            name="dateSignature"
            type="date"
            disabled
          />
        </Box>
      </Grid>
      <Grid item xl={6} lg={6}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="13. Insured or authorized person signature"
            className={classes.label}
          >
            13. Insured or authorized person signature
          </Text>
        </Box>
        <Text
          i18nKey="I authorize payment of medical benefit to the undesignated physician or supplier for services described below"
          className={classes.paragraph}
          style={{ paddingLeft: '8px' }}
        >
          I authorize payment of medical benefit to the undesignated physician
          or supplier for services described below
        </Text>
        <Box mt={2} ml={'8px'}>
          {editMode ? (
            <Box
              display="flex"
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection="column"
              style={{
                backgroundColor: '#F0F9FF',
                cursor: 'pointer',
                borderRadius: '16px',
              }}
              py={'16px'}
              px={'8px'}
            >
              <Box
                display={!open ? 'none' : 'flex'}
                justifyContent="space-between"
                flexDirection="row-reverse"
                width="100%"
              >
                <Box
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push('/doctor/admin?tab=5');
                  }}
                  pr={2}
                >
                  <Text
                    i18nKey="Edit"
                    style={{
                      fontWeight: 500,
                      fontSize: '16px',
                      color: '#0054B6',
                      textDecoration: 'underline',
                    }}
                  >
                    Edit
                  </Text>
                </Box>
                <Box px={2}>
                  <img
                    src={doctor?.signature?.picture}
                    style={{
                      width: '100%',
                      height: '58px',
                    }}
                  />
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    mb={'10px'}
                    mt={'10px'}
                  >
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontSize: '14px',
                        lineHeight: '17.74px',
                        color: '#0054B6',
                      }}
                    >
                      {t('Signed by')} {doctor?.firstname} {doctor?.lastname}
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 400,
                        lineHeight: '17.74px',
                        fontSize: '14px',
                        color: '#0054B6',
                      }}
                    >
                      {dayjs(doctor?.signature?.updatedAt).format(
                        'MMM D, YYYY h:mm A'
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <SignatureResponse />
          )}
        </Box>
      </Grid>
      <Grid item xl={5} lg={5}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="14. Date of current illness, injury or pregnancy (LMP)"
            className={classes.label}
          >
            14. Date of current illness, injury or pregnancy (LMP)
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'a. Insured date of birth'}
              {...commonProps}
              name="insuredDate"
              type="date"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Qual'}
              {...commonProps}
              name="qual"
              type="select"
              options={['']}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={2} lg={2}>
        <Box className={classes.labelRoot} p={1}>
          <Typography className={classes.label}>15. Other Date</Typography>
        </Box>
        <InputBlock
          label={'Qual'}
          {...commonProps}
          name="qualOther"
          type="date"
        />
      </Grid>
      <Grid item xl={5} lg={5}>
        <Box className={classes.labelRoot} p={1}>
          <Typography className={classes.label}>
            16. Dates patients unable to work in current occupation
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'From'}
              {...commonProps}
              name="from"
              type="date"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock label={'To'} {...commonProps} name="to" type="date" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="17. Name of referring provider or other source"
            className={classes.label}
          >
            17. Name of referring provider or other source
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'Provider'}
              {...commonProps}
              name="provider"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'First name'}
              {...commonProps}
              name="firstRefering"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Middle name'}
              {...commonProps}
              name="MiddletRefering"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Last name'}
              {...commonProps}
              name="LastRefering"
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Typography className={classes.label}>17.a</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Secondary ID type'}
              {...commonProps}
              name="secondaryIDtype"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Secondary ID'}
              {...commonProps}
              name="secondaryID"
              type="text"
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Box className={classes.labelRoot} p={1}>
            <Typography className={classes.label}>17.b</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12}>
              <InputBlock
                label={'NPI'}
                {...commonProps}
                name="npi"
                type="text"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Typography className={classes.label}>
            18. Hospital dates related to current services
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'From'}
              {...commonProps}
              name="fromhospital"
              type="date"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'To'}
              {...commonProps}
              name="tohospital"
              type="date"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={5} lg={5}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="19. Additional claim information (designated by NUCC)"
            className={classes.label}
          >
            19. Additional claim information (designated by NUCC)
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Additional info'}
              {...commonProps}
              name="additionalinfo"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Additional info code'}
              {...commonProps}
              name="additionalinfocode"
              type="select"
              options={['']}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={5} lg={5}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="19.a Supplemental claim information"
            className={classes.label}
          >
            19.a Supplemental claim information
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Type code'}
              {...commonProps}
              name="typecode"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Trans code'}
              {...commonProps}
              name="transcode"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Attachments ID'}
              {...commonProps}
              name="attachmentID"
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={2} lg={2}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="20. Outside lab?" className={classes.label}>
            20. Outside lab?
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <Box display="flex" alignItems="center" mt={-1.5}>
              <CustomRadioGroup
                name={'outsidelab'}
                control={commonProps.control}
                options={[
                  { name: 'Yes', value: 0 },
                  { name: 'No', value: 1 },
                ]}
                ariaLabel={'outsidelab'}
              />
            </Box>
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Charge'}
              {...commonProps}
              name="charge"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClaimIntermediaire;
