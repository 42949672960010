import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Paper,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'assets/searchIcon.svg';
import { ReactComponent as Add } from 'assets/add-icon.svg';
import { ReactComponent as Remove } from 'assets/remove-icon.svg';
import useStyles from './styles';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import Text from 'Text';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { useTranslation } from 'react-i18next';

interface SearchProviderProps {
  providers?: any[];
  onSearch?: (query: string) => void;
  setOpenUnassignModal?: any;
  setSelectedProvider?: any;
  relatedProviders?: any[];
  handleAssign?: any;
}

const SearchProvider = ({
  providers,
  setOpenUnassignModal,
  setSelectedProvider,
  relatedProviders,
  handleAssign,
}: SearchProviderProps) => {
  const { doctor, ownerUser } = useAuth();
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setDropdownOpen(true);
  };

  const handleClickAway = (event: MouseEvent | null) => {
    if (ref.current && !ref.current.contains(event?.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);
    return () => document.removeEventListener('mousedown', handleClickAway);
  }, []);
  const providersList = providers?.map((el) => {
    const assigned =
      relatedProviders &&
      relatedProviders?.some((provider) => provider._id === el._id);
    return {
      ...el,
      name: `${capitalizeFirstLetter(el?.firstname)} ${capitalizeFirstLetter(
        el?.lastname
      )}`,
      assigned,
    };
  });

  const filteredProviders = providersList?.filter((provider) =>
    provider?.name?.toLowerCase().includes(query.toLowerCase())
  );

  const handlAssignProvider = (e, provider) => {
    e.preventDefault();
    handleAssign(provider);
    setDropdownOpen(false);
  };
  const handleUnassignProvider = (e, provider) => {
    e.preventDefault();
    setDropdownOpen(false);
    setOpenUnassignModal(true);
    setSelectedProvider(provider);
  };

  const highlightText = (text: string, query: string) => {
    if (!text) return '';
    if (!query) return text;

    const parts = text.split(new RegExp(`(${query})`, 'gi')) || [];
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span
          key={index}
          style={{
            fontWeight: 'bold',
            color: 'white',
            backgroundColor: '#3892F3',
            borderRadius: '2px',
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div
      style={{
        position: 'relative',
        // maxWidth: '500px',
        width: '100%',
      }}
      ref={ref}
    >
      <Input
        type="text"
        placeholder={t('Search providers')}
        variant="filled"
        style={{
          maxHeight: '40px',
          marginBottom: '1rem',
          width: '100%',
        }}
        value={query}
        onChange={handleSearch}
        onClick={() => setDropdownOpen(true)}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />

      {dropdownOpen && (
        <Box
          className={classes.dropdown}
          style={{
            position: 'absolute',
            top: 50,
            left: 0,
            zIndex: 1000000000000000,
          }}
        >
          <List>
            {filteredProviders?.map((provider) => (
              <Box
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  if (String(provider?._id) !== String(ownerUser?.doctorId)) {
                    if (provider?.assigned) {
                      handleUnassignProvider(e, provider);
                    } else {
                      handlAssignProvider(e, provider);
                    }
                  }
                }}
              >
                <Box key={provider.id} className={classes.templateItem}>
                  <Typography className={classes.templateTitle}>
                    {highlightText(provider?.name, query)}
                  </Typography>
                  {String(provider?._id) !== String(ownerUser?.doctorId) && (
                    <>
                      {provider?.assigned ? (
                        <Button
                          onClick={(e) => {
                            handleUnassignProvider(e, provider);
                          }}
                          className={classes.unassignButton}
                        >
                          <Remove />
                          <Text i18nKey="Unassign" className={classes.assign}>
                            Unassign
                          </Text>
                        </Button>
                      ) : (
                        <Button
                          onClick={(e) => {
                            handlAssignProvider(e, provider);
                          }}
                          className={classes.assignButton}
                        >
                          <Add />
                          <Text i18nKey="Assign" className={classes.assign}>
                            Assign
                          </Text>
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
};

export default SearchProvider;
