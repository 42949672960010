import React from 'react';
import { Box, FilledTextFieldProps, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from 'assets/errorIcon.svg';
import Input from 'components/ui/Inputs';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

interface IInputBlockProps
  extends Omit<FilledTextFieldProps, 'variant' | 'error'> {
  register: any;
  control: any;
  errors: any;
  label?: string;
  placeholder?: string;
  name: string;
  type?: string;
  options?: string[];
  defaultValue?: any;
  showTimeSelect?: boolean;
  isRequired?: boolean;
  error?: string;
  maxDate?: Date;
  setError?: React.Dispatch<React.SetStateAction<string>>;
}

export default function InputBlock({
  label,
  name,
  errors,
  error,
  control,
  register,
  type = 'text',
  defaultValue,
  isRequired,
  setError,
  ...props
}: IInputBlockProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const hasError = error || (errors && !!errors[name]);
  const errorMessage = error || (errors && errors[name]?.message);

  return (
    <Box className={props.className}>
      {label && (
        <Text i18nKey={label} className={classes.labelText}>
          {label}
          {isRequired && <span style={{ color: '#D92D20' }}>*</span>}
        </Text>
      )}
      <Input
        inputRef={register}
        control={control}
        name={name}
        variant="filled"
        type={type}
        defaultValue={defaultValue || ''}
        error={hasError}
        helperText={
          hasError && (
            <Box
              display="flex"
              alignItems="center"
              data-cy="error-message"
              className={classes.error}
              mt={type === 'date' && 0.5}
            >
              <ErrorIcon className={classes.errorIcon} />
              <Box
                display="flex"
                alignItems="center"
                ml={'0.2rem'}
                mt={'0.2rem'}
              >
                {t(errorMessage)}
              </Box>
            </Box>
          )
        }
        {...props}
      />
    </Box>
  );
}
