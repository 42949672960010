import React, { FC, useCallback } from 'react';
import { Avatar, ListItem, Typography, Box } from '@material-ui/core';
import { formatInboxDate } from 'utils';
import useStyles from './styles';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useProvider } from 'pages/InboxPage/state/Provider';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRemoveUnreadThreadMessages from '@eggmed/graphql-client/operations/threadMessagesOperations/useRemoveUnreadMessages';
interface IConversationItemProps {
  handleSelected: (threadId: string) => void;
  selected: string;
  data: GetAllThreadConversationByUser_getAllThreadConvertationByUser;
  messages: GetAllThreadConversationByUser_getAllThreadConvertationByUser[];
}
const ConversationItem: FC<IConversationItemProps> = ({
  data,
  handleSelected,
  selected,
  messages,
}) => {
  const handleRemoveUnreadMessages = useRemoveUnreadThreadMessages();
  const isReadItMessage = (unReadItMessage, userId) => {
    if (unReadItMessage) {
      const readItMessage = unReadItMessage.filter(
        (item) => item.user === userId
      );
      if (readItMessage.length === 0) {
        return false;
      }
      return false;
    }
    return false;
  };
  const { t } = useTranslation();
  const classes = useStyles({ isPatient: false });
  const location = useLocation();
  const { setMessageHidden } = useProvider();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const {
    updatedAt,
    _id,
    creator,
    lastMessage,
    unReadItMessage,
    lastUpdateTime,
    participants,
    subject,
  } = data;
  const unreadMessage: any = unReadItMessage?.find(
    (item) => item.user === user?._id
  );

  const recepient = React.useMemo(() => {
    return participants?.find((p) => p?.user?._id !== user?._id);
  }, [participants, user]);
  const recepientName = `${recepient?.user?.firstname || ''} ${
    recepient?.user?.lastname || ''
  }`;

  const {
    content: { text },
  } = lastMessage;
  const { firstname, middlename, lastname, picture } = creator;
  const participant = participants
    ?.map((el) => el?.user)
    .filter((element) => element?._id !== (user?._id || user?.user?._id));
  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleSelectedThead = useCallback(() => {
    handleSelected(_id);
    handleRemoveUnreadMessages(_id);
    setMessageHidden(false);
  }, [handleSelected, handleRemoveUnreadMessages, _id]);
  const handleConfirmModalDelete = (event: any) => {
    setOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { handelDeleteThreadConversation } = useProvider();
  const handleDeleteItem = () => {
    handelDeleteThreadConversation(_id);
  };
  const handleDelete = () => {
    handleDeleteItem();
    handleClose();
  };
  function determineType(mimeType: string) {
    if (mimeType && mimeType.startsWith('image/')) {
      return 'image';
    }
    if (mimeType && mimeType.startsWith('application/')) {
      return 'attachment';
    }
    return 'text';
  }
  const getLastMessage = () => {
    const isSender = lastMessage?.user?._id === user?._id;
    const senderText = isSender ? 'You' : lastMessage?.user?.username;
    const messageType = determineType(
      lastMessage?.content?.files?.[0]?.mimeType || ''
    );
    let message;

    if (!!lastMessage?.content?.text && lastMessage?.content?.text.length > 0) {
      return `${
        senderText === 'You'
          ? `${t('You')}: `
          : `${lastMessage?.user?.firstname}: `
      }${lastMessage?.content?.text}`;
    }

    if (messageType === 'image') {
      message = `${senderText} ${t('sent an image')}`;
    } else if (messageType === 'attachment') {
      message = `${senderText} ${t('sent an attachment')}`;
    }

    return message;
  };
  const message = getLastMessage();
  const isUnread =
    !isReadItMessage(unReadItMessage, user?._id) && unreadMessage;
  const isSelected = selected === _id;
  return (
    <Box className={classes.hover}>
      <Box
        style={{
          backgroundColor: isSelected ? '#96CEFD' : 'transparent',
        }}
        className={classes.conversationItem}
        onClick={handleSelectedThead}
      >
        {participant?.length > 1 ? (
          participant?.slice(0, 2)?.map((part, index) => (
            <Box
              ml={index == 0 ? 2.5 : 0}
              mt={index > 0 ? 3 * index : 1.5}
              className={classes.cardSpacing}
              key={part._id}
            >
              <Avatar
                alt={part && part?.firstname.toUpperCase()}
                src={part && part?.picture}
                className={classes.avatarCard}
                style={{
                  width: '30px',
                  height: '30px',
                  marginTop: '6px !important',
                }}
              >
                {part?.firstname.slice(0, 1).toUpperCase()}
              </Avatar>
            </Box>
          ))
        ) : (
          <Avatar
            alt={participant && participant[0]?.firstname.toUpperCase()}
            src={participant && participant[0]?.picture}
            className={classes.Avatar}
          >
            {participant[0]?.firstname.slice(0, 1).toUpperCase()}
          </Avatar>
        )}
        <Box ml="15px" width="82%">
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box width="82%">
                <Typography
                  className={classes.recepientName}
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {recepientName}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                  marginLeft: '25px',
                }}
              >
                <Typography
                  className={classes.date}
                  style={{
                    color: isUnread
                      ? '#D92D20'
                      : isSelected
                      ? '#182230'
                      : '#667085',
                    fontWeight: isUnread ? 'bold' : 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {formatInboxDate(
                    lastMessage?.updatedAt || lastMessage?.createdAt,
                    true
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className={classes.recepientName}
                style={{
                  fontWeight: 700,
                  textOverflow: 'ellipsis',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                }}
              >
                {subject || ''}
              </Typography>
              {isUnread && (
                <Box
                  className={classes.unreadBox}
                  style={{ backgroundColor: '#D92D20' }}
                >
                  <Typography className={classes.unread}>
                    {unreadMessage?.count > 9 ? '+9' : unreadMessage?.count}
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography
              className={classes.recepientName}
              style={{
                fontWeight: 500,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
            >
              {getLastMessage()}
            </Typography>
          </Box>
        </Box>
      </Box>

      <ModalDelete
        text="conversation"
        open={open}
        onClose={handleClose}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default ConversationItem;
