import React from 'react';
import './style.css';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import Skeleton from 'components/ui/Skeleton';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as EggmedLogo } from 'assets/EggmedFinalLogo.svg';
import LoadingSummary from 'pages/PatientConsultations/LoadingSummary';
import ErrorSnackBar from 'pages/PatientConsultations/ErrorSnackBar';
import SuccessSnackBar from 'pages/PatientConsultations/SuccessSnackBar';
import { dayjsFormat } from 'utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#667085',
  },
  name: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#182230',
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#475467',
  },
}));

const SuperbillPreviewModal = ({
  currentRow,
  handleClose,
  downloadStarted,
  loadingBillMutation,
  setDownloadStarted,
  errorBill,
  formData,
  onSubmitBill,
}: any) => {
  const classes = useStyles();
  const { data, loading } = useQuery(SUPER_BILL_BY_ID, {
    variables: {
      id: currentRow?.superbill?._id,
    },
  });

  const totalRate = data?.superBillByID?.sessions?.reduce((sum, session) => {
    return sum + (parseFloat(session?.sessionType?.rate) || 0); // Convert rate to a number
  }, 0);

  const {
    doctor,
    patient: patientData,
    sessions,
    diagnosis,
    ref,
    issueDate,
    insuranceRelated,
    editableBill,
  } = data?.superBillByID || {};

  const { firstname, lastname, city, address } = editableBill || {};
  const { t } = useTranslation();
  const WrappedSkeleton = ({ children, ...props }) => {
    return (
      <Skeleton loading={loading}>
        <span {...props}>{children}</span>
      </Skeleton>
    );
  };
  return (
    <Box>
      <div className="invoice-preview">
        <div
          className="preview-section"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBlock: '2rem',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <WrappedSkeleton
              style={{ fontWeight: 600, fontSize: '32px', color: '#182230' }}
            >
              {t('Statement for Insurance')}
            </WrappedSkeleton>
            <WrappedSkeleton
              style={{ fontWeight: 600, fontSize: '32px', color: '#182230' }}
            >
              {t('Reimbursement')}
            </WrappedSkeleton>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <WrappedSkeleton style={{ fontWeight: 400, color: '#475467' }}>
              <span className={classes.label}>{t('Issued')}</span>{' '}
              {dayjsFormat(new Date(issueDate), 'DD/MM/YYYY')}
            </WrappedSkeleton>
            <WrappedSkeleton style={{ fontWeight: 400, color: '#475467' }}>
              <span className={classes.label}>{t('Statement')}</span> #{ref}
            </WrappedSkeleton>
          </div>
        </div>
        <Grid
          container
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            paddingBlock: '1rem',
          }}
        >
          <Grid
            item
            xl={4}
            lg={4}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <WrappedSkeleton className={classes.label}>
              {t('From')}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.name}>
              {doctor?.firstname} {doctor?.lastname}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {doctor?.address}
            </WrappedSkeleton>
            <WrappedSkeleton>
              {doctor?.city}, {doctor?.state} {doctor?.country}
            </WrappedSkeleton>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <WrappedSkeleton className={classes.label}>
              {t('Provider')}
            </WrappedSkeleton>

            <WrappedSkeleton className={classes.name}>
              {doctor?.firstname} {doctor?.lastname}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {doctor?.phone}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {doctor?.email}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              NPI {doctor?.providerSettings?.npi}
            </WrappedSkeleton>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <WrappedSkeleton className={classes.label}>
              {t('Practice')}
            </WrappedSkeleton>{' '}
            <WrappedSkeleton className={classes.name}>
              {doctor?.organizationSettings?.practiceName}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {t('Tax ID')} {doctor?.organizationSettings?.taxonomyOrg}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              NPI {doctor?.organizationSettings?.npiOrg}
            </WrappedSkeleton>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBlock: '1rem',
          }}
        >
          {/* <div className="vertical-divider"></div> */}
          <Grid
            item
            xl={4}
            lg={4}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <WrappedSkeleton className={classes.label}>
              {t('To')}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.name}>
              {firstname ?? patientData?.firstname}{' '}
              {lastname ?? patientData?.lastname}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {address ?? patientData?.address}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {city ?? patientData?.city}, {patientData?.country}
            </WrappedSkeleton>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <WrappedSkeleton className={classes.label}>Client</WrappedSkeleton>
            <WrappedSkeleton className={classes.name}>
              {patientData?.firstname} {patientData?.lastname}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {dayjsFormat(new Date(patientData?.birthday), 'DD/MM/YYYY')}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {patientData?.phone}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              <Typography
                style={{
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  color: '#475467',
                }}
              >
                {patientData?.email}
              </Typography>
            </WrappedSkeleton>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <WrappedSkeleton className={classes.label}>
              {t('Insured')}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.name}>
              {insuranceRelated?.firstname || patientData?.firstname}{' '}
              {insuranceRelated?.lastname || patientData?.lastname}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {insuranceRelated?.payer}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {t('Member ID')} {insuranceRelated?.memberID}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {t('Plan ID')} {insuranceRelated?.planID}
            </WrappedSkeleton>
            <WrappedSkeleton className={classes.description}>
              {t('Group ID')} {insuranceRelated?.groupID}
            </WrappedSkeleton>
          </Grid>
        </Grid>
        {diagnosis?.length > 0 && (
          <div className="preview-section">
            <table
              className="invoice-table"
              style={{ borderBottom: '1px solid #e0e0e0', marginBlock: '2rem' }}
            >
              <thead>
                <tr>
                  <th>DX</th>
                  <th>{t('Diagnosis code')}</th>
                </tr>
              </thead>
              <tbody>
                {diagnosis?.map((el) => (
                  <tr>
                    <td>{el?.isPrimary ? 1 : 2}</td>
                    <td>
                      {el?.diagnosisCode?.code} -{' '}
                      {el?.diagnosisCode?.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className=" preview-section">
          <table
            className="invoice-table"
            style={{ borderBottom: '1px solid #e0e0e0' }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>POS</th>
                <th>Service</th>
                <th>DX</th>
                <th>Description</th>
                <th>{t('Units')}</th>
                <th>{t('Fee')}</th>
                <th>{t('Paid')}</th>
              </tr>
            </thead>
            <tbody>
              {sessions?.map((el) => (
                <tr>
                  <td>{dayjsFormat(new Date(el?.startDate), 'DD/MM/YYYY')}</td>
                  <td>02</td>
                  <td>{el?.sessionType?.cptCode?.code}</td>
                  <td>2</td>
                  <td>
                    {el?.sessionType?.session}, {el?.sessionType?.duration}min
                  </td>
                  <td>1</td>
                  <td>${el?.sessionType?.rate}</td>
                  <td>${el?.isPaid === 'paid' ? el?.sessionType?.rate : 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{ paddingBottom: '2rem', borderBottom: '1px solid #e0e0e0' }}
        >
          <div
            className="balance-section"
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <div className="balance-row">
              <WrappedSkeleton className="balance-label">
                {t('Total')}
              </WrappedSkeleton>
              <WrappedSkeleton className="balance-total">
                ${totalRate}
              </WrappedSkeleton>
            </div>

            <div className="balance-row">
              <WrappedSkeleton className="balance-label">
                {t('amount paid')}
              </WrappedSkeleton>
              <WrappedSkeleton className="balance-due">$0</WrappedSkeleton>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '15px',
          }}
        >
          <EggmedLogo />
        </div>
      </div>
      {downloadStarted && loadingBillMutation && (
        <Box pl="2rem">
          <LoadingSummary
            loading={loadingBillMutation}
            isStyled
            text="Your superbill is downloading"
          />
        </Box>
      )}
      {downloadStarted && !loadingBillMutation && errorBill && (
        <Box pl="2rem">
          <ErrorSnackBar
            handleClick={() => onSubmitBill(formData)}
            setDownloadStarted={setDownloadStarted}
            isStyled
          />
        </Box>
      )}
      {downloadStarted && !loadingBillMutation && !errorBill && (
        <SuccessSnackBar
          handleClick={() => onSubmitBill(formData)}
          setDownloadStarted={setDownloadStarted}
          isStyled
          text={t('Superbill downloaded')}
        />
      )}
    </Box>
  );
};

export default SuperbillPreviewModal;

export const SUPER_BILL_BY_ID = gql`
  query superBillByID($id: ID) {
    superBillByID(id: $id) {
      doctor {
        firstname
        lastname
        address
        city
        state
        country
        phone
        email
        organizationSettings {
          practiceName
          taxonomyOrg
          npiOrg
        }
        providerSettings {
          npi
        }
      }
      patient {
        firstname
        lastname
        address
        city
        country
        phone
        email
      }
      sessions {
        startDate
        isPaid
        sessionType {
          cptCode {
            code
          }
          session
          duration
          rate
        }
      }
      diagnosis {
        diagnosisCode {
          code
          description
        }
        isPrimary
      }
      ref
      issueDate
      insuranceRelated {
        firstname
        lastname
        payer
        memberID
        groupID
        planID
      }
      editableBill {
        _id
        firstname
        middlename
        lastname
        address
        city
        state
        zipcode
      }
    }
  }
`;
