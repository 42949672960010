import React, { ReactElement } from 'react';
import { AppBar, Tabs, Tab, Box, Badge, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Skeleton from '../../ui/Skeleton';
import { ReactComponent as Activity } from 'assets/activity-heart.svg';
import { ReactComponent as Appointment } from 'assets/patientActivities/calendar-blue.svg';
import { ReactComponent as Tasks } from 'assets/check-done-01.svg';
import { ReactComponent as Records } from 'assets/medical-cross.svg';
import { ReactComponent as Billing } from 'assets/currency-dollar.svg';
import { ReactComponent as Settings } from 'assets/settings-01.svg';
import { ReactComponent as Messages } from 'assets/message-text-circle-02.svg';
import { ReactComponent as Generals } from 'assets/home-smile.svg';
import { ReactComponent as AddIconApp } from 'assets/patientActivities/calendar.svg';
import { ReactComponent as GeneralTab } from 'assets/GeneralTab.svg';

import { ReactComponent as GeneralSelected } from 'assets/GeneralSelected.svg';
import { ReactComponent as ActivitiesSelected } from 'assets/ActivitySelected.svg';
import { ReactComponent as SessionSelected } from 'assets/SessionSelected.svg';
import { ReactComponent as TasksSelected } from 'assets/TasksSelected.svg';
import { ReactComponent as RecordsSelected } from 'assets/RecordsSelected.svg';
import { ReactComponent as BillingSelected } from 'assets/BillingSelected.svg';
import { ReactComponent as MessagesSelected } from 'assets/MessagesSelected.svg';
import { ReactComponent as SettingsSelected } from 'assets/SettingsSlected.svg';

import { ReactComponent as GeneralUnselected } from 'assets/GeneralUnselected.svg';
import { ReactComponent as ActivityUnselected } from 'assets/ActivityUnselected.svg';
import { ReactComponent as SessionUnselected } from 'assets/SessionUnselected.svg';
import { ReactComponent as TasksUnselected } from 'assets/TasksUnselected.svg';
import { ReactComponent as RecordsUnselected } from 'assets/RecordsUnselected.svg';
import { ReactComponent as BillingUnselected } from 'assets/BillingUnselected.svg';
import { ReactComponent as MessagesUnselected } from 'assets/MessagesUnselected.svg';
import { ReactComponent as SettingsUnselected } from 'assets/SettingsUnselected.svg';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';

const CustomTabs = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    display: 'flex',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  indicator: {
    backgroundColor: '#39B54A',
    display: 'none',
  },
  scrollButtons: {
    color: 'black',
  },
}))(Tabs);

interface StyledTabProps {
  label: any;
  icon: ReactElement;
}
export const ItemsList = [
  {
    icon: <GeneralUnselected />,
    selectedIcon: <GeneralSelected />,
    label: 'General',
    ci: 'general-tab',
    name: 'general',
  },
  {
    icon: <ActivityUnselected />,
    selectedIcon: <ActivitiesSelected />,
    label: 'Activities',
    ci: 'activities-tab',
    name: 'activities',
  },
  {
    icon: <SessionUnselected />,
    selectedIcon: <SessionSelected />,
    label: 'Sessions',
    ci: 'Appointments-tab',
    name: 'Appointments',
  },

  {
    icon: <TasksUnselected />,
    selectedIcon: <TasksSelected />,
    label: 'Tasks',
    ci: 'Tasks-tab',
    name: 'Tasks',
  },

  {
    icon: <RecordsUnselected />,
    selectedIcon: <RecordsSelected />,
    label: 'Records',
    ci: 'Records-tab',
    name: 'Records',
  },

  {
    icon: <BillingUnselected />,
    selectedIcon: <BillingSelected />,
    label: 'Billing & Insurance',
    ci: 'Billing-tab',
    name: 'Billing',
  },
  {
    icon: <SettingsUnselected />,
    selectedIcon: <SettingsSelected />,
    label: 'Settings',
    ci: 'Settings-tab',
    name: 'Settings',
  },
  {
    icon: <MessagesUnselected />,
    selectedIcon: <MessagesSelected />,
    label: 'Messages',
    ci: 'Messages-tab',
    name: 'Messages',
  },
  {
    icon: null,
    selectedIcon: null,
    label: 'Facesheet',
    ci: 'facesheet-tab',
    name: 'Facesheet',
  },
];

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 150,
      // margin: '0px !important',
      // boxSizing: 'border-box',
      // flex: '1 1 0',
      // width: 0,
      fontWeight: theme.typography.fontWeightRegular as any,
      // borderRight: 'solid 2px #D3DFEF',
      // '&:nth-last-child(1)': {
      //   borderRight: 'none',
      // },
      // '&:hover': {
      //   color: ' white',
      //   opacity: 1,
      // },
      '&$selected': {
        color: 'white',
      },
      // '&:focus': {
      //   color: ' white',
      // },
    },
    selected: {
      background: 'white',
      borderBottom: '3px solid #0265DC',
      // borderRadius: '8px',
      // boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

function TabProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface IPatientTabsBarProps {
  handleChange: (event: unknown, newValue: number) => void;
  value: number;
  completed?: [string];
  isLoading?: boolean;
  AppointmentChecked?: boolean;
}

export default function PatientTabsBar({
  handleChange,
  value,
  completed,
  isLoading = false,
  AppointmentChecked = false,
}: IPatientTabsBarProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Box maxWidth="100%" style={{ overflowX: 'hidden' }}>
      <AppBar
        elevation={0}
        style={{
          display: 'flex',
          // alignItems: 'center',
          backgroundColor: 'white',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
        position="static"
      >
        <CustomTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          textColor="primary"
          // scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          {ItemsList.map(({ label, icon, selectedIcon, ci, name }, index) => {
            const IconSelected = value === index ? selectedIcon : icon;

            const IconAlerted = (
              // <Badge badgeContent={<ErrorIcon style={{ color: 'red' }} />}>
              <>{IconSelected}</>
              // </Badge>
            );
            const Icon =
              (completed &&
                completed.some((element) => element === name.toLowerCase())) ||
              label === 'Appointments'
                ? IconSelected
                : IconAlerted;
            return (
              <AntTab
                data-cy={ci}
                key={label}
                label={
                  <Skeleton loading={isLoading}>
                    <Box display="flex" alignItems="center">
                      <Skeleton loading={isLoading}>{Icon}</Skeleton>
                      <Typography
                        style={{
                          paddingLeft: '5px',
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          color: value === index ? '#0054B6' : '#848F9A',
                        }}
                      >
                        {t(`${label as TranslationKeys}`)}
                      </Typography>
                    </Box>
                  </Skeleton>
                }
                {...TabProps(index)}
                icon={null}
              />
            );
          })}
        </CustomTabs>
      </AppBar>
    </Box>
  );
}
PatientTabsBar.defaultProps = {
  completed: [],
  isLoading: false,
};
