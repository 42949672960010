import React, { ReactElement, useState } from 'react';
import { Box, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReactComponent as DownArrow } from 'assets/DownArrow.svg';
import { ReactComponent as UpArrow } from 'assets/UpArrow.svg';
import Cell, { ICellProps } from './TableCell';
import { sortByKey } from './tableutils';
import { IColumn } from './types';
import Skeleton from '../Skeleton';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>((theme) => ({
  Head: {
    border: '0.5px solid #EAECF0',
    borderRadius: '25px',
    backgroundColor: '#ECF4F7',
  },
  selectedDownSortIcon: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'flex-start',
    '& svg': {
      '& path': {
        stroke: ({ arrowState, index }: { arrowState: any; index: number }) =>
          arrowState[index]?.down === true && '#2DC76D',
      },
    },
  },
  sortIcon: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'flex-start',
  },
  selectedUpSortIcon: {
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'flex-start',
    '& svg': {
      '& path': {
        stroke: ({ arrowState, index }: { arrowState: any; index: number }) =>
          arrowState[index]?.up === true && '#2DC76D',
      },
    },
  },
  sticky: {
    position: 'sticky',
    zIndex: 10,
    backgroundColor: '#fff',
  },

  stickyLeft: {
    left: 0,
    position: 'sticky',
    zIndex: 10,
    backgroundColor: '#fff',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0, // Shadow on the right edge
      bottom: 0,
      width: '5px', // Adjust thickness
    },
  },
  stickyRight: {
    right: 0,
    position: 'sticky',
    zIndex: 10,
    backgroundColor: '#fff',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0, // Shadow on the left edge
      bottom: 0,
      width: '5px', // Adjust thickness
    },
  },
}));

interface ITableHeadProps<T> extends ICellProps {
  columns: IColumn<T>[];
  setTableData: (data: T[]) => void;
  tableData: T[];
  DownSortIcon?: any;
  UpSortIcon?: any;
  sorting: boolean;
  loading: boolean;
  isFreezed?: boolean;
  initialData: T[];
}

export default function TableHeadRow<T>({
  columns,
  setTableData,
  tableData,
  DownSortIcon = DownArrow,
  UpSortIcon = UpArrow,
  loading,
  sorting = false,
  type,
  isFreezed = false,
  initialData,
}: ITableHeadProps<T>): ReactElement {
  const [columnSort, setColmunsSort] = useState(
    columns.map(({ id }) => {
      return { down: false, up: false };
    })
  );
  function handleEditSorting(index: number, sortType: 'down' | 'up') {
    setColmunsSort((cloneSorts) => {
      const newClone = [...cloneSorts];
      for (let i = 0; i < newClone.length; i++) {
        if (i == index) {
          newClone[i][sortType] = !columnSort[i][sortType];
          newClone[i][sortType === 'down' ? 'up' : 'down'] = false;
        } else {
          newClone[i][sortType] = false;
        }
      }
      return newClone;
    });
  }
  const [arrowState, setArrowState] = useState(columnSort);
  const [index, setIndex] = useState<number>();
  const classes = useStyles({ columns, arrowState, index });

  return (
    <TableHead classes={{ root: classes.Head }}>
      <TableRow>
        {columns.map((column: IColumn<T>, index: number) => (
          <Cell
            type={type}
            key={column.id}
            align={column.align}
            height={'24px'}
            size="small"
            width={column?.minWidth}
            className={clsx({
              [classes.sticky]: column.freeze && isFreezed,
              [classes.stickyLeft]: column.freeze && index === 0 && isFreezed,
              [classes.stickyRight]:
                column.freeze && index === columns.length - 1 && isFreezed,
            })}
          >
            <Skeleton loading={loading}>
              <Box>
                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {column.label}
                  </Box>

                  {!loading && sorting && !column.disableSorting && (
                    <Box display="flex" flexDirection="row" ml={1}>
                      <Box
                        onClick={() => {
                          handleEditSorting(index, 'up');
                          const sortedArray = sortByKey<T>(
                            tableData,
                            column.id,
                            'up'
                          );
                          setTableData(
                            columnSort[index].up ? sortedArray : initialData
                          );
                          setIndex(index);
                        }}
                        style={{
                          cursor: 'pointer',
                          visibility:
                            column.id === 'actions' ? 'hidden' : 'initial',
                        }}
                        className={
                          arrowState[index]?.up
                            ? classes.selectedUpSortIcon
                            : classes.sortIcon
                        }
                        mr={0.5}
                      >
                        <UpSortIcon />
                      </Box>
                      <Box
                        onClick={() => {
                          handleEditSorting(index, 'down');

                          const sortedArray = sortByKey<T>(
                            tableData,
                            column.id,
                            'down'
                          );
                          setTableData(
                            columnSort[index].down ? sortedArray : initialData
                          );
                          setIndex(index);
                        }}
                        style={{
                          cursor: 'pointer',
                          visibility:
                            column.id === 'actions' ? 'hidden' : 'initial',
                        }}
                        className={
                          arrowState[index]?.down
                            ? classes.selectedDownSortIcon
                            : classes.sortIcon
                        }
                      >
                        <DownSortIcon />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Skeleton>
          </Cell>
        ))}
      </TableRow>
    </TableHead>
  );
}
