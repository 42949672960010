import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { log } from 'config/log';
import useModal from 'hooks/useModal';
import { calculateAge } from 'utils/dateUtils';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import BasicModal from 'components/ui/Modal';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import DeleteModalContent from 'components/DeleteHandler/DeleteModalContent';
import { ReactComponent as Error } from 'assets/illustrations/error.svg';
import PatientListHeader from './PatientListPageHeader';
import MissingInformation from 'components/MissingInformation';
import useCreatePatientRequest from '@eggmed/graphql-client/operations/patientRequestOperations/useCreatePatientRequest';
import { useToast } from 'hooks/useToast';
import useRemoveAllAccessToDoctor from '@eggmed/graphql-client/operations/patientAccessControlOperations/useRemoveAllAccessFromDoctor';
import useRemoveRelatedPatient from '@eggmed/graphql-client/operations/patientAccessControlOperations/useRemoveRelatedPatient';
import { gql, useQuery } from '@apollo/client';
import { ReactComponent as DeleteIcon } from 'assets/delete-icon.svg';
import { ReactComponent as EditIcon } from 'assets/edit-icon.svg';
import { ReactComponent as ViewIcon } from 'assets/view-icon.svg';
import { ReactComponent as Assign } from 'assets/assignProvider.svg';
import { ReactComponent as EmptyClient } from 'assets/emptyState/client.svg';
import { ReactComponent as DotsIcon } from 'assets/dashboard/finances/dots.svg';

import { colors } from './colors';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import { useProvider as useAssignProviderContext } from 'pages/PatientListPage/state/Provider';

import { useSnackbar } from 'hooks/useSnackbar';
import EmptyState from 'pages/DashboardPage/EmptyState';
import Text from 'Text';
import useEditPatient, {
  useAssignProvider,
} from '@eggmed/graphql-client/operations/patientOperations/useEditPatient';
import { ReactComponent as NewTabIcon } from 'assets/NewTabIcon.svg';
import TagsCell from 'pages/AdminPage/TabsContent/templatesTabs/TagsCell';
import ChipPreview from './ChipPreview';
import useAddCheckPermission from 'contexts/AddPermissionOntext';
import Menu from 'components/ui/Menu';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import AssignProvider from './AssignProvider';

const useStyles = makeStyles((theme) => ({
  dots: {
    cursor: 'pointer',
  },
  textnoassign: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '12px',
    color: '#B42318',
  },
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    padding: '20px',
  },
  container: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('xl')]: {
      marginBottom: 0,
    },
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  addPatientModal: {
    width: '40vw',
    [theme.breakpoints.only('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
    },
  },
  conditions: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',
  },
  condition: {
    borderRadius: '16px',
    border: '1px solid #B2DDFF',
    width: 'fit-content',
    padding: '3px 7px',
  },
  conditionPlus: {
    backgroundColor: '#F9FAFB',
    color: '#344054',
    borderRadius: '16px',
    border: '1px solid #EAECF0',
    width: 'fit-content',
    padding: '3px 7px',
  },
  chipBox: {
    backgroundColor: '#F0F9FF',
    border: '1px solid #B9E6FE',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
  },
  chipText: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#026AA2',
  },
}));
export const insertAndReturn = (arr, index, element) => {
  const newArr = [...arr];
  newArr.splice(index, 0, element);
  return newArr;
};
const PatientPage = (): ReactElement => {
  const { isEnabled } = useAddCheckPermission('Clients');

  const { t } = useTranslation();
  const { triggerSnack } = useSnackbar();
  const classes = useStyles();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  const { doctor, user } = useAuth();
  const isAdmin = ['Admin', 'owner']?.includes(user?.role);
  const history = useHistory();
  const { state, dispatch } = useModal();

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [currentRow, setCurrentRow] = useState<IPatientData | undefined>();
  const [valueSwitch, setValueSwitch] = React.useState(false);
  const { handleOpenPatientModal } = useProvider();
  const {
    assignProviderTitle,
    openProviderAssignModal,
    handleCloseAssignModal,
    handleOpenAssignModal,
  } = useAssignProviderContext();

  const methods = useForm({
    defaultValues: {
      selectedProviders: (currentRow as any)?.relatedTo,
      selectedProvider: {},
    },
  });

  const { reset, setValue } = methods;

  useEffect(() => {
    if (currentRow) {
      reset({
        selectedProviders: (currentRow as any)?.relatedTo || [],
      });
    }
  }, [currentRow, reset, doctor]);

  const { handleSubmit } = methods;
  const { handleRemoveRelatedPatient } = useRemoveRelatedPatient();
  const onSubmit = async (data) => {
    // data to be sent to backend
    const relatedProvidersIDs = data?.selectedProviders?.map(
      (provider) => provider?._id
    );

    await assignProvider(
      {
        EditPatientInput: {
          updatedAt: new Date(),
          email: currentRow?.email,
          firstname: currentRow?.firstname,
          lastname: currentRow?.lastname,
          relatedTo: relatedProvidersIDs,
        },
        patientId: currentRow?._id,
      },
      (e) => log('error', e)
    );
    handleCloseAssignModal();
    triggerSnack();
  };
  const [values, setValues] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [id, setId] = React.useState('');

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(PATIENTS_RELATED, {
    variables: {
      doctors: values?.map((el) => el.doctorId),
      isUnassign: valueSwitch,
    },
    fetchPolicy: 'network-only',
  });
  const patients = data?.listUnassignedClient;

  const { assignProvider, loading: loadingAssign } = useAssignProvider();
  const { updatePatient, loading: loadingPatient } = useEditPatient();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Patients' });
  }, [headerTitleDispatcher]);

  if (error)
    return (
      <Box p={4} display="flex" alignItems="center" justifyContent="center">
        <Error />
      </Box>
    );

  const handleDelete = (row: IPatientData) => {
    setCurrentRow(row);
    handleModalOpen();
  };
  const handleChange = (e, row: IPatientData) => {
    e.stopPropagation();
    setId(row?._id);
    setCurrentRow(row);
  };
  async function handleNavigate(row) {
    history.push(`/doctor/patient/${row?._id}`);
    await updatePatient(
      {
        EditPatientInput: {
          updatedAt: new Date(),
          email: row?.email,
          firstname: row?.firstname,
          lastname: row?.lastname,
        },
        picture: undefined,
        patientId: row?._id,
      },
      (e) => log('error', e)
    );
  }

  const columns: IColumn<IPatientData>[] = [
    {
      id: 'firstname',
      label: (
        <Box fontSize="1rem">
          <Text className={classes.text} i18nKey="name">
            Name
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 220,
      freeze: true,
      format: (
        value,
        { firstname, middlename, lastname, picture, _id, isDemo }
      ) => {
        const avatarPicture = picture?.split('/')[3];
        return (
          <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
            <Avatar
              alt="Patient picture"
              src={avatarPicture !== 'undefined' ? picture : ''}
            >
              {`${firstname && firstname[0].toUpperCase()}${
                lastname && lastname[0].toUpperCase()
              }`}
            </Avatar>
            <Box pl={2} sx={{ fontWeight: 500 }}>{`${firstname} ${
              middlename || ''
            } ${lastname}`}</Box>
            {isDemo && (
              <Box ml={1} className={classes.chipBox} px={1}>
                <Typography className={classes.chipText}>
                  Demo client
                </Typography>
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      id: 'birthday',
      label: (
        <Box fontSize="1rem">
          <Text className={classes.text} i18nKey="age">
            Age
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 60,
      format: (value) => {
        return calculateAge(value);
      },
    },
    {
      id: 'address',
      label: (
        <Box fontSize="1rem">
          <Text className={classes.text} i18nKey="address">
            Address
          </Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => {
        return value ?? '-';
      },
    },
    {
      id: 'email',
      label: (
        <Box fontSize="1rem">
          <Text className={classes.text} i18nKey="emailAddress">
            Email address
          </Text>
        </Box>
      ),
      minWidth: 300,
      align: 'left',
      format: (value) => value,
    },
    {
      id: 'phone',
      label: (
        <Box fontSize="1rem">
          <Text className={classes.text} i18nKey="phone">
            Phone number
          </Text>
        </Box>
      ),
      minWidth: 170,
      align: 'left',
      format: (value) => {
        return value ? '+' + value : '-';
      },
    },
    {
      id: 'conditions',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Diagnosis" className={classes.text}>
            Diagnosis
          </Text>
        </Box>
      ),
      minWidth: 300,
      align: 'left',
      format: (value, { conditions }: any) => {
        const conds = conditions
          ?.filter((val) => val?.diagnosisCode !== null)
          .filter((val) => val.isDisplayed)
          ?.map((cond, index) => ({
            _id: cond?._id,
            color: colors[index]?.color,
            backgroundColor: colors[index]?.background,
            border: colors[index]?.border,
            tag: cond?.diagnosisCode['description'],
          }));
        return (
          conds?.length > 0 && (
            <TagsCell
              tags={conds}
              page={page}
              editMode={false}
              setEditMode={(value) => {}}
              isSelected={false}
              diagnosis={true}
              templateId={null}
            />
          )
        );
      },
    },
    {
      id: 'actions',
      label: '',
      minWidth: 10,
      align: 'left',
      freeze: true,
      format: (value, row) => (
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            minWidth="40px"
            minHeight="40px"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
              setCurrentRow(row);
              handleChange(e, row);
            }}
          >
            <DotsIcon className={classes.dots} />
          </Box>
        </Box>
      ),
    },
  ];

  const allColumns = ['Admin', 'owner']?.includes(user?.role)
    ? insertAndReturn(columns, 4, {
        id: 'relatedTo',
        label: (
          <Box fontSize="1rem">
            <Text i18nKey="Assigned providers" className={classes.text}>
              Assigned providers
            </Text>
          </Box>
        ),
        minWidth: 700,
        // align: 'left',
        format: (value) => {
          return (
            <>
              {value?.length > 0 ? (
                <Box display="flex" alignItems="center">
                  <ChipPreview
                    value={value?.map((el) => {
                      return {
                        ...el,
                        name: `${el?.firstname} ${el?.lastname}`,
                      };
                    })}
                    maxVisible={2}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  border="1px solid #FECDCA"
                  borderRadius="16px"
                  padding="3px 8px"
                  style={{ backgroundColor: '#FEF3F2' }}
                >
                  <Text
                    i18nKey="No provider assigned"
                    className={classes.textnoassign}
                  >
                    No provider assigned{' '}
                  </Text>
                </Box>
              )}
            </>
          );
        },
      })
    : columns;

  const handleCloseDropDownMenu = () => {};
  const goToPatientDetails = async (row) => {
    window.open(`/doctor/patient/${row?._id}`, '_blank');
    await updatePatient(
      {
        EditPatientInput: {
          updatedAt: new Date(),
          email: row?.email,
          firstname: row?.firstname,
          lastname: row?.lastname,
        },
        picture: undefined,
        patientId: row?._id,
      },
      (e) => log('error', e)
    );
  };

  function handlePagination(e: unknown, pageNumber: number) {
    setPage(pageNumber);
  }
  function handleModalClose() {
    dispatch({ type: 'close' });
  }
  function handleModalOpen() {
    dispatch({ type: 'open' });
  }
  const handleOpenAddModal = () => {
    if (isEnabled) {
      handleOpenPatientModal();
    }
  };
  async function handleDeletePatient() {
    const { _id: id } = currentRow as any;
    await handleRemoveRelatedPatient(id, true);
    // handleRemoveAllAccessToDoctor(doctor?._id, id)
    //   .then(() => {
    //     refetch();
    //     handleModalClose();
    //     // toast.success('Deleted!');
    //   })
    //   .catch((e) => log('error', e));
    handleCloseDropDownMenu();
    handleModalClose();
    triggerSnack('Client successfully deleted');
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const MenuElements = [
    {
      name: t('View profile'),
      onClick: (e) => {
        e.stopPropagation();
        goToPatientDetails(currentRow);
      },
      avatar: <ViewIcon />,
    },
    ...(isAdmin
      ? [
          {
            name: t('Assign provider'),
            onClick: (e) => {
              e.stopPropagation();
              handleOpenAssignModal();
            },
            avatar: <Assign />,
          },
        ]
      : []),
    {
      name: t('Remove'),
      isRed: true,
      onClick: (e) => {
        setAnchorEl(null);
        e.stopPropagation();
        if (!currentRow?.isDemo && currentRow?.isDelete) {
          handleDelete(currentRow);
        }
      },
      avatar: (
        <DeleteIcon
          data-cy="delete-action"
          style={{
            cursor: !currentRow?.isDemo ? 'pointer' : 'default',

            opacity: currentRow?.isDemo || !currentRow?.isDelete ? 0.5 : 1,
          }}
        />
      ),
    },
  ];

  return (
    <Box className={classes.root}>
      <PatientListHeader
        handleOpen={handleOpenAddModal}
        limit={limit}
        values={values}
        setValues={setValues}
        setLimit={setLimit}
        isEnabled={isEnabled}
        valueSwitch={valueSwitch}
        setValueSwitch={setValueSwitch}
      />
      <Box className={classes.container}>
        <Table<IPatientData>
          loading={isLoading}
          page={page}
          isFreezed={true}
          columns={allColumns}
          limit={limit}
          handlePagination={handlePagination}
          data={patients || []}
          pagination
          sorting
          onRowClick={(row) => {
            handleNavigate(row);
          }}
          EmptyState={
            <Box height="70vh">
              <EmptyState
                icon={<EmptyClient />}
                title="No clients yet"
                text="to add a client."
                onClick={handleOpenAddModal}
                isClickLeft
                isText
              />
            </Box>
          }
        />
      </Box>
      <BasicModal
        isRegularModal
        open={state.open}
        onClose={handleModalClose}
        handleClose={handleModalClose}
        className={classes.deleteModal}
      >
        <Box>
          <DeleteModalContent
            text="client"
            handleClose={handleModalClose}
            handleDelete={handleDeletePatient}
            name={`${currentRow?.firstname} ${currentRow?.lastname}`}
          />
        </Box>
      </BasicModal>
      <Menu<any>
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={handleCloseMenu}
        MenuElements={MenuElements}
      />
      <FormProvider {...methods}>
        <BasicModal
          open={openProviderAssignModal}
          onClose={() => {
            handleCloseAssignModal();
            setValue('selectedProviders', (currentRow as any).relatedTo);
          }}
          handleClose={() => {
            handleCloseAssignModal();
            setValue('selectedProviders', (currentRow as any).relatedTo);
          }}
          isFromModal
          isSlide
          divider
          title={t(assignProviderTitle)}
          titlePadding="2rem"
          onSubmit={handleSubmit(onSubmit)}
          loading={loadingAssign}
        >
          <Box
            px="2rem"
            py={1}
            sx={{
              width: {
                xs: '50vw',
                lg: '43vw',
                xl: '35vw',
              },
            }}
            height="100%"
          >
            <AssignProvider patient={currentRow} />
          </Box>
        </BasicModal>
      </FormProvider>
      {/* <DropdownMenu
        goToPatientDetails={goToPatientDetails}
        anchorEl={dropDownEL}
        handleClose={handleCloseDropDownMenu}
        handleDeletePatient={handleModalOpen}
      /> */}
      {/* <FormProvider {...methods}>
        <BasicModal
          open={addModal}
          isFromModal
          onSubmit={onsubmit}
          title="Invite New Patient"
          onClose={handleCloseAddModal}
          handleClose={handleCloseAddModal}
          className={classes.addPatientModal}
        >
          <InviteNewPatient
            values={values}
            setValues={setValues}
            handleNavigate={handleNavigate}
          />
        </BasicModal>
      </FormProvider> */}
    </Box>
  );
};

export default PatientPage;

export const PATIENTS_RELATED = gql`
  query patientsRelated($doctors: [ID], $isUnassign: Boolean) {
    patientsRelated(doctors: $doctors) {
      _id
      isDemo
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      picture
      gender
      bloodType
      createdAt
      age
      state
      conditions {
        isDisplayed
        diagnosisCode {
          description
        }
      }
      relatedTo {
        _id
        firstname
        lastname
        picture
        role
      }
      isDelete
    }
    listUnassignedClient(doctors: $doctors, isUnassign: $isUnassign) {
      _id
      isDemo
      firstname
      lastname
      middlename
      phone
      birthday
      email
      completed
      picture
      address
      description
      city
      zipcode
      country
      suite
      picture
      gender
      bloodType
      createdAt
      age
      state
      conditions {
        isDisplayed
        diagnosisCode {
          description
        }
      }
      relatedTo {
        _id
        firstname
        lastname
        picture
        role
      }
      isDelete
    }
  }
`;
