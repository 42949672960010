import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { ReactComponent as IntakeFormIcon } from 'assets/form/templates/intake.svg';
import { ReactComponent as AssessmentFormIcon } from 'assets/form/templates/assessment.svg';
import { ReactComponent as NoteTakingIcon } from 'assets/form/templates/note-taking.svg';
import { ReactComponent as OtherIcon } from 'assets/form/templates/other.svg';
import { ReactComponent as Radio } from 'assets/form/templates/radio.svg';
import { ReactComponent as ClickedRadio } from 'assets/form/templates/clicked-radio.svg';
import { ReactComponent as CheckedMark } from 'assets/form/templates/checked-mark.svg';
import clsx from 'clsx';
import useStyles from './styles';
import { templateEnum, useEditorContext } from '../EditorContextProvider';
import Text from 'Text';

const templates = [
  {
    name: 'Intake',
    value: templateEnum.intakeForm,
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <IntakeFormIcon />,
  },
  {
    name: 'Assessment',
    value: templateEnum.assessmentForm,
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <AssessmentFormIcon />,
  },
  {
    name: 'Note-taking',
    value: templateEnum.noteTaking,
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <NoteTakingIcon />,
  },
  {
    name: 'Other',
    value: templateEnum.other,
    clicked: <ClickedRadio />,
    notClicked: <Radio />,
    icon: <OtherIcon />,
  },
];

const TemplateItem = ({ name, icon, selected, onSelect }) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.templateItem, selected && classes.selected)}
      onClick={onSelect}
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Box className={classes.iconSideBar} style={{ paddingRight: '4px' }}>
          {icon}
        </Box>
        <Text className={classes.templateName} i18nKey={name}>
          {name}
        </Text>
      </Box>
      {selected && <CheckedMark className={classes.checkIcon} />}
    </Box>
  );
};

//------------------------------------------------------------------------------\

export const SaveTemplateAs = () => {
  const { selectedTemplate, handleSelectedTemplate } = useEditorContext();
  const classes = useStyles();
  return (
    <Box className={classes.stpRoot}>
      <Box style={{ padding: '0 1.25rem 0' }}>
        <Text className={classes.stpTitle} variant="h6" i18nKey="saveAs">
          {'Save this template as'.toUpperCase()}
        </Text>
        <Box className={classes.templates}>
          {templates.map((template) => (
            <TemplateItem
              key={template.value}
              name={template.name}
              icon={template.icon}
              selected={selectedTemplate === template.value}
              onSelect={() => handleSelectedTemplate(template.value)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
