import { Box, Typography, Checkbox, InputAdornment } from '@material-ui/core';
import React, { SetStateAction, useEffect, useState } from 'react';
import useClipBoard from 'hooks/useClipBoard';
import PatientSelect from './PatientSelect';
import useStyles from './styles';
import { IDateRange } from '../types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import SessionSelect from './SessionSelect';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { convertObjectsFormat, convertTagFormat } from '../utils';
import { addStart, getDayName } from 'utils';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import FrequencyComponent from './FrequencyComponent';
import TagComponent from './TagComponent';
import { useTag } from 'pages/AdminPage/ManageTags/useTag';
import BasicModal from 'components/ui/Modal';
import { ConflictModal } from '../Conflicts/ConflictModal';
import { convertToRawAndStringify } from '@eggmed/common/utils/DraftUtils';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { convertTime, newDate, toDayjs } from 'utils/dateUtils';
import { RequirePrepayment } from './RequirePrepayment';
import { DateAndTime } from './DateAndTime';
import { Notes } from './Notes';
import { ReactComponent as FrequencyIcon } from 'assets/Schedule/new/freq.svg';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import dayjs from 'dayjs';
import Text from 'Text';
import Button from 'components/ui/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OnlineIcon } from 'assets/Schedule/new/online.svg';
import { ReactComponent as InPersonIcon } from 'assets/Schedule/new/in-person.svg';
import { ReactComponent as ConflictIcon } from 'assets/conflicts/conflicts-icon.svg';
import { ReactComponent as EggmedIcon } from 'assets/EggmedLocation.svg';
import { ReactComponent as EggmedIconZoom } from 'assets/EggmedZoom.svg';
import { ReactComponent as ZoomIcon } from 'assets/ZoomLocation.svg';
import { ReactComponent as Zoom } from 'assets/Zoom.svg';
import { ReactComponent as Copy } from 'assets/copyLogo.svg';
import { ReactComponent as Alert } from 'assets/alert.svg';
import { ReactComponent as Info } from 'assets/info.svg';
import Input from 'components/ui/Inputs';
import { useSnackbar } from 'hooks/useSnackbar';
import clsx from 'clsx';
import SearchLocation from 'pages/AdminPage/Payment/SearchLocation';
import { useGetLocationsByDoctor } from '@eggmed/graphql-client/operations/locationOperation/useGetLocationsByDoctor';

interface IEventFormProps {
  dateRange: IDateRange;
  handleChangeDate: (
    name: 'endDate' | 'startDate',
    value: string | number
  ) => void;
  members?: any[];
  loadingData?: boolean;
  patients?: any[];
  handleAddPatient: (data: any) => Promise<boolean>;
  addPatientError: string;
  meetingLink?: string;
  error?: string;
  setError?: (error: string) => void;
  setDateRange?: ({ startDate, endDate }) => void;
  errorMsg?: string;
  isPrepay?: boolean;
  doctorFreeTimes?: string[];
  loadingFreeTimes?: boolean;
  timeValues?: string;
  time?: string;
  setTime: (time: string) => void;
  editMode?: boolean;
  clickableEvent?: any;
  isClickable?: boolean;
  generateAccountLoading?: boolean;
  errorGenerateAccount?: ApolloError;
  getDateWithOutTime?: string;
  activeMeeting?: boolean;
  setActiveMeeting?: React.Dispatch<SetStateAction<boolean>>;
  selectedColorId: string;
  setSelectedColorId: React.Dispatch<React.SetStateAction<string>>;
  handleClickTag?: () => void;
  handleOpenAddPatient?: () => void;
  pickedRightTime?: boolean;
  setPickedRightTime?: React.Dispatch<SetStateAction<boolean>>;
  isActivePayout?: boolean;
  feedMode?: boolean;
}
interface IDate {
  startDate: string;
  endDate: string;
}

export default function EventForm({
  dateRange,
  handleChangeDate,
  errorMsg,
  doctorFreeTimes,
  timeValues,
  loadingFreeTimes,
  time,
  setTime,
  editMode,
  clickableEvent,
  isClickable,
  getDateWithOutTime,
  selectedColorId,
  setSelectedColorId,
  handleClickTag,
  handleOpenAddPatient,
  pickedRightTime,
  setPickedRightTime,
  isActivePayout,
  feedMode = false,
  setDateRange
}: IEventFormProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { triggerSnack } = useSnackbar();
  const { data: locationsData } = useGetLocationsByDoctor();
  const {
    testIndex,
    setTestIndex,
    expanded,
    doctorData,
    frequencyExpand,
    handleFrequencyAccordionChange,
    currentEvent,
    patientAdded,
  } = useProvider();
  React.useEffect(() => {
    if (editMode) {
      setTestIndex(false);
    }
  }, [editMode]);
  const [isActive, setIsActive] = useState();

  const [availableTime, setAvailableTime] = useState();
  const isDefaultTime = (element) => {
    if (isClickable) {
      return addStart(clickableEvent) === element && !testIndex;
    }
    return timeValues === element && editMode && !testIndex;
  };
  const pushedArray =
    doctorFreeTimes &&
    [...doctorFreeTimes, addStart(dateRange as IDate)].sort();
  const getExactFreeTime = editMode
    ? [...new Set(pushedArray)]
    : doctorFreeTimes;

  React.useEffect(() => {
    setPickedRightTime(getExactFreeTime?.includes(time));
  }, [getExactFreeTime, time]);

  const { handleCopyToClipboard } = useClipBoard();
  const { doctor, dataSubscription, loadingSubscription } = useAuth();
  const { register, control, errors, watch, reset, getValues, setValue } =
    useFormContext();

  const commonProps = { register, errors, control, setValue, watch };
  const {
    description,
    conditionType,
    patient,
    sessionType,
    repeatOn,
    endOn,
    repeatEvery,
    numberOfRepeat,
    locationLink,
    isPrepay: prepayment,
  } = getValues() || {};
  const {
    rate,
    duration,
    __typename,
    isFree,
    locationInPerson,
    tags: tagg,
    isPrepayment,
    ...rest
  } = sessionType || {};
  const activeMeeting = useWatch({ name: 'activeMeeting' }) as boolean;
  const isRateNull = rate == 0;
  const prepayValue =
    isFree || isRateNull || !isActivePayout ? false : activeMeeting;
  useEffect(() => {
    setValue('isPrepay', prepayValue);
  }, [
    activeMeeting,
    isActivePayout,
    isFree,
    isRateNull,
    prepayValue,
    setValue,
  ]);

  const appointmentInput = {
    description: description && convertToRawAndStringify(description),
    locationLink,
    conditionType: conditionType?.id,
    doctor: doctor?._id,
    patient: patient?._id,
    startDate: convertTime(getDateWithOutTime, time, doctorData?.timezone),
    isPrepay: prepayment,
    sessionType: {
      ...rest,
      isFree,
      isPrepayment: prepayment,
      locationId: locationInPerson?._id,
      rate: rate?.toString(),
      duration: duration?.toString(),
      assignedRoles: (rest as any)?.assignedRoles?.map((el) => el?._id),
      assignedProviders: (rest as any)?.assignedProviders?.map((el) => el?._id),
    },
    repeatOn: frequencyExpand ? repeatOn : undefined,
    // Check how to process this
    endOn: frequencyExpand
      ? new Date(endOn).setHours(
          new Date(
            convertTime(getDateWithOutTime, time, doctorData?.timezone)
          ).getHours()
        )
      : undefined,
    repeatEvery: frequencyExpand ? repeatEvery : undefined,
    numberOfRepeat: frequencyExpand ? Number(numberOfRepeat) : undefined,
  };
  const [open, setOpen] = React.useState(false);
  const [openConflict, setOpenConflict] = React.useState<boolean>(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const [matchingPatient, setMatchingPatient] = React.useState(true);
  const relatedPatient = dataSubscription?.patientsRelated;
  const [options, setOptions] = React.useState(
    convertObjectsFormat(relatedPatient, 'Patients')
  );

  const classes = useStyles({ expanded });
  React.useEffect(() => {
    if (relatedPatient) {
      setOptions(convertObjectsFormat(relatedPatient, 'Patients'));
    }
  }, [relatedPatient]);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  const handleClick = (index, times) => {
    setIsActive(index);
    setAvailableTime(times);
    setTime(times);
  };
  const { data: tags, loadingTags } = useTag();
  const onClose = () => {
    setOpenConflict(false);
  };

  React.useEffect(() => {
    if (dateRange?.startDate && !currentEvent?.endOn) {
      setValue(
        'endOn',
        toDayjs(new Date(dateRange?.startDate)).add(3, 'month')
      );
    } else {
      setValue('endOn', new Date(currentEvent?.endOn));
    }
  }, [dateRange?.startDate, currentEvent?.endOn]);

  const { data: manageConflicts } = useQuery(MANAGE_CONFLICTS, {
    variables: {
      appointmentInput,
    },
  });

  React.useEffect(() => {
    if (patientAdded) {
      reset({
        ...getValues(),
        patient: {
          ...patientAdded,
          name: `${patientAdded?.firstname ?? ''} ${
            patientAdded?.lastname ?? ''
          }`,
        },
      });
    }
  }, [reset, patientAdded]);
  return (
    <>
      <Box>
        {!isActivePayout && (
          <Box
            display="flex"
            flexDirection="column"
            className={classes.payoutAlert}
            px={2}
            py={2.5}
            mb={2}
          >
            <Box display="flex" flexDirection="column">
              <Typography className={classes.alertMsg}>
                {t(
                  'Missing payout information, set up your payout method to receive payments.'
                )}
              </Typography>
              <Button
                className={classes.managePayout}
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/doctor/admin?tab=13');
                }}
              >
                {t('Manage payout settings')}
              </Button>
            </Box>
          </Box>
        )}
        <PatientSelect
          data-cy="add-appointment-patient"
          options={options}
          loading={loadingSubscription}
          setMatchingPatient={setMatchingPatient}
          handleInputChange={handleInputChange}
          inputValue={inputValue}
          errors={errors}
          editMode={editMode}
          handleOpenAddPatient={handleOpenAddPatient}
          {...commonProps}
        />
        <SessionSelect
          feedMode={feedMode}
          control={control}
          register={register}
          errors={errors}
          hasMeetingOptions={false}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          my={2}
        >
          <Box width={'50%'}>
            <Text
              i18nKey="Location"
              className={classes.textTitle}
              style={{ marginBottom: '5px' }}
            >
              Location
            </Text>
            <Controller
              name="activeMeeting"
              control={control}
              render={({ onChange, value }) => (
                <Box
                  className={classes.eventType}
                  style={{ opacity: feedMode ? 0.4 : 1 }}
                >
                  <Box
                    className={clsx(
                      classes.oneEvent,
                      !value && classes.activeEvent
                    )}
                    onClick={!feedMode ? () => onChange(false) : undefined}
                  >
                    <InPersonIcon />
                    <Text i18nKey="inPerson">In-person</Text>
                  </Box>
                  <Box
                    className={clsx(
                      classes.oneEvent,
                      value && classes.activeEvent
                    )}
                    onClick={() => {
                      onChange(true);
                    }}
                  >
                    <OnlineIcon />
                    <Text i18nKey="online">Online</Text>
                  </Box>
                </Box>
              )}
            />
          </Box>

          <ConflictIcon
            style={{
              marginTop: '25px',
              marginInline: '10px',
              width: '16px',
            }}
          />

          {watch().activeMeeting && (
            <Box width="50%">
              <Text
                i18nKey="Online session platform"
                className={classes.textTitle}
                style={{ marginBottom: '5px' }}
              >
                Online session platform
              </Text>
              <Controller
                name="locationPlace"
                control={control}
                render={({ onChange, value }) => (
                  <Box
                    className={classes.eventType}
                    style={{ opacity: feedMode ? 0.4 : 1 }}
                  >
                    <Box
                      className={clsx(
                        classes.oneEvent,
                        classes.onlineEvent,
                        value === 'Eggmed' && classes.activeEvent
                      )}
                      onClick={!feedMode ? () => onChange('Eggmed') : undefined}
                    >
                      {value !== 'Eggmed' ? <EggmedIcon /> : <EggmedIconZoom />}
                      <Typography>Eggmed</Typography>
                    </Box>
                    <Box
                      className={clsx(
                        classes.oneEvent,
                        value === 'Zoom' && classes.activeEvent
                      )}
                      onClick={!feedMode ? () => onChange('Zoom') : undefined}
                    >
                      {value === 'Zoom' ? <Zoom /> : <ZoomIcon />}
                      <Typography>Zoom</Typography>
                    </Box>
                  </Box>
                )}
              />
            </Box>
          )}
          {!watch().activeMeeting && (
            <Box width="50%">
              <Text
                i18nKey="Physical location"
                className={classes.textTitle}
                style={{ marginBottom: '5px' }}
              >
                Physical location
              </Text>

              <Controller
                name="locationInPerson"
                control={control}
                render={({ value }) => (
                  <SearchLocation
                    locationsData={locationsData}
                    value={value}
                    commonProps={commonProps}
                    feedMode={feedMode}
                  />
                )}
              />
            </Box>
          )}
        </Box>
        {watch().locationPlace === 'Zoom' && (
          <Box mt={2}>
            <Typography
              className={classes.textTitle}
              style={{ marginBottom: '5px' }}
            >
              Session link
            </Typography>
            <Controller
              control={control}
              name="externalUrl"
              render={({ onChange, value }) => (
                <Input
                  type="text"
                  defaultValue={value}
                  onChange={onChange}
                  {...commonProps}
                  inputRef={commonProps.register}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          className={classes.copyBtn}
                          onClick={(e) => {
                            e.preventDefault();
                            handleCopyToClipboard(value);
                            triggerSnack(t('Link copied successfully'));
                          }}
                        >
                          <Copy
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                          />
                          <Typography className={classes.copyText}>
                            {t('copy URL')}
                          </Typography>
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Box display="flex" className={classes.alert} mt={2}>
              <Alert />
              <Typography className={classes.alertText}>
                {t('Anyone with this URL can access the session')}
              </Typography>
            </Box>
            <Box className={classes.infoBox} mb={2}>
              <Box
                style={{
                  gap: '10px',
                }}
              >
                <Box className={classes.infoboxTitle}>
                  <Info />
                  <Typography className={classes.infoTitle}>
                    {t('Important notice')}
                  </Typography>
                </Box>
                <Typography>
                  By enabling this third-party video service, you agree to
                  comply with HIPAA and protect client information, including
                  Telehealth links.
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        <RequirePrepayment
          feedMode={feedMode}
          isActivePayout={isActivePayout || !activeMeeting}
          control={control}
          isFree={isFree || !activeMeeting || isRateNull}
        />

        <DateAndTime
          feedMode={feedMode}
          dateRange={dateRange}
          handleChangeDate={!feedMode ? handleChangeDate : undefined}
          loadingFreeTimes={loadingFreeTimes}
          getExactFreeTime={getExactFreeTime}
          handleClick={!feedMode ? handleClick : undefined}
          setTestIndex={setTestIndex}
          isActive={isActive}
          isDefaultTime={isDefaultTime}
          pickedRightTime={pickedRightTime}
          setDateRange={setDateRange}
        />
        <Box
          className={classes.frequencyContainer}
          mb={2}
          style={{ opacity: feedMode ? 0.4 : 1 }}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" sx={{ gridGap: '1rem' }}>
              <FrequencyIcon />
              <Text i18nKey="frequency" className={classes.freqTitle}>
                Frequency
              </Text>
            </Box>
            <Checkbox
              name="frequencyExpanded"
              size="medium"
              defaultChecked={frequencyExpand}
              onChange={handleFrequencyAccordionChange}
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon />}
              style={{ backgroundColor: 'transparent' }}
              disabled={feedMode}
            />
          </Box>
          <Text
            i18nKey="Set up recurring sessions"
            className={classes.freqDesc}
            style={{
              height: !frequencyExpand ? 'unset' : 0,
              visibility: !frequencyExpand ? 'visible' : 'hidden',
              opacity: !frequencyExpand ? 1 : 0,
              transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            Set up recurring sessions
          </Text>

          <Box
            pr="9px"
            pl="2.5rem"
            pb={frequencyExpand && '9px'}
            pt={frequencyExpand && 3}
            style={{
              height: frequencyExpand ? 'unset' : 0,
              visibility: frequencyExpand ? 'visible' : 'hidden',
              overflow: frequencyExpand ? 'unset' : 'hidden',
              transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
          >
            <FrequencyComponent
              register={register}
              control={control}
              errors={errors}
              watch={watch}
              defaultValue={getDayName(dateRange?.startDate)}
              setOpenConflict={setOpenConflict}
              data={manageConflicts?.manageConflicts}
              dateRange={dateRange}
              name={'endOn'}
            />
          </Box>
        </Box>
        <TagComponent
          feedMode={feedMode}
          data-cy="add-tag"
          options={convertTagFormat(tags?.doctorTags)}
          loading={loadingTags}
          editMode={editMode}
          selectedColorId={selectedColorId}
          setSelectedColorId={setSelectedColorId}
          handleClickTag={handleClickTag}
          {...commonProps}
        />
        <Notes {...commonProps} />
        {errorMsg && (
          <Typography style={{ color: 'red' }}>{errorMsg}</Typography>
        )}
      </Box>
      <BasicModal
        open={openConflict}
        onClose={onClose}
        handleClose={onClose}
        isRegularModal
        isSlide
        className={classes.conflict}
        title={t('Manage conflicts')}
        divider
        titlePadding="4.5rem"
      >
        <ConflictModal
          onClose={onClose}
          data={manageConflicts?.manageConflicts}
        />
      </BasicModal>
    </>
  );
}

export const MANAGE_CONFLICTS = gql`
  query manageConflicts($appointmentInput: addAppointmentInput) {
    manageConflicts(appointmentInput: $appointmentInput) {
      description
      doctor {
        _id
        firstname
        lastname
        email
        picture
      }
      patient {
        _id
        firstname
        lastname
        email
        picture
      }
      startDate
      endDate
      location
      locationLink
      conditionType {
        _id
        tag
        color
      }
      sessionType {
        session
        rate
        duration
        currency
        isFree
        locationId {
          _id
          name
        }
      }
      isPaid
      invitation {
        invitation_id
      }
      doctorGoing
      patientGoing
    }
  }
`;
