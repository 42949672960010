import { gql, useMutation } from '@apollo/client';

export enum TypeEnum {
  Reflection = 'reflection',
  Mood = 'moodCheckin',
  Sleep = 'sleepCheckin',
  Form = 'form',
}
interface Ifrequency {
  startDate: Date;
  endDate: Date;
  frequencyType: string;
  repetition: {
    value: number;
    unit: string;
  };
  repeatOn: string[];
}

export interface IPatientActivitiesInput {
  reflectionType?: string;
  question: string;
  photo: boolean;
  sleep?: boolean;
  notes?: boolean;
  type: TypeEnum;
  options?: string[];
  frequency: Ifrequency;
  doctor: string;
  patient: string;
  assignedAt?: Date;
}

const CREATE_PATIENT_ACTIVITY = gql`
  mutation createPatientActivities(
    $patientActivitiesInput: PatientActivitiesInput
  ) {
    createPatientActivities(patientActivitiesInput: $patientActivitiesInput) {
      _id
    }
  }
`;

const useCreatePatientActivity = () => {
  return useMutation(CREATE_PATIENT_ACTIVITY);
};

export default useCreatePatientActivity;
