import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import ErrorIcon from '@material-ui/icons/Error';
import dayjs from 'dayjs';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DateRange from 'components/ui/Inputs/DateRange';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { ReactComponent as DescriptionIcon } from 'assets/icons/descriptionIcon.svg';
import ChipSelector from 'components/ui/ChipSelector';
import Switch from 'components/ui/Switch';
import useClipBoard from 'hooks/useClipBoard';
import DoctorSelect from './DoctorSelect';
import InputBlock from 'pages/SchedulePage/AddEventPage/EventForm/InputBlock';
import useStyles from 'pages/SchedulePage/AddEventPage/EventForm/styles';
import { IDateRange } from 'pages/SchedulePage/AddEventPage/types';
import { conditions as eventTypes } from 'pages/SchedulePage/constants';
import { ReactComponent as NoTimeIllustration } from 'assets/illustrations/NoTimesIllustration.svg';
import { IDoctorProps } from './DoctorSelect';
import ProgressLoader from 'components/ui/ProgressLoader';
import { getTimeDate, formatMonthYear, addStart } from 'utils';
import SessionSelect from 'pages/SchedulePage/AddEventPage/EventForm/SessionSelect';
import { ReactComponent as OnlineIcon } from 'assets/Schedule/new/online.svg';
import { ReactComponent as InPersonIcon } from 'assets/Schedule/new/in-person.svg';
import Text from 'Text';
import DatePickerSession from 'pages/SchedulePage/AddEventPage/EventForm/DatePickerSession';
interface IEventFormProps {
  isPatient?: boolean;
  control: Control<Record<string, any>>;
  register: (name: string) => void;
  errors: DeepMap<Record<string, any>, FieldError>;
  dateRange: IDateRange;
  handleChangeDate: (
    name: 'endDate' | 'startDate',
    value: string | number
  ) => void;
  loadingData: boolean;
  getValues: () => { title: string; locationLink: string };
  meetingLink: string;
  error: string;
  setError: (error: string) => void;
  doctors: IDoctorProps[];
  doctorFreeTimes: string[];
  time: string;
  setTime: (time: string) => void;
  setDateRange?: any;
  loadingFreeTimes?: boolean;
  timeValues?: string;
  editMode?: boolean;
  isClickable?: boolean;
  watch?: any;
  clickableEvent?: any;
  setValue?: any;
}
interface IDate {
  startDate: string;
  endDate: string;
}

export default function EventForm({
  isPatient = false,
  control,
  register,
  getValues,
  errors,
  meetingLink,
  dateRange,
  handleChangeDate,
  loadingData,
  doctors,
  doctorFreeTimes,
  time,
  setTime,
  loadingFreeTimes,
  timeValues,
  editMode,
  watch,
  isClickable,
  clickableEvent,
  setValue,
  setDateRange,
}: IEventFormProps) {
  const { startDate } = dateRange;
  const [isActive, setIsActive] = useState();
  const [testIndex, setTestIndex] = useState(false);
  const [availableTime, setAvailableTime] = useState();
  const classes = useStyles();
  const isDefaultTime = (element) => {
    if (isClickable) {
      return addStart(clickableEvent) === element && !testIndex;
    }
    return timeValues === element && editMode && !testIndex;
  };
  const pushedArray =
    doctorFreeTimes &&
    [...doctorFreeTimes, addStart(dateRange as IDate)].sort();
  const getExactFreeTime = editMode
    ? [...new Set(pushedArray)]
    : doctorFreeTimes;
  const { handleCopyToClipboard } = useClipBoard();
  const [patientForm, setPatientForm] = useState<boolean>(false);
  const [isChanged, setIsChanged] = React.useState(true);
  const formattedStartDate = dayjs(dateRange.startDate).format('MMMM D');

  const commonProps = { register, errors, control };
  const values = getValues();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleClick = (index, times) => {
    setIsActive(index);
    setAvailableTime(times);
    setTime(times);
  };

  const resetSessionType = () => {
    setValue('sessionType', null);
  };

  useEffect(() => {
    if (watch('sessionType')?.location === 'Online') {
      setValue('activeMeeting', true);
    }
    if (watch('sessionType')?.location === 'In-person') {
      setValue('activeMeeting', false);
    }
  }, [watch('sessionType')]);
  return (
    <>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <DoctorSelect
          data-cy="add-appointment-patient"
          options={doctors}
          loading={loadingData}
          resetSessionType={resetSessionType}
          {...commonProps}
        />
        <Box mb={4} />
        <SessionSelect
          isPatient={isPatient}
          control={control}
          register={register}
          errors={errors}
          hasMeetingOptions={false}
          doctorId={watch('doctor') ? watch('doctor')._id : ''}
        />
        <Box mb={4} />
        <Box className={clsx(classes.formPatientDate)}>
          <Box className={classes.dateWrapper}>
            <Box width="55%">
              <Text
                i18nKey="dateAndTime"
                className={clsx(classes.label, classes.bottomLabelSpacing)}
              >
                Date & time
              </Text>

              <DatePickerSession
                doctorId={watch('doctor')._id || ''}
                isSession
                color="#1F61DC"
                startDate={dateRange.startDate as Date}
                endDate={dateRange.endDate as Date}
                setStartDate={(startDate) =>
                  setDateRange((prev) => ({ ...prev, startDate }))
                }
                setEndDate={(endDate) =>
                  setDateRange((prev) => ({ ...prev, endDate }))
                }
                setIsChanged={setIsChanged}
                minDate={new Date()}
              />
            </Box>
            <Box
              style={{ gap: '10px' }}
              display="flex"
              flexWrap="wrap"
              mb={0}
              mt={'1.5rem'}
              width="100%"
              justifyContent="space-between"
            >
              {loadingFreeTimes ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  width="100%"
                  mt={2}
                  mb={2}
                >
                  <ProgressLoader width={60} height={60} />
                </Box>
              ) : (
                <>
                  {getExactFreeTime?.length > 0 && (
                    <Text
                      i18nKey="Availability for "
                      className={classes.labelTime}
                    >
                      {`Availability for `}{' '}
                      <Typography
                        style={{
                          color: '#147AF3',
                          fontWeight: 600,
                          marginLeft: '5px',
                        }}
                      >
                        {formattedStartDate}
                      </Typography>
                    </Text>
                  )}
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {getExactFreeTime?.length > 0 ? (
                      <Box
                        gridTemplateColumns={
                          getExactFreeTime?.length <= 6
                            ? '1fr'
                            : getExactFreeTime?.length <= 12
                            ? 'repeat(2, 1fr)'
                            : 'repeat(3, 1fr)'
                        }
                        className={classes.wrapTimes}
                      >
                        {getExactFreeTime?.map((el, index) => (
                          <Box
                            className={classes.freeTimes}
                            onClick={() => {
                              handleClick(index, el);
                              setTestIndex(true);
                            }}
                            style={{
                              userSelect: 'none',
                              backgroundColor:
                                (isActive === index || isDefaultTime(el)) &&
                                '#147AF3',
                              color:
                                (isActive === index || isDefaultTime(el)) &&
                                'white',
                              textAlign: 'center',
                              minWidth:
                                getExactFreeTime?.length <= 6
                                  ? '100%'
                                  : getExactFreeTime?.length <= 12
                                  ? 'calc(100% - 5px)'
                                  : '90%',
                            }}
                          >
                            {el}
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Box width="100%" alignItems={'flex-start'}>
                        <Text
                          i18nKey="No availability for "
                          className={classes.labelTime}
                        >
                          {`No availability for `}
                          <Typography
                            style={{
                              color: '#147AF3',
                              fontWeight: 600,
                              marginLeft: '5px',
                            }}
                          >
                            {' '}
                            {formattedStartDate}
                          </Typography>
                        </Text>
                        <Box className={classes.noAvaWrapper}>
                          <NoTimeIllustration />
                          <Text
                            i18nKey="No availability, select another date"
                            className={classes.textNoAvailability}
                          >
                            No availability, select another date
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>

          <Box alignSelf="flex-start" ml={1}>
            {errors && errors.sessionType && (
              <span data-cy="error-message" className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                {errors?.sessionType?.message}
              </span>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box mt={-0.5} mb={3}>
          <Text i18nKey="location" className={clsx(classes.label)}>
            Location
          </Text>
          <Controller
            name="activeMeeting"
            control={control}
            render={({ onChange, value }) => (
              <Box className={classes.eventType} mt={1}>
                <Box
                  className={clsx(
                    classes.oneEvent,
                    classes.onlineEvent,
                    value && classes.activeEvent
                  )}
                  onClick={() => onChange(true)}
                >
                  <OnlineIcon />
                  <Text style={{ fontWeight: 500 }} i18nKey="online">
                    Online
                  </Text>
                </Box>
                <Box
                  className={clsx(
                    classes.oneEvent,
                    !value && classes.activeEvent
                  )}
                  onClick={() => onChange(false)}
                >
                  <InPersonIcon />
                  <Text style={{ fontWeight: 500 }} i18nKey="inPerson">
                    {' '}
                    In-person
                  </Text>
                </Box>
              </Box>
            )}
          />
        </Box>
        <Box display="flex" alignItems="center" mt={3}>
          <Text i18nKey="notes" className={clsx(classes.label)}>
            Notes
          </Text>
        </Box>
        <InputBlock
          type="draftjs"
          name="description"
          data-cy="add-appointment-description"
          className={classes.flexElement}
          {...commonProps}
          isPatient
          Icon={''}
        />
        <Box className={classes.leftSpacing} mt={-1} alignSelf="flex-start">
          {errors && errors.description && (
            <span data-cy="error-message" className={classes.error}>
              <ErrorIcon className={classes.errorIcon} />
              {errors?.description?.message}
            </span>
          )}
        </Box>
      </Grid>
    </>
  );
}
