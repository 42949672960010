import React, { useState } from 'react';
import { IConflict } from './types';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import useDeleteAppointment from '@eggmed/graphql-client/operations/appointmentsOperations/useDeleteAppointment';
import useDeleteEvent from '@eggmed/graphql-client/operations/eventOperations/useDeleteEvent';
import useStyles from './styles';
import { Box, Typography, Avatar, Button } from '@material-ui/core';
import { getTimeDate, toDayjs } from 'utils/dateUtils';
import { ReactComponent as CalendarIcon } from 'assets/conflicts/calendar(1).svg';
import { ReactComponent as ClockIcon } from 'assets/conflicts/clock.svg';
import { ReactComponent as VideoIcon } from 'assets/conflicts/video-recorder.svg';
import { ReactComponent as EditIcon } from 'assets/conflicts/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/conflicts/trash.svg';
import { ReactComponent as MessageIcon } from 'assets/conflicts/message.svg';
import dayjs from 'dayjs';
import { useProvider } from 'pages/SchedulePage/state/SchedulePageProvider';
import { colorsMapping } from 'pages/AdminPage/ManageTags/Constant';
import { useProvider as useInbox } from 'pages/InboxPage/state/Provider';
import { useSnackbar } from 'hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
type IConflictCard = {
  event: IConflict;
  onClose?: () => void;
};
export enum EventType {
  APPOINTMENT = 'session',
  EVENT = 'event',
}

export const ConflictCard = ({ event, onClose }: IConflictCard) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { deleteAppointment, loading: loadingSession } = useDeleteAppointment();
  const { deleteEvent, loading: loadingEvent } = useDeleteEvent();
  const classes = useStyles();
  const {
    handleEditCurrentEvent,
    handleBack,
    setPatientAdded,
    handleOpenConflictState,
  } = useProvider();
  const { handleOpenCreateNewThreadModal } = useInbox();
  const { patient, title, conditionType, sessionType } = event;
  const { triggerSnack } = useSnackbar();
  const [startTime, endTime] = getTimeDate(
    {
      startDate: event.startDate,
      endDate: event.endDate,
    },
    true
  );

  function handleCloseDeleteModal() {
    setOpenDeleteModal(false);
  }

  function handleOpenDeleteModal() {
    if (event?.doctorGoing) {
      setOpenDeleteModal(true);
    } else {
      onClose();
    }
  }

  const handelDelete = async (id: string) => {
    if (!event?.title) {
      setOpenDeleteModal(true);
      await deleteAppointment(id, true, null);
    } else {
      await deleteEvent(id, true, null);
    }
    setOpenDeleteModal(false);
    triggerSnack('Changes deleted successfully');
  };
  return (
    <>
      <Box className={classes.conflictCard}>
        <Box>
          <Box className={classes.cUser}>
            {sessionType && (
              <Avatar
                alt={patient?.firstname}
                src={patient?.picture}
                className={classes.avatar}
              >
                {patient?.firstname && patient?.firstname[0]}{' '}
                {patient?.lastname && patient?.lastname[0]}
              </Avatar>
            )}

            <Typography
              variant="body1"
              component="h5"
              className={classes.cName}
            >
              {patient ? `${patient.firstname} ${patient.lastname}` : title}
            </Typography>
          </Box>
          <Box className={classes.cTime}>
            <Box className={classes.cDate}>
              <CalendarIcon />
              <Typography
                variant="body2"
                component="h6"
                className={classes.cDateText}
              >
                {toDayjs(event.startDate).format(
                  i18n.language === 'fr' ? 'D MMMM YYYY' : 'MMMM D, YYYY'
                )}
              </Typography>
            </Box>{' '}
            <Box className={classes.cDate}>
              <ClockIcon />
              <Typography
                variant="body2"
                component="h6"
                className={classes.cDateText}
              >
                {startTime} - {endTime}
              </Typography>
            </Box>
          </Box>
          {patient && (
            <Box display="flex" alignItems="center" gridGap="0.5rem">
              <Box className={classes.cDate}>
                <VideoIcon />
                <Typography
                  variant="body2"
                  component="h6"
                  className={classes.cDateText}
                >
                  {t('Online')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gridGap="0.3rem">
                <Box className={classes.cCondition}>
                  {conditionType?.tag && (
                    <Typography
                      variant="body2"
                      component="h6"
                      className={classes.conditionTag}
                      style={{
                        color: colorsMapping[conditionType?.color],
                        border: `1px solid ${
                          colorsMapping[conditionType?.color]
                        }`,
                      }}
                    >
                      {conditionType?.tag}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gridGap="0.5rem"
          mt={2}
        >
          <Button
            className={classes.removeBtn}
            onClick={() => handleOpenDeleteModal()}
          >
            <RemoveIcon />
            {t('Remove')}
          </Button>
          {patient && (
            <Button
              className={classes.messageBtn}
              onClick={() => {
                setPatientAdded(patient);
                handleOpenCreateNewThreadModal();
              }}
            >
              <MessageIcon /> Message
            </Button>
          )}
          <Button
            className={classes.editBtn}
            onClick={() => {
              onClose();
              handleBack(true);
              if (event?.doctorGoing) {
                handleEditCurrentEvent(event);
              }
              handleOpenConflictState(true);
            }}
          >
            <EditIcon />
            {t('Edit')}
          </Button>
        </Box>
      </Box>
      <ModalDelete
        text={event?.title ? EventType.EVENT : EventType.APPOINTMENT}
        open={openDeleteModal}
        onClose={() => handleCloseDeleteModal()}
        onDelete={() => handelDelete(event?._id)}
        loading={loadingSession || loadingEvent}
      />
    </>
  );
};
