import React, { useEffect } from 'react';
import { Box, Typography, Grid, Checkbox, Divider } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from './styles';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import Text from 'Text';
import {
  convertTagFormat,
  convertRoleFormat,
  convertObjectsFormatStaffs,
} from 'pages/SchedulePage/AddEventPage/utils';
import { useTranslation } from 'react-i18next';
import ClientAdultIcon from 'assets/ClientAdultIcon';
import ClientMinor from 'assets/ClientMinorIcon';
import { ReactComponent as BellIcon } from 'assets/Bell.svg';
import Switch from 'components/ui/SwitchNew';
import CheckboxBlock from './CheckboxBlock';
import PaymentBlock from './PaymentBlock';
import SearchBar from 'pages/AdminPage/TabsContent/templatesTabs/SearchBar';
// import { templates } from 'pages/AdminPage/TabsContent/templatesTabs/constants';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as Add } from 'assets/add.svg';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import Symptoms from 'pages/AppointmentPage/ConsultationForm/Symptoms';
import { useQuery } from '@apollo/client';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import { useLazyQuery, gql } from '@eggmed/graphql-client';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';

interface Props {
  checked: boolean;
  handleChange: (state: boolean) => void;
  commonProps: any;
  error?: string;
  setError?: React.Dispatch<React.SetStateAction<string>>;
  roles: any;
  open: boolean;
}
const AddPatientModal = ({
  checked,
  handleChange,
  commonProps,
  error,
  setError,
  roles: dataRoles,
  open,
}: Props) => {
  const [loadTeams, { data: team }] = useLazyQuery(TEAM_MEMBERS);
  React.useEffect(() => {
    if (open) {
      loadTeams({
        variables: {
          role: '',
          status: '',
        },
      });
    }
  }, [loadTeams, open]);
  const [id, setId] = React.useState<any>(['']);
  const [errorRoles, setErrorRoles] = React.useState('');

  React.useEffect(() => {
    const providers: any = commonProps.watch('assignedProviders') || [];
    const roles = commonProps.watch('assignedRoles') || [];
    const roleProviderMap = {};
    const unmatchedRoles = new Set();

    providers.forEach((provider) => {
      const matchingRole = roles.find((role) => role?.name === provider?.role);
      if (matchingRole) {
        if (!roleProviderMap[matchingRole.name]) {
          roleProviderMap[matchingRole.name] = [];
        }
        roleProviderMap[matchingRole.name].push(provider);
      } else {
        unmatchedRoles.add(provider?.role);
      }
    });

    const roleErrors = Object.entries(roleProviderMap).map(
      ([roleName, providers]) => {
        const providerNames = (providers as any)
          .map(
            (provider) =>
              `${capitalizeFirstLetter(
                provider?.firstname
              )} ${capitalizeFirstLetter(provider?.lastname)}`
          )
          .join(', ');
        const verb = (providers as any).length > 1 ? 'are' : 'is';
        return `${providerNames} ${verb} already assigned to the ${roleName} role.`;
      }
    );

    const allErrors = [...roleErrors].filter(Boolean).join(' ');
    const validProviderIds = providers
      .filter((provider) => roles.some((role) => role?.name === provider?.role))
      .map((provider) => provider?._id);
    if (allErrors) {
      setErrorRoles(allErrors);
      setId(validProviderIds);
    } else {
      setErrorRoles('');
      setId(['']);
    }
  }, [
    commonProps.watch('assignedRoles'),
    commonProps.watch('assignedProviders'),
    team?.teamsSettings,
  ]);
  const { user, dataSubscription } = useAuth();
  const classes = useStyles();
  useEffect(() => {
    return () => setErrorRoles('');
  }, []);
  const { t } = useTranslation();
  const [sendIntake, setSendIntake] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [usedTemplates, setTemplates] = React.useState([]);
  const [selectedTemplates, setSelectedTemplates] = React.useState<string[]>(
    []
  );
  const { setValue } = commonProps;
  const autoSendTemplates = dataSubscription?.doctorAutoSendTemplates;
  useEffect(() => {
    setTemplates(autoSendTemplates);

    const initialSelectedTemplates = autoSendTemplates?.map(
      (template) => template._id
    );
    setSelectedTemplates(initialSelectedTemplates);
    setValue('selectedTemplates', initialSelectedTemplates);
  }, [autoSendTemplates, setValue, dataSubscription]);

  const handleTemplateSelect = (templateId: string) => {
    const currentSelected = selectedTemplates.includes(templateId);
    const updatedSelected = currentSelected
      ? selectedTemplates.filter((id: string) => id !== templateId)
      : [...selectedTemplates, templateId];
    setSelectedTemplates(updatedSelected);
    setValue('selectedTemplates', updatedSelected);
  };

  const onSearchClick = (template) => {
    if (usedTemplates?.some((el) => el?._id === template?._id)) {
      const filteredTemplates = [...usedTemplates]?.filter(
        (el) => el?._id !== template?._id
      );
      setTemplates(filteredTemplates);
    } else {
      setTemplates([...usedTemplates, template]);
    }
    handleTemplateSelect(template._id);
  };

  return (
    <Box>
      <Box px={'2rem'} pt={2} pb="2rem">
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Text i18nKey="ageGroup" className={classes.agegroup}>
              Client type
            </Text>
            <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.ageBox}
                style={{
                  border: `1px solid ${
                    checked === true ? '#0265DC' : '#E0E0E0'
                  }`,
                  backgroundColor: checked === true ? '#0265DC' : '#fff',
                }}
                onClick={() => handleChange(true)}
              >
                <ClientAdultIcon
                  color={checked === true ? '#fff' : '#182230'}
                />
                <Text
                  className={classes.ageText}
                  style={{ color: checked === true ? '#fff' : '#182230' }}
                  i18nKey="adult"
                >
                  Adult
                </Text>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.ageBox}
                style={{
                  border: `1px solid ${
                    checked != true ? '#0265DC' : '#E0E0E0'
                  }`,
                  backgroundColor: checked != true ? '#0265DC' : '#fff',
                  marginLeft: '8px',
                }}
                onClick={() => handleChange(false)}
              >
                <ClientMinor color={checked != true ? '#fff' : '#182230'} />
                <Text
                  className={classes.ageText}
                  style={{ color: checked != true ? '#fff' : '#182230' }}
                  i18nKey="minor"
                >
                  Minor
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('First name')}
              {...commonProps}
              name="firstname"
              type="text"
              isRequired
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Middle name')}
              {...commonProps}
              name="middlename"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Last name')}
              {...commonProps}
              name="lastname"
              type="text"
              isRequired
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <InputBlock
              label={t('Date of birth')}
              {...commonProps}
              name="birthday"
              type="date"
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputBlock
              label={t('Email')}
              {...commonProps}
              name="email"
              type="text"
              isRequired
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt="8px"
            >
              <Box display="flex" alignItems="center">
                <BellIcon />
                <Text
                  className={classes.agegroup}
                  style={{ marginLeft: '5px' }}
                  i18nKey="Email about"
                >
                  Email about
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="email_about_upcoming"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.agegroup}
                  style={{ marginLeft: '5px' }}
                  i18nKey="Upcoming sessions"
                >
                  Upcoming sessions
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="email_about_canceled"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.agegroup}
                  style={{ marginLeft: '5px' }}
                  i18nKey="Cancelled sessions"
                >
                  Cancelled sessions
                </Text>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputBlock
              label={t('Phone number')}
              {...commonProps}
              name="phone"
              type="phone"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mt="8px"
            >
              <Box display="flex" alignItems="center">
                <BellIcon />
                <Text
                  className={classes.agegroup}
                  style={{ marginLeft: '5px' }}
                  i18nKey="Send texts about"
                >
                  Send texts about
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="text_about_upcoming"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.agegroup}
                  style={{ marginLeft: '5px' }}
                  i18nKey="Upcoming sessions"
                >
                  Upcoming sessions
                </Text>
              </Box>
              <Box display="flex" alignItems="center">
                <Controller
                  name="text_about_canceled"
                  render={({ onChange, value, ref }) => (
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      inputRef={ref}
                    />
                  )}
                  {...commonProps}
                />
                <Text
                  className={classes.agegroup}
                  style={{ marginLeft: '5px' }}
                  i18nKey="Cancelled sessions"
                >
                  Cancelled sessions
                </Text>
              </Box>
            </Box>
          </Grid>

          <Box
            display="flex"
            flexDirection="column"
            mt="2rem"
            mx={'0.5rem'}
            style={{
              width: '100%',
              borderRadius: '16px',
              border: '1px solid #D0D5DD',
              padding: '12px',
            }}
            mb={!['Admin', 'owner']?.includes(user?.role) && 4}
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                className={classes.intakeText}
                style={{ fontSize: '18px' }}
                i18nKey="Send intake documents"
              >
                Send intake documents
              </Text>
              <Controller
                name="sendIntake"
                render={({ onChange, value, ref }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setSearch(false);
                    }}
                    inputRef={ref}
                  />
                )}
                {...commonProps}
              />
            </Box>
            {commonProps.watch()?.sendIntake && (
              <Box display="flex" flexDirection="column" mt="1rem">
                {usedTemplates?.map((template) => (
                  <Controller
                    key={template.id}
                    name="selectedTemplates"
                    render={({ value, ref }) => (
                      <CheckboxBlock
                        name={template._id}
                        text={template.title}
                        inputRef={ref}
                        onChange={() => handleTemplateSelect(template._id)}
                        checked={selectedTemplates.includes(template._id)}
                      />
                    )}
                    {...commonProps}
                  />
                ))}

                {!search ? (
                  <Box
                    display={'flex'}
                    style={{
                      gap: '12px',
                      marginLeft: '0.8rem',
                      marginTop: '12px',
                    }}
                  >
                    <Add />
                    <Text
                      onClick={() => setSearch(true)}
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        textAlign: 'left',
                        color: '#0265DC',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      i18nKey="+ Add document"
                    >
                      {' '}
                      Add document
                    </Text>
                  </Box>
                ) : (
                  <Box ml={1}>
                    <SearchBar
                      templates={dataSubscription?.doctorSearchedTemplates}
                      onSearch={() => {}}
                      onSearchClick={onSearchClick}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {['Admin', 'owner']?.includes(user?.role) && (
            <>
              <Box width="100%" mt={4} style={{ marginInline: '0.5rem' }}>
                <Box>
                  <Text
                    i18nKey="Assigned roles"
                    className={classes.agegroup}
                    style={{ marginBottom: '5px' }}
                  >
                    Assigned roles
                  </Text>
                  <Box width="100%" mt={1}>
                    <Controller
                      name="assignedRoles"
                      render={({ onChange, value }) => {
                        return (
                          <Symptoms
                            type=""
                            disableCustomFilter={true}
                            options={convertRoleFormat(
                              dataRoles?.ownerRoles?.filter(
                                (el) => el?.name !== 'Admin'
                              )
                            )}
                            values={value}
                            setValues={onChange}
                            img={false}
                          />
                        );
                      }}
                      {...commonProps}
                    />
                  </Box>
                </Box>
                <Box mt={2} mb={'2.8rem'}>
                  <Text
                    i18nKey="Assigned care team"
                    className={classes.agegroup}
                    style={{ marginBottom: '5px' }}
                  >
                    Assigned care team
                  </Text>
                  <Box width="100%" mt={1}>
                    <Controller
                      name="assignedProviders"
                      render={({ onChange, value }) => {
                        return (
                          <Symptoms
                            type=""
                            disableCustomFilter={true}
                            options={convertObjectsFormatStaffs(
                              team?.teamSettings?.filter(
                                (el) => el?.role !== 'owner'
                              ),
                              ''
                            )}
                            values={value}
                            setValues={onChange}
                            img={false}
                            highlightId={id}
                            patient
                          />
                        );
                      }}
                      {...commonProps}
                    />
                    {errorRoles && (
                      <Typography
                        style={{
                          fontStyle: 'italic',
                          fontWeight: 400,
                          fontSize: '14px',
                          color: '#B42318',
                          marginTop: '8px',
                        }}
                      >
                        {errorRoles}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
          <Box
            display="flex"
            flexDirection="column"
            style={{
              width: '100%',
              borderRadius: '16px',
              border: '1px solid #D0D5DD',
              padding: '12px',
              marginInline: '0.5rem',
            }}
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                className={classes.intakeText}
                style={{ fontSize: '18px' }}
                i18nKey="Add client’s payment method"
              >
                Add client’s payment method
              </Text>

              <Controller
                name="payment"
                render={({ onChange, value, ref }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    inputRef={ref}
                  />
                )}
                {...commonProps}
              />
            </Box>
            {commonProps.watch()?.payment && <PaymentBlock />}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddPatientModal;
export const AUTO_SEND_TEMPLATES = gql`
  query doctorAutoSendTemplates {
    doctorAutoSendTemplates {
      _id
      title
      description
      lastOpenedAt
      isDefault
      doctor {
        _id
      }
      formType
      blocks
      isAutoSent
    }
    doctorSearchedTemplates {
      _id
      title
      description
      lastOpenedAt
      isDefault
      questions {
        _id
        question
        type
        options
        description
        isEdited
        isDescription
        required
        range
      }
      doctor {
        _id
      }
      formType
      blocks
      isAutoSent
    }
  }
`;
