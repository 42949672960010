import { Box, Typography } from '@material-ui/core';
import CheckboxBlock from 'components/Layout/header/CheckboxBlock';
import dayjs from 'dayjs';
import React from 'react';

const ActiveMed = ({ data }) => {
  const [checkedItems, setCheckedItems] = React.useState({});

  const handleCheckboxChange = (medicationId) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [medicationId]: !prevCheckedItems[medicationId],
    }));
  };
  return (
    <Box width="100%" ml={'-2rem'}>
      {data?.map((medication) => (
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          py={3}
          pr={'0rem'}
          borderBottom="1px solid #e5e7eb"
        >
          <Box display="flex">
            <Box mt={-0.5}>
              <CheckboxBlock
                name=""
                text={null}
                checked={checkedItems[medication._id] || false}
                onChange={() => handleCheckboxChange(medication._id)}
                width={22}
                height={22}
              />
            </Box>
            <Box ml={2} display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Typography>{medication?.name}</Typography>
                {medication?.type === 'active' && (
                  <Box
                    ml={2}
                    padding={'2px 6px'}
                    style={{ background: '#D6EEFF' }}
                  >
                    <Typography style={{ color: 'blue' }}>Active</Typography>
                  </Box>
                )}
              </Box>
              <Typography>Diagnosis: {medication?.diagnosis}</Typography>
            </Box>
          </Box>
          <Typography>
            Added on {dayjs(medication?.date).format('MM-DD-YYYY')}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ActiveMed;
