import React, { useContext, useReducer, useState } from 'react';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useCreateThreadConversation from '@eggmed/graphql-client/operations/threadMessagesOperations/useCreateThreadConversation';
import useDeleteThreadConversation from '@eggmed/graphql-client/operations/threadMessagesOperations/useDeleteThreadConversation';
import useGetAllThreadConversationByUser, {
  useLazyGetAllThreadConversationByUser,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetAllThreadConversationByUser';
import useGetThreadConversationById, {
  useLazyGetThreadConversationById,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetThreadConversationById';
import useGetThreadMessages, {
  useLazyGetThreadMessages,
} from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetThreadMessages';
import usePushMessage from '@eggmed/graphql-client/operations/threadMessagesOperations/usePushMessage';
import useUpdateIsArchivedThreadConversation from '@eggmed/graphql-client/operations/threadMessagesOperations/useUpdateIsArchivedThreadConversation';
import { IModalType } from 'shared/interfaces/inbox.interface';
import { Context, reducer } from './ContextReducer';
import useLocalStateDispatcher from './useLocalStateDispatcher';
import useSubscriptionNewUpdateThread from '@eggmed/graphql-client/operations/threadMessagesOperations/useSubscriptionNewUpdateThread';
import useSubscriptionUnreadThread from '@eggmed/graphql-client/operations/threadMessagesOperations/useSubscriptionUnreadMessage';
import useSubscriptionNewThreadCreated from '@eggmed/graphql-client/operations/threadMessagesOperations/useSubscriptionNewThreadCreated';
import useSubscriptionNewMessage from '@eggmed/graphql-client/operations/threadMessagesOperations/useSubscriptionNewMessage';
import useSubscriptionThreadDeleted from '@eggmed/graphql-client/operations/threadMessagesOperations/useSubscriptionDeletedThread';
import useSubscriptionThreadArchived from '@eggmed/graphql-client/operations/threadMessagesOperations/useSubscriptionArchivedThread';
import { useEffect } from 'react';
import { GetAllThreadConversationByUser_getAllThreadConvertationByUser } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetAllThreadConversationByUser';
import { GetThreadMessages_getThreadMessages } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/GetThreadMessages';
import { subscriptionNewUpdateThread_subscriptionNewUpdateThread } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/subscriptionNewUpdateThread';
import { subscriptionNewMessage_subscriptionNewMessage } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/subscriptionNewMessage';
import { subscriptionNewThreadCreated_subscriptionNewThreadCreated } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/subscriptionNewThreadCreated';
import { subscriptionArchivedThread_subscriptionArchivedThread } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/subscriptionArchivedThread';
import { getFilesByThreadConversation_getFilesByThreadConversation } from '@eggmed/graphql-client/operations/threadMessagesOperations/__generated__/getFilesByThreadConversation';
import { useLazyQuery } from '@apollo/client';
import { GET_FILES_BY_THREAD_CONVERSATION } from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetFilesByThreadConversation';
import { typeConversationEnum } from '@eggmed/graphql-client/__generated__/globalTypes';
import { useLazySearchThread } from '@eggmed/graphql-client/operations/threadMessagesOperations/useSearchThreadConversation';
import { useLocation } from 'react-router-dom';
import { GET_THREAD_CONVERSATION_BY_USERS } from '@eggmed/graphql-client/operations/threadMessagesOperations/useGetThreadConversationByUsers';

interface IPropsProvider {
  children: React.ReactNode;
  initialValue?: any;
  userPatient?: string;
  isOnly?: boolean;
}

const Provider = ({
  children,
  initialValue,
  userPatient = null,
  isOnly = true,
}: IPropsProvider) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    selected: initialValue?.selected,
    modal: false,
    isEmptyConversationList: false,
    modalType: IModalType.createThead,
  });

  const [archivedConversationListData, setArchivedConversationListData] =
    useState<GetAllThreadConversationByUser_getAllThreadConvertationByUser[]>(
      null
    );

  const [unArchivedConversationListData, setUnArchivedConversationListData] =
    useState<GetAllThreadConversationByUser_getAllThreadConvertationByUser[]>(
      null
    );

  const [typeConversationList, setTypeConversationList] =
    useState<typeConversationEnum | null>(null);

  const [messagesData, setMessagesData] = useState<
    GetThreadMessages_getThreadMessages[]
  >([]);
  const [listFiLes, setListFiLes] = useState<
    getFilesByThreadConversation_getFilesByThreadConversation[]
  >([]);
  const { user, patient } = useAuth();
  const isPatient = !!patient;
  const userId = isPatient ? user?._id : user?._id;
  const [getFilesListByThreadConversation, { data: listFiLesData }] =
    useLazyQuery(GET_FILES_BY_THREAD_CONVERSATION);
  const handelUpdateIsArchivedThreadConversation =
    useUpdateIsArchivedThreadConversation();
  const { handlePushMessage, loading: sendMessageLoading } = usePushMessage();
  const handelDeleteThreadConversation = useDeleteThreadConversation();
  const [handelCreateThreadConversation, { loading }] =
    useCreateThreadConversation();
  const [getMessage, { data: dataMessages }] = useLazyGetThreadMessages();
  const [
    searchThread,
    {
      data: dataSearchThread,
      loading: loadingSearchThread,
      error: errorSearchThread,
    },
  ] = useLazySearchThread();
  const dispatchers = useLocalStateDispatcher(dispatch);
  const {
    handleOpenCreateNewThreadModal,
    handleCloseCreateNewThreadModal,
    handleSelectedThead,
    handelIsEmptyConversationList,
  } = dispatchers;
  const [getThreadUnArchivedByUser, { data: dataUnArchived }] =
    useLazyGetAllThreadConversationByUser();
  const [getThreadArchivedByUser, { data: dataArchived }] =
    useLazyGetAllThreadConversationByUser();
  const [getThreadPagination, { data: ThreadPagination }] =
    useLazyGetAllThreadConversationByUser();
  const [messageHidden, setMessageHidden] = React.useState(true);

  const handelSelectTypeConversationList = (
    type: typeConversationEnum | null
  ) => {
    setTypeConversationList(type);
  };
  const handelNewThreadCreated = (
    NewThread: subscriptionNewThreadCreated_subscriptionNewThreadCreated
  ) => {
    if (
      typeConversationList != null &&
      NewThread.type != typeConversationList
    ) {
      return;
    }
    if (NewThread.isArchived) {
      setArchivedConversationListData([
        NewThread,
        ...archivedConversationListData,
      ]);
    } else {
      setUnArchivedConversationListData([
        NewThread,
        ...unArchivedConversationListData,
      ]);
    }
  };

  const deleteElementFromArray = (
    array: GetAllThreadConversationByUser_getAllThreadConvertationByUser[],
    id: string
  ) => {
    return array.filter((item) => item._id !== id);
  };

  const handelPaginationConversationList = (isArchived: boolean) => {
    if (isArchived) {
      getThreadPagination({
        variables: {
          type: typeConversationList,
          isArchived: isArchived,
          limit: 2,
          skip: archivedConversationListData.length,
          userPatient,
        },
        fetchPolicy: 'network-only',
      });
    } else {
      getThreadPagination({
        variables: {
          type: typeConversationList,
          isArchived: isArchived,
          limit: 2,
          skip: unArchivedConversationListData.length,
          userPatient,
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const handelNewThreadDeleted = (deletedThreadId: string) => {
    setArchivedConversationListData(
      deleteElementFromArray(archivedConversationListData, deletedThreadId)
    );
    setUnArchivedConversationListData(
      deleteElementFromArray(unArchivedConversationListData, deletedThreadId)
    );
  };

  const handelNewUpdateThread = (
    updatedThread: subscriptionNewUpdateThread_subscriptionNewUpdateThread
  ) => {
    if (updatedThread.isArchived) {
      setArchivedConversationListData([
        updatedThread,
        ...deleteElementFromArray(
          archivedConversationListData,
          updatedThread._id
        ),
      ]);
    } else {
      setUnArchivedConversationListData([
        updatedThread,
        ...deleteElementFromArray(
          unArchivedConversationListData,
          updatedThread._id
        ),
      ]);
    }
  };
  const handleUnreadThread = (updatedThread) => {
    if (updatedThread.isArchived) {
      setArchivedConversationListData((prevList) =>
        prevList?.map((item) =>
          item?._id === updatedThread?._id ? updatedThread : item
        )
      );
    } else {
      setUnArchivedConversationListData((prevList) =>
        prevList?.map((item) =>
          item?._id === updatedThread?._id ? updatedThread : item
        )
      );
    }
  };

  const handelAddNewFile = (
    message: subscriptionNewMessage_subscriptionNewMessage
  ) => {
    setListFiLes([...listFiLes, message]);
  };

  const handelNewMessage = (
    message: subscriptionNewMessage_subscriptionNewMessage
  ) => {
    if (state.selected === message.idThreadConversation) {
      setMessagesData([...messagesData, message]);
      if (message.content.files.length > 0) {
        handelAddNewFile(message);
      }
    }
  };

  const handelSearchThread = (search: string) => {
    searchThread({
      variables: {
        search,
      },
    });
  };

  const handelNewArchivedThread = ({
    newArchivedThread,
    type,
  }: subscriptionArchivedThread_subscriptionArchivedThread) => {
    if (type === 'ARCHIVED') {
      setArchivedConversationListData([
        newArchivedThread,
        ...archivedConversationListData,
      ]);
      setUnArchivedConversationListData(
        deleteElementFromArray(
          unArchivedConversationListData,
          newArchivedThread._id
        )
      );
    } else {
      setUnArchivedConversationListData([
        newArchivedThread,
        ...unArchivedConversationListData,
      ]);
      setArchivedConversationListData(
        deleteElementFromArray(
          archivedConversationListData,
          newArchivedThread._id
        )
      );
    }
  };
  useEffect(() => {
    if (isOnly) {
      if (ThreadPagination?.getAllThreadConvertationByUser?.length > 0) {
        if (
          ThreadPagination?.getAllThreadConvertationByUser[0].isArchived ===
          false
        ) {
          setUnArchivedConversationListData([
            ...unArchivedConversationListData,
            ...ThreadPagination?.getAllThreadConvertationByUser,
          ]);
        } else {
          setArchivedConversationListData([
            ...archivedConversationListData,
            ...ThreadPagination?.getAllThreadConvertationByUser,
          ]);
        }
      }
    }
  }, [ThreadPagination, isOnly]);

  useEffect(() => {
    if (isOnly) {
      getThreadArchivedByUser({
        variables: {
          type: typeConversationList,
          isArchived: true,
          limit: 10,
          userPatient,
        },
        fetchPolicy: 'no-cache',
      });
      getThreadUnArchivedByUser({
        variables: {
          type: typeConversationList,
          isArchived: false,
          limit: 10,
          userPatient,
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [typeConversationList, isOnly]);

  useEffect(() => {
    if (state.selected && isOnly) {
      getMessage({
        variables: {
          ThreadConversationID: state.selected,
        },
      });
    }
  }, [state.selected, isOnly]);

  useEffect(() => {
    if (dataUnArchived && isOnly) {
      const { getAllThreadConvertationByUser } = dataUnArchived;
      setUnArchivedConversationListData(getAllThreadConvertationByUser);
    }
  }, [dataUnArchived, isOnly]);

  useEffect(() => {
    if (dataArchived && isOnly) {
      const { getAllThreadConvertationByUser } = dataArchived;
      setArchivedConversationListData(getAllThreadConvertationByUser);
    }
  }, [dataArchived, isOnly]);
  useEffect(() => {
    if (dataUnArchived && dataArchived && isOnly) {
      if (
        unArchivedConversationListData?.length === 0 &&
        archivedConversationListData?.length === 0
      ) {
        handelIsEmptyConversationList(true);
      } else {
        handelIsEmptyConversationList(false);
      }
    }
  }, [unArchivedConversationListData, archivedConversationListData, isOnly]);

  useEffect(() => {
    if (dataMessages && isOnly) {
      const { getThreadMessages } = dataMessages;
      setMessagesData(getThreadMessages);
    }
  }, [dataMessages, isOnly]);

  useEffect(() => {
    if (state.selected && isOnly) {
      getFilesListByThreadConversation({
        variables: {
          threadConversationID: state.selected,
        },
      });
    }
  }, [state.selected, isOnly]);

  useEffect(() => {
    if (listFiLesData && isOnly) {
      const { getFilesByThreadConversation } = listFiLesData;
      if (getFilesByThreadConversation) {
        setListFiLes(getFilesByThreadConversation);
      }
    }
  }, [listFiLesData, isOnly]);

  const location = useLocation<{ staffId?: string }>();
  const [getThread] = useLazyQuery(GET_THREAD_CONVERSATION_BY_USERS, {
    onCompleted: (data) => {
      data?.getThreadConversationByUsers
        ? handleSelectedThead(data?.getThreadConversationByUsers?._id)
        : handleOpenCreateNewThreadModal();
    },
  });
  const staffId = location?.state?.staffId;
  useEffect(() => {
    if (staffId && isOnly) {
      getThread({ variables: { participant: staffId } });
    }
  }, [staffId, isOnly]);
  useSubscriptionNewMessage(userId, handelNewMessage);
  useSubscriptionNewThreadCreated(userId, handelNewThreadCreated);
  useSubscriptionThreadDeleted(userId, handelNewThreadDeleted);
  useSubscriptionNewUpdateThread(userId, handelNewUpdateThread);
  useSubscriptionUnreadThread(userId, handleUnreadThread);
  useSubscriptionThreadArchived(userId, handelNewArchivedThread);

  return (
    <Context.Provider
      value={{
        ...state,
        handleOpenCreateNewThreadModal,
        handleCloseCreateNewThreadModal,
        handelIsEmptyConversationList,
        handleSelectedThead,
        useGetThreadMessages,
        useGetAllThreadConversationByUser,
        useGetThreadConversationById,
        handlePushMessage,
        handelUpdateIsArchivedThreadConversation,
        handelCreateThreadConversation,
        handelDeleteThreadConversation,
        useLazyGetThreadConversationById,
        handelSelectTypeConversationList,
        handelPaginationConversationList,
        handelSearchThread,
        setMessageHidden,
        messageHidden,
        typeConversationList,
        archivedConversationListData,
        unArchivedConversationListData,
        messagesData,
        listFiLes,
        searchThreadData: {
          data: dataSearchThread,
          loading: loadingSearchThread,
          error: errorSearchThread,
        },
        loading,
        sendMessageLoading,
      }}
    >
      {children}
    </Context.Provider>
  );
};

function useProvider() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useProvider must be used within a Provider');
  }
  return context;
}

export { useProvider };
export default Provider;
