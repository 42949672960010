import {
  Box,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import BasicModal from 'components/ui/Modal';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';
import AddLabsImagingModal from './AddLabsImagingModal';
import LabsImagingModal from './LabsImagingModal';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
    padding: '1rem',
    minHeight: '100px',
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4,0,0.2,1)',
    transitionDuration: '0.3s',
    '&:hover': {
      border: '1px solid #425364',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
    color: '#494B5A',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'blue',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#494B5A',
  },
  date: {
    fontSize: '14px',
    color: '#8E96A4',
  },
}));

const LabsImaging = (props) => {
  const classes = useStyles();
  const labs = [
    {
      name: 'Rheumatoid factor [Presence] in serium',
      date: new Date(),
      status: 'pending',
    },
    {
      name: 'CBC w auto differential panel-blood',
      date: new Date(),
      status: 'pending',
    },
    {
      name: 'XR wrist - bilaterial views',
      date: new Date(),
      status: 'pending',
    },
  ];
  const theme = useTheme();

  const isLarge = useMediaQuery(theme.breakpoints.only('lg'), {
    defaultMatches: true,
  });
  const [open, setOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <Box position="relative">
      <Box className={classes.root} onClick={() => setOpen(true)}>
        <Typography className={classes.title}>Labs & Imaging (3)</Typography>
        <Box mt={2}>
          {labs?.map((medication) => (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
              mb={2}
            >
              <Typography className={classes.text}>
                {medication?.name}
              </Typography>
              <Typography className={classes.date}>
                {dayjs(medication?.date).format('MM-DD-YYYY')}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <BasicModal
        open={open}
        handleClose={() => {
          setOpen(false);
          setIsExpanded(false);
        }}
        onClose={() => {
          setOpen(false);
          setIsExpanded(false);
        }}
        isRegularModal
        isSlide
        divider
        title={'Labs & Imaging'}
        titlePadding={'2rem'}
      >
        <>
          {isExpanded && (
            <Box
              position="absolute"
              right={isLarge ? '58vw' : '48vw'}
              top={0}
              py={'1.2rem'}
            >
              <CancelIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsExpanded(false)}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              width: isExpanded
                ? isLarge
                  ? '90vw'
                  : '70vw'
                : isLarge
                ? '57vw'
                : '47vw',
            }}
            style={{
              transition: 'width 0.3s ease',
            }}
          >
            <Box
              sx={{
                width: isExpanded ? (isLarge ? '33vw' : '23vw') : '0',
                overflow: 'hidden',
                borderRight: isExpanded ? '1px solid #EAECF0' : 'none',
                position: 'relative',
                height: '100vh',
                // backgroundColor: '#fff',
              }}
              style={{
                transition: 'width 0.3s ease',
              }}
            >
              {isExpanded && (
                <AddLabsImagingModal {...props} handleExpand={setIsExpanded} />
              )}
            </Box>

            <Box
              sx={{
                width: isLarge ? '57vw' : '47vw',
                flexShrink: 0,
                overflow: 'scroll',
                maxHeight: '90vh',
              }}
            >
              <LabsImagingModal
                handleExpand={setIsExpanded}
                {...props}
                labs={labs}
              />
            </Box>
          </Box>
        </>
      </BasicModal>{' '}
    </Box>
  );
};

export default LabsImaging;
