import React, { ReactElement, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import useStyles from './style';
import HelpContent from './HelpContent';
import { useTranslation } from 'react-i18next';

const HelpPage = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.bottomGridContainer}>
      <HelpContent title="q1">
        <Box width="100%">
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              {t('Old email address?')}
              <br />
              {t('p.1')}
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>{t('p.2')}</Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              {t('p.3')}
              <br />
              {t('p.4')}
              <br />
              {t('p.5')}
              <br />
              {t('p.6')}
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>{t('p.7')}</Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>{t('p.8')}</Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              {t('p.9')}
              <br />
              {t('p.10')}
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>{t('p.11')}</Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              {t('p.12')}
              <br />
              {t('p.13')}
            </Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>{t('p.14')}</Typography>
          </Box>
          <Box width="100%" mt="1rem">
            <Typography className={classes.helpText}>
              {t('p.15')}
              <br />
              {t('p.16')}
            </Typography>
          </Box>
        </Box>
      </HelpContent>
      <HelpContent title="q2">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>{t('p.17')}</Typography>
        </Box>
      </HelpContent>
      <HelpContent title="q3">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>{t('p.18')}</Typography>
        </Box>
      </HelpContent>
      <HelpContent title="q4">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>{t('p.19')}</Typography>
        </Box>
      </HelpContent>
      <HelpContent title="q5">
        <Box width="100%" mt="1rem">
          <Typography className={classes.helpText}>{t('p.20')}</Typography>
        </Box>
      </HelpContent>
    </Grid>
  );
};

export default HelpPage;
