import React, { ReactElement } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import PatientGeneralInformations from './PatientGeneralInfo';
import useModal from 'hooks/useModal';
import { HistoryTabProvider } from 'pages/PatientTabHistory/HistoryTabContext';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import PatientActivities from 'pages/PatientActivities';
import InboxPageProvider from 'pages/InboxPage/state/Provider';
import TaskPageProvider from 'pages/Taskpage/state/Provider';
import AssignProvider from 'pages/PatientListPage/state/Provider';
import TaskPage from 'pages/Taskpage/TaskPage';
import PatientConsultations from 'pages/PatientConsultations';
import WrapperComponent from './WrapperComponent';
import PatientConditions from 'pages/PatientConditions';
import PatientDrugs from 'pages/PatientDrugs';
import DoctorInbox from 'pages/InboxPage/DoctorInbox';
import FinancePage from 'pages/DoctorFinancePage/index';
import SettingTab from './SettingTab';
import { WrapperContent } from './WrapperContent';
import Tabs from 'components/ui/Tabs';
import FaceSheet from './FaceSheet';
import ActivityProvider from 'pages/PatientActivities/Questionaires/ActivityProvider';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}
interface IvalueProps {
  value: string | number;
}
export interface IPatientProps {
  patientId?: string;
  isPatient?: boolean;
}
const useStyles = makeStyles((theme) => ({
  tabForm: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    marginTop: theme.spacing(6),
  },
}));

export const tabs = {
  general: 0,
  activities: 1,
  appointments: 2,
  tasks: 3,
  records: 4,
  billing: 5,
  settings: 6,
  message: 7,
};

export function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;
  const { dispatch } = useModal();
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      style={{
        width: '100%',
      }}
    >
      {value === index && <Box hidden={value !== index}>{children}</Box>}
    </Box>
  );
}
interface IPatientTabsContentProps {
  value: number | string;
  isPatient: boolean;
  patientId?: string;
}

export default function PatientTabsContent({
  value,
  isPatient,
  patientId,
}: IPatientTabsContentProps): ReactElement {
  const { t } = useTranslation();

  const {
    doctor: { _id: doctorId },
  } = useAuth();
  const classes = useStyles();

  const [customValue, setCustomValue] = React.useState(0);
  return (
    <HistoryTabProvider initialState={{ open: false, currentTab: 0 }}>
      <>
        <TabPanel value={value} index={0}>
          <TaskPageProvider
            isAppt={true}
            patientParam={patientId}
            isLoad={false}
          >
            <AssignProvider>
              <WrapperContent patientId={patientId}>
                <PatientGeneralInformations
                  isPatient={isPatient}
                  generalInformation
                  patientId={patientId}
                />
              </WrapperContent>
            </AssignProvider>
          </TaskPageProvider>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ActivityProvider>
            <InboxPageProvider isOnly={false}>
              <WrapperContent patientId={patientId}>
                <PatientActivities patientId={patientId} doctorId={doctorId} />
              </WrapperContent>
            </InboxPageProvider>
          </ActivityProvider>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <InboxPageProvider isOnly={false}>
            <WrapperContent patientId={patientId}>
              <PatientConsultations patientId={patientId} doctorId={doctorId} />
            </WrapperContent>
          </InboxPageProvider>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TaskPageProvider isAppt={true} patientParam={patientId}>
            <WrapperContent patientId={patientId}>
              <TaskPage />
            </WrapperContent>
          </TaskPageProvider>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <WrapperComponent patientId={patientId} isPatient={isPatient}>
            <WrapperContent patientId={patientId}>
              <>
                <Tabs
                  tabsMode="form"
                  classNameTab={classes.tabForm}
                  tabsBar={[t('Diagnosis'), t('Drugs')]}
                  tabsContent={[
                    <Box mt={4} ml="2rem">
                      <PatientConditions patientId={patientId} />
                    </Box>,
                    <Box mt={4} ml="2rem">
                      <PatientDrugs patientId={patientId} />
                    </Box>,
                  ]}
                  customValue={customValue}
                  setCustomValue={setCustomValue}
                />
              </>
            </WrapperContent>
          </WrapperComponent>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <WrapperContent patientId={patientId}>
            <FinancePage isAll={false} patientId={patientId} />
          </WrapperContent>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <WrapperContent patientId={patientId}>
            <SettingTab patientId={patientId} />
          </WrapperContent>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <InboxPageProvider userPatient={patientId}>
            <WrapperContent patientId={patientId}>
              <DoctorInbox doctorPatientTab={true} />
            </WrapperContent>
          </InboxPageProvider>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <WrapperContent patientId={patientId}>
            <FaceSheet />
          </WrapperContent>
        </TabPanel>
      </>
    </HistoryTabProvider>
  );
}

TabPanel.defaultProps = {
  children: <div />,
};
