import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  time: {
    color: '#7a90ab',
    fontSize: '13px',
  },
  messagingTxt: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: 'Inter, sans-serif',
    color: '#101828',
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  item: {
    borderLeft: '6px solid transparent',
    paddingInline: '1rem',
    paddingBlock: '0.5rem',
    marginBottom: '4px',
  },
  itemIsSelected: {
    background: '#D4E9FF',
    borderLeft: '6px solid #3794FC',
    paddingInline: '1rem',
    paddingBlock: '0.5rem',
    marginBottom: '4px',
  },
  Badge: {
    left: '-6px',
    top: '10px',
  },
  Avatar: {
    width: '60px',
    height: '60px',
    borderRadius: '100%',
  },
  conversationListTabs: {
    background: '#000',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerSelectedText: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: theme.palette.black.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  tab: {
    // background: '#F5F7FB',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    flexDirection: 'column',
    width: '100%',
    '& .MuiTabs-scrollable': {
      width: '100%',
    },
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingInline: '1.5rem',
    },
    '& .MuiButtonBase-root': {
      flex: '1',
    },
  },
  addMessageBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    background: '#147AF3',
    borderRadius: '8px',
    boxShadow: 'none',
    color: theme.palette.white.main,
    padding: '10px 12px',

    '&:hover': {
      boxShadow: 'none',
      background: '#0265DC',
    },
  },
  addMessageBtnTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  addMessageBtnText: {
    fontSize: '14px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    marginLeft: '6px',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  Chip: {
    color: '#9B51E0',
    backgroundColor: 'rgba(143, 97, 241, 0.2)',
    [theme.breakpoints.down('sm')]: {
      height: '22px',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: '500',
      textTransform: 'lowercase',
    },
  },
  centred: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  conversationListContainer: {
    height: '100%',
    marginLeft: '1.5rem',
    paddingRight: '1rem',
  },
  TitleItemConversation: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.palette.black.dark,
    marginLeft: '10px',
    textTransform: 'capitalize',
  },
  TitleItemConversationUnread: {
    fontWeight: 400,
    fontSize: '14px',
    color: theme.palette.black.dark,
    marginLeft: '10px',
    lineHeigh: '20px',
  },
  headerRightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '15px',
  },
  SearchInput: {
    margin: '10px 15px',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '8px',
      paddingRight: '20px',
    },
  },
  MoreHorizIcon: {
    cursor: 'pointer',
    fontSize: '30px',
    color: '#BCC6D3',
  },
  noWarpText: {
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    marginLeft: '10px',
  },
  ListItemTextConversationItem: {
    padding: '2px',
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  searchItemListCardContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    height: '4rem',
    padding: '8px 4px',
    gap: '10px',
  },
  secondaryTextSearchItemListCard: {
    fontSize: '15px',
    color: theme.palette.gray.main,
  },
  icon: {
    padding: '0px 5px',
  },
  cardSpacing: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(-1),
    },
  },
  avatarCard: {
    position: 'relative',
    left: '-10px',
    width: '25px',
    height: '25px',
    fontSize: '14px',
    [theme.breakpoints.only('lg')]: {
      width: '23px',
      height: '23px',
    },
  },
  conversationItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    paddingBlock: '10px',
    borderRadius: '4px',
    cursor: 'pointer',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#F5FBFF',
    },
  },
  recepientName: {
    fontFamily: 'Inter',
    fontSize: '14px',
    maxWidth: '28vw',
    lineHeight: '20px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#344054',
  },
  date: {
    fontFamily: 'Inter',
    fontSize: '12px',
    lineHeight: '14px',
  },
  unread: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'right',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#FFFFFF',
  },
  unreadBox: {
    borderRadius: '100%',
    backgroundColor: '#41A8FF',
    width: '21px',
    height: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.25rem',
  },
}));

export default useStyles;
