import { Box, Grid } from '@material-ui/core';
import React, { ReactElement, useEffect } from 'react';
import Conversation from 'pages/InboxPage/components/Conversation/Conversation';
import Message from 'pages/InboxPage/components/Message';
import Modals from 'pages/InboxPage/modals';
import useStyles from 'pages/InboxPage/style';
import { useProvider } from 'pages/InboxPage/state/Provider';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';
import { useTranslation } from 'react-i18next';

const PatientInboxPage = (): ReactElement => {
  const classes = useStyles();
  const { setMessageHidden, messageHidden } = useProvider();
  const { patient } = useAuth();
  const isPatient = !!patient;
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Messaging') });
  }, [headerTitleDispatcher]);
  return (
    <div style={{ display: 'flex' }} className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxSizing="border-box"
        height="100%"
        style={{
          gap: '1rem',
          paddingRight: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <Conversation />
      </Box>
      <Box
        style={{ paddingBottom: '1rem' }}
        width="100%"
        flex={1}
        boxSizing="border-box"
      >
        <Message patient={isPatient} />
      </Box>
      <Modals isPatient={isPatient} />
    </div>
  );
};

export default PatientInboxPage;
