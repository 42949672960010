import React from 'react';
import AppointmentListPageProvider from 'pages/AppointmentListPage/state/Provider';
import DoctorFinance from './DoctorFinance';
import InboxPageProvider from 'pages/InboxPage/state/Provider';

const index = ({
  isAll = true,
  patientId,
  data,
}: {
  isAll?: boolean;
  patientId?: string;
  data?: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    address: string;
    country: string;
    suite: string;
    city: string;
    state: string;
    zipcode: string;
  };
}) => {
  return (
    <AppointmentListPageProvider>
      <InboxPageProvider isOnly={false}>
        <DoctorFinance data={data} isAll={isAll} patientId={patientId} />
      </InboxPageProvider>
    </AppointmentListPageProvider>
  );
};

export default index;
