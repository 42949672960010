import { gql, useMutation } from '@apollo/client';

export const CHECK_ELLIGIBIILTY = gql`
  mutation checkElligibility($checkElligibility: ElligibilityType) {
    checkElligibility(checkElligibility: $checkElligibility)
  }
`;

export default function useCheckElligibility() {
  const [checkElligibility, { data, error, loading }] =
    useMutation(CHECK_ELLIGIBIILTY);

  return { checkElligibility, data, error, loading };
}
