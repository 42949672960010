import React from 'react';
import DatePicker from 'react-datepicker';
import { FormInput } from './FormInput';

interface ItimeProps {
  date: Date;
  handleChange: (date: Date) => void;
  timeIntervals?: number;
}
const TimeInput = ({ date, handleChange, timeIntervals = 60 }: ItimeProps) => {
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const handleTimeChange = (selectedDate: Date) => {
    // Convert back to local time before passing up
    const localDate = new Date(
      selectedDate.getTime() - date.getTimezoneOffset() * 60000
    );
    handleChange(localDate);
  };

  return (
    <DatePicker
      selected={utcDate}
      onChange={handleTimeChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeIntervals}
      timeCaption="Time"
      dateFormat="h:mm aa"
      customInput={
        <FormInput
          data-cy="date-input"
          fullWidth
          style={{ width: '100%' }}
          variant="filled"
        />
      }
      popperProps={{
        positionFixed: true,
      }}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
    />
  );
};

export default TimeInput;
