import React, { useEffect, useState } from 'react';
import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import { ReactComponent as ReflectionIcon } from 'assets/ReflectionIcon.svg';
import { ReactComponent as MoodIcon } from 'assets/Moodicon.svg';
import { ReactComponent as SleepIcon } from 'assets/SleepIcon.svg';
import dayjs from 'dayjs';
import {
  Moods,
  Sleep as sleepEmojies,
  _Moods,
  _Sleep,
} from './commun/constants';
import Button from 'components/ui/Button';
import { useStyles } from './styles';
import { ReactComponent as ViewIcon } from 'assets/eye-view.svg';
import { ReactComponent as MoonStarIcon } from 'assets/patient-profile/response/moon-star.svg';
import { ReactComponent as SunIcon } from 'assets/patient-profile/response/sun.svg';
import { IPatientActivities } from './index';
import { ReactComponent as Saved } from 'assets/Etoile.svg';
import { ReactComponent as SavedYellow } from 'assets/EtoileJaune.svg';
import useHandlePatientActivities from './commun/useHandlePatientActivities';
import BasicModal from 'components/ui/Modal';
import ResultModal from './commun/ResultModal';
import { FormProvider, useForm } from 'react-hook-form';
import { ViewModal } from 'pages/PatientActivitiesPage/ViewModal';
import { ReactComponent as ArrowRightActivity } from 'assets/ArrowRightActivity.svg';
import { IActivitySource, IActivityToOpen } from 'pages/PatientActivitiesPage';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { FormRecentCard } from './FormRecentCard';
import { ReactComponent as FormIcon } from 'assets/FormIcon.svg';
import ModalDelete from 'components/DeleteHandler/ModalDelete';
import useDeletePatientActivities from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeletePatientActivity';
import { dayjsFormat, toDayjs } from 'utils';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

export enum ActivitiesTypes {
  SLEEP = 'sleepCheckin',
  MOOD = 'moodCheckin',
  REFLECTION = 'reflection',
  FORM = 'form',
}
export const types = {
  sleepCheckin: { name: 'Sleep check-in', icon: <SleepIcon /> },
  moodCheckin: { name: 'Mood check-in', icon: <MoodIcon /> },
  reflection: { name: 'Reflection', icon: <ReflectionIcon /> },
  form: { name: 'Form', icon: <FormIcon /> },
};
export const emojis = [...Moods, ...sleepEmojies];
export const handleEmoji = (emoji: string, emojiArray) => {
  return emojiArray.find(
    (el) => el.label.toUpperCase() === emoji?.toUpperCase()
  )?.icon;
};
export const typeMapping = {
  moodCheckin: 'Mood',
  sleepCheckin: 'Sleep',
};

interface IRecentActivityCardProps extends IPatientActivities {
  doctorAndPatientId: { doctor: string; patient: string };
  isFromClient?: boolean;
  handleOpenCreateEdit?: () => void;
  setActivityToOpen?: React.Dispatch<React.SetStateAction<IActivityToOpen>>;
}

const RecentActivityCard = ({
  doctorAndPatientId,
  isFromClient = false,
  handleOpenCreateEdit,
  setActivityToOpen,
  patientResponseDate,
  ...props
}: IRecentActivityCardProps) => {
  const methods = useForm();

  const {
    _id,
    type,
    question,
    patientResponse,
    assignedAt,
    options,
    bookmarked,
  } = { ...props };
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const emojiBtType = type === ActivitiesTypes.MOOD ? Moods : sleepEmojies;

  const classes = useStyles({});
  const [handleDeletePatientActivity] = useDeletePatientActivities();
  const [bookmark, setBookmark] = useState(bookmarked);
  const [openResult, setOpenResult] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setBookmark(bookmarked);
  }, [bookmarked]);

  const { handleBookMark } = useHandlePatientActivities({
    defaultValues: {
      ...props,
    },
    type,
    doctorAndPatientId,
  });

  const handleDelete = () => {
    handleDeletePatientActivity({
      variables: { id: _id },
    });
    setOpenDeleteModal(false);
  };

  if (patientResponseDate) {
    return (
      <FormRecentCard
        id={_id}
        title={props?.title}
        type="form"
        description={props?.description}
        questions={props?.questions}
        doctorId={props?.doctorId}
        patientId={props?.patient?._id}
        assignedAt={assignedAt}
        patientResponseDate={patientResponseDate}
        blocks={props?.blocks}
        isPatientSigned={props?.isPatientSigned}
      />
    );
  }

  return (
    <Box className={classes.cardRoot} p={2} position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
        borderBottom="1px solid #F4F2EE"
        onClick={() => {
          setOpenResult(true);
        }}
        style={{ cursor: 'pointer' }}
      >
        <Box display="flex" alignItems="center">
          {type !== ActivitiesTypes.REFLECTION
            ? handleEmoji(patientResponse[0]?.response, emojiBtType)
            : types[type]?.icon}
          <Box display="flex" flexDirection="column" ml={2}>
            <Box>
              <Box className={classes.cardTitleWrapper}>
                <Typography className={classes.cardTitle}>
                  {type !== ActivitiesTypes.REFLECTION
                    ? t(patientResponse[0]?.response)
                    : t('Reflection')}
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBookMark(_id, !bookmark), setBookmark((s) => !s);
                  }}
                >
                  {bookmark ? <SavedYellow /> : <Saved />}
                </Box>
              </Box>
            </Box>
            <Typography className={classes.cardDate}>
              {t('Logged')} {t('Logged')}{' '}
              {dayjsFormat(new Date(assignedAt), 'MMM D, YYYY')}
            </Typography>
          </Box>
        </Box>

        <ArrowRightActivity />
      </Box>
      <Box mt={2}>
        {type === ActivitiesTypes.REFLECTION && (
          <Box>
            <Typography
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {patientResponse[0]?.question
                ? patientResponse[0]?.question
                : '-'}
            </Typography>
            <Typography
              className={classes.cardAnswer}
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
              }}
            >
              {patientResponse[0]?.response
                ? patientResponse[0]?.response
                : '-'}
            </Typography>
          </Box>
        )}
      </Box>

      {type === ActivitiesTypes.SLEEP && (
        <>
          <Box display="flex" alignItems="center" flexWrap="wrap" gridGap="4px">
            {patientResponse[0]?.tags?.map((el) => (
              <Box className={classes.emojiResponse}>{t(el)}</Box>
            ))}
          </Box>
          <Box className={classes.sleepData} my={2}>
            {patientResponse[0]?.sleepHours && (
              <Box className={classes.sleepBedTime}>
                <MoonStarIcon />
                {t('Bed time:')}
                <span style={{ fontWeight: 700 }}>
                  {dayjsFormat(patientResponse[0]?.sleepHours, 'h:mmA')}
                </span>
              </Box>
            )}
            {patientResponse[0]?.wakeHours && (
              <Box className={classes.sleepWakeupTime}>
                <SunIcon />
                {t('Wake up:')}
                <span style={{ fontWeight: 700 }}>
                  {dayjsFormat(patientResponse[0]?.wakeHours, 'h:mmA')}
                </span>
              </Box>
            )}
            {patientResponse[0]?.sleepHours &&
              patientResponse[0]?.wakeHours && (
                <Box className={classes.sleepTotal}>
                  Total:
                  <span style={{ fontWeight: 700 }}>
                    {Math.abs(
                      toDayjs(patientResponse[0]?.sleepHours).diff(
                        toDayjs(patientResponse[0]?.wakeHours).isBefore(
                          toDayjs(patientResponse[0]?.sleepHours)
                        )
                          ? toDayjs(patientResponse[0]?.wakeHours)
                          : toDayjs(patientResponse[0]?.wakeHours),
                        'hour'
                      )
                    )}{' '}
                    {t('hours')}
                  </span>
                </Box>
              )}
          </Box>
        </>
      )}
      {type === ActivitiesTypes.MOOD && (
        <Box>
          <Box display="flex" alignItems="center" flexWrap="wrap" gridGap="4px">
            {patientResponse[0]?.tags?.map((el) => (
              <Box className={classes.emojiResponse}>{t(el)}</Box>
            ))}
          </Box>

          {patientResponse[0]?.notes && (
            <Typography>{patientResponse[0]?.notes}</Typography>
          )}
        </Box>
      )}
      <BasicModal
        isRegularModal
        isSlide
        open={!isFromClient && openResult}
        onClose={() => setOpenResult(false)}
        handleClose={() => setOpenResult(false)}
      >
        <ResultModal
          activities={[{ ...props }]}
          activityType={typeMapping[type] || type}
          selectedDay={props?.frequency?.startDate}
          handleEmoji={handleEmoji}
          emojis={emojis}
          currentRowId={_id}
          onClose={() => setOpenResult(false)}
          doctorAndPatientId={doctorAndPatientId}
          handleBookMark={handleBookMark}
        />
      </BasicModal>

      <FormProvider {...methods}>
        <BasicModal
          isFromModal
          isSlide
          divider
          onSubmit={() => {
            setActivityToOpen({
              source: IActivitySource.EDIT,
              type: type as ActivitiesTypes,
              activity: { ...props },
            });
            handleOpenCreateEdit();
          }}
          loading={false}
          hasTitle={false}
          open={isFromClient && openResult}
          onClose={() => setOpenResult(false)}
          handleClose={() => setOpenResult(false)}
          submitText="Edit"
          deleteText="Delete"
          handleDelete={() => {
            setOpenResult(false);
            setOpenDeleteModal(true);
          }}
          editMode
        >
          <Box
            sx={{
              width: {
                xs: '50vw',
                lg: '43vw',
                xl: '35vw',
              },
            }}
          >
            <ViewModal
              activities={[{ ...props }]}
              activityType={typeMapping[type] || type}
              currentId={_id}
            />
          </Box>
        </BasicModal>
      </FormProvider>
      <ModalDelete
        text={
          type === ActivitiesTypes.REFLECTION
            ? 'reflection'
            : `${typeMapping[type]} check-in`
        }
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
};

export default RecentActivityCard;

//---------------------

export const RecentActivityCardLoading = () => {
  const classes = useStyles({});

  return (
    <Box className={classes.cardRoot} p={2} position="relative" width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pb={2}
        borderBottom="1px solid #F4F2EE"
        style={{ cursor: 'pointer' }}
      >
        <Box display="flex" alignItems="center">
          <Skeleton loading={true} type="circle" width="2.5rem" height="2.5rem">
            <></>
          </Skeleton>
          <Box display="flex" flexDirection="column" ml={2}>
            <Box>
              <Box className={classes.cardTitleWrapper}>
                <Skeleton
                  loading={true}
                  type="text"
                  width="4rem"
                  height="1.2rem"
                >
                  <></>
                </Skeleton>
                <Skeleton loading={true} type="text" width="15px" height="15px">
                  <></>
                </Skeleton>
              </Box>
            </Box>
            <Skeleton loading={true} type="text" width="6rem" height="1.2rem">
              <></>
            </Skeleton>
          </Box>
        </Box>

        <Skeleton loading={true} type="rect" width="15px" height="15px">
          <></>
        </Skeleton>
      </Box>

      <Box>
        <Box display="flex" alignItems="center" flexWrap="wrap" mt={1}>
          {[0, 1, 2].map((el) => (
            <Box className={classes.emojiResponse}>
              {' '}
              <Skeleton loading={true} type="text" width="3rem" height="1.2rem">
                <></>
              </Skeleton>
            </Box>
          ))}
        </Box>
        <Skeleton loading={true} type="text" width="7rem" height="1.2rem">
          <></>
        </Skeleton>
      </Box>
    </Box>
  );
};
