import { makeStyles } from '@material-ui/core/styles';
import CreatePatientIllustration from 'assets/illustrations/createPatientIllustration.svg';

const useStyles = makeStyles((theme) => ({
  confirmButton: {
    marginTop: theme.typography.pxToRem(27),
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 12px',
    gap: '4px',
    width: '340px',
    height: '40px',
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    textTransform: 'none',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#D92D20',
    },
    lineHeight: 'unset !important',
  },
  avatar: {
    width: theme.typography.pxToRem(25),
    height: theme.typography.pxToRem(25),
    boxSizing: 'border-box',
    border: '0.75px solid #000000',
    borderRadius: '200px',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperProvider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    marginTop: '0.5rem',
  },
  providerName: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#475467',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    fontSize: '19px',
    fontWeight: 400,
    color: '#A4A4A4',
    marginTop: '0.5em',
  },
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#425364',
    lineHeight: '37px',
    marginTop: '0.5em',
    [theme.breakpoints.only('xs')]: {
      fontSize: '26px',
    },
  },
  titleWrapper: {
    // backgroundColor: '#F8FAFB',
    // border: '1px solid #D7E3F1',
    borderRadius: '12px',
  },

  tabWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBox: {
    backgroundColor: 'white',
    borderRadius: '12px',
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
  },
  divider: {
    width: '85%',
    backgroundColor: '#D7E3F1',
    marginTop: '7px',
  },
  label: {
    fontSize: theme.typography.pxToRem(19),
    fontWeight: 650,
    color: '#425364',
    lineHeight: '22px',
    // marginTop: '0.5em',
  },
  icon: {
    paddingRight: '13px',
    marginLeft: theme.spacing(3),
  },
  btn: {
    backgroundColor: '#49DCBB',
    borderRadius: '19px',
    color: '#425364',
    fontSize: '12px',
    marginTop: theme.spacing(1),
    textTransform: 'capitalize',
    padding: '8px 18px',
  },
  iconMedical: {
    paddingRight: '8px',
    marginLeft: '20px',
  },
  upload: {
    fontSize: '14px',
    lineHeight: '17px',
    color: '#425364',
  },
  paragraph: {
    color: '#425364',
    fontSize: '0.875rem',
    // fontWeight: 700,
    // marginLeft: theme.spacing(7),
  },
  bloodInformation: {
    [theme.breakpoints.only('xl')]: {
      position: 'absolute',
      right: '0.3rem',
      top: '0rem',
    },
    [theme.breakpoints.only('lg')]: {
      position: 'absolute',
      right: '0.3rem',
      top: '0rem',
    },
    [theme.breakpoints.only('md')]: {
      position: 'absolute',
      right: '0.3rem',
      top: '0rem',
    },
    [theme.breakpoints.only('sm')]: {
      position: 'absolute',
      right: '0.3rem',
      top: '0rem',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: theme.spacing(-8),
    },
  },
  paragraphWrapper: {
    marginLeft: theme.spacing(2),
  },
  backgroundColor: {
    backgroundColor: '#F8FAFB',
    borderRadius: '12px',
    border: '1px solid #BCC6D3',
  },
  editIcon: {
    // position: 'absolute',
    // right: '1rem',
    display: 'flex',
    border: '1px solid #425364',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: '6px',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    lineHeight: '16px',
    backgroundColor: 'white',
    textTransform: 'capitalize',
    color: '#425364',
    padding: '5.5px 16.5px',
    '&:hover ': {
      border: '1px solid White',
      backgroundColor: '#2DC76D',
      color: 'white',
      '& svg': {
        '& path': {
          stroke: 'white',
        },
      },
    },
  },
  margin: {
    marginTop: theme.spacing(1),
  },
  drugsName: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '21px',
    letterSpacing: ' 0.300349px',
    color: '#1F61DC',
    marginBottom: '4px',
  },
  drugsConcentration: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: ' 0.233605px',
    color: '#425364',
    marginBottom: '4px',
  },
  drugsDetails: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    letterSpacing: ' 0.233605px',
    color: '#F96999',
  },
  illustration: {
    width: '100%',
  },
  medication: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#425364',
    lineHeight: '37px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    marginTop: theme.spacing(-4),
  },
  btnMedication: {
    fontSize: '14px',
    color: '#FFFFFF',
    weight: '400',
    lineHeight: '16px',
    backgroundColor: '#1F61DC',
    borderRadius: '5px',
    textTransform: 'unset',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 18px',
    '&:hover ': {
      backgroundColor: '#082CAF',
    },
  },
  shapeIcon: {
    width: '14px',
    height: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  textHistory: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.44px',
    color: '#425364',
  },
  dividerCurrentConditions: {
    width: '100%',
    // marginLeft: theme.spacing(-4),
  },
  textCondition: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '21px',
    letterSpacing: '0.233605px',
    color: '#425364',
  },
  dateCondition: {
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '0.233605px',
    color: '#39C38D',
  },
  conditionBox: {
    display: 'flex',
    width: '100%',
    borderBottom: '0.75px solid #D7E3F1',
    '&:nth-last-child(1)': {
      borderBottom: 'none',
    },
  },
  textTreatments: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.233605px',
    color: '#425364',
  },
  centre: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    fontSize: '23px',
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0px `,
  },
  ScrollContainer: {
    overflowX: 'hidden',
    paddingBlock: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      height: '55vh',
      maxHeight: '55vh',
    },
    [theme.breakpoints.only('xs')]: {
      height: '55vh',
      maxHeight: '55vh',
    },
    [theme.breakpoints.only('md')]: {
      height: '60vh',
      maxHeight: '60vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '60vh',
      maxHeight: '60vh',
    },
    [theme.breakpoints.only('xl')]: {
      height: '60vh',
      maxHeight: '60vh',
    },
  },
  formContainer: {
    width: '40vw',
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
    borderRadius: '24px',
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  badge: {
    width: '25px',
    cursor: 'pointer',
  },
  large: {
    width: theme.typography.pxToRem(53),
    height: theme.typography.pxToRem(53),
    backgroundColor: '#FFFFFF',
    border: '1px solid #9CB2CD',
  },
  InputLabel: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    color: '#707090',
    lineHeight: theme.typography.pxToRem(21),
  },
  TextFiled: {
    width: '100%',
    marginTop: theme.typography.pxToRem(10),
    borderRadius: '5px',
    backgroundColor: '#f8fafb',
    minHeight: theme.typography.pxToRem(200),
  },
  notchedOutline: {
    border: 'none !important',
  },
  errorBox: {
    position: 'absolute',
    bottom: '-1.5rem',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  modalHeader: {
    backgroundImage: `url(${CreatePatientIllustration})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    backgroundSize: 'cover',
    height: theme.typography.pxToRem(104),
    display: 'flex',
    alignItems: 'center',
    padding: '0 3rem',
  },
  titleAccordion: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
  },
  editButton: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#0265DC',
    textDecoration: 'underline',
  },
  subtitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#0054B6',
  },
  summary: {
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    alignItems: 'flex-start',
  },
  summaryTopArrow: {
    '& .MuiAccordionSummary-root': {
      alignItems: 'flex-start',

      '& .MuiAccordionSummary-expandIcon': {
        top: 9,
      },
    },
  },
  accordionInDivider: {
    width: 'calc(100% - 25px)',
    marginTop: '0.8rem',
    marginBottom: '0.8rem',
  },
  accordionDivider: {
    width: 'calc(100% - 60px)',
    marginBottom: '0.8rem',
  },
  personalNotesSummary: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  textField: {
    width: '100%',
    '& fieldset': {
      border: '1px solid #D0D5DD !important',
      borderRadius: '8px',
    },
  },
}));

export default useStyles;
