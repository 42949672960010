import { useLazyQuery, useQuery, gql } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import useAddTask from '@eggmed/graphql-client/operations/tasksOperations/useAddTask';
import useUpdateTask from '@eggmed/graphql-client/operations/tasksOperations/useUpdateTask';
import useUpdateTaskList from '@eggmed/graphql-client/operations/tasksOperations/useUpdateTaskList';

import { useHistory } from 'react-router-dom';
import React, {
  useReducer,
  useContext,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useCallback,
} from 'react';
import useDeleteTask from '@eggmed/graphql-client/operations/tasksOperations/useDeleteTask';
import useDeleteTaskAttachment from '@eggmed/graphql-client/operations/tasksOperations/useDeleteTaskAttachment';
import useAddTaskAttachment from '@eggmed/graphql-client/operations/tasksOperations/useAddTaskAttachment';
import { log } from 'config/log';
import {
  editTaskInput,
  taskInput,
} from '../../../../__generated__/globalTypes';
import { TASKS_LIST_QUERY, TASKS_LIST_PATIENT_QUERY } from '../TaskPage';
import { getTasks_tasks } from '../__generated__/getTasks';
import {
  CLOSE_MODAL,
  VIEW_TASK,
  NO_USED_FEATURE,
  ADD_TASK,
  EDIT_TASK,
  DELETE_TASK,
  ADD_ATTACHMENT_TASK,
} from './actions';
import reducer, { Context, initialState } from './reducer';
import {
  convertObjectsFormatStaffs,
  convertObjectsFormatCurrentUser,
} from 'pages/SchedulePage/AddEventPage/utils';

interface IProvider {
  children: ReactNode;
  isAppt?: boolean;
  patientParam?: string;
  isLoad?: boolean;
}

export default function Provider({
  children,
  isAppt = false,
  patientParam,
  isLoad = true,
}: IProvider): ReactElement {
  const [values, setValues] = React.useState({ id: '' });
  const [tasksList, setTasksList] = useState(
    isAppt ? { tasksListPatient: [] } : { tasksList: [] }
  );
  const [shoudFetchTask, setShoudfetchTask] = React.useState(true);
  const { push } = useHistory();

  const { doctor, user, dataSubscription } = useAuth();

  const doctorId = doctor?._id || null;
  const noUsedFeatures = doctor?.noUsedFeatures || null;
  const [allList, setAllList] = React.useState({ tasksList: [] });

  const [
    loadTasks,
    { error: errorList, loading: loadingList, refetch: refetchList, data },
  ] = useLazyQuery(TASKS_LIST_QUERY);
  const [
    loadPatientTasks,
    {
      error: errorPatientList,
      loading: loadingPatientList,
      refetch: refetchPatientList,
      data: dataPatient,
    },
  ] = useLazyQuery(TASKS_LIST_PATIENT_QUERY);

  const { editTask, loading: loadingUpdateTask } = useUpdateTask();

  const patients = dataSubscription?.patientsRelated;
  const { editTasksList, loading: loadingEditTask } = useUpdateTaskList();
  const { createTask, loading: loadingTask } = useAddTask();
  const { deleteTask, loading: loadingDeleteTask } = useDeleteTask();
  const { deleteTaskAttachment } = useDeleteTaskAttachment();
  const { addTaskAttachment } = useAddTaskAttachment();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const fetchTasks = useCallback(
    () =>
      isAppt
        ? loadPatientTasks({
            variables: {
              userId: user?._id,
              patient: patientParam,
            },
          }).then((data) => {
            refetchPatientList();
            setTasksList(data.data);
          })
        : loadTasks({
            variables: {
              userId: user?._id,
              doctors: values?.id ? [values?.id] : [],
            },
          }).then((data) => {
            refetchList();
            setTasksList(data.data);
            setAllList(data.data);
          }),
    [user?._id, loadTasks, loadPatientTasks, isAppt, values]
  );
  useEffect(() => {
    // if (noUsedFeatures.indexOf('task') !== -1) {
    //   dispatch({ type: NO_USED_FEATURE });
    // }
    if (shoudFetchTask && isLoad) {
      fetchTasks();
    }
  }, [fetchTasks, data, dataPatient, values, isLoad]);

  function handleViewTask(task: getTasks_tasks) {
    dispatch({ type: VIEW_TASK, payload: task });
  }
  function handleOpenEditTask(task: getTasks_tasks) {
    dispatch({ type: EDIT_TASK, payload: task });
  }
  function handleOpenAddTaskModal() {
    dispatch({ type: ADD_TASK });
  }
  function handleOpenDeleteModal() {
    dispatch({ type: DELETE_TASK });
  }
  function closeModal() {
    dispatch({ type: CLOSE_MODAL });
  }
  async function handleAddTaskAttachment(taskId: string, files: any[]) {
    try {
      const addAttachmentResult = await addTaskAttachment({
        variables: { taskId, files },
        refetchQueries: [{ query: TASKS_LIST_QUERY }],
        context: {
          hasUpload: true,
        },
      });
      setShoudfetchTask(true);
      dispatch({
        type: ADD_ATTACHMENT_TASK,
        payload: {
          ...state.selectedTask,
          attachments: addAttachmentResult.data?.addAttachment?.attachments,
        },
      });
    } catch (error) {
      log('error', error);
    }
  }
  async function handleDeleteTaskAttachment(
    taskId: string,
    attachmentUrl: string,
    closeModal: () => void
  ) {
    try {
      await deleteTaskAttachment({
        variables: { taskId, url: attachmentUrl },
      });
      setShoudfetchTask(true);
      closeModal();
      // dispatch({
      //   type: VIEW_TASK,
      //   payload: {
      //     ...state.selectedTask,
      //     attachments: data.data?.deleteAttachment?.attachments,
      //   },
      // });
    } catch (error) {
      log('error', error);
    }
  }
  async function handleEditTask(
    taskId: string,
    taskInput: editTaskInput,
    close?: boolean
  ) {
    try {
      await editTask({ variables: { taskId, taskInput } });
      setShoudfetchTask(true);
      close && closeModal();
      fetchTasks();
    } catch (error) {
      log('error', error);
    }
  }
  function handleEditTaskList(tasksListsIds: string[], taskListInputs: any[]) {
    const data = (tasksLists) =>
      isAppt ? tasksLists.tasksListPatient : tasksLists.tasksList;
    setTasksList((tasksList) => {
      const newList = data(tasksList)?.map((taskList) => {
        const index = tasksListsIds.indexOf(taskList._id);
        if (index !== -1) {
          return { ...taskList, tasks: taskListInputs[index].fullTasks };
        }
        return taskList;
      });
      if (isAppt)
        return {
          tasksListPatient: newList,
        };
      return {
        tasksList: newList,
      };
    });
    Promise.all(
      tasksListsIds.map(async (taskListId, index) => {
        try {
          await editTasksList({
            variables: {
              taskListId,
              taskListInput: { tasks: taskListInputs[index]?.tasks },
            },
          });
        } catch (error) {
          log('error', error);
        }
      })
    );
    setShoudfetchTask(false);
  }
  async function handleDeleteTask(
    taskId: string,
    closeDeleteModal?: () => void
  ) {
    try {
      await deleteTask({ variables: { taskId } });
      setShoudfetchTask(true);
      fetchTasks();
      closeDeleteModal?.();
    } catch (error) {
      log('error', error);
    }
  }
  async function handleAddTask(data: taskInput, files: File[], listId: string) {
    const taskInput = { ...data, userId: user?._id };
    try {
      await createTask({
        variables: { taskInput, files, listId },
        context: {
          hasUpload: true,
        },
      });
      setShoudfetchTask(true);
      fetchTasks();
      closeModal();
    } catch (error) {
      log('error', error);
    }
  }

  return (
    <Context.Provider
      value={{
        ...state,
        doctorId,
        // users: [
        //   ...staffArray,
        //   convertObjectsFormatCurrentUser([currentUser], ''),
        // ],
        doctors: [],
        patients,
        loadingTask,
        closeModal,
        handleEditTask,
        loadingEditTask,
        handleAddTask,
        loadingUpdateTask,
        handleViewTask,
        handleOpenAddTaskModal,
        handleOpenEditTask,
        handleDeleteTask,
        handleDeleteTaskAttachment,
        handleAddTaskAttachment,
        handleOpenDeleteModal,
        dataList: tasksList,
        refetchList: isAppt ? refetchPatientList : refetchList,
        handleEditTaskList,
        loadingList: isAppt ? loadingPatientList : loadingList,
        errorList: isAppt ? errorPatientList : errorList,
        isAppt,
        loadingDeleteTask,
        values,
        setValues,
        allList,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useTaskPageProvider must be used within a TasksPageProvider'
    );
  }

  return context;
}
