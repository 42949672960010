import { Grid } from '@material-ui/core';
import React from 'react';
import LeftSheet from './Facesheet/LeftSheet';
import { RightSheet } from './Facesheet/RightSheet';

const FaceSheet = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xl={6} lg={6}>
        <LeftSheet {...props} />
      </Grid>
      <Grid item xl={6} lg={6}>
        <RightSheet {...props} />
      </Grid>
    </Grid>
  );
};

export default FaceSheet;
