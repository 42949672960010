import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './index';
import Text from 'Text';

const ClaimTableHeader = () => {
  const classes = useStyles();

  return (
    <Box boxSizing="border-box" width="100%">
      <Box
        display="flex"
        flexWrap="no-wrap"
        boxSizing="border-box"
        width="100%"
      >
        <Box
          style={{ flexBasis: '15%', minWidth: '290px' }}
          display="flex"
          flexDirection="column"
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            boxSizing="border-box"
            flex={1}
          >
            <Text i18nKey="24. a. Date(s) of service" className={classes.label}>
              24. a. Date(s) of service
            </Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ gap: '5px' }}
            mt={1}
          >
            <Box width="100%" flex={1}>
              <Box className={classes.tableLabelDetails} p={0}>
                <Text
                  i18nKey="FromDATE"
                  className={classes.label}
                  style={{ textAlign: 'center' }}
                >
                  From
                </Text>
              </Box>
            </Box>
            <Box width="100%" flex={1}>
              <Box className={classes.tableLabelDetails} p={0}>
                <Text
                  i18nKey="To"
                  className={classes.label}
                  style={{ textAlign: 'center' }}
                >
                  To
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            flexBasis: '7%',
            minWidth: '90px',
          }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Text i18nKey="b. Place of service" className={classes.label}>
              b. Place of service
            </Text>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '5%', minWidth: '65px' }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Typography className={classes.label}>c. EMG</Typography>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '24%', minWidth: '345px' }}
          display="flex"
          flexDirection="column"
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            boxSizing="border-box"
            style={{ flexGrow: 1 }}
          >
            <Text
              i18nKey="d. procedures, services, or supplies"
              className={classes.label}
            >
              d. procedures, services, or supplies
            </Text>
          </Box>
          <Box
            display="flex"
            alignItems="stretch"
            justifyContent="center"
            style={{ gap: '5px' }}
            mt={1}
          >
            <Box width="100%" minWidth="56.5%" flex={1}>
              <Box className={classes.tableLabelDetails} p={0}>
                <Typography
                  className={classes.label}
                  style={{ textAlign: 'center' }}
                >
                  CPT/HPCS descriptions
                </Typography>
              </Box>
            </Box>

            <Box width="100%" flex={1}>
              <Box
                className={classes.tableLabelDetails}
                p={0}
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text i18nKey="Modifiers" className={classes.label}>
                  Modifiers
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '12.5%', minWidth: '12.5%' }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Text i18nKey="e. Diagnosis pointer" className={classes.label}>
              e. Diagnosis pointer
            </Text>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '6.2%', minWidth: '100px' }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Text i18nKey="f. $ Charges" className={classes.label}>
              f. $ Charges
            </Text>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '4.1%', minWidth: '70px' }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Text i18nKey="g. Units" className={classes.label}>
              g. Units
            </Text>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '4.9%', minWidth: '85px' }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Typography className={classes.label}>h. EPSDT</Typography>
          </Box>
        </Box>
        <Box
          style={{ flexBasis: '20.7%', minWidth: '20.7%' }}
          className={classes.cell}
        >
          <Box
            className={classes.tableLabelRoot}
            p={1}
            mb={0}
            height="100%"
            boxSizing="border-box"
            display="flex"
            alignItems="center"
          >
            <Text
              i18nKey="j. Rendering provider NPI#"
              className={classes.label}
            >
              j. Rendering provider NPI#
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimTableHeader;
