import React from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputBase,
  withStyles,
  Typography,
} from '@material-ui/core';
import { colorsMapping } from './Constant';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CustomChevronIcon } from 'assets/dashboard/down.svg';

interface CustomSelectProps {
  values: string[];
  selectedColor: string;
  setSelectedColor: React.Dispatch<React.SetStateAction<string>>;
  hasLabel?: boolean;
}
export const FormInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    height: '44px',
    position: 'relative',
    backgroundColor: ' #FFFFFF',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    padding: 0,
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 8,
      border: '1px solid #082CAF',
    },
  },
}))(InputBase);

function TagSelect({
  selectedColor,
  setSelectedColor,
  values,
  hasLabel = true,
}: CustomSelectProps) {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    setSelectedColor(value as string);
  };
  const { t } = useTranslation();
  const CustomChevron = () => (
    <Box
      position="absolute"
      right="10px"
      top="50%"
      style={{
        transform: 'translateY(-50%)',
      }}
    >
      <CustomChevronIcon />
    </Box>
  );

  return (
    <Box flex={1}>
      {hasLabel && (
        <Text
          i18nKey="color"
          style={{
            fontFamily: 'Inter, sans-serif',
            fontWeight: 500,
            fontSize: '14px',
            color: '#344054',
            marginBottom: '10px',
          }}
        >
          Color
        </Text>
      )}
      <FormControl style={{ width: '100%' }}>
        <Select
          value={selectedColor}
          onChange={handleChange}
          IconComponent={CustomChevron}
          input={<FormInput />}
          MenuProps={{
            disableScrollLock: false,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            PaperProps: {
              style: {
                maxHeight: '300px',
                overflowY: 'auto',
              },
            },
          }}
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
        >
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              <Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                style={{
                  gap: '0.5rem',
                }}
              >
                <Box
                  width={17}
                  height={17}
                  style={{
                    borderRadius: '50%',
                    backgroundColor:
                      value === '#BDBDBD' ? '#BDBDBD' : colorsMapping[value],
                    marginRight: '5px',
                  }}
                ></Box>

                {t(value)}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default TagSelect;
