import React from 'react';
import TextField, { FilledTextFieldProps } from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { Controller, Control } from 'react-hook-form';
// Variant filled
export const FormInput = withStyles((theme) => ({
  root: {
    width: '100%',
    // height: '50px',
    '& .MuiFilledInput-root': {
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      height: 44,
      border: '1px solid #D0D5DD',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px',
      },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },
    '& .Mui-focused': {
      border: '1px solid #082CAF',
    },
    '& .Mui-error': {
      border: '1px solid red',
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: 0,
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
      minWidth: 'auto',
    },
    '& .MuiFilledInput-input': {
      fontFamily: 'Inter, sans-serif',
      padding: '10px ',
      borderRadius: 'inherit',
      // '&::placeholder': {
      //   color: '#182230',
      //   fontFamily: 'Inter',
      //   fontSize: '16px',
      //   fontWeight: 500,
      //   lineHeight: '24px',
      //   opacity: 1,
      // },
    },
    '& .MuiInputBase-multiline': {
      padding: '6px 0 7px',
    },
    '& .MuiFilledInput-underline:after': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '1px solid #3f50b5',
    },
    '& .MuiFilledInput-underline:before': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '0px solid #3f50b5',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid white',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: theme.spacing(0),
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}))(TextField);

interface IControllerFormInput extends FilledTextFieldProps {
  control: Control;
}

const ControllerFormInput = ({
  control,
  name,
  defaultValue,
  variant = 'filled',
  ...props
}: IControllerFormInput): JSX.Element => {
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name!}
      InputProps={{
        inputProps: {
          style: {
            // '::placeholder': {
            //   color: '#182230', // Change the placeholder color
            //   fontFamily: 'Inter',
            //   fontSize: '16px',
            //   fontWeight: 500,
            //   lineHeight: '24px',
            // },
          },
        },
      }}
      render={({ ...params }) => (
        <FormInput {...props} {...params} variant={variant} name={name} />
      )}
    />
  );
};

export default ControllerFormInput;
