import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modalsContainer: {
    backgroundColor: 'white',
    borderRadius: '12px',
    paddingInline: '3rem',
    height: '100vh',
  },

  responsiveMobile: {
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    height: 'auto',
  },

  locationTitle: {
    color: '#1F61DC',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(43),
  },
  inputBox: {
    marginTop: theme.spacing(1),
  },
  label: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#344054',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '20px',
    fontWeight: 500,
  },
  filed: {
    marginBottom: theme.typography.pxToRem(15),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    color: '#344054',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
  buttonFormContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonForm: {
    marginLeft: theme.typography.pxToRem(10),
  },
  buttonFormSave: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
  },
  buttonFormCancel: {
    backgroundColor: theme.palette.grey[400],
    color: 'white',
  },
  marginElement: {
    marginTop: theme.typography.pxToRem(12),
  },
  newThreadModal: {
    overflowX: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('md')]: {
      maxHeight: '70vh',
      width: '50vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
  },
}));

export default useStyles;
