/* eslint-disable*/
import { useMutation } from '@apollo/client';
import { DELETE_APPOINTMENT } from '../../queries/appointment';

export default function useDeleteAppointment() {
  const [removeAppointment, result] = useMutation(DELETE_APPOINTMENT, {
    update: (cache, { data: { deleteAppointment } }) => {
      cache.modify({
        fields: {
          appointments(appointments) {
            const newAppointments = appointments?.result?.filter(
              (appointment) => {
                const appointmentId = appointment?._id;
                return appointmentId !== deleteAppointment?._id;
              }
            );
            return newAppointments;
          },
          appointmentsByDate(appointments) {
            const newAppointments = appointments?.result?.filter(
              (appointment) => {
                const appointmentId = appointment?._id;
                return appointmentId !== deleteAppointment?._id;
              }
            );
            return newAppointments;
          },
          doctorUpcomingAppointments(upcomingAppointment) {
            const newAppintments = upcomingAppointment?.result?.filter(
              (appointment) => {
                const appointmentId = appointment?._id;
                return appointmentId !== deleteAppointment?._id;
              }
            );
            return newAppintments;
          },
          patientUpcomingAppointments(upcomingAppointment) {
            const newAppintments = upcomingAppointment?.result?.filter(
              (appointment) => {
                const appointmentId = appointment?._id;
                return appointmentId !== deleteAppointment?._id;
              }
            );
            return newAppintments;
          },
          patientPastAppointments(pastAppointment) {
            const newAppintments = pastAppointment?.result?.filter(
              (appointment) => {
                const appointmentId = appointment?._id;
                return appointmentId !== deleteAppointment?._id;
              }
            );
            return newAppintments;
          },
          appointmentSlide(appointments) {
            const newAppintments = appointments?.result?.filter(
              (appointment) => {
                const appointmentId = appointment?._id;
                return appointmentId !== deleteAppointment?._id;
              }
            );
            return newAppintments;
          },
          availableTimes(times) {
            const date = new Date(deleteAppointment?.startDate);
            const doctorFormatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              timeZone: deleteAppointment?.doctor?.timeZone,
            });

            const doctorFormattedDate = doctorFormatter.format(date);

            return [...times, doctorFormattedDate];
          },

          conflictsAppointments(appointment) {
            const newAppointments = appointment?.result
              ?.map((appointment) => {
                const shouldRemoveInnerArray = appointment?.some((el) => {
                  const appointmentId = el?._id;
                  return appointmentId === deleteAppointment?._id;
                });

                return shouldRemoveInnerArray ? [] : appointment;
              })
              .filter((element) => element.length > 0);

            return newAppointments;
          },
        },
      });
    },
  });
  async function deleteAppointment(
    id: string,
    isOne = true,
    sideEffectCall: (error: Error) => void
  ) {
    try {
      const result = await removeAppointment({
        variables: { appointmentId: id, isOne },
      });
      return result;
    } catch (e) {
      return sideEffectCall(e as Error);
    }
  }

  return { deleteAppointment, ...result };
}
