import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  type: {
    backgroundColor: '#F9FAFB',
    borderRadius: '16px',
    whiteSpace: 'nowrap',
    border: '1px solid #EAECF0',
    padding: '2px 8px',
    marginRight: '4px',
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Inter',
  },
  root: {
    borderRadius: '25px',
    padding: theme.spacing(2),
  },
  transactionText: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#101828',
  },
  tabHeader: {
    paddingInline: '0rem',
    marginTop: theme.spacing(4),
    position: 'relative',
  },
  selectMenu: {
    [theme.breakpoints.only('xs')]: {
      width: '72px',
      height: '20px',
    },
  },
  tabForm: {
    width: '100%',
    '& .MuiTabs-scrollable': {
      marginLeft: '2rem',
    },
    marginLeft: '-2rem',
  },
  menu: {
    borderRadius: '7px',
    width: '200px',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  arrow: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      right: '2px',
    },
  },
  title: { fontWeight: 500, fontSize: '15px', color: 'white' },
  amount: { fontWeight: 900, fontSize: '24px', color: 'white' },
  btn: {
    border: '0.75px solid #A1B0C4FF',
    paddingInline: '20px',
    color: '#425364',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  svgMarginRight: {
    marginRight: '10px',
  },
  btnC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '20px',
    color: '#425364',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  btnS: {
    paddingInline: '20px',
    color: 'white',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    backgroundColor: '#1F61DC',
    marginInline: '10px',
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  btnSettings: {
    paddingInline: '20px',
    color: '#147AF3',
    fontSize: '17px',
    fontWeight: 500,
    textTransform: 'unset',
    backgroundColor: 'transparent',
    marginInline: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& svg': {
      marginRight: '0.5rem',
    },
  },
  btnSC: {
    height: '36px',
    border: '0.75px solid #A4A4A4',
    paddingInline: '30px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 500,
    textTransform: 'capitalize',
    backgroundColor: '#1F61DC',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#1F61DC',
    },
  },
  paid: {
    border: '1px solid #6FCF97',
    color: '#127850',
    backgroundColor: '#DCFFEB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  overdue: {
    border: '1px solid #FECDCA',
    color: '#B42318',
    backgroundColor: '#FEF3F2',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  waived: {
    border: '1px solid #D5D9EB',
    color: '#363F72',
    backgroundColor: '#F8F9FC',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  refunded: {
    border: '1px solid #D9D6FE',
    color: '#5925DC',
    backgroundColor: '#F4F3FF',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  free: {
    border: '1px solid #EAECF0',
    color: '#344054',
    backgroundColor: '#F9FAFB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '2px 8px',
  },
  pending: {
    border: '1px solid #FEDF89',
    color: '#B54708',
    backgroundColor: '#FFFAEB',
    borderRadius: 16,
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '12px',
    width: 'fit-content',
    padding: '4px 8px',
  },
  rootRadio: {
    maxWidth: '22px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: 'white',
    border: '1px solid #39B54A',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(#39B54A,#39B54A 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#f5f8fa',
    },
  },
  paymentTitle: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '24px',
    letterSpacing: '0.300349px',
    color: '#425364',
    marginBlock: theme.spacing(1),
  },
  paymentText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    letterSpacing: '0.300349px',
    color: '#425364',
  },
  addBtn: {
    backgroundColor: '#147AF3',
    color: 'white',
    textTransform: 'unset',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#147AF3',
      color: 'white',
    },

    '& svg': {
      marginRight: '7px',
    },
  },
  cancel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '10px 14px',
    boxSizing: 'border-box',
    border: '1px solid #D0D5DD',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#344054',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  previewBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '10px 14px',
    boxSizing: 'border-box',
    border: '1px solid #0265DC',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#0265DC',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  assignButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    padding: '10px 14px',
    minWidth: '100px',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
    marginRight: '0.5rem',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
    '&:disabled': {
      border: '1px solid #D0D5DD',
      backgroundColor: '#D0D5DD',
      color: 'white',
    },
  },
  scrollbar: {
    height: 'calc(94vh - 95px)',
    maxHeight: 'calc(94vh - 95px)',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTop: '1px solid #EAECF0',
    height: '90px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '10px',
  },
  labelText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '0.5rem',
  },
  accordionContainer: {
    marginBlock: '12px',
  },
  accordionSummaryWrapper: {},
  accordionTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    color: '#344054',
  },
  accordionSummary: {},
  accordionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F2F4F7',
    paddingInline: '8px',
  },
  accordionContent: {
    marginBlock: '12px',
  },
  label: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '23px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '0.4rem',
  },
  text: {
    flexGrow: 1,
    fontSize: '12px',
  },
  textLabel: {
    fontWeight: 'bold',
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '3px',
  },
  avatar: {
    width: '2.25rem',
    height: '2.25rem',
  },
  balance: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 16,
    fontWeight: 500,
    color: '#0054B6',
    textAlign: 'right',
  },
  name: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#425364',
  },
  common: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#425364',
  },
  previewTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '30px',
    fontWeight: 600,
    color: '#3F4857',
  },
  previewDetail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  previewLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    color: '#425364',
  },
  previewText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#425364',
    display: 'flex',
    alignItems: 'center',
  },
  balanceRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px',
  },
  balanceDue: {
    backgroundColor: '#EFF6FF',
    padding: '8px 6px',
  },
  balanceDueLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 700,
    color: '#425364',
  },
  balanceDueText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '18px',
    fontWeight: 700,
    color: '#425364',
  },
  balanceLabel: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: '#425364',
  },
  noClient: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'italic',
    color: '#D92D20',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  lastCol: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1rem',
  },
  invoiceText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '16px',
    textDecoration: 'underline',
    textAlign: 'right',
    color: '#147AF3',
    cursor: 'pointer',
    minWidth: '80px',
  },
  dots: {
    cursor: 'pointer',
  },
  rowText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#344054',
  },
  textRow: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
  },
  textField: {
    backgroundColor: '#F7F7F7',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0.5px solid #D0D5DD',
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        border: '0.5px solid #D0D5DD',
        borderRadius: '8px',
      },
      '&.Mui-focused fieldset': {
        border: '0.5px solid #D0D5DD',
        borderRadius: '8px',
      },
    },
    '& .MuiInputBase-input': {
      padding: '12px 14px 12px 5px',
    },
    '& .MuiFormLabel-root': {},
    '& .MuiFormLabel-root.Mui-focused': {},
  },
}));
export default useStyles;
