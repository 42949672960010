import React, { useState } from 'react';

function useUploadPicture(editDoctor, id, setValue) {
  const [openUpload, setOpenUpload] = useState(false);
  const [fileObjects, setFileObjects] = React.useState<any>([]);
  function onAddPicture(newFileObjs: any) {
    if (fileObjects.length > 0) return null;
    setFileObjects([].concat(fileObjects, newFileObjs));
    return null;
  }
  function onDeletePicture(deleteFileObj: any) {
    const filltredArray = fileObjects.filter(
      (value: any, index: any) => value !== deleteFileObj
    );
    setFileObjects(filltredArray);
  }
  function onSave() {
    setOpenUpload(false);
    editPictureValue(fileObjects[0]?.file);
  }
  function editPictureValue(file: File) {
    setValue('picture', file);
    editDoctor({
      variables: {
        doctorID: id,
        DoctorInput: {},
        picture: typeof file === 'string' ? undefined : file,
      },
      context: {
        hasUpload: true,
      },
    });
  }
  return {
    openUpload,
    setOpenUpload,
    fileObjects,
    setFileObjects,
    onAddPicture,
    onDeletePicture,
    onSave,
  };
}

export default useUploadPicture;
