import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';

const useStyles = makeStyles<Theme>((theme) => ({
  search: {
    display: 'flex',
    height: '40px',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '100%',
    border: `1px solid #D0D5DD`,
    padding: '0 10px',
    gap: '5px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      background: 'white',
    },
  },
  searchIcon: {
    pointerEvents: 'none',
    color: theme.palette.gray.main,
    display: 'flex',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputInput: {
    flex: 1,
    border: 'none',
    resize: 'none',
    outline: 'none',
    // color: 'transparent',
    '&::placeholder': {
      color: '#667085',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
}));
interface IProps {
  groupedOptions: any;
  handleChange: (e: any) => void;
  loading?: boolean;
  renderOption?: (
    option: any,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any>
  ) => void;
}

const SearchInput = ({
  groupedOptions,
  handleChange,
  ...props
}: IProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Autocomplete
      options={groupedOptions}
      onInputChange={handleChange}
      renderInput={(params) => (
        <div className={classes.search} ref={params.InputProps.ref}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <input
            type="text"
            {...params.inputProps}
            className={classes.inputInput}
            placeholder={t('Search messages')}
          />
        </div>
      )}
      filterOptions={(options) => options}
      {...props}
    />
  );
};

export default SearchInput;
