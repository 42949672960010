import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, IconButton, Grid } from '@material-ui/core';
import { useStyles } from './styles';
import { ReactComponent as AddIcon } from 'assets/patientActivities/addQestion.svg';
import QestionCard from './QuestionCard';
import { IQuestion } from './FormsPage';
import QuestionPreview from './QuestionPreview';
import { ReactComponent as CopyIcon } from 'assets/patientActivities/copy.svg';
import { ReactComponent as TrashIcon } from 'assets/trashicon.svg';

interface IFormGeneratorProps {
  questions: IQuestion[];
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>;
  question: IQuestion;
  isNotes?: boolean;
  changeQuestion?: any;
  api?: any;
  deleteBlock?: (index: number) => void;
  getBlockIndex?: () => number;
  edit?: boolean;
}

function FormGenerator({
  questions,
  setQuestions,
  isNotes = false,
  changeQuestion,
  api,
  deleteBlock,
  getBlockIndex,
  edit,
}: IFormGeneratorProps): React.ReactElement {
  const classes = useStyles();
  const ref = useRef(null);
  const question = questions[0];
  const [focusedQuestionIndex, setFocusedQuestionIndex] = useState(0);
  const [isClickedBlock, setClickedBlock] = useState<boolean>(
    edit ? false : true
  );
  const [open, setOpen] = useState(true);
  const handleMouseDown = useCallback((event: MouseEvent | null) => {
    if (!ref.current) {
      console.warn('ref.current is null or undefined');
      return;
    }

    if (ref.current && ref.current.contains(event?.target as Node)) {
      event?.stopPropagation();
      setClickedBlock(true);
    }
  }, []);

  const handleMouseUp = useCallback((event: MouseEvent | null) => {
    if (!ref.current) {
      console.warn('ref.current is null or undefined');
      return;
    }
    if (
      ref.current &&
      !ref.current.contains(event?.target as Node) &&
      !(event.target as HTMLElement).closest('.delete-button, .copy-button')
    ) {
      setClickedBlock(false);
    }
  }, []);
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setClickedBlock(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (open && ref.current) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [open, handleMouseDown, handleMouseUp]);

  const handleClick = () => {
    setClickedBlock(true);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(true);
    setClickedBlock(false);
  };

  const handleUpdateQuestion = (index, question) => {
    setQuestions((_questions) => {
      const newQuestions = [..._questions];
      newQuestions[index] = question;
      return newQuestions;
    });
  };

  const addNewQuestion = (type, question) => {
    const blockType = {
      'Multiple choice': 'Multiple_choice',
      'Single choice': 'Single_choice',
      'Short answer': 'Short_answer',
      'Long answer': 'Long_answer',
      Dropdown: 'DropDown',
      'Linear scale': 'LineaireScale',
      Date: 'Date',
      Email: 'Email',
      Phone: 'Phone',
      Address: 'Address',
      'Date range': 'DateRange',
      Signature: 'Signature',
      list: 'list',
      Orderedlist: 'Orderedlist',
    };
    if (api) {
      api.blocks.insert(blockType[type], {
        questions: [
          {
            question: question,
            type,
            options: ['Option 1', 'Option 2'],
            description: '',
            required: false,
            isDescription: false,
            range: [1, 5],
          },
        ],
      });
    }
  };
  const handleDeleteBlock = (e) => {
    e.stopPropagation();
    if (!isClickedBlock) return;
    setClickedBlock(true);
    const index = getBlockIndex();
    if (index !== undefined) {
      deleteBlock(index || api.blocks.getCurrentBlockIndex());
      setOpen(true);
    } else {
      console.warn('No block selected for deletion');
    }
  };
  const handleCopyBlock = (e) => {
    e.stopPropagation();
    if (!isClickedBlock) return;
    setOpen(true);
    setTimeout(() => {
      setClickedBlock(false);
      addNewQuestion(question?.type, question?.question);
    }, 5);
  };

  const handleRemoveQuestion = (cardIndex) => {
    const questionLength = questions.length;
    questionLength > 1 &&
      setQuestions((_questions) => {
        const newQuestions = _questions.filter(
          (_, index) => index !== cardIndex
        );
        return newQuestions;
      });
  };
  return (
    <Box className={classes.root}>
      <Box width="100%" className={classes.padding}>
        {isClickedBlock ? (
          <div ref={ref}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              flexDirection={{ xs: 'column', sm: 'row' }}
              onClick={handleClick}
              width="100%"
            >
              <Box flex="1">
                <QestionCard
                  handleClose={handleClose}
                  setOpenDP={setOpen}
                  ref={ref}
                  handleClick={handleClick}
                  deleteBlock={deleteBlock}
                  getBlockIndex={getBlockIndex}
                  updateQuestion={handleUpdateQuestion}
                  removeQuestion={handleRemoveQuestion}
                  question={question}
                  index={0}
                  setFocusedQuestionIndex={setFocusedQuestionIndex}
                  isNotes={isNotes}
                  changeQuestion={changeQuestion}
                  addNewQuestion={addNewQuestion}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                className={classes.sideBotton}
                alignItems={{ xs: 'center', sm: 'flex-start' }}
              >
                <Box
                  className={classes.pointer + ' copy-button'}
                  onClick={(e) => handleCopyBlock(e)}
                  data-cy={`copy-${0}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CopyIcon style={{ color: '#475467' }} />
                </Box>
                <Box
                  className={classes.pointer + ' delete-button'}
                  onClick={(e) => handleDeleteBlock(e)}
                  data-cy={`delete-${0}`}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <TrashIcon style={{ color: '#D92D20' }} />
                </Box>
              </Box>
            </Box>
          </div>
        ) : (
          <div ref={ref}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              onClick={handleClick}
            >
              <Box flex={1}>
                <QuestionPreview
                  isFormGenerator
                  question={question}
                  index={0}
                  setFocusedQuestionIndex={setFocusedQuestionIndex}
                  isFastPreview
                  ignoreRequired={isNotes}
                  editMode={true}
                />
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
}

export default FormGenerator;
