import { Box, Typography, Button, makeStyles, Theme } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Table from 'components/ui/Table';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import Text from 'Text';

const useStyles = makeStyles<Theme>((theme) => ({
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#1F61DC',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '16px',
    height: '43px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  tabForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  date: {
    fontSize: '14px',
    color: '#8E96A4',
  },
  names: {
    color: '#494B5A',
  },
}));
const columns: any = [
  {
    id: 'timestamp',
    label: <Box fontSize="1rem">Date</Box>,
    align: 'left',
    minWidth: 220,
    freeze: true,
    format: (
      value,
      { firstname, middlename, lastname, picture, _id, isDemo }
    ) => {
      return (
        <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
          {dayjs(value).format('MM-DD-YYYY h:mm A')}
        </Box>
      );
    },
  },
  {
    id: 'temperature',
    label: <Box fontSize="1rem">Temperature</Box>,
    align: 'left',
    minWidth: 60,
    format: (value) => {
      return <>{value}</>;
    },
  },
  {
    id: 'bmi',
    label: <Box fontSize="1rem">Bmi</Box>,
    align: 'left',
    minWidth: 60,
    format: (value) => {
      return <>{value}</>;
    },
  },
  {
    id: 'respiratory',
    label: <Box fontSize="1rem">Respiratory rate</Box>,
    minWidth: 170,
    align: 'left',
    format: (value) => {
      return value ?? '-';
    },
  },
  {
    id: 'blood_glucose',
    label: <Box fontSize="1rem">Blood glucose</Box>,
    minWidth: 300,
    align: 'left',
    format: (value) => value,
  },
  {
    id: 'blood_pressure',
    label: <Box fontSize="1rem">Blood pressure</Box>,
    minWidth: 170,
    align: 'left',
    format: (value) => {
      return value ?? '-';
    },
  },
  {
    id: 'head_circumference',
    label: <Box fontSize="1rem">Head circumference </Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'oxigen',
    label: <Box fontSize="1rem">Oxygen saturation</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'height',
    label: <Box fontSize="1rem">Height</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'weight',
    label: <Box fontSize="1rem">Weight</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'waist',
    label: <Box fontSize="1rem">Waist</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'pain',
    label: <Box fontSize="1rem">Pain scale</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'heart',
    label: <Box fontSize="1rem">Heart rate</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
  {
    id: 'peak',
    label: <Box fontSize="1rem">Peak flow</Box>,
    minWidth: 300,
    align: 'left',
    format: (value, { conditions }: any) => {
      return <>{value}</>;
    },
  },
];
const VitalsModal = (props) => {
  const vitals = props?.vitalsArray || {};
  const allVitals = Object.values(vitals).flat();
  const classes = useStyles();
  const vitalsData = [
    {
      timestamp: '2025-02-09T20:15:00',
      bmi: 12,
      temperature: 100,
      respiratory: 160,
      blood_glucose: 90,
      blood_pressure: 10,
      head_circumference: 40,
      oxigen: 90,
      height: 90,
      waist: 50,
      pain: 10,
      weight: 88,
      heart: 33,
      peak: 99,
    },
    {
      timestamp: '2025-02-10T20:28:00',
      temperature: 120,
      bmi: 14,
      respiratory: 130,
      blood_glucose: 50,
      blood_pressure: 70,
      head_circumference: 20,
      oxigen: 10,
      height: 70,
      waist: 20,
      pain: 80,
      weight: 38,
      heart: 53,
      peak: 69,
    },
    {
      timestamp: '2025-02-12T22:57:00',
      temperature: 60,
      bmi: 19,
      respiratory: 60,
      blood_glucose: 60,
      blood_pressure: 50,
      head_circumference: 60,
      oxigen: 60,
      height: 70,
      waist: 60,
      pain: 30,
      weight: 48,
      heart: 93,
      peak: 49,
    },
  ];

  const [selectedVital, setSelectedVital] = React.useState('temperature');

  const formattedData = vitalsData.map((v: any) => ({
    ...v,
    timestamp: dayjs(v.timestamp).format('MM/DD/YYYY (hh:mm A)'),
  }));

  return (
    <Box px="2rem" mt="1rem">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={'row-reverse'}
      >
        <Button
          className={classes.addTask}
          onClick={() => props.handleExpand(true)}
          data-cy="add-drug"
        >
          <AddTaskIcon style={{ paddingRight: '6px', width: '1.3rem' }} />
          <Text i18nKey="add">Add</Text>
        </Button>
        <Typography className={classes.date}>
          Last taken : {dayjs(new Date()).format('MM-DD-YYYY')}
        </Typography>{' '}
      </Box>

      <Box
        mt={2}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        style={{ gap: '10px' }}
      >
        {allVitals?.map((vital: any) => (
          <Box
            border="1px solid #e5e7eb"
            display="flex"
            flexDirection="column"
            px={1}
            pt={1}
            maxWidth="122px"
            minWidth="122px"
            height="52.5px"
          >
            <Box display="flex" alignItems="center">
              <Typography
                style={{ fontSize: '12px', color: '#494B5A', fontWeight: 600 }}
              >
                {vital?.value}
              </Typography>
              {vital?.unit && (
                <Typography
                  style={{
                    fontSize: '11px',
                    marginLeft: '2px',
                    color: '#6E7787',
                  }}
                >
                  ({vital?.unit})
                </Typography>
              )}
            </Box>
            <Typography
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                fontSize: '13px',
                color: '#494B5A',
              }}
            >
              {vital?.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box mt={1}>
        <Typography
          style={{
            marginBlock: '16px',
            fontSize: '14px',
            color: '#494B5A',
            fontWeight: 600,
          }}
        >
          Past Vitals (Graph View)
        </Typography>
        <Box
          display="flex"
          p={2}
          style={{ background: '#f8f9fb', borderRadius: '8px' }}
        >
          {/* Left Sidebar for Vital Selection */}
          <Box
            display="flex"
            flexDirection="column"
            pr={2}
            maxHeight="280px"
            overflow="scroll"
          >
            {allVitals.map(({ key, name, unit }: any) => (
              <Box
                key={name}
                py={1}
                color="primary"
                onClick={() => setSelectedVital(key)}
                style={{
                  marginBottom: '5px',
                  border: '1px solid #e5e7eb',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: selectedVital === key && '#0062F5',
                  color: selectedVital === key && 'white',
                  cursor: 'pointer',
                }}
              >
                {name} ({unit})
              </Box>
            ))}
          </Box>

          {/* Chart Container */}
          <Box
            flex={1}
            p={1}
            style={{ background: 'white', borderRadius: '8px' }}
          >
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={formattedData}>
                <XAxis
                  dataKey="timestamp"
                  axisLine={false}
                  tickLine={false}
                  tick={(props) => {
                    const { x, y, payload } = props;
                    const parts = payload.value.split(' ');
                    const date = parts[0];
                    const time = parts[1]?.replace(/[()]/g, '');

                    return (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          textAnchor="middle"
                          fill="#666"
                          fontSize={11}
                        >
                          {date}
                        </text>
                        <text
                          x={0}
                          y={0}
                          dy={16}
                          textAnchor="middle"
                          fill="#666"
                          fontSize={11}
                        >
                          {time}
                        </text>
                      </g>
                    );
                  }}
                  interval={0}
                  padding={{ left: 28, right: 28 }}
                />
                <YAxis hide />
                {allVitals.map(({ key, name, color }: any) => (
                  <Line
                    key={key}
                    type="monotone"
                    dataKey={key}
                    stroke={color}
                    strokeWidth={selectedVital === key ? 1.5 : 1}
                    opacity={selectedVital === key ? 1 : 0.3}
                    dot={{ fill: color, r: selectedVital === key ? 3 : 0 }}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography
          style={{
            marginBottom: '16px',
            fontSize: '14px',
            color: '#494B5A',
            fontWeight: 600,
          }}
        >
          Past Vitals (Table View)
        </Typography>
        <Table
          loading={false}
          page={0}
          limit={5}
          columns={columns}
          data={vitalsData}
        />
      </Box>
    </Box>
  );
};

export default VitalsModal;
