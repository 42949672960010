import React from 'react';
import { createRoot } from 'react-dom/client';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
  edit: boolean;
}

export default class Email {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private root: any | null;

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16 7.99938V12.9994C16 13.795 16.3161 14.5581 16.8787 15.1207C17.4413 15.6833 18.2044 15.9994 19 15.9994C19.7957 15.9994 20.5587 15.6833 21.1213 15.1207C21.6839 14.5581 22 13.795 22 12.9994V11.9994C21.9999 9.74241 21.2362 7.55186 19.8333 5.78391C18.4303 4.01596 16.4706 2.7746 14.2726 2.26168C12.0747 1.74875 9.76794 1.99442 7.72736 2.95875C5.68677 3.92307 4.03241 5.54933 3.03327 7.5731C2.03413 9.59687 1.74898 11.8991 2.22418 14.1055C2.69938 16.3119 3.90699 18.2926 5.65064 19.7256C7.39429 21.1587 9.57144 21.9597 11.8281 21.9985C14.0847 22.0373 16.2881 21.3116 18.08 19.9394M16 11.9994C16 14.2085 14.2092 15.9994 12 15.9994C9.79087 15.9994 8.00001 14.2085 8.00001 11.9994C8.00001 9.79024 9.79087 7.99938 12 7.99938C14.2092 7.99938 16 9.79024 16 11.9994Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>                
      `,
      title: 'Email',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? false;
    this.root = null;

    const defaultQuestion = {
      question: 'Email',
      type: 'Email',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };

  private updateDom() {
    if (this.nodes.holder) {
      if (!this.root) {
        this.root = createRoot(this.nodes.holder);
      }
      this.root.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          changeQuestion={this.changeQuestion.bind(this)}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />
      );
    }
  }

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };
  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    this.root = createRoot(rootNode);
    this.root.render(
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion.bind(this)}
        question={this.data.question}
        isNotes={false}
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
