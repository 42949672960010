import { Box, makeStyles, Theme, Button, Typography } from '@material-ui/core';
import React from 'react';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import Text from 'Text';

const useStyles = makeStyles<Theme>((theme) => ({
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#1F61DC',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '16px',
    height: '43px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  tabForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));
const LabsImagingModal = (props) => {
  const classes = useStyles();
  return (
    <Box px="2rem" mt="1rem">
      <Box display="flex" justifyContent="flex-end">
        <Button
          className={classes.addTask}
          onClick={() => props.handleExpand(true)}
          data-cy="add-drug"
        >
          <AddTaskIcon style={{ paddingRight: '6px', width: '1.3rem' }} />
          <Text i18nKey="add">Add</Text>
        </Button>
      </Box>
      <Box mt={1}>
        {props.labs?.map((lab) => (
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            py={3}
            pr={'0rem'}
            borderBottom="1px solid #e5e7eb"
          >
            <Box display="flex" flexDirection="column">
              <Typography>{lab?.name}</Typography>
              <Typography>Ordered by: William</Typography>
            </Box>
            <Box padding={'2px 6px'} style={{ background: '#fff7ed' }}>
              <Typography
                style={{ color: '#ea580c', textTransform: 'capitalize' }}
              >
                {lab?.status}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LabsImagingModal;
