import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles';
import dayjs from 'dayjs';
import { ReactComponent as ReflectionIcon } from 'assets/patientActivities/a-reflection.svg';
import { ReactComponent as MoodIcon } from 'assets/patientActivities/a-mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/a-sleep.svg';
import { ReactComponent as FormIcon } from 'assets/patientActivities/a-form.svg';
import { modalTitle } from 'pages/PatientActivities/commun/ResultModal';
import { ActivitiesTypes } from 'pages/PatientActivities/RecentActivityCard';
import Skeleton from 'components/ui/Skeleton';
import { useTranslation } from 'react-i18next';
import { dayjsFormat } from 'utils';

interface IAssignedCard {
  activityType: ActivitiesTypes;
  assignedAt: string;
  formTitle: string;
  openComplete: () => void;
}

export const AssignedCard = ({
  activityType,
  assignedAt,
  formTitle,
  openComplete,
}: IAssignedCard) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const assignCardIcon = {
    [ActivitiesTypes.MOOD]: () => <MoodIcon />,
    [ActivitiesTypes.SLEEP]: () => <SleepIcon />,
    [ActivitiesTypes.REFLECTION]: () => <ReflectionIcon />,
    [ActivitiesTypes.FORM]: () => <FormIcon />,
  };

  const title = modalTitle(activityType, formTitle);

  return (
    <Box className={classes.assignedCard}>
      <Box display="flex" alignItems="center" gridGap="0.8rem">
        {assignCardIcon[activityType ?? ActivitiesTypes.FORM]()}
        <Box>
          <Typography className={classes.assignCardTitle}>
            {activityType ? (title ? t(title) : '') : formTitle}
          </Typography>
          <Typography className={classes.assignCardDate}>
            {t('Assigned')} {dayjsFormat(new Date(assignedAt), 'MMM D, YYYY')}
          </Typography>
        </Box>
      </Box>
      <Typography className={classes.assignCardAction} onClick={openComplete}>
        {t('Complete')}
      </Typography>
    </Box>
  );
};

//-----------------------------------

export const AssignedCardLoading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.assignedCard}>
      <Box display="flex" alignItems="center" gridGap="0.8rem">
        <Skeleton loading={true} type="circle" width="2.5rem" height="2.5rem">
          <></>
        </Skeleton>
        <Box>
          <Skeleton loading={true} type="text" width="4rem" height="1.2rem">
            <></>
          </Skeleton>
          <Skeleton loading={true} type="text" width="6rem" height="1.1rem">
            <></>
          </Skeleton>
        </Box>
      </Box>
      <Skeleton loading={true} type="text" width="4rem" height="1.1rem">
        <></>
      </Skeleton>
    </Box>
  );
};
