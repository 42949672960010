import { Box, FormHelperText, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import DateRange from 'components/ui/Inputs/DateRange';
import ProgressLoader from 'components/ui/ProgressLoader';
import { ReactComponent as NoTimeIllustration } from 'assets/illustrations/NoTimesIllustration.svg';
import { IDateRange } from '../types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import Text from 'Text';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import { dayjsFormat } from 'utils';
import DatePickerSession from './DatePickerSession';
import useAuth from 'graphql/operations/doctorOperations/useAuth';

dayjs.extend(customParseFormat);

interface IDateAndTimeProps {
  dateRange: IDateRange;
  handleChangeDate: (
    name: 'startDate' | 'endDate',
    value: string | number
  ) => void;
  loadingFreeTimes: boolean;
  getExactFreeTime: string[];
  handleClick: (index: number, times: string) => void;
  setTestIndex: (test: boolean) => void;
  isActive: number;
  isDefaultTime: (element: string) => boolean;
  pickedRightTime: boolean;
  feedMode?: boolean;
  setDateRange?: any;
}

export const DateAndTime = ({
  dateRange,
  handleChangeDate,
  loadingFreeTimes,
  getExactFreeTime,
  handleClick,
  setTestIndex,
  isActive,
  isDefaultTime,
  pickedRightTime,
  feedMode = false,
  setDateRange,
}: IDateAndTimeProps) => {
  const classes = useStyles();
  const { doctor } = useAuth();
  const { i18n } = useTranslation();
  const language = i18n.language || 'en';
  dayjs.locale(language === 'fr' ? 'fr' : 'en');
  const [isChanged, setIsChanged] = React.useState(true);

  const formatTime = (time: string, language: string) => {
    return time;
  };

  const formattedStartDate = dayjs(dateRange.startDate).format('MMMM D');
  return (
    <Box mb={2}>
      <Box
        className={classes.dateTimeContainer}
        style={{ border: pickedRightTime && '1px solid red' }}
        mb={pickedRightTime && 1}
      >
        <Box className={classes.dateWrapper}>
          <Box width="55%" style={{ opacity: feedMode ? 0.4 : 1 }}>
            <Text i18nKey="dateAndTime" className={classes.label}>
              Date & time
            </Text>
            <DatePickerSession
              doctorId={doctor._id || ''}
              feedMode={feedMode}
              isSession
              color="#1F61DC"
              startDate={dateRange.startDate as Date}
              endDate={dateRange.endDate as Date}
              setStartDate={(startDate) =>
                setDateRange((prev) => ({ ...prev, startDate }))
              }
              setEndDate={(endDate) =>
                setDateRange((prev) => ({ ...prev, endDate }))
              }
              setIsChanged={setIsChanged}
              minDate={new Date()}
            />
          </Box>
          <Box
            style={{ opacity: feedMode ? 0.4 : 1, gap: '10px' }}
            display="flex"
            flexWrap="wrap"
            mt={'1.7rem'}
            width="100%"
            justifyContent="space-between"
          >
            {loadingFreeTimes ? (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                mt={2}
                mb={2}
              >
                <ProgressLoader width={60} height={60} />
              </Box>
            ) : (
              <>
                {getExactFreeTime?.length > 0 && (
                  <Text
                    i18nKey="Availability for "
                    className={classes.labelTime}
                  >
                    {`Availability for `}{' '}
                    <Typography
                      style={{
                        color: '#147AF3',
                        fontWeight: 600,
                        marginLeft: '5px',
                      }}
                    >
                      {formattedStartDate}
                    </Typography>
                  </Text>
                )}
                <Box
                  display="flex"
                  flexWrap="wrap"
                  width="100%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {getExactFreeTime?.length === 0 || loadingFreeTimes ? (
                    <Box width="100%" alignItems={'flex-start'}>
                      <Text
                        i18nKey="No availability for "
                        className={classes.labelTime}
                      >
                        {`No availability for `}
                        <Typography
                          style={{
                            color: '#147AF3',
                            fontWeight: 600,
                            marginLeft: '5px',
                          }}
                        >
                          {' '}
                          {formattedStartDate}
                        </Typography>
                      </Text>
                      <Box className={classes.noAvaWrapper}>
                        <NoTimeIllustration />
                        <Text
                          i18nKey="No availability, select another date"
                          className={classes.textNoAvailability}
                        >
                          No availability, select another date
                        </Text>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      className={classes.wrapTimes}
                      gridTemplateColumns={
                        getExactFreeTime?.length <= 6
                          ? '1fr'
                          : getExactFreeTime?.length <= 12
                          ? 'repeat(2, 1fr)'
                          : 'repeat(3, 1fr)'
                      }
                    >
                      {getExactFreeTime?.map((el, index) => (
                        <Box
                          className={classes.freeTimes}
                          onClick={() => {
                            handleClick(index, el);
                            setTestIndex(true);
                          }}
                          style={{
                            userSelect: 'none',
                            backgroundColor:
                              (isActive === index || isDefaultTime(el)) &&
                              '#147AF3',
                            color:
                              (isActive === index || isDefaultTime(el)) &&
                              'white',
                            textAlign: 'center',
                            minWidth:
                              getExactFreeTime?.length <= 6
                                ? '100%'
                                : getExactFreeTime?.length <= 12
                                ? 'calc(100% - 5px)'
                                : '90%',
                          }}
                        >
                          {formatTime(el, language)}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box alignSelf="flex-start">
        {pickedRightTime && (
          <FormHelperText data-cy="error-message" className={classes.error}>
            <ErrorIcon className={classes.errorIcon} />
            Selection of available session is required
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};
