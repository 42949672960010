import {
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClaimBasicInfo from './ClaimBasicInfo';
import ClaimIntermediaire from './ClaimIntermediaire';
import ClaimDiagnosisSection from './ClaimDiagnosisSection';
import ClaimTableDetails from './ClaimTableDetails';
import ClaimFinal from './ClaimFinal';
import Button from 'components/ui/Button';
// import { ReactComponent as ClaimIcon } from 'assets/SubmitClaim.svg';
import { ReactComponent as SaveClaimIcon } from 'assets/SaveClaimIcon.svg';
import { ReactComponent as Submitclm } from 'assets/Submitclm.svg';
import { ReactComponent as ClmDetail } from 'assets/ClmDetail.svg';

import useCreateClaim, {
  useUpdateClaim,
  useSubmitClaim,
} from '@eggmed/graphql-client/operations/appointmentsOperations/useCreateClaim';
import { useHistory } from 'react-router-dom';
import ProgressLoader from 'components/ui/ProgressLoader';
import { gql, useLazyQuery } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { timeConverter } from 'pages/AdminPage/TimeConverter';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '16px',
  },
  signLabel: {
    fontStyle: 'italic',
    fontSize: '15px',
    fontWeight: 500,
    color: '#182230',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3.5),
  },
  note: {
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: 400,
    color: '#182230',
    marginLeft: theme.spacing(2),
  },
  description: {
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: 700,
    color: '#182230',
  },
  paragraph: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#182230',
  },
  margin: { marginBottom: theme.spacing(1) },
  title: { fontWeight: 700, fontSize: '24px', color: '#182230' },
  labelDetail: { backgroundColor: '#F2F4F7', marginBottom: theme.spacing(3) },
  tableLabelDetails: { backgroundColor: '#F2F4F7' },
  tableLabelRoot: {
    backgroundColor: '#EAECF0',
    display: 'flex',
    alignItems: 'center',
  },
  labelRoot: { backgroundColor: '#EAECF0', marginBottom: theme.spacing(3) },
  label: { fontWeight: 700, fontSize: '20px', color: '#182230' },
  rootRadio: {
    maxWidth: '22px',
    marginLeft: '-8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconPatient: {
    cursor: 'pointer',
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(white,white 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0265DC',
    },
  },
  radioLabel: {
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#475467',
  },
  textTitle: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
  },
  checkboxText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'left',
    color: '#182230',
    marginTop: '-8px',
    marginLeft: '-5px',
  },
  cell: {
    borderRight: '1px solid #D0D5DD',
    paddingInline: '5px',
    boxSizing: 'border-box',
    flex: 1,
  },
  direction: {
    flexDirection: 'row',
    [theme.breakpoints.down(1400)]: {
      flexDirection: 'column',
    },
  },
  submitBtn: {
    background: '#2F80ED',
    border: '1px solid #2F80ED',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    padding: '10px 18px',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'none',
    '&:hover': {
      background: '#2F80ED',
    },
  },
  cancelBtn: {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    minWidth: '96px',
    height: '43px',
    color: '#182230',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submit: {
    background: '#2F80ED',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    minWidth: '96px',
    height: '43px',
    color: 'white',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover ': {
      background: '#2F80ED',
    },
  },
  saveBtn: {
    background: '#FFFFFF',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    minWidth: '96px',
    height: '43px',
    color: '#2F80ED',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const relationShip = {
  Self: 0,
  Spouse: 1,
  Child: 2,
  Other: 3,
};

const ClaimPage = () => {
  const { t, i18n } = useTranslation();
  const [loaderAdd, setLoaderAll] = React.useState(true);

  const history = useHistory();
  const { createClaim, loading: loadingUpdate } = useCreateClaim();
  const { submitClaim, loading: loadingSubmit } = useSubmitClaim();
  const { updateClaim, loading: LoadingCreate } = useUpdateClaim();
  const loading = loadingUpdate || LoadingCreate;
  const location = useLocation<{
    selectedClient: string;
    sessionLength: number;
    sessions: [string];
    patient: string;
    editMode: boolean;
    currentValues: any;
  }>();
  const classes = useStyles();
  const {
    selectedClient,
    sessionLength,
    sessions,
    patient,
    editMode,
    currentValues,
  } = location.state || {};
  const { doctor } = useAuth();
  const [loadInsurance, { data, loading: loadingInsurance }] =
    useLazyQuery(GET_INSURANCES);
  const [loadDoctor, { data: doctorInfo, loading: loadingQuery }] =
    useLazyQuery(GET_DOCTOR);
  const [loadPatientInfo, { data: PatientData, loading: PatientLoading }] =
    useLazyQuery(GET_PATIENT);
  React.useEffect(() => {
    if (!editMode) {
      loadInsurance({
        variables: {
          patient,
        },
      });
      loadDoctor({
        variables: { DoctorId: doctor?._id },
      });
      loadPatientInfo({ variables: { patientId: patient } });
    }
  }, [loadInsurance, patient, loadDoctor, doctor, loadPatientInfo]);
  const { register, control, errors, handleSubmit, reset, getValues } =
    useForm<any>({
      defaultValues: {
        dateSignaturePhy: new Date(),
        dateSignature: new Date(),
        insuredDateBirth: new Date(),
        qualOther: new Date(),
        from: new Date(),
        to: new Date(),
        fromhospital: new Date(),
        tohospital: new Date(),
      },
    });
  React.useEffect(() => {
    if (editMode && currentValues) {
      reset({
        ...currentValues,
        ...currentValues?.patientInsuranceInfo,
        ...currentValues?.otherInsuranceInfo,
        ...currentValues?.diagnosisClaimInfo,
        ...currentValues?.diagnosisClaimInfo?.claimInfo,
        ...currentValues?.billingInfo,
        insuredDate: new Date(currentValues?.otherInsuranceInfo?.insuredDate),
        birthDate: new Date(currentValues?.patientInsuranceInfo?.birthDate),
        dateSignaturePhy: new Date(
          currentValues?.billingInfo?.dateSignaturePhy
        ),
        dateSignature: new Date(
          currentValues?.otherInsuranceInfo?.dateSignature
        ),
        insuredDateBirth: new Date(
          currentValues?.otherInsuranceInfo?.insuredDateBirth
        ),
        qualOther: new Date(currentValues?.otherInsuranceInfo?.qualOther),
        from: new Date(currentValues?.otherInsuranceInfo?.from),
        to: new Date(currentValues?.otherInsuranceInfo?.to),
        fromhospital: new Date(currentValues?.otherInsuranceInfo?.fromhospital),
        tohospital: new Date(currentValues?.otherInsuranceInfo?.tohospital),
      });
    }
  }, [editMode, reset, currentValues]);
  React.useEffect(() => {
    if (!editMode) {
      reset({
        payerName:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.payer?.payer_name,
        memberID: data?.patientInsurance && data?.patientInsurance[0]?.memberID,
        firstname: PatientData?.patient?.firstname,
        middlename: PatientData?.patient?.middlename,
        lastname: PatientData?.patient?.lastname,
        payerID:
          data?.patientInsurance && data?.patientInsurance[0]?.payer?.payerid,
        birthDate: timeConverter(
          PatientData?.patient?.birthday ||
            new Date().getMilliseconds().toString()
        ),
        gender: PatientData?.patient?.gender === 'male' ? 0 : 1,
        insuranceName:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.firstname
            : data?.patientInsurance[0]?.firstname,
        insuranceMiddle:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.middlename
            : data?.patientInsurance[0]?.middlename,
        insuranceLast:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.lastname
            : data?.patientInsurance[0]?.lastname,
        address1: PatientData?.patient?.address,
        address2: PatientData?.patient?.suite,
        city: PatientData?.patient?.city,
        state: PatientData?.patient?.state,
        zipcode: PatientData?.patient?.zipcode,
        phone: PatientData?.patient?.phone,
        Insuredaddress1:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.address
            : data?.patientInsurance[0]?.address,
        Insuredaddress2:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.suite
            : '',
        Insuredcity:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.city
            : data?.patientInsurance[0]?.city,
        Insuredstate:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.state
            : data?.patientInsurance[0]?.state,
        Insuredzipcode:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.zipcode
            : data?.patientInsurance[0]?.zipCode,
        Insuredphone:
          data?.patientInsurance &&
          data?.patientInsurance[0]?.relationShipType === 'Self'
            ? PatientData?.patient?.phone
            : data?.patientInsurance[0]?.phone,
        relationship:
          relationShip[
            data?.patientInsurance &&
              data?.patientInsurance[0]?.relationShipType
          ],
        employment: 0,
        accident: 0,
        otheraccident: 0,
        sexPolicy: 0,
        anotherHealth: 0,
        outsidelab: 0,
        idType: 0,
        isAccept: 0,
        insuredDate: timeConverter(
          PatientData?.patient?.birthday ||
            new Date().getMilliseconds().toString()
        ),
        provider: `${doctorInfo?.doctor?.firstname} ${doctorInfo?.doctor?.lastname}`,
        firstRefering: doctorInfo?.doctor?.firstname,
        MiddletRefering: doctorInfo?.doctor?.middlename,
        LastRefering: doctorInfo?.doctor?.lastname,
        npi: doctorInfo?.doctor?.providerSettings?.npi,
        phoneBilling: doctorInfo?.doctor?.phone,
        firstBilling: doctorInfo?.doctor?.firstname,
        MiddleBilling: doctorInfo?.doctor?.middlename,
        LastBilling: doctorInfo?.doctor?.lastname,
        line1Billing: doctorInfo?.doctor?.address,
        line2Billing: doctorInfo?.doctor?.suite,
        cityBilling: doctorInfo?.doctor?.city,
        stateBilling: doctorInfo?.doctor?.state,
        ZipBilling: doctorInfo?.doctor?.zipcode,
        billingnpi: doctorInfo?.doctor?.providerSettings?.npi,
        taxonomyBilling: doctorInfo?.doctor?.providerSettings?.taxonomy,
        dateSignaturePhy: new Date(),
        dateSignature: new Date(),
        insuredDateBirth: new Date(),
        qualOther: new Date(),
        from: new Date(),
        to: new Date(),
        fromhospital: new Date(),
        tohospital: new Date(),
      });
    }
  }, [data?.patientInsurance, PatientData?.patient, doctorInfo?.doctor]);

  const commonProps = { register, control, errors };

  async function SubmitClaim() {
    setLoaderAll(false);
    const data = getValues();
    if (editMode) {
      const {
        payerID,
        payerName,
        address,
        city,
        state,
        zip,
        memberID,
        firstname,
        middlename,
        lastname,
        gender,
        insuranceName,
        insuranceMiddle,
        insuranceLast,
        address1,
        address2,
        cityPatient,
        statePatient,
        zipcode,
        phone,
        relationship,
        insuredAddress1,
        insuredAddress2,
        insuredCity,
        insuredState,
        insuredZipcode,
        insuredPhone,
        Insuredaddress1,
        Insuredaddress2,
        Insuredcity,
        Insuredstate,
        Insuredzipcode,
        Insuredphone,
        firstnameOther,
        lastnameOther,
        mi,
        relationshipother,
        policy,
        insuredID,
        indicator,
        planname,
        insurancetype,
        ressequence,
        payerIDReserved,
        employment,
        accident,
        otheraccident,
        claimcode,
        lastPolicy,
        insuredDate,
        birthDate,
        sexPolicy,
        otherClaimID,
        insurancePlan,
        anotherHealth,
        dateSignature,
        insuredDateBirth,
        qual,
        qualOther,
        from,
        to,
        provider,
        firstRefering,
        MiddletRefering,
        LastRefering,
        secondaryIDtype,
        secondaryID,
        npi,
        fromhospital,
        tohospital,
        additionalinfo,
        additionalinfocode,
        typecode,
        transcode,
        attachmentID,
        outsidelab,
        charge,
        icd,
        A,
        B,
        C,
        D,
        E,
        F,
        G,
        H,
        K,
        L,
        I,
        J,
        codeResubmit,
        originalRef,
        priorNumber,
        cliaNumber,
        fromdate,
        todate,
        place,
        EMG,
        procedureCode,
        description,
        modifier,
        modifier2,
        modifier3,
        modifier4,
        DiagnosisA,
        DiagnosisB,
        DiagnosisC,
        DiagnosisD,
        Charges,
        units,
        epsdt,
        lastnameClaim,
        firstnameClaim,
        npiClaim,
        federalTax,
        idType,
        patientAccount,
        isAccept,
        totalCharge,
        dateSignaturePhy,
        facilityname,
        address1Facility,
        address2Facility,
        cityFacility,
        stateFacility,
        zipcodeFacility,
        npiFacility,
        phoneBilling,
        firstBilling,
        MiddleBilling,
        LastBilling,
        line1Billing,
        line2Billing,
        cityBilling,
        stateBilling,
        ZipBilling,
        billingnpi,
        taxonomyBilling,
      } = data || {};
      const patientInsuranceInfo = {
        payerID,
        payerName,
        address,
        city,
        state,
        zip,
        memberID,
        firstname,
        middlename,
        lastname,
        gender,
        birthDate,
        insuranceName,
        insuranceMiddle,
        insuranceLast,
        address1,
        address2,
        cityPatient,
        statePatient,
        zipcode,
        phone,
        relationship,
        insuredAddress1,
        insuredAddress2,
        insuredCity,
        insuredState,
        insuredZipcode,
        insuredPhone,
      };
      const otherInsuranceInfo = {
        Insuredaddress1,
        Insuredaddress2,
        Insuredcity,
        Insuredstate,
        Insuredzipcode,
        Insuredphone,
        firstnameOther,
        lastnameOther,
        mi,
        relationshipother,
        policy,
        insuredID,
        indicator,
        planname,
        insurancetype,
        ressequence,
        payerIDReserved,
        employment,
        accident,
        place,
        otheraccident,
        claimcode,
        lastPolicy,
        insuredDate,
        sexPolicy,
        otherClaimID,
        insurancePlan,
        anotherHealth,
        dateSignature,
        insuredDateBirth,
        qual,
        qualOther,
        from,
        to,
        provider,
        firstRefering,
        MiddletRefering,
        LastRefering,
        secondaryIDtype,
        secondaryID,
        npi,
        fromhospital,
        tohospital,
      };

      const diagnosisClaimInfo = {
        additionalinfo,
        additionalinfocode,
        typecode,
        transcode,
        attachmentID,
        outsidelab,
        charge,
        icd,
        A,
        B,
        C,
        D,
        E,
        F,
        G,
        H,
        K,
        L,
        I,
        J,
        codeResubmit,
        originalRef,
        priorNumber,
        cliaNumber,
        claimInfo: {
          fromdate,
          todate,
          place,
          EMG,
          procedureCode,
          description,
          modifier,
          modifier2,
          modifier3,
          modifier4,
          DiagnosisA,
          DiagnosisB,
          DiagnosisC,
          DiagnosisD,
          Charges,
          units,
          epsdt,
          lastnameClaim,
          firstnameClaim,
          npiClaim,
        },
      };
      const billingInfo = {
        federalTax,
        idType,
        patientAccount,
        isAccept,
        totalCharge,
        dateSignaturePhy,
        facilityname,
        address1Facility,
        address2Facility,
        cityFacility,
        stateFacility,
        zipcodeFacility,
        npiFacility,
        phoneBilling,
        firstBilling,
        MiddleBilling,
        LastBilling,
        line1Billing,
        line2Billing,
        cityBilling,
        stateBilling,
        ZipBilling,
        billingnpi,
        taxonomyBilling,
      };
      await updateClaim({
        variables: {
          id: currentValues?._id,
          claimInput: {
            sessions,
            patient,
            patientInsuranceInfo,
            otherInsuranceInfo,
            diagnosisClaimInfo,
            billingInfo,
          },
        },
      });
      history.push('/doctor/finance');

      return;
    }
    submitClaim({
      variables: {
        claim: [
          {
            accept_assign: 'Y',
            auto_accident: 'N',
            balance_due: '300.00',
            bill_addr_1: '1234 MEDICAL CENTER DRIVE',
            bill_city: 'SANTA FE',
            bill_name: 'MEDICAL CARE CENTER',
            bill_npi: '1234567893',
            bill_phone: '5059999999',
            bill_state: 'NM',
            bill_taxid: '741111111',
            bill_taxid_type: 'E',
            bill_zip: '875059998',
            charge: [
              {
                charge: '100.00',
                charge_record_type: 'UN',
                diag_ref: 'ABCD',
                from_date: '2022-10-13',
                place_of_service: '11',
                proc_code: '99214',
                remote_chgid: '221043722',
                thru_date: '2022-10-13',
                units: '1',
              },
            ],
            claim_form: '1500',
            diag_1: 'J300',
            diag_2: 'K5289',
            diag_3: 'I10',
            diag_4: 'G9331',
            employment_related: 'N',
            ins_addr_1: '202 COLUMBIA DR NE',
            ins_city: 'ALBUQUERQUE',
            ins_dob: '1953-07-01',
            ins_group: '290375',
            ins_name_f: 'JANET',
            ins_name_l: 'IMHOFF',
            ins_number: '22222933300',
            ins_sex: 'F',
            ins_state: 'NM',
            ins_zip: '87103',
            pat_addr_1: '202 COLUMBIA DR NE',
            pat_city: 'ALBUQUERQUE',
            pat_dob: '1953-07-01',
            pat_name_f: 'JANET',
            pat_name_l: 'IMHOFF',
            pat_rel: '18',
            pat_sex: 'F',
            pat_state: 'NM',
            pat_zip: '87103',
            payerid: '87726',
            pcn: '47147-1',
            prov_name_f: 'JASON',
            prov_name_l: 'LUNDGREN MD',
            prov_name_m: 'P',
            prov_npi: '1111111112',
            prov_taxonomy: '208D00000X',
            ref_name_f: 'JASON',
            ref_name_l: 'LUNDGREN MD',
            ref_name_m: 'P',
            ref_npi: '1231231238',
            remote_batchid: '2569142',
            remote_claimid: '199455342',
            remote_fileid: '000000001',
            total_charge: '300.00',
          },
        ],
      },
    });

    await onSubmit({ ...data, isSubmitted: true });
    setLoaderAll(true);
  }
  async function onSubmit(data) {
    const {
      payerID,
      payerName,
      address,
      city,
      state,
      zip,
      memberID,
      firstname,
      middlename,
      lastname,
      gender,
      insuranceName,
      insuranceMiddle,
      insuranceLast,
      address1,
      address2,
      cityPatient,
      statePatient,
      zipcode,
      phone,
      relationship,
      insuredAddress1,
      insuredAddress2,
      insuredCity,
      insuredState,
      insuredZipcode,
      insuredPhone,
      Insuredaddress1,
      Insuredaddress2,
      Insuredcity,
      Insuredstate,
      Insuredzipcode,
      Insuredphone,
      firstnameOther,
      lastnameOther,
      mi,
      relationshipother,
      policy,
      insuredID,
      indicator,
      planname,
      insurancetype,
      ressequence,
      payerIDReserved,
      employment,
      accident,
      otheraccident,
      claimcode,
      lastPolicy,
      insuredDate,
      birthDate,
      sexPolicy,
      otherClaimID,
      insurancePlan,
      anotherHealth,
      dateSignature,
      insuredDateBirth,
      qual,
      qualOther,
      from,
      to,
      provider,
      firstRefering,
      MiddletRefering,
      LastRefering,
      secondaryIDtype,
      secondaryID,
      npi,
      fromhospital,
      tohospital,
      additionalinfo,
      additionalinfocode,
      typecode,
      transcode,
      attachmentID,
      outsidelab,
      charge,
      icd,
      A,
      B,
      C,
      D,
      E,
      F,
      G,
      H,
      K,
      L,
      I,
      J,
      codeResubmit,
      originalRef,
      priorNumber,
      cliaNumber,
      fromdate,
      todate,
      place,
      EMG,
      procedureCode,
      description,
      modifier,
      modifier2,
      modifier3,
      modifier4,
      DiagnosisA,
      DiagnosisB,
      DiagnosisC,
      DiagnosisD,
      Charges,
      units,
      epsdt,
      lastnameClaim,
      firstnameClaim,
      npiClaim,
      federalTax,
      idType,
      patientAccount,
      isAccept,
      totalCharge,
      dateSignaturePhy,
      facilityname,
      address1Facility,
      address2Facility,
      cityFacility,
      stateFacility,
      zipcodeFacility,
      npiFacility,
      phoneBilling,
      firstBilling,
      MiddleBilling,
      LastBilling,
      line1Billing,
      line2Billing,
      cityBilling,
      stateBilling,
      ZipBilling,
      billingnpi,
      taxonomyBilling,
    } = data || {};

    const patientInsuranceInfo = {
      payerID,
      payerName,
      address,
      city,
      state,
      zip,
      memberID,
      firstname,
      middlename,
      lastname,
      gender,
      birthDate,
      insuranceName,
      insuranceMiddle,
      insuranceLast,
      address1,
      address2,
      cityPatient,
      statePatient,
      zipcode,
      phone,
      relationship,
      insuredAddress1,
      insuredAddress2,
      insuredCity,
      insuredState,
      insuredZipcode,
      insuredPhone,
    };
    const otherInsuranceInfo = {
      Insuredaddress1,
      Insuredaddress2,
      Insuredcity,
      Insuredstate,
      Insuredzipcode,
      Insuredphone,
      firstnameOther,
      lastnameOther,
      mi,
      relationshipother,
      policy,
      insuredID,
      indicator,
      planname,
      insurancetype,
      ressequence,
      payerIDReserved,
      employment,
      accident,
      place,
      otheraccident,
      claimcode,
      lastPolicy,
      insuredDate,
      sexPolicy,
      otherClaimID,
      insurancePlan,
      anotherHealth,
      dateSignature,
      insuredDateBirth,
      qual,
      qualOther,
      from,
      to,
      provider,
      firstRefering,
      MiddletRefering,
      LastRefering,
      secondaryIDtype,
      secondaryID,
      npi,
      fromhospital,
      tohospital,
    };

    const diagnosisClaimInfo = {
      additionalinfo,
      additionalinfocode,
      typecode,
      transcode,
      attachmentID,
      outsidelab,
      charge,
      icd,
      A,
      B,
      C,
      D,
      E,
      F,
      G,
      H,
      K,
      L,
      I,
      J,
      codeResubmit,
      originalRef,
      priorNumber,
      cliaNumber,
      claimInfo: {
        fromdate,
        todate,
        place,
        EMG,
        procedureCode,
        description,
        modifier,
        modifier2,
        modifier3,
        modifier4,
        DiagnosisA,
        DiagnosisB,
        DiagnosisC,
        DiagnosisD,
        Charges,
        units,
        epsdt,
        lastnameClaim,
        firstnameClaim,
        npiClaim,
      },
    };
    const billingInfo = {
      federalTax,
      idType,
      patientAccount,
      isAccept,
      totalCharge,
      dateSignaturePhy,
      facilityname,
      address1Facility,
      address2Facility,
      cityFacility,
      stateFacility,
      zipcodeFacility,
      npiFacility,
      phoneBilling,
      firstBilling,
      MiddleBilling,
      LastBilling,
      line1Billing,
      line2Billing,
      cityBilling,
      stateBilling,
      ZipBilling,
      billingnpi,
      taxonomyBilling,
    };

    await createClaim({
      variables: {
        claimInput: {
          sessions,
          patient,
          patientInsuranceInfo,
          otherInsuranceInfo,
          diagnosisClaimInfo,
          billingInfo,
          isSubmitted: data?.isSubmitted ? true : false,
        },
      },
    });

    history.push('/doctor/finance');
  }
  const loadingQueries =
    !editMode && (loadingInsurance || loadingQuery || PatientLoading);
  if (loadingQueries)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="90vh"
      >
        <ProgressLoader color="#2F80ED" />
      </Box>
    );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.root} px={2} py={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={2}
          borderBottom={'1px solid #D0D5DD'}
          mb={2}
        >
          <Typography className={classes.title}>{`${
            editMode
              ? `${currentValues?.patient?.firstname} ${currentValues?.patient?.lastname}`
              : selectedClient
          } > CMS-1500 ${t('Health Insurance Claim')}`}</Typography>
          <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
            {currentValues?.isSubmitted && (
              <Button
                className={classes.saveBtn}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/doctor/claim/${currentValues?._id}`, {
                    currentValues: { ...currentValues },
                    sessionLength: currentValues?.sessions?.length,
                    editMode: true,
                  });
                }}
              >
                <Box display="flex" alignItems="center">
                  <ClmDetail style={{ marginRight: '5px' }} />
                  <Typography
                    style={{
                      color: '#2F80ED',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      textTransform: 'none',
                    }}
                  >
                    Claim details
                  </Typography>
                </Box>
              </Button>
            )}
            {!editMode && (
              <Button className={classes.saveBtn}>
                {loading && loaderAdd ? (
                  <ProgressLoader color="#2F80ED" width={18} height={18} />
                ) : (
                  <Box display="flex" alignItems="center">
                    <SaveClaimIcon style={{ marginRight: '5px' }} />
                    <Typography
                      style={{
                        color: '#2F80ED',
                        fontWeight: 600,
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        textTransform: 'none',
                      }}
                    >
                      {t('Save')}
                    </Typography>
                  </Box>
                )}
              </Button>
            )}
            <Button
              className={classes.submit}
              onClick={(e) => {
                e.preventDefault();
                SubmitClaim();
              }}
            >
              {loadingSubmit || LoadingCreate ? (
                <ProgressLoader width={18} height={18} />
              ) : (
                <Box display="flex" alignItems="center">
                  <Submitclm style={{ marginRight: '5px' }} />
                  <Typography
                    style={{
                      color: 'white',
                      fontWeight: 600,
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      textTransform: 'none',
                    }}
                  >
                    {!editMode ? t('Submit') : 'Re-submit'}
                  </Typography>
                </Box>
              )}
            </Button>
            <Button
              className={classes.cancelBtn}
              onClick={(e) => {
                e.preventDefault();
                history.push('/doctor/finance');
              }}
            >
              <Typography
                style={{
                  color: '#182230',
                  fontWeight: 600,
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  textTransform: 'none',
                }}
              >
                {t('Cancel')}
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            paddingRight: '8px',
          }}
        >
          <Grid container spacing={2}>
            <ClaimBasicInfo commonProps={commonProps} />
            <ClaimIntermediaire commonProps={commonProps} editMode={editMode} />
            <ClaimDiagnosisSection commonProps={commonProps} />
            <ClaimTableDetails
              commonProps={commonProps}
              sessionLength={sessionLength}
            />
            <ClaimFinal commonProps={commonProps} />
          </Grid>
        </Box>
      </Box>
    </form>
  );
};

export default ClaimPage;
export const GET_INSURANCES = gql`
  query patientInsurance($patient: ID) {
    patientInsurance(patient: $patient) {
      _id
      relationShipType
      firstname
      middlename
      lastname
      sex
      birthday
      phone
      address
      city
      state
      zipCode
      country
      insuranceType
      payer {
        payer_name
        payerid
      }
      memberID
      groupID
      planID
      copay
      deductible
      startDate
      endDate
    }
  }
`;
export const GET_DOCTOR = gql`
  query getDoctor($DoctorId: ID!) {
    doctor(doctorId: $DoctorId) {
      _id
      firstname
      providerSettings {
        specialty
        npi
        licenseNumber
        taxonomy
      }
      lastname
      middlename
      phone
      email
      picture
      address
      city
      zipcode
      country
      suite
      about
      birthday
      gender
      state
    }
  }
`;
export const GET_PATIENT = gql`
  query PatientData($patientId: ID!) {
    patient(patientId: $patientId) {
      _id
      formatDate
      description
      pronouns
      email
      phone
      firstname
      lastname
      birthday
      middlename
      country
      city
      suite
      picture
      createdAt
      zipcode
      completed
      bloodType
      state
      address
      gender
    }
  }
`;
