import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useStyles } from './styles';
import ScrollBar from 'components/ui/ScrollBar';
import { ReactComponent as BackArrowIcon } from 'assets/patientActivities/backArrow.svg';
import QuestionPreview from './QuestionPreview';
import { IQuestion } from './FormsPage';
import EditButtons from 'components/EditButtons';
import { useQuery } from '@apollo/client';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import dayjs from 'dayjs';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { dayjsFormat } from 'utils';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';

interface IFormPreviewProps {
  questions: IQuestion[];
  handleBack: () => void;
  title: string;
  description: string;
  viewMode?: boolean;
  source: string;
  editMode?: boolean;
  isSigned?: boolean;
  patientId?: string;
  isView?: boolean;
}
function FormPreview({
  questions,
  handleBack,
  title,
  description,
  viewMode,
  source,
  editMode = true,
  isSigned = false,
  patientId,
  isView = false,
}: IFormPreviewProps): React.ReactElement {
  const { patient } = useAuth();

  const { data, loading } = useQuery(GET_PATIENT, {
    variables: { patientId: patientId || patient?._id },
  });
  const classes = useStyles();
  const questionSignature = questions?.find(
    (el) => el?.type == 'Signature'
  )?.question;

  return (
    <>
      <Box
        className={classes.root}
        minWidth="40vw"
        style={{ paddingInline: '0.2rem' }}
      >
        <Box width="100%" className={classes.padding}>
          {title && (
            <Box
              mb={2}
              p={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #D0D5DD',
              }}
            >
              <Typography
                className={classes.description}
                style={{ textTransform: 'capitalize', marginLeft: '8px' }}
              >
                {title}
              </Typography>
              <CancelIcon
                className={classes.cancelIconSlide}
                onClick={handleBack}
              />
            </Box>
          )}

          <Box display="flex" flexDirection="column" width="100%">
            {questions
              ?.filter((el) => el.type !== 'Signature')
              ?.map((question, index) => (
                <QuestionPreview
                  question={question}
                  viewMode={viewMode}
                  index={index}
                  editMode={editMode}
                  isView={isView}
                />
              ))}
          </Box>
          {source && (
            <Box py={6} borderTop={'1px solid #F2F4F7'}>
              <Typography className={classes.source}>{source}</Typography>
            </Box>
          )}
        </Box>
        {isSigned && (
          <>
            <Box mb={2} className={classes.boxStyle}>
              <Typography className={classes.QuestionTitle}>
                {questionSignature}
              </Typography>
            </Box>
            <Box
              mt={0}
              ml={2}
              px={2}
              className={classes.imageContainer}
              style={{ backgroundColor: '#F2F4F7' }}
              py={'2rem'}
            >
              <img
                className={classes.image}
                src={data?.patient?.signature?.picture}
              />
              <Box ml={1} style={{ margin: '10px 0px' }}>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: '14px',
                    color: '#0054B6',
                  }}
                >
                  Signed by {data?.patient?.firstname} {data?.patient?.lastname}
                </Typography>
                <Typography
                  style={{
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#0054B6',
                  }}
                >
                  {dayjsFormat(
                    new Date(data?.patient?.signature?.updatedAt),
                    'MMM D, YYYY h:mm A'
                  )}
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default FormPreview;
