import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';
import clsx from 'clsx';
import SignatureResponse from 'components/Editorjs/Responses/Signature';
import { useStyles } from './index';
import Text from 'Text';

const ClaimDiagnosisSection = ({ commonProps }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid item xl={6} lg={6}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="21. Diagnosis or nature of illness or injury (relate A-L to service line below(24E))"
            className={classes.label}
          >
            21. Diagnosis or nature of illness or injury (relate A-L to service
            line below(24E))
          </Text>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <InputBlock
            label={'ICD Ind.'}
            {...commonProps}
            name="icd"
            type="text"
            disabled
            placeholder="10"
          />
        </Box>
        <Grid container spacing={1}>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              A
            </Box>
            <InputBlock label={''} {...commonProps} name="A" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              B
            </Box>
            <InputBlock label={''} {...commonProps} name="B" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              C
            </Box>
            <InputBlock label={''} {...commonProps} name="C" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              D
            </Box>
            <InputBlock label={''} {...commonProps} name="D" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              E
            </Box>
            <InputBlock label={''} {...commonProps} name="E" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              F
            </Box>
            <InputBlock label={''} {...commonProps} name="F" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              G
            </Box>
            <InputBlock label={''} {...commonProps} name="G" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              H
            </Box>
            <InputBlock label={''} {...commonProps} name="H" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              I
            </Box>
            <InputBlock label={''} {...commonProps} name="I" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              J
            </Box>
            <InputBlock label={''} {...commonProps} name="J" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              K
            </Box>
            <InputBlock label={''} {...commonProps} name="K" type="text" />
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box mr={1} mt={-1}>
              L
            </Box>
            <InputBlock label={''} {...commonProps} name="L" type="text" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={6} lg={6}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="22. Resubmission code" className={classes.label}>
            22. Resubmission code
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Code'}
              {...commonProps}
              name="codeResubmit"
              type="select"
              options={['']}
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Original ref. no.'}
              {...commonProps}
              name="originalRef"
              type="text"
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Box className={classes.labelRoot} p={1}>
            <Text
              i18nKey="23. Prior authorization number"
              className={classes.label}
            >
              23. Prior authorization number
            </Text>
          </Box>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6}>
              <InputBlock
                label={'Prior authorization number'}
                {...commonProps}
                name="priorNumber"
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <InputBlock
                label={'CLIA number'}
                {...commonProps}
                name="cliaNumber"
                type="text"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ClaimDiagnosisSection;
