import { Box, Checkbox, Typography, makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'assets/Schedule/new/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/Schedule/new/checkbox-unchecked.svg';
import Skeleton from 'components/ui/Skeleton';
import { ReactComponent as BillCalendar } from 'assets/BillAssets/BillCalendar.svg';
import dayjs from 'dayjs';
import { ReactComponent as TimeBill } from 'assets/BillAssets/TimeBill.svg';
import { ReactComponent as CurrencyBill } from 'assets/BillAssets/CurrencyBill.svg';
import { ReactComponent as OnlineBill } from 'assets/BillAssets/OnlineBill.svg';
import { ReactComponent as InPersonBill } from 'assets/BillAssets/InPersonBill.svg';
import { chipColor } from 'pages/PatientConsultations/ApptCardConsultation';
import DateRangeInput from 'components/ui/Inputs/DateRangeInput';
import { SearchClients } from './SearchClients';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import { ReactComponent as NosessionAvailable } from 'assets/NosessionAvailable.svg';
import { dayjsFormat } from 'utils';
import ProgressLoader from 'components/ui/ProgressLoader';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

interface Isesion {
  sessions: {
    _id: string;
    isPaid: string;
    startDate: string;
    endDate: string;
    locationLink: string;
    sessionType: {
      session: string;
      rate: string;
      duration: string;
      currency: string;
      color: string;
      location: string;
      isPrepayment: boolean;
    };
  }[];
  loadingBill: boolean;
  isFilled: boolean;
  range: { startDate: Date | null; endDate: Date | null };
  onChange: (date: any) => void;
  data: any;
  selectedClient: string;
  setSelectedClient: (client: string) => void;
  setSelectedClientID: (id: string) => void;
  downloadStarted: boolean;
  loadingBillMutation: boolean;
  onSubmitBill: any;
  formData: any;
  errorBill: any;
  setDownloadStarted: any;
  insuranceName: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #EAECF0',
    borderRadius: '4px',
    padding: '8px',
    marginBottom: '16px',
  },
  text: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#000000',
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#182230',
    marginLeft: theme.spacing(1),
  },
}));

const SuperBillModal = ({
  sessions,
  loadingBill,
  isFilled,
  range,
  onChange,
  data,
  selectedClient,
  setSelectedClient,
  setSelectedClientID,
  insuranceName,
}: Isesion) => {
  const { control, register, errors } = useFormContext();
  const { t } = useTranslation();
  const classes = useStyles();
  if (loadingBill)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="90vh"
      >
        <ProgressLoader color="#2F80ED" />
      </Box>
    );
  return (
    <Box display="flex" flexDirection="column">
      {!isFilled && (
        <Grid
          container
          spacing={1}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '16px',
            flexDirection: 'row-reverse',
            marginBottom: '6rem',
          }}
        >
          <Grid item xl={6} lg={6}>
            <DateRangeInput range={range} onChange={onChange} />
          </Grid>
          <Grid item xl={6} lg={6}>
            <SearchClients
              options={convertObjectsFormat(data, '')}
              placeholder="Select a client"
              value={selectedClient}
              handleChangePage={() => {}}
              setValue={setSelectedClient}
              setSearchQuery={setSelectedClientID}
              filterOptions={(options, { inputValue }) => {
                const inputValueLowerCase = inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    option.email.toLowerCase().includes(inputValueLowerCase) ||
                    option.name.toLowerCase().includes(inputValueLowerCase)
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      {isFilled && sessions?.length > 0 && (
        <Typography className={classes.text}>
          {t('Select the session(s) to include in the')} {t(insuranceName)}
        </Typography>
      )}
      <Box mt={2}>
        {sessions?.length > 0
          ? sessions?.map((session, index) => (
              <Grid
                container
                spacing={0}
                style={{ display: 'flex', alignItems: 'center' }}
                className={classes.root}
              >
                <Grid
                  item
                  xl={3}
                  lg={3}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Controller
                    name={`sessions[${session?._id}]`}
                    control={control}
                    defaultValue={true}
                    render={({ onChange, value, ref }) => {
                      return (
                        <Checkbox
                          size="medium"
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                          checkedIcon={<CheckedIcon width={16} height={16} />}
                          icon={<UncheckedIcon width={16} height={16} />}
                          style={{ backgroundColor: 'transparent' }}
                        />
                      );
                    }}
                  />
                  <BillCalendar />
                  <Typography className={classes.label}>
                    {dayjsFormat(
                      new Date(session?.startDate),
                      'ddd MMM D, YYYY'
                    )}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TimeBill />
                  <Typography className={classes.label}>
                    {dayjsFormat(session?.startDate, 'h:mm')} -{' '}
                    {dayjsFormat(session?.endDate, 'h:mm')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {session?.locationLink ? <OnlineBill /> : <InPersonBill />}
                  <Typography className={classes.label}>
                    {session?.locationLink ? t('Online') : t('In person')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CurrencyBill />
                  <Typography className={classes.label}>
                    ${session?.sessionType?.rate}
                  </Typography>
                  <Box
                    style={{
                      width: '30%',
                      borderRadius: '16px',
                      background: chipColor[session?.isPaid]?.background,
                      border: chipColor[session?.isPaid]?.border,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '5px 8px',
                    }}
                    ml={1}
                  >
                    <Text
                      i18nKey={session?.isPaid}
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        color: chipColor[session?.isPaid]?.color,
                        textTransform: 'capitalize',
                      }}
                    >
                      {session?.isPaid}
                    </Text>
                  </Box>
                </Grid>
              </Grid>
            ))
          : !loadingBill && (
              <Box
                display="flex"
                alignItems="center"
                textAlign="center"
                justifyContent="center"
                flexDirection="column"
                mt="10rem"
              >
                <NosessionAvailable />
                <Text
                  i18nKey="No sessions available"
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    color: '#475467',
                  }}
                >
                  No sessions available
                </Text>
              </Box>
            )}
      </Box>
    </Box>
  );
};

export default SuperBillModal;
