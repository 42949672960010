import React from 'react';
import { createRoot } from 'react-dom/client';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
  edit: boolean;
}

export default class DropDown {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private root: any | null;

  static get toolbox() {
    return {
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 11L12 16L17 11" stroke="#475467" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>                 
      `,
      title: 'Drop down',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? false;
    this.root = null;

    const defaultQuestion = {
      question: 'Custom question',
      type: 'Dropdown',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };

  private updateDom() {
    if (this.nodes.holder) {
      if (!this.root) {
        this.root = createRoot(this.nodes.holder);
      }
      this.root.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          changeQuestion={this.changeQuestion.bind(this)}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />
      );
    }
  }

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    this.root = createRoot(rootNode);
    this.root.render(
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion.bind(this)}
        question={this.data.question}
        isNotes={false}
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
