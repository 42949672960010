import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from '../styles';
import { ReactComponent as ChevronIcon } from 'assets/dashboard/finances/chevron-down.svg';
import Text from 'Text';

interface ICustomAccordion {
  hasOnlyTitle?: boolean;
  defaultExpanded?: boolean;
  title: string;
  AccordionSummary: React.ReactNode;
  AccordionContent: React.ReactNode;
}

export const CustomAccordion = ({
  title,
  AccordionSummary,
  AccordionContent,
  hasOnlyTitle = false,
  defaultExpanded = true,
}: ICustomAccordion) => {
  const classes = useStyles();
  const [expand, setExpand] = useState<boolean>(defaultExpanded);

  const toggleExpanded = () => {
    setExpand((s) => !s);
  };

  return (
    <Box className={classes.accordionContainer}>
      <Box className={classes.accordionWrapper} py={expand ? '4px' : '10px'}>
        <Box className={classes.accordionSummaryWrapper}>
          <Text i18nKey={title} className={classes.accordionTitle}>
            {title}
          </Text>
          {!hasOnlyTitle && (
            <Box
              className={classes.accordionSummary}
              style={{
                height: !expand ? 'unset' : 0,
                visibility: !expand ? 'visible' : 'hidden',
                opacity: !expand ? 1 : 0,
                transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              }}
            >
              {AccordionSummary}
            </Box>
          )}
        </Box>
        {!hasOnlyTitle && (
          <ChevronIcon
            onClick={toggleExpanded}
            style={{
              cursor: 'pointer',
              transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        )}
      </Box>

      <Box
        className={classes.accordionContent}
        style={{
          height: expand ? 'unset' : 0,
          visibility: expand ? 'visible' : 'hidden',
          overflow: expand ? 'unset' : 'hidden',
          transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
      >
        {AccordionContent}
      </Box>
    </Box>
  );
};
