/* eslint-disable*/
import { Box } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  p?: number;
  hasNoHeight?: boolean;
  isConversation?: boolean;
}

type IHeightProps = {
  isConversation: boolean;
  hasNoHeight: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: ({ isConversation, hasNoHeight }: IHeightProps) =>
      isConversation ? 'calc(100% - 178px)' : hasNoHeight ? 'unset' : '100%',
    maxHeight: ({ isConversation }: IHeightProps) =>
      isConversation ? 'calc(100% - 178px)' : '100%',
  },
}));

export default function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    index,
    hasNoHeight = false,
    isConversation = false,
    ...other
  } = props;

  const classes = useStyles({ hasNoHeight, isConversation });

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      className={classes.root}
      {...other}
    >
      {value === index && (
        <Box height="100%" hidden={value !== index}>
          {children}
        </Box>
      )}
    </Box>
  );
}
