import React, { useEffect } from 'react';
import { PatientFinance } from './PatientFinance';
import AppointmentListPageProvider from 'pages/AppointmentListPage/state/Provider';
import InboxPageProvider from 'pages/InboxPage/state/Provider';
import { useTranslation } from 'react-i18next';
import { useHeaderPageNameDispatch } from 'components/Layout/HeaderPageNameConext';

const PatientFinancePage = () => {
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: t('Finances') });
  }, [headerTitleDispatcher]);

  return (
    <AppointmentListPageProvider>
      <InboxPageProvider isOnly={false}>
        <PatientFinance />
      </InboxPageProvider>
    </AppointmentListPageProvider>
  );
};

export default PatientFinancePage;
