import React, { useState } from 'react';
import Tabs from 'components/ui/Tabs';
import { Box, Grid, Typography } from '@material-ui/core';
import Questionaires from './Questionaires/Questionaires';
import { useQuery, gql } from '@apollo/client';
import { GET_PATIENT } from 'pages/PatientProfilePage';
import { TypeEnum } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useCreatePatientActivity';
import Activity, {
  TypeEnum as activityType,
  GET_PATIENT_ACTIVITIES_BY_FILTER,
} from './commun/Activity';
import { Moods, Sleep as sleepEmojies } from './commun/constants';
import Modals from 'pages/InboxPage/modals/index';
import FormProvider from 'pages/PatientActivities/Questionaires/ActivityProvider';
import RecentActivityCard from './RecentActivityCard';
import PatientListHeader from 'pages/PatientListPage/PatientListHeader';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import { ReactComponent as ArrowLeft } from 'assets/patient-profile/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/patient-profile/arrow-right.svg';
import clsx from 'clsx';
import { useStyles } from './styles';
import { ActivitiesTypes } from './RecentActivityCard';
import { useHistory } from 'react-router-dom';

import ActivityModal from '../../pages/PatientActivities/commun/ActivityModal';
import { useProvider as useFormProvider } from 'pages/PatientActivities/Questionaires/ActivityProvider';

import { ReactComponent as MoodSel } from 'assets/MoodSel.svg';
import { ReactComponent as ReflectionSel } from 'assets/ReflectionSel.svg';
import { ReactComponent as SleepSel } from 'assets/SleepSel.svg';
import { ReactComponent as FormsSel } from 'assets/FormsSel.svg';
import { ReactComponent as MoodUnsel } from 'assets/MoodUnsel.svg';
import { ReactComponent as ReflectionUnsel } from 'assets/ReflectionUnsel.svg';
import { ReactComponent as SleepUnsel } from 'assets/SleepUnsel.svg';
import { ReactComponent as FormsUnsel } from 'assets/FormsUnsel.svg';
import { ReactComponent as RecentAct } from 'assets/RecentActivIcon.svg';
import { ReactComponent as AllActv } from 'assets/AllActv.svg';
import { ReactComponent as EmptyTask } from 'assets/emptyState/task.svg';
import EmptyState from 'pages/DashboardPage/EmptyState';
import Skeleton from 'components/ui/Skeleton';
import ActivitySkeleton from './ActivitySkeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MoodIcon } from 'assets/patientActivities/mood.svg';
import { ReactComponent as SleepIcon } from 'assets/patientActivities/sleep-2.svg';
import { ReactComponent as ReflectionIcon } from 'assets/patientActivities/reflection.svg';
import { ReactComponent as FormIcon } from 'assets/patientActivities/formIcon.svg';
import Menu from 'components/ui/Menu';
import BasicModal from 'components/ui/Modal';
import FormModal from 'pages/PatientActivities/Questionaires/FormModal';
import AssignModal from 'pages/AppointmentPage/tabs/AssignModal';
import useAssignQuestionaire from './Questionaires/useAssignQuestionaire';
import { useSnackbar } from 'hooks/useSnackbar';
import { useForm } from 'react-hook-form';
import { convertObjectsFormat } from 'pages/SchedulePage/AddEventPage/utils';
import AssignClientModal from 'pages/AdminPage/TabsContent/templatesTabs/AssignClient';
import FormModals from 'pages/PatientActivities/Questionaires/FormModals';

export interface IPatientResponse {
  _id: String;
  question: string;
  response: string;
  image: string;
  sleepHours: string;
  wakeHours: string;
  notes: string;
  createdAt: Date;
  tags: string[];
}
export interface IPatientActivities {
  _id: string;
  reflectionType: string;
  question: string;
  options: string[];
  type: string;
  notes: boolean;
  sleep: boolean;
  photo: boolean;
  doctor: {
    _id: string;
  };
  patient: {
    _id: string;
    firstname: string;
    lastname: string;
  };
  assignedAt: string;
  patientResponse: IPatientResponse | IPatientResponse[];
  frequency: {
    startDate: string;
    endDate: string;
    frequencyType: string;
    repetition: {
      value: number;
      unit: string;
    };
    repeatOn: string[];
  };
  bookmarked: boolean;
  title?: string;
  description?: string;
  questions?: any[];
  doctorId?: string;
  patientId?: string;
  patientResponseDate?: string;
  blocks?: string;
  isPatientSigned?: boolean;
}

function Index({
  patientId,
  doctorId,
}: {
  patientId: string;
  doctorId: string;
}) {
  const { t } = useTranslation();
  const { data: PatientData } = useQuery(GET_PATIENT, {
    variables: { patientId },
  });

  const [page, setPage] = React.useState(1);
  const [limits, setLimits] = React.useState<number>(5);
  const [open, setOpen] = React.useState(false);

  const history = useHistory();
  const [selectedValues, setSelectedValues] = useState<ActivitiesTypes[]>([]);
  const { data, loading: activitiesLoading } = useQuery(
    GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID,
    {
      variables: {
        patientId,
        doctorId,
        types: selectedValues,
      },
    }
  );

  const {
    data: templates,
    openAssignModal,
    handleDeleteModal,
    deleteId,
  } = useFormProvider();
  const [typeValue, setTypeValue] = React.useState(null);
  const { data: activity, loading } = useQuery(
    GET_PATIENT_ACTIVITIES_BY_FILTER,
    {
      variables: {
        patientId: patientId,
        doctorId: doctorId,
        filter: activityType,
        page: page.toString(),
        limit: limits.toString(),
      },
    }
  );

  const defaultValues = React.useMemo(() => {
    if (activity?.patientActivitiesfilter?.result && deleteId) {
      return activity?.patientActivitiesfilter?.result.find(
        (item) => item._id === deleteId
      );
    }
    return null;
  }, [activity?.patientActivitiesfilter?.result, deleteId]);

  const recentActivity =
    data?.patientActivitiesAndFormsByPatientDoctorId?.result;
  const doctorAndPatientId = { doctor: doctorId, patient: patientId };
  const { firstname, lastname, middlename, _id, email, picture } =
    PatientData?.patient || {};
  const [openAssign, setOpenAssign] = React.useState(false);
  const [openActivity, setOpenActivity] = React.useState<activityType | null>(
    null
  );
  const [limit, setLimit] = React.useState<number>(10);
  const [formTitle, setFormTitle] = React.useState<string>(t('Form'));
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles({});
  const queryParams = new URLSearchParams(location.search);
  const tabSelected = queryParams.get('table');
  const [customValue, setCustomValue] = React.useState(
    tabSelected ? Number(tabSelected) : 0
  );
  React.useEffect(() => {
    if (tabSelected && Number(tabSelected) !== customValue) {
      setCustomValue(Number(tabSelected));
    }
  }, [tabSelected]);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (customValue !== null && customValue !== undefined) {
        history.push(`/doctor/patient/${patientId}?tab=1&table=${customValue}`);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [customValue]);

  const MenuElements = [
    {
      cy: 'mood-check-in',
      avatar: <MoodIcon width={24} height={24} />,
      name: t('Mood check-in'),
      onClick: () => {
        setOpenActivity(activityType.MOOD);
        handleMenuClose();
      },
    },
    {
      cy: 'sleep-check-in',
      avatar: <SleepIcon width={24} height={24} />,
      name: t('Sleep check-in'),
      onClick: () => {
        setOpenActivity(activityType.SLEEP);
        handleMenuClose();
      },
    },
    {
      cy: 'form',
      avatar: <FormIcon width={24} height={24} />,
      name: t('Form'),
      onClick: () => {
        setOpenActivity(activityType.FORM);
        handleMenuClose();
      },
    },
    {
      cy: 'reflection',
      avatar: <ReflectionIcon width={24} height={24} />,
      name: t('Reflection'),
      onClick: () => {
        setOpenActivity(activityType.REFLECTION);
        handleMenuClose();
      },
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const modalType = {
    [activityType.MOOD]: () => (
      <ActivityModal
        name={firstname}
        handleClose={() => setOpen(false)}
        doctorAndPatientId={doctorAndPatientId}
        defaultValues={defaultValues}
        type={activityType.MOOD}
      />
    ),
    [activityType.SLEEP]: () => (
      <ActivityModal
        name={firstname}
        handleClose={() => setOpen(false)}
        doctorAndPatientId={doctorAndPatientId}
        defaultValues={defaultValues}
        type={activityType.SLEEP}
      />
    ),
    [activityType.REFLECTION]: () => (
      <ActivityModal
        name={firstname}
        handleClose={() => setOpen(false)}
        defaultValues={defaultValues}
        doctorAndPatientId={doctorAndPatientId}
        type={activityType.REFLECTION}
      />
    ),
    [activityType.FORM]: () => (
      <FormModal
        setFormTitle={setFormTitle}
        handleClose={handleClose}
        title={`Assign form to ${firstname}`}
        patientId={_id}
        patient={PatientData?.patient}
        assignText={`Assign this form to ${firstname} ${lastname}?`}
        doctorTemplates={templates}
        openAssignModal={openAssignModal}
        openDeleteModal={handleDeleteModal}
      />
    ),
  };
  return (
    <FormProvider>
      <Box pt={0}>
        <Splide
          options={{
            gap: '1rem',
            perPage: 5,
          }}
          hasTrack={false}
          aria-label="My Favorite Images"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Box display="flex" flexDirection="column">
              <Box display="flex" alignItems="center">
                <RecentAct style={{ marginRight: '10px' }} />
                <Text
                  i18nKey="recentActivities"
                  style={{
                    fontSize: '30px',
                    fontFamily: 'Inter',
                    fontWeight: 600,
                    color: '#101828',
                    marginRight: '1rem',
                  }}
                >
                  Recent activities
                </Text>
                <div className={clsx('splide__arrows', classes.arrows)}>
                  <button
                    className={clsx(
                      'splide__arrow splide__arrow--prev',
                      classes.arrow
                    )}
                  >
                    <ArrowLeft />
                  </button>
                  <button
                    className={clsx(
                      'splide__arrow splide__arrow--next',
                      classes.arrow
                    )}
                  >
                    <ArrowRight />
                  </button>
                </div>
              </Box>
            </Box>
            <PatientListHeader
              handleOpenMenu={(event) => setAnchorEl(event.currentTarget)}
              limit={limit}
              setLimit={setLimit}
              disableEntries
              btnText={t('Assign activity')}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
          </Box>
          <Box style={{ marginBottom: '20px' }}>
            <SplideTrack>
              {activitiesLoading && (
                <Box display="flex" width="100%" style={{ gap: '1rem' }}>
                  {[1, 2, 3, 4, 5].map(() => (
                    <ActivitySkeleton />
                  ))}
                </Box>
              )}
              {!activitiesLoading && recentActivity?.length === 0 && (
                <Box py="2rem" width="100%">
                  <EmptyState
                    icon={<EmptyTask />}
                    title={t('No activity yet')}
                    hasAction={false}
                  />
                </Box>
              )}
              {recentActivity?.map((element) => (
                <SplideSlide>
                  <RecentActivityCard
                    {...element}
                    doctorAndPatientId={doctorAndPatientId}
                    key={element?._id}
                  />
                </SplideSlide>
              ))}
            </SplideTrack>
            <ul
              className={clsx(
                'splide__pagination splide__pagination--ltr',
                classes.pagination
              )}
              role="tablist"
            ></ul>
          </Box>
        </Splide>
        <Tabs
          tabsBar={[
            <Box
              className={classes.tabHeader}
              display="flex"
              alignItems="center"
            >
              {customValue === 0 ? <MoodSel /> : <MoodUnsel />}
              <Text i18nKey="moodCheckIns">Mood check-ins</Text>
              {/* <Typography className={classes.unreadRed}>2</Typography> */}
            </Box>,
            <Box
              className={classes.tabHeader}
              display="flex"
              alignItems="center"
            >
              {customValue === 1 ? <ReflectionSel /> : <ReflectionUnsel />}
              <Text i18nKey="reflections">Reflections</Text>
            </Box>,
            <Box
              className={classes.tabHeader}
              display="flex"
              alignItems="center"
            >
              {customValue === 2 ? <SleepSel /> : <SleepUnsel />}
              <Text i18nKey="sleepCheckIns">Sleep check-ins</Text>
            </Box>,
            <Box
              className={classes.tabHeader}
              display="flex"
              alignItems="center"
            >
              {customValue === 3 ? <FormsSel /> : <FormsUnsel />}
              <Text i18nKey="forms">Forms</Text>
            </Box>,
          ]}
          tabsContent={[
            <Activity
              patient={PatientData?.patient}
              doctorAndPatientId={doctorAndPatientId}
              type={activityType.MOOD}
              activityType={TypeEnum.Mood}
              emojis={Moods}
              openAssign={openAssign}
              setOpenAssign={setOpenAssign}
              openActivity={openActivity}
              setOpenActivity={setOpenActivity}
            />,
            <Activity
              patient={PatientData?.patient}
              doctorAndPatientId={doctorAndPatientId}
              type={activityType.REFLECTION}
              activityType={TypeEnum.Reflection}
              openAssign={openAssign}
              setOpenAssign={setOpenAssign}
              openActivity={openActivity}
              setOpenActivity={setOpenActivity}
            />,
            <Activity
              patient={PatientData?.patient}
              doctorAndPatientId={doctorAndPatientId}
              type={activityType.SLEEP}
              activityType={TypeEnum.Sleep}
              emojis={sleepEmojies}
              openAssign={openAssign}
              setOpenAssign={setOpenAssign}
              openActivity={openActivity}
              setOpenActivity={setOpenActivity}
            />,
            <Questionaires
              patient={PatientData?.patient}
              doctorAndPatientId={doctorAndPatientId}
            />,
          ]}
          tabsMode="greenButtons"
          setCustomValue={setCustomValue}
          customValue={customValue}
          classNameTab={classes.tab}
          tabClassHeader={classes.headerTab}
          componetAlignedLeftWithTabs={
            <Box display="flex" alignItems="center">
              <AllActv style={{ marginRight: '10px' }} />
              <Text
                i18nKey="allActivities"
                style={{
                  fontSize: '30px',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  color: '#101828',
                  marginRight: '1rem',
                }}
              >
                All activities
              </Text>
            </Box>
          }
        />
      </Box>
      <Modals
        subject="Activities"
        contact={[{ firstname, lastname, middlename, _id, email, picture }]}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        handleMenuClose={handleMenuClose}
        MenuElements={MenuElements}
      />
      <BasicModal
        isRegularModal
        isSlide
        titlePadding="0"
        open={!!typeValue}
        onClose={() => {
          setOpen(false);
          setOpenActivity(null);
        }}
        handleClose={() => {
          setOpen(false);
          setOpenActivity(null);
        }}
      >
        {modalType[openActivity] ? (
          modalType[openActivity]()
        ) : (
          <div>No modal available</div>
        )}
      </BasicModal>
      <BasicModal
        isRegularModal
        isSlide
        divider={openActivity === activityType.FORM}
        titlePadding={openActivity === activityType.FORM ? '0' : '0'}
        title={''}
        open={!!openActivity}
        onClose={() => setOpenActivity(null)}
        handleClose={() => setOpenActivity(null)}
        isIcon={null}
      >
        {modalType[openActivity] ? (
          modalType[openActivity]()
        ) : (
          <div>No modal available</div>
        )}
      </BasicModal>
      <FormModals patient={PatientData?.patient} />
    </FormProvider>
  );
}

export default Index;

export const GET_PATIENT_ACTIVITIES_BY_DOCTOR_AND_PATIENT_ID = gql`
  query patientActivitiesAndFormsByPatientDoctorId(
    $patientId: ID!
    $doctorId: ID!
    $limit: Int
    $page: Int
    $types: [String]
  ) {
    patientActivitiesAndFormsByPatientDoctorId(
      patientId: $patientId
      doctorId: $doctorId
      limit: $limit
      page: $page
      types: $types
    ) {
      result {
        _id
        title
        reflectionType
        question
        options
        type
        notes
        photo
        sleep
        assignedAt
        activityUrl
        bookmarked
        isEdited
        patientResponseDate
        blocks
        isPatientSigned
        frequency {
          startDate
          endDate
          frequencyType
          repetition {
            value
            unit
          }
          repeatOn
        }
        patientResponse {
          _id
          question
          response
          image
          sleepHours
          wakeHours
          notes
          createdAt
          tags
        }
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
        }
        patient {
          _id
          firstname
          middlename
          lastname
          picture
        }
      }
      count
      hasNext
    }
  }
`;
