import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../styles';
import clsx from 'clsx';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import dayjs from 'dayjs';
import { currencySymbols } from './Steps';
import { ReactComponent as DashIcon } from 'assets/dashboard/finances/dash.svg';
import { useTranslation } from 'react-i18next';
import Text from 'Text';
import ScrollBar from 'components/ui/ScrollBar';
import Button from 'components/ui/Button';
import ProgressLoader from 'components/ui/ProgressLoader';
import useGenerateInvoicePdf from '@eggmed/graphql-client/operations/appointmentsOperations/useGenerateInvoicePdf';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { dayjsFormat } from 'utils';

export interface IInvoiceRowProps {
  date: string | React.ReactNode;
  description: string | React.ReactNode;
  amount: string | React.ReactNode;
}

interface IInvoicePreviewProps {
  currentRow: any;
  handleClose: () => void;
}

export const InvoicePreview = ({
  currentRow,
  handleClose,
}: IInvoicePreviewProps) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');

  const classes = useStyles();
  const invoice = currentRow?.invoice;
  const { generateInvoicePdf, loading } = useGenerateInvoicePdf();

  const columns: IColumn<IInvoiceRowProps>[] = [
    {
      id: 'date',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="date">Date</Text>
        </Box>
      ),
      freeze: true,
    },
    {
      id: 'description',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="description">Description</Text>
        </Box>
      ),
    },
    {
      id: 'amount',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="amount">Amount</Text>
        </Box>
      ),
      freeze: true,
    },
  ];

  const data: IInvoiceRowProps[] = [
    {
      date: (
        <Box className={classes.row}>
          {dayjsFormat(
            new Date(invoice?.issueDate),
            i18n.language === 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
          ) ?? <DashIcon />}
        </Box>
      ),
      description: (
        <Box className={classes.row}>
          {currentRow?.sessionType?.duration ? (
            `${currentRow?.sessionType?.duration} min, ${currentRow?.sessionType?.session}`
          ) : (
            <DashIcon />
          )}
        </Box>
      ),
      amount: (
        <Box className={classes.row}>
          {currentRow?.sessionType?.duration ? (
            `${currencySymbols[currentRow?.sessionType?.currency]}${
              invoice?.amount
            }`
          ) : (
            <DashIcon />
          )}
        </Box>
      ),
    },
  ];

  const balanceDue: number | undefined =
    parseInt(invoice?.amount) - parseInt(invoice?.amountPaid);

  const handleGeneratePdf = async () => {
    const link = await generateInvoicePdf({
      variables: { invoiceId: invoice?._id },
    });
    window.open(`${link?.data?.generateInvoicePdf?.invoicePdf}`, '_blank');
    handleClose();
  };

  return (
    <Box width="40vw">
      <ScrollBar className={classes.scrollbar}>
        <Box px="2rem">
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            mt={2}
            mb={6}
          >
            <Box flex={1}>
              <Typography className={classes.previewTitle}>
                {t('Invoice')}
              </Typography>
            </Box>
            <Box flex={1} maxWidth={250}>
              <Box className={classes.previewDetail}>
                <Typography className={classes.previewLabel}>
                  {t('Invoice no:')}
                </Typography>
                <Typography className={classes.previewText}>
                  {invoice?.invoiceRef}
                </Typography>
              </Box>
              <Box className={classes.previewDetail}>
                <Typography className={classes.previewLabel}>
                  {t('Issued')}:
                </Typography>
                <Typography className={classes.previewText}>
                  {dayjsFormat(
                    new Date(invoice?.issueDate),
                    i18n.language === 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
                  ) ?? <DashIcon />}
                </Typography>
              </Box>
              <Box className={classes.previewDetail}>
                <Typography className={classes.previewLabel}>
                  {t('Due')}:
                </Typography>
                <Typography className={classes.previewText}>
                  {dayjsFormat(
                    new Date(invoice?.dueDate),
                    i18n.language === 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
                  ) ?? <DashIcon />}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            mb={6}
          >
            <Box flex={1}>
              <Typography className={classes.accordionTitle}>
                {t('From')}
              </Typography>
              <Typography className={classes.name}>
                {invoice?.doctorInfo?.firstname} {invoice?.doctorInfo?.lastname}
              </Typography>
              <Typography className={classes.common}>
                {currentRow?.doctor?.email ?? ''}
              </Typography>
              <Typography className={classes.common}>
                {invoice?.doctorInfo?.phone ?? ''}
              </Typography>
              <Typography className={classes.common}>
                {invoice?.doctorInfo?.address
                  ? invoice?.doctorInfo?.address + ', '
                  : ''}
                {invoice?.doctorInfo?.suite
                  ? invoice?.doctorInfo?.suite + ', '
                  : ''}
                {invoice?.doctorInfo?.state
                  ? invoice?.doctorInfo?.country
                    ? invoice?.doctorInfo?.state + ', '
                    : invoice?.doctorInfo?.state
                  : ''}
                {invoice?.doctorInfo?.country ?? ''}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography className={classes.accordionTitle}>
                {t('Bill to')}
              </Typography>
              <Typography className={classes.name}>{`${
                invoice?.patientInfo?.firstname ?? ''
              } ${invoice?.patientInfo?.lastname ?? ''}`}</Typography>
              <Typography className={classes.common}>
                {currentRow?.patient?.email ?? ''}
              </Typography>
              <Typography className={classes.common}>
                {invoice?.patientInfo?.phone ?? ''}
              </Typography>
              <Typography className={classes.common}>
                {invoice?.patientInfo?.address
                  ? invoice?.patientInfo?.address + ', '
                  : ''}
                {invoice?.patientInfo?.suite
                  ? invoice?.patientInfo?.suite + ', '
                  : ''}
                {invoice?.patientInfo?.state
                  ? invoice?.patientInfo?.country
                    ? invoice?.patientInfo?.state + ', '
                    : invoice?.patientInfo?.state
                  : ''}
                {invoice?.patientInfo?.country ?? ''}
              </Typography>
            </Box>
          </Box>
          <Box mb={6}>
            <Table<any>
              loading={false}
              page={1}
              columns={columns}
              limit={1}
              data={data}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box flex={1} maxWidth={220}>
              <Box className={classes.balanceRow}>
                <Typography className={classes.balanceLabel}>
                  {t('Subtotal')}
                </Typography>
                <Typography className={classes.previewText}>
                  {currentRow?.sessionType ? (
                    `${currencySymbols[currentRow?.sessionType?.currency]}${
                      invoice?.amount
                    }`
                  ) : (
                    <DashIcon />
                  )}
                </Typography>
              </Box>
              <Box className={classes.balanceRow}>
                <Typography className={classes.balanceLabel}>Total</Typography>
                <Typography className={classes.previewText}>
                  {currentRow?.sessionType ? (
                    `${currencySymbols[currentRow?.sessionType?.currency]}${
                      invoice?.amount
                    }`
                  ) : (
                    <DashIcon />
                  )}
                </Typography>
              </Box>
              <Box className={classes.balanceRow}>
                <Typography className={classes.previewLabel}>
                  {t('Amount paid')}
                </Typography>
                <Typography className={classes.previewText}>
                  {currencySymbols[currentRow?.sessionType?.currency]}
                  {invoice?.amountPaid}
                </Typography>
              </Box>
              <Box className={clsx(classes.balanceRow, classes.balanceDue)}>
                <Typography className={classes.balanceDueLabel}>
                  {t('Balance due')}
                </Typography>
                <Typography className={classes.balanceDueText}>
                  {currencySymbols[currentRow?.sessionType?.currency]}
                  {balanceDue}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </ScrollBar>

      <Box className={classes.buttons}>
        <Box className={classes.container} px="2rem">
          <Button
            className={classes.cancel}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            buttonType="reset"
          >
            {t('Cancel')}
          </Button>

          <Button
            variant="contained"
            className={classes.assignButton}
            buttonType="submit"
            onClick={handleGeneratePdf}
          >
            {loading ? (
              <ProgressLoader width={20} height={20} />
            ) : (
              t('Download')
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
