import { useQuery, gql } from '@apollo/client';

interface IUseAppointmentArgs {
  startDate?: string | Date | number | undefined;
  endDate?: string | Date | number | undefined;
  page?: string | number;
  limit?: string | number;
  filter?: string;
  patient?: string;
  searchValue?: string;
  start?: string | Date;
  end?: string | Date;
}

export default function useGetFinances({
  startDate,
  endDate,
  page,
  limit,
  filter,
  patient,
  searchValue,
  start,
  end,
}: IUseAppointmentArgs) {
  let queryObject = {};
  if (startDate) {
    queryObject = {
      startDate,
      endDate,
      filter,
      patient,
    };
  } else {
    queryObject = {
      limit,
      page,
      filter,
      patient,
      searchValue,
      start,
      end,
    };
  }
  const { data, error, loading, refetch } = useQuery(GET_FINANCES, {
    variables: queryObject,
    // fetchPolicy: 'network-only',
  });
  return { data, error, loading, refetch };
}

export const GET_FINANCES = gql`
  query finances(
    $limit: String
    $page: String
    $filter: String
    $patient: ID
    $searchValue: String
    $start: Date
    $end: Date
    $doctors: [ID]
  ) {
    finances(
      page: $page
      limit: $limit
      filter: $filter
      patient: $patient
      searchValue: $searchValue
      start: $start
      end: $end
      doctors: $doctors
    ) {
      result {
        _id
        description
        title
        appointmentUri
        paymentIntentId
        invitation {
          invitation_id
        }
        doctor {
          _id
          firstname
          middlename
          email
          lastname
          picture
          timeZone
        }
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        startDate
        endDate
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        isPaid
        isPrepay
        sessionType {
          session
          rate
          duration
          currency
          locationPlace
          externalUrl
          isPrepayment
          location
        }
        invoice {
          _id
          invoiceRef
          doctorInfo {
            firstname
            lastname
            phone
            address
            suite
            city
            zipcode
            country
            state
          }
          patientInfo {
            firstname
            lastname
            phone
            address
            suite
            city
            zipcode
            country
            state
          }
          amount
          amountPaid
          issueDate
          dueDate
          invoicePdf
        }
        claim {
          _id
          ref
          isSubmitted
          patient {
            _id
            firstname
            middlename
            lastname
            picture
            email
          }
          sessions {
            _id
            startDate
            endDate
          }
          issueDate
          insuranceRelated {
            firstname
            middlename
            lastname
            zipCode
            city
            address
            state
            relationShipType
          }
          patientInsuranceInfo {
            payerID
            payerName
            address
            city
            state
            zip
            memberID
            firstname
            middlename
            lastname
            gender
            birthDate
            insuranceName
            insuranceMiddle
            insuranceLast
            address1
            address2
            cityPatient
            statePatient
            zipcode
            phone
            relationship
            insuredAddress1
            insuredAddress2
            insuredCity
            insuredState
            insuredZipcode
            insuredPhone
          }
          otherInsuranceInfo {
            Insuredaddress1
            Insuredaddress2
            Insuredcity
            Insuredstate
            Insuredzipcode
            Insuredphone
            firstnameOther
            lastnameOther
            mi
            relationshipother
            policy
            insuredID
            indicator
            planname
            insurancetype
            ressequence
            payerIDReserved
            employment
            accident
            place
            otheraccident
            claimcode
            lastPolicy
            insuredDate
            sexPolicy
            otherClaimID
            insurancePlan
            anotherHealth
            dateSignature
            insuredDateBirth
            qual
            qualOther
            from
            to
            provider
            firstRefering
            MiddletRefering
            LastRefering
            secondaryIDtype
            secondaryID
            npi
            fromhospital
            tohospital
          }
          diagnosisClaimInfo {
            additionalinfo
            additionalinfocode
            typecode
            transcode
            attachmentID
            outsidelab
            charge
            icd
            A
            B
            C
            D
            E
            F
            G
            H
            K
            L
            I
            J
            codeResubmit
            originalRef
            priorNumber
            cliaNumber
            claimInfo {
              fromdate
              todate
              place
              EMG
              procedureCode
              description
              modifier
              modifier2
              modifier3
              modifier4
              DiagnosisA
              DiagnosisB
              DiagnosisC
              DiagnosisD
              Charges
              units
              epsdt
              lastnameClaim
              firstnameClaim
              npiClaim
            }
          }
          billingInfo {
            federalTax
            idType
            patientAccount
            isAccept
            totalCharge
            dateSignaturePhy
            facilityname
            address1Facility
            address2Facility
            cityFacility
            stateFacility
            zipcodeFacility
            npiFacility
            phoneBilling
            firstBilling
            MiddleBilling
            LastBilling
            line1Billing
            line2Billing
            cityBilling
            stateBilling
            ZipBilling
            billingnpi
            taxonomyBilling
          }
        }
        superbill {
          _id
          ref
          superBillPdf
          insuranceRelated {
            firstname
            middlename
            lastname
            zipCode
            city
            address
            state
            relationShipType
          }
          patient {
            firstname
            middlename
            lastname
            city
            address
            state
            zipcode
          }
          editableBill {
            firstname
            middlename
            lastname
            city
            address
            state
            zipcode
          }
        }
      }
      count
    }
  }
`;
