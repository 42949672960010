import React, { useState, useRef } from 'react';
import {
  Box,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import Input from 'components/ui/Inputs';
import { useStyles } from './styles';
import Text from 'Text';

function Address(): React.ReactElement {
  const classes = useStyles();

  return (
    <Box className={classes.addressWrapper}>
      <Box className={classes.wrapperBox}>
        <Text i18nKey="Address" className={classes.titleStyle}>
          Address
        </Text>
        <Input
          variant="filled"
          type="text"
          style={{
            height: '40px',
            maxWidth: '100%',
            marginBottom: '9px',
          }}
          inputProps={{
            style: {
              padding: '10px 14px',
              fontSize: '14px',
              width: '100%',
            },
          }}
          disabled
        />
      </Box>
      <Box className={classes.wrapperBoxCountry}>
        <Box className={classes.lowerBox}>
          <Text i18nKey="City" className={classes.titleStyle}>
            City
          </Text>

          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
        <Box className={classes.lowerBox}>
          <Text i18nKey="stateCard" className={classes.titleStyle}>
            State
          </Text>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
      </Box>
      <Box className={classes.wrapperBoxCountry}>
        <Box className={classes.lowerBox}>
          <Text i18nKey="ZIP Code" className={classes.titleStyle}>
            ZIP Code
          </Text>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
        <Box className={classes.lowerBox}>
          <Text i18nKey="Country" className={classes.titleStyle}>
            Country
          </Text>
          <Input
            variant="filled"
            type="text"
            style={{
              height: '40px',
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '14px',
                width: '100%',
              },
            }}
            disabled
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Address;
