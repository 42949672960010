import React, { ReactElement } from 'react';
import { Controller, Control } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { makeStyles } from '@material-ui/core';

import { FormInput } from './FormInput';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: '100%',
    '& div.react-datepicker-wrapper': {
      width: '100%',
      zIndex: 1000000000000000,
    },
    '& .react-datepicker-popper': {
      zIndex: 50000000000000,
    },
    '& .react-datepicker__day--selected': {
      backgroundColor: '#39C38D',
      color: 'white !important',
      borderRadius: '30px',
    },
    '& .react-datepicker__day-name': {
      color: '#9CB2CD',
    },
    '& .react-datepicker__header': {
      backgroundColor: '#ffffff',
      borderBottom: '0px',
    },
    '& .react-datepicker__day': {
      color: '#9CB2CD',
    },
    '& .react-datepicker__day--outside-month': {
      color: '#D8E3F1',
    },
    '& .react-datepicker__triangle': {
      borderBottom: '1px solid #ffffff',
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before':
      {
        borderBottom: '1px solid #fbfbfb',
        display: 'none',
      },
    '& .react-datepicker__day--keyboard-selected': {
      backgroundColor: 'white !important',
    },
  },
  error: {
    color: 'red',
    fontSize: '0.75rem',
    fontWeight: 400,
    letterSpacing: '0.03333em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  nextButton: {
    border: '1px solid #DEE2EC',
    color: '#9CB2CD',
    backgroundColor: 'white',
    width: '20.7px',
    height: '20.7px',
    fontSize: '16px',
    borderRadius: '10px',
    // margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

interface ICustomDateProps {
  name: string;
  label?: string;
  style?: StyleSheetList;
  value: string | number | Date;
  onChange?: any;
  onBlur?: (e: any) => void;
  dateFormat?: string;
  showTimeSelect?: boolean;
  excludeTimes?: Date[];
  minTime?: Date;
  maxTime?: Date;
  onInputClick?: any;
}
interface IControllerCustomDateProps extends ICustomDateProps {
  control: Control;
  defaultValue?: Date;
  helperText?: any;
  error?: boolean;
}

export default function CustomTime({
  control,
  name,
  defaultValue = new Date(),
  label,
  style,
  value,
  helperText,
  error,
  ...props
}: IControllerCustomDateProps): ReactElement {
  const classes = useStyles();
  return (
    <Controller
      defaultValue={new Date(defaultValue)}
      control={control}
      name={name}
      {...props}
      render={({ onChange, onBlur, value }) => (
        <div className={classes.datePicker}>
          <DatePicker
            data-cy="date-input"
            // showTimeSelect
            customInput={
              <FormInput
                fullWidth
                data-cy="date-input"
                style={{ width: '100%', ...style }}
                variant="filled"
              />
            }
            popperProps={{
              positionFixed: true,
            }}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            name={name}
            onBlur={onBlur}
            selected={new Date(value)}
            onChange={onChange}
            {...props}
          />
          <div className={classes.error}>{error && helperText}</div>
        </div>
      )}
    />
  );
}

export function UncontrollerCustomTime({
  name,
  value,
  onChange,
  onBlur,
  dateFormat,
  style,
  showTimeSelect,
  excludeTimes,
  minTime,
  maxTime,
  onInputClick,
}: ICustomDateProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.datePicker}>
      <DatePicker
        data-cy="date-input"
        minTime={minTime as Date}
        maxTime={maxTime as Date}
        timeIntervals={60}
        excludeTimes={excludeTimes}
        customInput={
          <FormInput
            data-cy="date-input"
            fullWidth
            style={{ width: '100%', ...style }}
            variant="filled"
          />
        }
        popperProps={{
          positionFixed: true,
        }}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        showTimeSelect={showTimeSelect}
        dateFormat={dateFormat}
        name={name}
        onBlur={onBlur}
        showTimeSelectOnly
        selected={new Date(value)}
        onChange={onChange}
        onInputClick={onInputClick}
      />
    </div>
  );
}
