import { Box } from '@material-ui/core';
import React from 'react';
import Problems from './Problems';
import Allergies from './Allergies';
import History from './History';

export const RightSheet = () => {
  return (
    <Box display="flex" flexDirection="column" style={{ gap: '20px' }}>
      <Problems />
      <Allergies />
      <History />
    </Box>
  );
};
