import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Avatar, Divider } from '@material-ui/core';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import Text from 'Text';
import SearchProvider from './SearchProvider';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { ReactComponent as Unassign } from 'assets/Unassign.svg';
import { ReactComponent as NoProvider } from 'assets/NoProvider.svg';
import UnassignProvider from './UnassignProvider';
import BasicModal from 'components/ui/Modal';
import { IPatientData } from 'pages/SchedulePage/state/types';
import { useProvider as useAssignProvider } from 'pages/PatientListPage/state/Provider';
import { TEAM_MEMBERS } from 'pages/AdminPage/TabsContent/TeamSetting/teamtab/TeamSettings';
import { useQuery } from '@apollo/client';
interface Props {
  relatedProviders?: IProviderData[];
  teamMembers?: IProviderData[];
  error?: string;
  setError?: React.Dispatch<React.SetStateAction<string>>;
  patient?: any;
  isGeneralInfo?: boolean;
}

export interface IProviderData {
  assignedOn?: Date;
  firstname: string;
  middlename?: string;
  picture?: string;
  lastname: string;
  _id: string;
}

const AssignProvider: React.FC<Props> = ({ patient, isGeneralInfo }) => {
  const { control, setValue, watch, getValues } = useFormContext();

  const classes = useStyles();
  const { doctor, user, ownerUser } = useAuth();
  const { data: teamSettingData, loading } = useQuery(TEAM_MEMBERS, {
    variables: {
      role: '',
      status: '',
    },
  });

  const handleAssign = useCallback(
    (value: IProviderData) => {
      const selectedProviders = watch('selectedProviders') || [];
      const isAlreadyAssigned = selectedProviders.some(
        (provider) => provider._id === value._id
      );
      if (isAlreadyAssigned) {
        return;
      }
      const updatedProviders = [...selectedProviders, value];
      setValue('selectedProviders', updatedProviders);
    },
    [setValue, watch]
  );

  const handleUnassign = useCallback(
    (providers, selectedProvider) => {
      if (!selectedProvider || !selectedProvider._id) {
        console.error(
          'Invalid provider selected for unassignment:',
          selectedProvider
        );
        return;
      }
      const updatedProviders = providers.filter(
        (provider) => provider?._id !== selectedProvider._id
      );
      setValue('selectedProviders', updatedProviders);
    },
    [watch, setValue]
  );
  const teamMembers =
    teamSettingData?.teamSettings?.map((team) => team.doctorData) || [];

  const isAdmin = ['Admin', 'owner']?.includes(user?.role);

  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [openUnassignModal, setOpenUnassignModal] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const label = isGeneralInfo
    ? t('removeClient', { name: `${patient.firstname} ${patient.lastname}` })
    : t('unassignProvider', {
        providerName: `${watch()?.selectedProvider?.firstname} ${
          watch()?.selectedProvider?.lastname
        }`,
      });

  const description = isGeneralInfo
    ? t('removeClientDescription')
    : t('unassignProviderDescription', {
        providerName: `${watch()?.selectedProvider?.firstname} ${
          watch()?.selectedProvider?.lastname
        }`,
        patientName: `${patient?.firstname} ${patient?.lastname}`,
      });
  const columns: IColumn<IProviderData>[] = [
    {
      id: 'firstname',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Providers" className={classes.header}>
            Providers
          </Text>
        </Box>
      ),
      align: 'left',
      minWidth: 250,
      format: (value, { firstname, lastname, picture }) => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar alt={`${firstname} ${lastname}`} src={picture}>
              {`${firstname?.[0]?.toUpperCase() || ''}${
                lastname?.[0]?.toUpperCase() || ''
              }`}
            </Avatar>
            <Box pl={2} className={classes.providerName}>
              {`${firstname} ${lastname}`}
            </Box>
          </Box>
        );
      },
    },
  ];

  if (isAdmin) {
    columns.push({
      id: 'actions',
      label: '',
      minWidth: 10,
      align: 'right',
      format: (value, { firstname, lastname, picture, _id }) => (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {String(_id) !== String(ownerUser?.doctorId) && (
            <Box
              className={classes.action}
              onClick={() => {
                setOpenUnassignModal(true);
                setValue('selectedProvider', {
                  firstname,
                  lastname,
                  picture,
                  _id,
                });
              }}
            >
              <Unassign />
              <Text i18nKey="Unassign" className={classes.titleAction}>
                Unassign
              </Text>
            </Box>
          )}
        </Box>
      ),
    });
  }

  const handlePagination = (_: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <Controller
        name="selectedProviders"
        control={control}
        render={({ value }) => (
          <Box>
            <Box pt={2} pb="2rem">
              <Box className={classes.search}>
                <Text
                  i18nKey="Assign providers"
                  className={classes.assignTitle}
                >
                  Assign providers
                </Text>
                <Controller
                  name="selectedProvider"
                  control={control}
                  render={({ value, onChange }) => (
                    <SearchProvider
                      providers={teamMembers}
                      setOpenUnassignModal={setOpenUnassignModal}
                      setSelectedProvider={(value) => {
                        setValue('selectedProvider', value);
                      }}
                      relatedProviders={watch('selectedProviders')}
                      handleAssign={handleAssign}
                    />
                  )}
                />
              </Box>

              <Divider
                style={{
                  borderColor: '#D0D5DD',
                  borderWidth: '1px',
                  alignSelf: 'stretch',
                  flexGrow: 0,
                  marginBottom: '1rem',
                }}
              />

              <Box>
                <Table<IProviderData>
                  limit={limit}
                  loading={false}
                  page={page}
                  columns={columns}
                  handlePagination={handlePagination}
                  data={value || []}
                  EmptyState={
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                    >
                      <NoProvider />
                      <Text i18nKey="No providers yet">No providers yet</Text>
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
      />

      <BasicModal
        open={openUnassignModal}
        onClose={() => setOpenUnassignModal(false)}
        isRegularModal
        isIcon={false}
        title=""
        handleClose={() => setOpenUnassignModal(false)}
      >
        <UnassignProvider
          label={label}
          description={description}
          deleteBtn="Unassign"
          handleClose={() => setOpenUnassignModal(false)}
          selectedProvider={watch()?.selectedProvider}
          handleDelete={() => {
            handleUnassign(
              watch('selectedProviders'),
              watch('selectedProvider')
            );
            setOpenUnassignModal(false);
          }}
        />
      </BasicModal>
    </>
  );
};

export default AssignProvider;
