import {
  Box,
  Typography,
  makeStyles,
  Theme,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import BasicModal from 'components/ui/Modal';
import dayjs from 'dayjs';
import React from 'react';
import { ReactComponent as CancelIcon } from 'assets/x-close.svg';
import VitalsModal from './VitalsModal';
import AddVitalsModal from './AddVitalsModal';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
    padding: '1rem',
    minHeight: '100px',
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4,0,0.2,1)',
    transitionDuration: '0.3s',
    position: 'relative',
    '&:hover': {
      border: '1px solid #425364',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
    color: '#494B5A',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'blue',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#494B5A',
  },
  date: {
    fontSize: '14px',
    color: '#8E96A4',
  },
  value: {
    fontSize: '14px',
    color: '#494b5A',
    fontWeight: 600,
  },
  unit: {
    fontSize: '10px',
    color: '#6E7787',
    fontWeight: 500,
    marginLeft: '5px',
  },
  name: {
    fontSize: '14px',
    color: '#8E96A4',
    [theme.breakpoints.only('lg')]: {
      fontSize: '12px',
    },
  },
}));
const Vitals = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const isLarge = useMediaQuery(theme.breakpoints.only('lg'), {
    defaultMatches: true,
  });
  const [open, setOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const vitalsArray = {
    1: [
      {
        name: 'Temperature',
        unit: '°F',
        value: '100',
        color: 'red',
        key: 'temperature',
      },
      { name: 'Bmi', unit: 'kg/m²', value: '27.89', color: 'blue', key: 'bmi' },
      {
        name: 'Respiratory rate',
        unit: 'BPM',
        value: '49',
        color: 'green',
        key: 'respiratory',
      },
      {
        name: 'Blood glucose level',
        unit: 'mg/dL',
        value: '34',
        color: 'yellow',
        key: 'blood_glucose',
      },
    ],
    2: [
      {
        name: 'Blood pressure',
        unit: 'mmHg',
        value: '120 / 80',
        color: 'purple',
        key: 'blood_pressure',
      },
      {
        name: 'Head circumference',
        unit: 'cm',
        value: '20',
        color: 'pink',
        key: 'head_circumference',
      },
      {
        name: 'Oxygen saturation',
        unit: 'Sp02',
        value: '99',
        color: 'orange',
        key: 'oxigen',
      },
    ],
    3: [
      {
        name: 'Height',
        unit: null,
        value: `5'11"`,
        color: 'black',
        key: 'height',
      },
      {
        name: 'Waist curcumference',
        unit: 'cm',
        value: '60',
        color: 'grey',
        key: 'waist',
      },
      {
        name: 'Pain scale',
        unit: '0-10',
        value: '5',
        color: 'pink',
        key: 'pain',
      },
    ],
    4: [
      {
        name: 'Weight',
        unit: 'lbs',
        value: '200',
        color: 'purple',
        key: 'weight',
      },
      {
        name: 'Heart rate',
        unit: 'BPM',
        value: '87',
        color: 'red',
        key: 'heart',
      },
      {
        name: 'Peak flow measurement',
        unit: 'L/min',
        value: '23',
        color: 'yellow',
        key: 'peak',
      },
    ],
  };
  return (
    <Box position="relative">
      <Box className={classes.root} onClick={() => setOpen(true)}>
        <Box position="absolute" top={15} right={20}>
          <Typography className={classes.date}>{`Last taken at ${dayjs(
            new Date()
          ).format('MM-DD-YYYY')} `}</Typography>
        </Box>
        <Typography className={classes.title}>Vitals</Typography>
        <Box mt={2}>
          <Grid container>
            <Grid item xl={3} lg={3}>
              {vitalsArray['1']?.map((vital) => (
                <Box mb={'2rem'}>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.value}>
                      {vital?.value}
                    </Typography>
                    <Typography className={classes.unit}>
                      ({vital?.unit})
                    </Typography>
                  </Box>
                  <Typography className={classes.name}>
                    {vital?.name}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xl={3} lg={3}>
              {vitalsArray['2']?.map((vital) => (
                <Box mb={'2rem'}>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.value}>
                      {vital?.value}
                    </Typography>
                    <Typography className={classes.unit}>
                      ({vital?.unit})
                    </Typography>
                  </Box>
                  <Typography className={classes.name}>
                    {vital?.name}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xl={3} lg={3}>
              {vitalsArray['3']?.map((vital) => (
                <Box mb={'2rem'}>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.value}>
                      {vital?.value}
                    </Typography>
                    {vital?.unit && (
                      <Typography className={classes.unit}>
                        ({vital?.unit})
                      </Typography>
                    )}
                  </Box>
                  <Typography className={classes.name}>
                    {vital?.name}
                  </Typography>
                </Box>
              ))}
            </Grid>
            <Grid item xl={3} lg={3}>
              {vitalsArray['4']?.map((vital) => (
                <Box mb={'2rem'}>
                  <Box display="flex" alignItems="center">
                    <Typography className={classes.value}>
                      {vital?.value}
                    </Typography>
                    <Typography className={classes.unit}>
                      ({vital?.unit})
                    </Typography>
                  </Box>
                  <Typography className={classes.name}>
                    {vital?.name}
                  </Typography>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <BasicModal
        open={open}
        handleClose={() => {
          setOpen(false);
          setIsExpanded(false);
        }}
        onClose={() => {
          setOpen(false);
          setIsExpanded(false);
        }}
        isRegularModal
        isSlide
        divider
        title={'Vitals'}
        titlePadding={'2rem'}
      >
        <>
          {isExpanded && (
            <Box
              position="absolute"
              right={isLarge ? '58vw' : '48vw'}
              top={0}
              py={'1.2rem'}
            >
              <CancelIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setIsExpanded(false)}
              />
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              width: isExpanded
                ? isLarge
                  ? '90vw'
                  : '70vw'
                : isLarge
                ? '57vw'
                : '47vw',
            }}
            style={{
              transition: 'width 0.3s ease',
            }}
          >
            <Box
              sx={{
                width: isExpanded ? (isLarge ? '33vw' : '23vw') : '0',
                overflow: 'hidden',
                borderRight: isExpanded ? '1px solid #EAECF0' : 'none',
                position: 'relative',
                height: '100vh',
                // backgroundColor: '#fff',
              }}
              style={{
                transition: 'width 0.3s ease',
              }}
            >
              {isExpanded && (
                <AddVitalsModal {...props} handleExpand={setIsExpanded} />
              )}
            </Box>

            <Box
              sx={{
                width: isLarge ? '57vw' : '47vw',
                flexShrink: 0,
                overflow: 'scroll',
                maxHeight: '90vh',
              }}
            >
              <VitalsModal
                handleExpand={setIsExpanded}
                {...props}
                vitalsArray={vitalsArray}
              />
            </Box>
          </Box>
        </>
      </BasicModal>
    </Box>
  );
};

export default Vitals;
