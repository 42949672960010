import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  BoxProps,
  Theme,
  Input,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from 'clsx';
import Card from './Card';
import { useProvider } from './state/Provider';
import Skeleton from 'components/ui/Skeleton';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import Button from 'components/ui/Button';
import { ReactComponent as AddIcon } from 'assets/icons/Icon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluswhite.svg';
import { ReactComponent as ToDo } from 'assets/icons/ToDo.svg';
import { ReactComponent as InProgress } from 'assets/icons/InProgress.svg';
import { ReactComponent as Done } from 'assets/icons/Done.svg';
import useCheckPermission from 'contexts/PermissionContext';

interface StyleProps {
  type: 'overdue' | 'pending' | 'completed'; // Define possible types
}
const backgroundColors = {
  overdue: '#3892F3',
  pending: '#F79009',
  done: '#079455',
};
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {},
  inner: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F9FAFB',
    maxHeight: 'calc(100vh - 170px)',
    // overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
  },
  title: ({ type }) => ({
    color: 'white',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Inter',
    marginBottom: '15px',
    backgroundColor: backgroundColors[type] || backgroundColors.done,
    width: '100%',
    display: 'inline-block',
    borderRadius: '10px',
    lineHeight: '20px',
    float: 'left',
    padding: '1.5%',
  }),
  addTask: {
    cursor: 'pointer',
    opacity: '0.8',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.black.main,
  },
  droppableArea: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    // paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: '24px',
    minHeight: '1px',
  },
  menu: {
    width: 240,
  },
  listName: {
    // backgroundColor: '#F9FAFB',
    // paddingBottom: theme.spacing(2),
    // paddingTop: theme.spacing(2),
    // paddingLeft: "16px",
    alignItems: 'center',
    padding: '0px 24px 8px',
    justifyContent: 'center',
    color: 'white',
    borderRadius: '8px',
  },
  addTaskText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    marginRight: '10px',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '20px',
  },
  addTaskBtn: {
    backgroundColor: '#EAECF0',
    color: '#667085',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '36px',
    '&:hover': {
      backgroundColor: '#D0D5DD !important',
    },
    '&:click': {
      backgroundColor: '#D0D5DD !important',
    },
  },
  addIcon: {
    marginRight: '10px',
  },
}));

interface IKanbanListProps extends BoxProps {
  listId: string;
  list: any;
  isDashboard: any;
  className?: string;
  handleOpenAddTaskModal: () => void;
  loading?: boolean;
}

export const convertString = {
  overdue: 'To do',
  pending: 'In progress',
  completed: 'Done',
};

export default function List({
  className,
  listId,
  list,
  isDashboard,
  handleOpenAddTaskModal,
  loading = false,
  ...rest
}: IKanbanListProps) {
  const classes = useStyles({ type: list?.name });
  const { handleViewTask, handleDeleteTask } = useProvider();
  const { t } = useTranslation();

  const [hoveredCardId, setHoveredCardId] = React.useState<string | null>('');

  const handleMouseEnter = (cardId: string) => {
    setHoveredCardId(cardId);
  };

  const handleMouseLeave = () => {
    setHoveredCardId('');
  };
  const { isEnabled, loading: loadingAccess } = useCheckPermission(
    'Tasks',
    'edit',
    hoveredCardId
  );
  return (
    <Skeleton loading={loading}>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Paper elevation={0} className={classes.inner}>
          <Box
            py={1}
            px={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="row"
            className={classes.listName}
            style={
              list.name === 'overdue'
                ? { backgroundColor: '#F8F9FC', marginTop: '8px' }
                : list.name === 'pending'
                ? { backgroundColor: '#FFFCF5', marginTop: '8px' }
                : { backgroundColor: '#F6FEF9', marginTop: '8px' }
            }
          >
            <Typography className={classes.title}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  {' '}
                  {list.name === 'overdue' ? (
                    <ToDo />
                  ) : list.name == 'pending' ? (
                    <InProgress />
                  ) : (
                    <Done />
                  )}{' '}
                  {` `}
                  {`${t(convertString[list?.name])} (${list?.tasks?.length})`}
                </div>
                <PlusIcon
                  style={{ cursor: 'pointer' }}
                  onClick={handleOpenAddTaskModal}
                />
              </div>
            </Typography>
          </Box>
          <Droppable droppableId={list._id} type="card">
            {(provided: any) => (
              <div
                ref={provided.innerRef}
                className={classes.droppableArea}
                style={
                  list.name === 'overdue'
                    ? { backgroundColor: '#F8F9FC' }
                    : list.name === 'pending'
                    ? { backgroundColor: '#FFFCF5' }
                    : { backgroundColor: '#F6FEF9' }
                }
              >
                {list.tasks.map((card: any, index: any) => (
                  <div>
                    <Draggable
                      draggableId={card._id}
                      index={index}
                      key={card._id}
                      isDragDisabled={!isEnabled}
                    >
                      {(provided: any, snapshot: any) => (
                        <div
                          onMouseEnter={() => handleMouseEnter(card._id)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Card
                            handleDelete={handleDeleteTask}
                            onClick={() => {
                              handleViewTask(card);
                            }}
                            cardId={card._id}
                            dragging={snapshot.isDragging}
                            index={index}
                            list={list}
                            data={card}
                            ref={provided.innerRef}
                            style={{ ...provided.draggableProps.style }}
                            data-cy={`task-card-${index}`}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDashboard={isDashboard}
                          />
                        </div>
                      )}
                    </Draggable>
                  </div>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Box
            py={1}
            px={3}
            display="flex"
            alignItems="flex-start"
            flexDirection="row"
            className={classes.listName}
            style={
              list.name === 'overdue'
                ? list?.tasks?.length > 0
                  ? {
                      backgroundColor: '#F8F9FC',
                      padding: '16px 16px 8px 16px',
                    }
                  : {
                      backgroundColor: '#F8F9FC',
                    }
                : list.name === 'pending'
                ? list?.tasks?.length > 0
                  ? {
                      backgroundColor: '#FFFCF5',
                      padding: '16px 16px 8px 16px',
                    }
                  : { backgroundColor: '#FFFCF5' }
                : list?.tasks?.length > 0
                ? { backgroundColor: '#F6FEF9', padding: '16px 16px 8px 16px' }
                : { backgroundColor: '#F6FEF9' }
            }
          >
            <Button
              onClick={handleOpenAddTaskModal}
              className={classes.addTaskBtn}
            >
              <AddIcon className={classes.addIcon} />{' '}
              <p className={classes.addTaskText}>{t('addTask')}</p>
            </Button>
          </Box>
        </Paper>
      </Box>
    </Skeleton>
  );
}

List.defaultProps = {
  className: '',
};
