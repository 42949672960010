import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { TranslationKeys } from 'shared/interfaces/i18';
import { useStyles } from './index';
import { useTranslation } from 'react-i18next';

interface ICustomRadioGroup {
  name: string;
  control?: any;
  options: { name: string; value: number }[];
  ariaLabel: string;
  style?: any;
}
const CustomRadioGroup = ({
  name,
  control,
  options,
  ariaLabel,
  style,
}: ICustomRadioGroup) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Box ml={1}>
      <Controller
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <FormControl component="fieldset">
            <RadioGroup aria-label={ariaLabel || name} row style={style}>
              {options.map((el, key) => (
                <FormControlLabel
                  key={key}
                  value={el.value}
                  control={
                    <Radio
                      className={classes.rootRadio}
                      color="default"
                      disableRipple
                      checkedIcon={
                        <span
                          className={clsx(
                            classes.iconRadio,
                            classes.checkedIconRadio
                          )}
                        />
                      }
                      icon={<span className={classes.iconRadio} />}
                      data-cy={`${el.name.toLowerCase()}-input`}
                      inputProps={{ 'aria-label': el.name }}
                      checked={value === el.value}
                      onChange={(e) => onChange(Number(e.target.value))}
                      value={el.value}
                    />
                  }
                  label={
                    <Typography
                      className={classes.radioLabel}
                      style={{
                        fontWeight: value === el.value ? 700 : 500,
                      }}
                    >
                      {t(`${el.name as TranslationKeys}`)}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default CustomRadioGroup;
