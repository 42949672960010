import React, { ReactElement, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { useStyles } from './style';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
const Password = React.lazy(() => import('./TabsContent/Password'));
const EmailNotifications = React.lazy(
  () => import('./TabsContent/EmailNotifications')
);
const PersonnelInformation = React.lazy(
  () => import('./TabsContent/PersonalInformation')
);
import { useHeaderPageNameDispatch } from '../../components/Layout/HeaderPageNameConext';
import { Box } from '@material-ui/core';
import { useToast } from 'hooks/useToast';
const Staff = React.lazy(() => import('./TabsContent/TeamSetting/Staff'));
const WorkingHours = React.lazy(() => import('./TabsContent/WorkingHours'));
const ManageTags = React.lazy(() => import('./ManageTags'));
const ManageTemplates = React.lazy(
  () => import('./TabsContent/ManageTemplates')
);
import { useLocation, useHistory } from 'react-router-dom';
const Language = React.lazy(() => import('./Language'));
import { useTranslation } from 'react-i18next';
const Payment = React.lazy(
  () => import('pages/AdminPage/TabsContent/BillingInformation')
);
const Subscription = React.lazy(() => import('./TabsContent/Subscription'));
import SideBar from './SideBar';
const InsuranceSettings = React.lazy(() => import('./InsuranceSettings'));
const Payouts = React.lazy(() => import('./Payment/Payouts'));
const Rates = React.lazy(() => import('./Payment/Rates'));
const DemoClientPage = React.lazy(() => import('./TabsContent/DemoClientPage'));
const CalendarSync = React.lazy(() => import('./TabsContent/CalendarSync'));
const PhysicalLocation = React.lazy(
  () => import('./TabsContent/PhysicalLocation/PhysicalLocation')
);
const PracticeInformation = React.lazy(
  () => import('./TabsContent/PracticeInformation')
);
const Demographics = React.lazy(() => import('./TabsContent/Demographics'));

export enum REDIRECT {
  payment = 'paymentsettings',
  notification = 'Notification',
  workingHours = 'workingHours',
}
const AdminPage = (): ReactElement => {
  const { dataSubscription, loadingSubscription, user } = useAuth();
  const isSubscribed = dataSubscription?.subscription;
  const history = useHistory();
  const { openSuccess } = useToast();
  const classes = useStyles({ openSuccess });
  const { t } = useTranslation();
  const headerTitleDispatcher = useHeaderPageNameDispatch();
  useEffect(() => {
    headerTitleDispatcher({ type: 'setTitle', payload: 'Settings' });
  }, [headerTitleDispatcher]);

  const [value, setValue] = React.useState(0);
  const location = useLocation<{
    tab: number;
    subTab: number;
  }>();
  useEffect(() => {
    if (location.state?.tab) {
      setCustomValue(location.state?.tab);
      setValue(location.state?.subTab);
    }
  }, [location.state]);
  const queryParams = new URLSearchParams(location.search);
  const redirectStatus = queryParams.get('query');
  const tabSelected = queryParams.get('tab');
  const [customValue, setCustomValue] = React.useState(
    tabSelected ? Number(tabSelected) : 0
  );
  React.useEffect(() => {
    if (tabSelected && Number(tabSelected) !== customValue) {
      setCustomValue(Number(tabSelected));
    }
  }, [tabSelected]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (customValue !== null && customValue !== undefined) {
        history.push(`/doctor/admin?tab=${customValue}`);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [customValue]);

  useEffect(() => {
    if (redirectStatus === REDIRECT.payment) {
      setCustomValue(5);
      setValue(2);
    }
    if (redirectStatus === REDIRECT.notification) {
      setCustomValue(3);
    }
    if (redirectStatus === REDIRECT.workingHours) {
      setCustomValue(2);
    }
  }, [redirectStatus]);

  const menuData = [
    {
      title: 'YOUR ACCOUNT',
      items: [
        {
          title: 'Contact information',
          content: <PersonnelInformation />,
          disabled: isSubscribed ? false : true,
          index: 0,
        },
        {
          title: 'Demographics',
          content: <Demographics />,
          disabled: false,
          index: 18,
        },
        {
          title: 'Login and security',
          content: <Password />,
          disabled: false,
          index: 1,
        },
        {
          title: 'Regional settings',
          content: <Language />,
          disabled: false,
          index: 9,
        },
        {
          title: 'Notifications',
          content: <EmailNotifications />,
          disabled: isSubscribed ? false : true,
          index: 3,
        },
      ],
    },
    {
      title: 'PRACTICE SETTINGS',
      items: [
        {
          title: 'Practice information',
          content: <PracticeInformation isPracticeSetting />,
          disabled: isSubscribed ? false : true,
          index: 5,
        },
        {
          title: 'Working hours',
          content: <WorkingHours />,
          disabled: isSubscribed ? false : true,
          index: 2,
        },
        {
          title: 'Calendar sync',
          content: <CalendarSync />,
          disabled: isSubscribed ? false : true,
          index: 17,
        },
        {
          title: 'Booking configuration',
          content: <></>,
          disabled: true,
          index: -1,
        },
        {
          title: 'Services',
          content: <Rates />,
          disabled: isSubscribed ? false : true,
          index: 14,
        },
        {
          title: 'Team settings',
          content: <Staff />,
          disabled:
            isSubscribed && ['Admin', 'owner']?.includes(user?.role)
              ? false
              : true,
          index: 4,
        },
        {
          title: 'Physical locations',
          content: <PhysicalLocation />,
          disabled: isSubscribed ? false : true,
          index: 20,
        },
        {
          title: 'Demo client',
          content: <DemoClientPage />,
          disabled: isSubscribed ? false : true,
          index: 12,
        },
      ],
    },
    {
      title: 'CONFIGURATIONS',
      items: [
        {
          title: 'Templates',
          content: <ManageTemplates />,
          disabled: isSubscribed ? false : true,
          index: 7,
        },
        {
          title: 'Tags',
          content: <ManageTags />,
          disabled: isSubscribed ? false : true,
          index: 6,
        },
      ],
    },
    {
      title: 'PAYMENT',
      items: [
        {
          title: 'Subscription',
          content: (
            <Subscription
              data={dataSubscription}
              loading={loadingSubscription}
            />
          ),
          disabled: ['owner']?.includes(user?.role) ? false : true,
          index: 15,
        },
        {
          title: 'Payment settings',
          content: <Payment />,
          disabled: ['owner']?.includes(user?.role) ? false : true,
          index: 16,
        },
        {
          title: 'Payouts',
          content: <Payouts />,
          disabled: isSubscribed ? false : true,
          index: 13,
        },
        {
          title: 'Insurance',
          content: <InsuranceSettings />,
          disabled: isSubscribed ? false : true,
          index: 21,
        },
        {
          title: 'Billing',
          content: <></>,
          disabled: true,
          index: -1,
        },
      ],
    },
  ];

  return (
    <Box
      style={{
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <SideBar
        menuData={menuData}
        setCustomValue={setCustomValue}
        customValue={customValue}
        loadingSubscription={loadingSubscription}
      />
    </Box>
  );
};
export default AdminPage;

export const GET_CURRENT_USER = gql`
  query user($userId: ID!) {
    user(userId: $userId) {
      _id
      firstname
      middlename
      lastname
      email
      country
      phoneNumber
      city
      address
      zipcode
      birthday
      password
      description
      MartialStatus
      gender
      streetadress2
      streetadress1
      provinceState
      emailNotification
      smsNotification
      desktopAlert
      sendNotificationtoConfirm
      youhavenewnotification
      youresentadirectmessage
      someoneaddsyouasaconnection
      whentoescalateemails
      uponneworder
      newmembershipapproval
      memberregistration
      passwordChanged
      language
      isOnboarded
      onboardingStep
    }
  }
`;
export const GET_CREDIT_CARD = gql`
  query userCreditCard($userid: ID!) {
    userCreditCard(userid: $userid) {
      _id
      nameOnCard
      cardNumber
      expirationCard
      defaultCard
      cardZipCode
      securityCode
      FirstName
      MiddleName
      month
      year
      LastName
      Phone
      Email
      StreetAddress1
      StreetAddress2
      City
      ZipCode
      Country
      ProvinceState
      selectedCreditCard
      user {
        firstname
        lastname
        _id
      }
    }
  }
`;
