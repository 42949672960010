export const humanReadableSize = (size: number) => {
  const units = ['B', 'KB', 'MB', 'GB'];
  let i = 0;
  while (size >= 1024) {
    size /= 1024;
    i++;
  }
  return `${size?.toFixed(2)} ${units[i]}`;
};
export const generateURLFromFile = (file) => {
  const url = URL.createObjectURL(file);
  return url;
};
