import React from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import useAddTag from '@eggmed/graphql-client/operations/tagsOperation/useAddTag';
import useEditTag from '@eggmed/graphql-client/operations/tagsOperation/useEditTag';
import useRemoveTag from '@eggmed/graphql-client/operations/tagsOperation/useRemoveTag';

export function useTag(isRendered = true) {
  const [dropDownEL, setDropDownEl] = React.useState<null | HTMLElement>(null);
  const [currentRow, setCurrentRow] = React.useState();
  const [selectedColor, setSelectedColor] = React.useState<string>('');
  const handleClickDrop = (event: any, row: any) => {
    setDropDownEl(event.currentTarget);
    setCurrentRow(row);
    setSelectedColor(row.color);
  };
  const [editMode, setEditMode] = React.useState(false);
  const { addTag, loading: createLoading } = useAddTag();
  const { removeTag, loading: deleteLoading } = useRemoveTag();
  const { editTag, loading: updateLoading } = useEditTag();
  const [loadTags, { data, loading: loadingTags }] = useLazyQuery(DOCTOR_TAGS);

  React.useEffect(() => {
    if (isRendered) {
      loadTags();
    }
  }, [loadTags, isRendered]);
  async function handleDelete() {
    await removeTag({
      variables: {
        tagId: (currentRow as { _id: string })?._id,
      },
    });
    setDropDownEl(null);
    return setopenDelete(false);
  }
  const [open, setOpen] = React.useState(false);
  const [openDelete, setopenDelete] = React.useState(false);
  function handleClick() {
    setOpen(true);
    setEditMode(false);
    setSelectedColor('');
  }
  function handleClose() {
    setOpen(false);
  }
  const handleEdit = () => {
    setOpen(true);
    setEditMode(true);
    setDropDownEl(null);
  };

  return {
    dropDownEL,
    setDropDownEl,
    currentRow,
    handleClickDrop,
    editMode,
    addTag,
    createLoading,
    data,
    handleDelete,
    open,
    openDelete,
    setopenDelete,
    handleClick,
    handleClose,
    handleEdit,
    editTag,
    updateLoading,
    deleteLoading,
    selectedColor,
    setSelectedColor,
    loadingTags,
  };
}

export const DOCTOR_TAGS = gql`
  query doctorTags {
    doctorTags {
      _id
      tag
      color
      doctor {
        firstname
        _id
        lastname
      }
    }
  }
`;
