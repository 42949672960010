import { gql, useQuery } from '@apollo/client';

const GET_HIGHLIGHTED_TIMES = gql`
  query highlightedTimes($doctor: ID, $monthName: String, $year: Int) {
    highlightedTimes(doctor: $doctor, monthName: $monthName, year: $year) 
  }
`;
export default function useHighlightedTimes({ doctor, monthName, year }) {
  const { data, error, loading } = useQuery(GET_HIGHLIGHTED_TIMES, {
    variables: {
      doctor,
      monthName,
      year,
    },
  });

  return { data, error, loading };
}
export { GET_HIGHLIGHTED_TIMES };
