import { Box, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from '../styles';
import clsx from 'clsx';
import Table from 'components/ui/Table';
import { IColumn } from 'components/ui/Table/types';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { currencySymbols } from './Steps';
import { ReactComponent as DashIcon } from 'assets/dashboard/finances/dash.svg';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import { dayjsFormat } from 'utils';

export interface IInvoiceRowProps {
  date: string | React.ReactNode;
  description: string | React.ReactNode;
  amount: string | React.ReactNode;
}
interface IInvoicePreviewProps {
  isCurrent: boolean;
  invoiceRef: number;
}

export const InvoicePreview = ({
  isCurrent = false,
  invoiceRef,
}: IInvoicePreviewProps) => {
  const classes = useStyles();
  const { watch } = useFormContext();
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language || 'en');

  const columns: IColumn<IInvoiceRowProps>[] = [
    {
      id: 'date',
      label: <Box fontSize="1rem">Date</Box>,
      freeze: true,
    },
    {
      id: 'description',
      label: <Box fontSize="1rem">Description</Box>,
    },
    {
      id: 'amount',
      label: <Box fontSize="1rem">{t('Amount')}</Box>,
      freeze: true,
    },
  ];

  const data: IInvoiceRowProps[] = [
    {
      date: (
        <Box className={classes.row}>
          {dayjsFormat(
            new Date(watch('issueDate')),
            i18n.language === 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
          ) ?? <DashIcon />}
        </Box>
      ),
      description: (
        <Box className={classes.row}>
          {watch('sessionType')?.duration ? (
            `${watch('sessionType')?.duration} min, ${
              watch('sessionType')?.session
            }`
          ) : (
            <DashIcon />
          )}
        </Box>
      ),
      amount: (
        <Box className={classes.row}>
          {watch('sessionType')?.duration ? (
            `${currencySymbols[watch('sessionType')?.currency]}${watch(
              'amount'
            )}`
          ) : (
            <DashIcon />
          )}
        </Box>
      ),
    },
  ];

  const balanceDue: number | undefined =
    parseInt(watch('amount')) - parseInt(watch('amountPaid'));

  return (
    <Box
      style={{
        visibility: isCurrent ? 'visible' : 'hidden',
        height: isCurrent ? 'auto' : 0,
        overflow: !isCurrent && 'hidden',
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mt={2}
        mb={6}
      >
        <Box flex={1}>
          <Typography className={classes.previewTitle}>
            {t('Invoice')}
          </Typography>
        </Box>
        <Box flex={1} maxWidth={250}>
          <Box className={classes.previewDetail}>
            <Typography className={classes.previewLabel}>
              {t('Invoice no:')}
            </Typography>
            <Typography className={classes.previewText}>
              {invoiceRef}
            </Typography>
          </Box>
          <Box className={classes.previewDetail}>
            <Typography className={classes.previewLabel}>
              {t('Issued')}:
            </Typography>
            <Typography className={classes.previewText}>
              {dayjsFormat(
                new Date(watch('issueDate')),
                i18n.language === 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
              ) ?? <DashIcon />}
            </Typography>
          </Box>
          <Box className={classes.previewDetail}>
            <Typography className={classes.previewLabel}>
              {t('Due')}:
            </Typography>
            <Typography className={classes.previewText}>
              {dayjsFormat(
                new Date(watch('dueDate')),
                i18n.language === 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'
              ) ?? <DashIcon />}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={6}
      >
        <Box flex={1}>
          <Typography className={classes.accordionTitle}>
            {t('From')}
          </Typography>
          <Typography className={classes.name}>
            {watch('nameOnInvoice') ?? ''}
          </Typography>
          <Typography className={classes.common}>
            {watch('doctorEmail') ?? ''}
          </Typography>
          <Typography className={classes.common}>
            {watch('doctorPhone') ?? ''}
          </Typography>
          <Typography className={classes.common}>
            {`${watch('doctorAddress') ? watch('doctorAddress') + ', ' : ''}${
              watch('doctorSuite') ? watch('doctorSuite') + ', ' : ''
            }${
              watch('doctorState')
                ? watch('doctorCountry')
                  ? watch('doctorState') + ', '
                  : watch('doctorState')
                : ''
            }${watch('doctorCountry') ?? ''}`}
          </Typography>
        </Box>
        <Box flex={1}>
          <Typography className={classes.accordionTitle}>
            {t('Bill to')}
          </Typography>
          {watch('patient') ? (
            <>
              <Typography className={classes.name}>{`${
                watch('patient')?.firstname ?? ''
              } ${watch('patient')?.lastname ?? ''}`}</Typography>
              <Typography className={classes.common}>
                {watch('patientEmail') ?? ''}
              </Typography>
              <Typography className={classes.common}>
                {watch('patientPhone') ?? ''}
              </Typography>
              <Typography className={classes.common}>
                {watch('patientAddress') ? watch('patientAddress') + ', ' : ''}
                {watch('patientSuite') ? watch('patientSuite') + ', ' : ''}
                {watch('patientState')
                  ? watch('patientCountry')
                    ? watch('patientState') + ', '
                    : watch('patientState')
                  : ''}
                {watch('patientCountry') ?? ''}
              </Typography>
            </>
          ) : (
            <Typography className={classes.noClient}>
              {t('Please select a client')}
            </Typography>
          )}
        </Box>
      </Box>
      <Box mb={6}>
        <Table<any>
          loading={false}
          page={1}
          columns={columns}
          limit={1}
          data={data}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Box flex={1} maxWidth={220}>
          <Box className={classes.balanceRow}>
            <Typography className={classes.balanceLabel}>
              {t('Subtotal')}
            </Typography>
            <Typography className={classes.previewText}>
              {watch('sessionType') ? (
                `${currencySymbols[watch('sessionType')?.currency]}${watch(
                  'amount'
                )}`
              ) : (
                <DashIcon />
              )}
            </Typography>
          </Box>
          <Box className={classes.balanceRow}>
            <Typography className={classes.balanceLabel}>Total</Typography>
            <Typography className={classes.previewText}>
              {watch('sessionType') ? (
                `${currencySymbols[watch('sessionType')?.currency]}${watch(
                  'amount'
                )}`
              ) : (
                <DashIcon />
              )}
            </Typography>
          </Box>
          <Box className={classes.balanceRow}>
            <Typography className={classes.previewLabel}>
              {t('Amount paid')}
            </Typography>
            <Typography className={classes.previewText}>
              {currencySymbols[watch('sessionType')?.currency]}
              {watch('amountPaid')}
            </Typography>
          </Box>
          <Box className={clsx(classes.balanceRow, classes.balanceDue)}>
            <Typography className={classes.balanceDueLabel}>
              {t('Balance due')}
            </Typography>
            <Typography className={classes.balanceDueText}>
              {currencySymbols[watch('sessionType')?.currency]}
              {balanceDue}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
