import { MutationResult, useMutation } from '@apollo/client';
import { EDIT_APPOINTMENT } from '../../queries/appointment';

interface IUseEditReturn extends MutationResult<any> {
  updateAppointment: (
    id: string,
    data: any,
    sideEffectCallback: (e: Error) => void,
    isOne?: boolean
  ) => Promise<any>;
  editAppointmentWithoutUpdate: (
    id: string,
    data: any,
    sideEffectCallback: (e: Error) => void,
    successCallback?: () => void
  ) => Promise<any>;
}
export default function useEditAppointment(): IUseEditReturn {
  const [editAppointment, result] = useMutation(EDIT_APPOINTMENT, {
    update: (cache, { data: { editAppointment: editAppointmentResult } }) => {
      cache.modify({
        fields: {
          appointmentsByDate(appointments) {
            const editedAppointments = appointments?.result?.map(
              (appointment: any) => {
                if (appointment?._id === editAppointmentResult?._id)
                  return editAppointmentResult;
                return appointments;
              }
            );
            return { ...appointments, result: editedAppointments };
          },
          doctorUpcomingAppointments(upcomingAppointment) {
            return [...upcomingAppointment?.result, editAppointmentResult];
          },
          conflictsAppointments(appointments) {
            return [...appointments?.result, editAppointmentResult];
          },
          availableTimes(times) {
            const startDate = new Date(editAppointmentResult?.startDate);
            const endDate = new Date(editAppointmentResult?.endDate);

            const doctorFormatter = new Intl.DateTimeFormat('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
              timeZone: editAppointmentResult?.doctor?.timeZone,
            });

            const doctorFormattedDateStart = doctorFormatter.format(startDate);
            const doctorFormattedDateEnd = doctorFormatter.format(endDate);

            const existsBeforeStart = times?.some(
              (el) => el < doctorFormattedDateStart
            );
            const existsAfterEnd = times?.some(
              (el) => el > doctorFormattedDateEnd
            );

            if (!existsBeforeStart) {
              return times?.filter((el) => el > doctorFormattedDateEnd);
            } else if (!existsAfterEnd) {
              return times?.filter((el) => el < doctorFormattedDateStart);
            } else {
              return times?.filter(
                (el) =>
                  el < doctorFormattedDateStart || el > doctorFormattedDateEnd
              );
            }
          },
          appointmentSlide(appointments) {
            return [...appointments?.appointments, editAppointmentResult];
          },
        },
      });
    },
  });
  const [editAppointmentWithoutRender] = useMutation(EDIT_APPOINTMENT, {
    ignoreResults: true,
    update: (cache, { data: { editAppointment: editAppointmentResult } }) => {
      cache.modify({
        fields: {
          appointmentsByDate(appointments) {
            const editedAppointments = appointments?.result?.map(
              (appointment: any) => {
                if (appointment?._id === editAppointmentResult?._id)
                  return editAppointmentResult;
                return appointments;
              }
            );
            return { ...appointments, result: editedAppointments };
          },
          doctorUpcomingAppointments(upcomingAppointment) {
            return [...upcomingAppointment?.result, editAppointmentResult];
          },
          conflictsAppointments(appointments) {
            return [...appointments?.result, editAppointmentResult];
          },
          availableTimes(times) {
            const date = new Date(editAppointmentResult?.startDate);
            const hour = date.getHours().toString().padStart(2, '0');
            const minute = date.getMinutes().toString().padStart(2, '0');
            const formattedTime = hour + ':' + minute;
            return times?.filter((el) => el !== formattedTime);
          },
          appointmentSlide(appointments) {
            return [...appointments?.appointments, editAppointmentResult];
          },
        },
      });
    },
  });

  async function updateAppointment(
    id: string,
    data: any,
    sideEffectCallback: (e: Error) => void,
    isOne = true
  ) {
    try {
      const resultEditAppointment = await editAppointment({
        variables: { appointmentId: id, appointmentInput: data, isOne },
      });
      return resultEditAppointment;
    } catch (e) {
      return sideEffectCallback(e as Error);
    }
  }
  async function editAppointmentWithoutUpdate(
    id: string,
    data: any,
    sideEffectCallback: (e: Error) => void,
    successCallback?: () => void
  ) {
    try {
      const resultEditAppointment = await editAppointmentWithoutRender({
        variables: { appointmentId: id, appointmentInput: data, isOne: true },
      });
      successCallback && successCallback();
      return resultEditAppointment;
    } catch (e) {
      return sideEffectCallback(e as Error);
    }
  }
  return {
    updateAppointment,
    editAppointmentWithoutUpdate,
    ...result,
  };
}
