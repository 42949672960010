import { Box, TextField, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './styles';
import ErrorIcon from '@material-ui/icons/Error';
import { Controller } from 'react-hook-form';
import InputBlock from './InputBlock';
import Text from 'Text';

type INotesProps = {
  name?: string;
} & React.ComponentProps<any>;

export const Notes = ({ name = 'description', ...props }: INotesProps) => {
  const classes = useStyles();
  const { register, control, errors } = props;

  return (
    <Box style={{ paddingRight: '0.1rem' }}>
      <Text i18nKey="notes" className={classes.label}>
        Notes
      </Text>
      <Box className={classes.notesContainer}>
        <InputBlock
          Icon={null}
          type="draftjs"
          name={name}
          data-cy="add-appointment-description"
          placeholder=""
          register={register}
          control={control}
          errors={errors}
          margin={false}
        />
        {/* <Controller
          register={register}
          control={control}
          errors={errors}
          name={name}
          render={({ onChange, value }) => (
            <TextField
              className={classes.textField}
              variant="outlined"
              multiline
              minRows={5}
              maxRows={5}
              onChange={onChange}
              value={value}
            />
          )}
        /> */}
      </Box>
    </Box>
  );
};
