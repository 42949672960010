import React from 'react';
import { Avatar, Box, Chip, Tooltip, Typography } from '@material-ui/core';
import Text from 'Text';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { useStyles } from '../styles';
import DropDownSelect from '../DropdownSelect';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ITeam } from './TeamSettingsTable';
import { highlightText } from '../../templatesTabs/SearchBar';
import { toDayjs } from 'utils';
import { useTranslation } from 'react-i18next';

dayjs.extend(relativeTime);

const statusColor = (status: string) => {
  switch (status) {
    case 'Pending':
      return {
        color: '#B54708',
        backgroundColor: '#FFFAEB',
        border: '1px solid #FEDF89',
      };
    case 'Accepted':
      return {
        color: '#067647',
        backgroundColor: '#ECFDF3',
        border: '1px solid #ABEFC6',
      };
    case 'Expired':
      return {
        color: '#B42318',
        backgroundColor: '#FEF3F2',
        border: '1px solid #FECDCA',
      };
    default:
      return {
        color: '#B54708',
        backgroundColor: '#FFFAEB',
        border: '1px solid #FEDF89',
      };
  }
};

interface IProps {
  hoveredRowIndex: string | null;
  handleOpenDropDown: (e: React.MouseEvent<HTMLElement>, row: any) => void;
  handleEditFields: (row: ITeam) => void;
  handleChangeRole: (row: ITeam, newRole: string) => void;
  roles: [string];
  query?: string;
}

function Columns({
  hoveredRowIndex,
  handleEditFields,
  handleOpenDropDown,
  handleChangeRole,
  roles,
  query,
}: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getRelativeTime = (timestamp: number): string => {
    return `${toDayjs(new Date()).from(toDayjs(new Date(timestamp)), true)} ${t(
      'ago'
    )}`;
  };
  const columns = [
    {
      id: 'title',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="name" className={classes.text}>
            Name
          </Text>
        </Box>
      ),
      freeze: true,
      align: 'left',
      minWidth: 100,
      format: (value, { picture, firstname, lastname }) => {
        return (
          <Box display="flex" alignItems="center">
            <Avatar
              src={picture}
              style={{
                fontSize: '15px',
                textTransform: 'uppercase',
              }}
            >
              {firstname && firstname[0]} {lastname && lastname[0]}
            </Avatar>
            <Typography
              style={{
                fontWeight: 600,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '14px',
                marginLeft: '1rem',
                fontFamily: 'Inter',
                textTransform: 'capitalize',
              }}
            >
              {highlightText(`${firstname} ${lastname}`, query)}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'email',
      minWidth: 100,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Email" className={classes.text}>
            Email
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        const handleEmailClick = (e) => {
          e.stopPropagation(); // Prevent row click event from triggering
        };

        const handleEmailSelection = (e) => {
          e.stopPropagation(); // Prevent row click event when selecting text
        };

        return (
          <Typography
            onClick={handleEmailClick}
            onMouseDown={handleEmailSelection}
            onMouseUp={handleEmailSelection}
            style={{
              fontWeight: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontFamily: 'Inter',
              cursor: 'text', // Changes cursor to text selection cursor
              userSelect: 'text', // Ensures text is selectable
            }}
          >
            {highlightText(value, query)}
          </Typography>
        );
      },
    },
    {
      id: 'supervisor',
      minWidth: 100,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Supervisor" className={classes.text}>
            Supervisor
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        const { picture, firstname, lastname } = value || {};
        return (
          <>
            {!!value ? (
              <Box display="flex" alignItems="center">
                <Avatar
                  src={picture}
                  style={{
                    maxWidth: '30px',
                    maxHeight: '30px',
                  }}
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontSize: '14px',
                    marginLeft: '1rem',
                    fontFamily: 'Inter',
                  }}
                >
                  {highlightText(`${firstname} ${lastname}`, query)}
                </Typography>
              </Box>
            ) : (
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  fontFamily: 'Inter',
                  color: '#98A2B3',
                  textDecoration: 'italic',
                }}
              >
                N/A
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: 'memberStatus',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Invite status" className={classes.text}>
            Invite status
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, row) => {
        return (
          <Chip
            label={
              row?.role !== 'owner' ? t(value) || t('Pending') : t('Accepted')
            }
            style={{
              borderRadius: '16px',
              fontSize: '12px',
              width: 'auto',
              fontFamily: 'Inter',
              maxHeight: '22px',
              padding: '0',
              fontWeight: 500,
              color:
                row?.role !== 'owner' ? statusColor(value).color : '#067647',
              backgroundColor:
                row?.role !== 'owner'
                  ? statusColor(value).backgroundColor
                  : '#ECFDF3',
              border:
                row?.role !== 'owner'
                  ? statusColor(value).border
                  : '1px solid #ABEFC6',
            }}
          />
        );
      },
    },
    {
      id: 'role',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Role" className={classes.text}>
            Role
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, rest) => {
        return (
          <>
            {rest.role === 'owner' ? (
              <Typography>Admin (owner)</Typography>
            ) : (
              <Box width="158px">
                <DropDownSelect
                  defaultMenuName={value}
                  setValue={(role) => {
                    handleChangeRole(rest, role);
                  }}
                  options={roles ?? []}
                  onClickAddOption={() => {}}
                  addOptionText="Add a new role"
                  hasAddOption={false}
                />
              </Box>
            )}
          </>
        );
      },
    },
    {
      id: 'lastRequest',
      minWidth: 10,
      maxWidth: 30,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Last active" className={classes.text}>
            Last active
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              color: '#344054',
              whiteSpace: 'nowrap',
            }}
          >
            {!!value ? getRelativeTime(value) : '—'}
          </Typography>
        );
      },
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      minWidth: 10,
      freeze: true,
      format: (value, row) => (
        <Box>
          {row?.role !== 'owner' && (
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {/* <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                className={`${classes.actions} ${
                  hoveredRowIndex === row?._id ? classes.actionsVisible : ''
                }`}
              >
                <EditIcon
                  data-cy="edit-action"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditFields(row);
                  }}
                  style={{ cursor: 'pointer', marginRight: '1rem' }}
                />
              </Box> */}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenDropDown(e, row);
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ cursor: 'pointer', width: '18px' }}
              >
                <MoreIcon data-cy="more-action" />
              </Box>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  return columns;
}

export default Columns;
