import React from 'react';
import { IinputBoxProps } from './types';
import useStyles from './style';
import clsx from 'clsx';
import { InputLabel, FormHelperText, Box } from '@material-ui/core';
import Skeleton from 'components/ui/Skeleton';
import Input from 'components/ui/Inputs';
import ErrorIcon from '@material-ui/icons/Error';
import Text from 'Text';

export const InputBox = ({
  labelName,
  type,
  name,
  datacy,
  className,
  defaultValue,
  options,
  errors,
  loading,
  error = null,
  ...props
}: IinputBoxProps) => {
  const classes = useStyles();
  const errorMessage = error || (errors as any)[name]?.message;
  return (
    <Box>
      <Skeleton loading={loading}>
        <InputLabel className={clsx(className, classes.label)}>
          {labelName}
        </InputLabel>
      </Skeleton>
      <Skeleton loading={loading}>
        <Input
          className={clsx(classes.input, classes.marginTop)}
          type={type}
          name={name}
          data-cy={datacy}
          defaultValue={defaultValue}
          options={options}
          helperText={
            errorMessage && (
              <Box className={classes.error}>
                <ErrorIcon className={classes.errorIcon} />
                <Text
                  i18nKey={errorMessage}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '20px',
                  }}
                >
                  {errorMessage}
                </Text>
              </Box>
            )
          }
          {...props}
        />
      </Skeleton>
    </Box>
  );
};
