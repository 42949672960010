import { Avatar, Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Tabs from 'components/ui/Tabs';
import dayjs from 'dayjs';
import { timeConverter } from 'pages/AdminPage/TimeConverter';
import React from 'react';
import { calculateAge } from 'utils';
import ActiveMedication from './ActiveMedication';
import NewPrescription from './NewPrescription';

const useStyles = makeStyles<Theme>((theme) => ({
  infoContainer: {
    background: 'rgb(249 249 249)',
    borderBottom: '1px solid #e5e7eb',
  },
  avatar: {
    width: 40,
    height: 40,
    textTransform: 'capitalize',
  },
  tabForm: {
    width: '40%',
    paddingLeft: '1rem',
    [theme.breakpoints.only('lg')]: {
      width: '60%',
    },
  },
  name: {
    color: '#494b5A',
    fontSize: '16px',
    fontWeight: 600,
  },
  birth: {
    color: '#494b5A',
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.only('lg')]: {
      fontSize: '12px',
    },
  },
}));
const DrugFile = (props) => {
  const classes = useStyles();
  const {
    picture,
    firstname,
    gender,
    lastname,
    birthday,
    address,
    city,
    country,
  } = props?.data?.patient || {};
  const [customValue, setCustomValue] = React.useState(0);
  return (
    <Box>
      <Box className={classes.infoContainer} display="flex" py={2} px="2rem">
        <Avatar src={picture} className={classes.avatar}>
          {firstname && firstname[0]} {lastname && lastname[0]}
        </Avatar>
        <Box ml={2}>
          <Typography
            className={classes.name}
            style={{ textTransform: 'capitalize' }}
          >
            {firstname} {lastname}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography className={classes.birth}>
              {calculateAge(birthday)}Y
            </Typography>
            <Typography
              style={{ marginLeft: '5px', textTransform: 'capitalize' }}
              className={classes.birth}
            >
              {' '}
              | {gender}
            </Typography>
            <Box ml={4}>
              <Typography className={classes.birth}>
                DOB:{' '}
                {timeConverter(
                  birthday || new Date().getMilliseconds().toString()
                )}
              </Typography>
            </Box>
            <Box
              ml={4}
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
            >
              <Typography className={classes.birth}>
                Height: 71inches
              </Typography>
              <Typography className={classes.birth}>Weight: 90Kg</Typography>
            </Box>
          </Box>
          <Typography className={classes.birth}>
            Adress 218, Kipp Ave, Kemah, TX, 77565
          </Typography>
        </Box>
      </Box>
      <Box mt={2} width="100%">
        <Tabs
          tabsMode="other"
          tabClassHeader={classes.tabForm}
          tabsBar={['Active Rx', 'New Rx']}
          tabsContent={[
            <ActiveMedication handleExpand={props.handleExpand} />,
            <NewPrescription handleExpand={props.handleExpand} />,
          ]}
          customValue={customValue}
          setCustomValue={setCustomValue}
          scrollable={false}
        />
      </Box>
    </Box>
  );
};

export default DrugFile;
