import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import Input from 'components/ui/Inputs';
import { Autocomplete } from '@material-ui/lab';
import ReactCountryFlag from 'react-country-flag';
import { FormInput } from 'components/ui/Inputs/FormInput';
import countries from 'utils/countries';
import { useStyles } from '../styles';
import Text from 'Text';

interface IFormAddress {
  response?: any;
  register?: any;
  errors?: any;
  control?: any;
  question?: string;
  handleAddress?: any;
  questionIndex?: number;
  name?: string;
  isView?: boolean;
}

export const FormAddress = ({
  register,
  control,
  question,
  handleAddress,
  questionIndex,
  name,
  response,
  isView = false,
}: IFormAddress) => {
  const { address, city, state, country, zipCode } = response || {};
  const classes = useStyles();
  return (
    <Box>
      <Typography className={classes.formQuestion}>{question}</Typography>

      <Box className={classes.wrapperBox}>
        <Text i18nKey="Address" className={classes.titleStyle}>
          Address
        </Text>
        <Controller
          control={control}
          name="address1"
          render={({ onChange, value }) => (
            <Input
              variant="filled"
              inputRef={register}
              type="text"
              name="address"
              style={{
                maxWidth: '100%',
                marginBottom: '9px',
                gap: '10px',
              }}
              inputProps={{
                style: {
                  padding: '10px 14px',
                  fontSize: '16px',
                  width: '100%',
                  lineHeight: '20px',
                  fontWeight: 400,
                },
              }}
              onChange={(e) =>
                handleAddress(questionIndex, e.target.value, e.target.name)
              }
              value={address?.address || address}
              disabled={isView}
            />
          )}
        />
      </Box>

      <Box className={classes.wrapperBoxCountry}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Text i18nKey="City" className={classes.titleStyle}>
            City
          </Text>
          <Controller
            control={control}
            name="city"
            render={({ onChange, value }) => (
              <Input
                variant="filled"
                inputRef={register}
                type="text"
                name="city"
                style={{
                  maxWidth: '100%',
                  marginBottom: '9px',
                }}
                inputProps={{
                  style: {
                    padding: '10px 14px',
                    fontSize: '16px',
                    width: '100%',
                    lineHeight: '20px',
                    fontWeight: 400,
                  },
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleAddress(questionIndex, e.target.value, e.target.name);
                }}
                value={address?.city || city}
                disabled={isView}
              />
            )}
          />
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Text i18nKey="stateCard" className={classes.titleStyle}>
            State
          </Text>
          <Input
            variant="filled"
            inputRef={register}
            type="text"
            name="state"
            style={{
              maxWidth: '100%',
              marginBottom: '9px',
            }}
            inputProps={{
              style: {
                padding: '10px 14px',
                fontSize: '16px',
                width: '100%',
                lineHeight: '20px',
                fontWeight: 400,
              },
            }}
            onChange={(e) =>
              handleAddress(questionIndex, e.target.value, e.target.name)
            }
            value={address?.state || state}
            disabled={isView}
          />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          width: '100%',
          gap: '6px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Text i18nKey="ZIP Code" className={classes.titleStyle}>
            ZIP Code
          </Text>
          <Controller
            control={control}
            name="zipCode"
            render={({ onChange, value }) => (
              <Input
                variant="filled"
                inputRef={register}
                type="text"
                name="zipCode"
                style={{
                  height: '40px',
                  maxWidth: '100%',
                  marginBottom: '9px',
                }}
                inputProps={{
                  style: {
                    padding: '10px 14px',
                    fontSize: '16px',
                    width: '100%',
                    lineHeight: '20px',
                    fontWeight: 400,
                  },
                }}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleAddress(questionIndex, e.target.value, e.target.name);
                }}
                value={address?.zipCode || zipCode}
                disabled={isView}
              />
            )}
          />
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            width: '100%',
          }}
        >
          <Text i18nKey="Country" className={classes.titleStyle}>
            Country
          </Text>
          {response?.address?.country || address?.country || country ? (
            <Input
              variant="filled"
              type="text"
              name="country"
              style={{ height: '40px' }}
              onBlur={(e) =>
                handleAddress(questionIndex, e.target.value, e.target.name)
              }
              onChange={(e) =>
                handleAddress(questionIndex, e.target.value, e.target.name)
              }
              inputProps={{
                style: {
                  padding: '10px 14px',
                  fontSize: '16px',
                  width: '100%',
                  lineHeight: '20px',
                  fontWeight: 400,
                },
              }}
              value={
                country?.label || address?.country?.label || address?.country
              }
              disabled={isView}
            />
          ) : (
            <Controller
              control={control}
              name="country"
              render={({ onChange, value }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.label}
                  id="country-autocomplete"
                  options={countries}
                  value={value || response?.country}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleAddress(questionIndex, newValue, 'country');
                  }}
                  renderOption={(option) => (
                    <>
                      <ReactCountryFlag
                        countryCode={option.code}
                        svg
                        style={{ paddingRight: '10px' }}
                      />
                      {option.label} ({option.code})
                    </>
                  )}
                  renderInput={(params) => (
                    <FormInput placeholder="" {...params} variant="filled" />
                  )}
                  disabled={isView}
                />
              )}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
