import React, { useEffect } from 'react';
import FormType from './FormType';
import NewForm from './NewForm';
import Steps from '../commun/Steps';
import { IQuestion } from 'pages/FormsPage/FormsPage';
import { IPatientData } from '@eggmed/graphql-client/operations/patientOperations/usePatientList';
import { useTranslation } from 'react-i18next';
import { useProvider as useFormProvider } from 'pages/PatientActivities/Questionaires/ActivityProvider';

export interface IDoctorTemplates {
  _id?: string;
  title: string;
  description?: string;
  isDefault?: boolean;
  questions: IQuestion[];
  doctor?: {
    _id?: string;
  };
  lastOpenedAt?: string;
  previewMode?: boolean;
  source?: string;
  formType?: string;
  blocks?: string;
  assignedAt?: string;
}

interface FormModalProps {
  handleClose: () => void;
  title: string;
  patientId?: string;
  assignText?: string;
  doctorTemplates?: IDoctorTemplates[];
  openAssignModal: (form: IDoctorTemplates) => void;
  openDeleteModal: (form: IDoctorTemplates) => void;
  patient: IPatientData;
  loading?: boolean;
  setFormTitle?: React.Dispatch<React.SetStateAction<string>>;
}

function FormModal({
  handleClose,
  title,
  patientId,
  assignText,
  doctorTemplates,
  openAssignModal,
  openDeleteModal,
  patient,
  loading,
  setFormTitle,
}: FormModalProps) {
  const { data: templates } = useFormProvider();
  const [step, setStep] = React.useState(0);
  const { t } = useTranslation();
  const handleNext = () => {
    setStep(step + 1);
  };
  const handleBack = () => {
    step > 0 && setStep(step - 1);
  };
  const handleSubmit = () => {};
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (step === 0) {
      setFormTitle && setFormTitle(t('Form'));
    } else {
      setFormTitle && setFormTitle(t('Form > Create new form'));
    }
  }, [step]);

  return (
    <Steps
      step={step}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      steps={[
        <FormType
          handleNext={handleNext}
          title={title}
          patientId={patientId}
          assignText={assignText}
          doctorTemplates={templates}
          openAssignModal={openAssignModal}
          openDeleteModal={openDeleteModal}
          open={open}
          setOpen={setOpen}
          loading={loading}
        />,
        <NewForm
          handleBack={handleBack}
          patientId={patientId}
          patient={patient}
        />,
      ]}
    />
  );
}

export default FormModal;
