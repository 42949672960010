import React, { ReactElement, useState, useEffect } from 'react';
import { Box, Table, TableContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../Pagination';
import { calculatePaginationTabs, getLimitData } from './tableutils';
import { IColumn } from './types';
import TableHeadRow from './TableHeadRow';
import TableBodyRow from './TableBodyRow';
import FreezedTables from './FreezedTable';

const useStyles = makeStyles({
  tableContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableCell: {
    padding: '0.5px',
    textAlign: 'center',
    border: 'none',
    borderBottom: '1px solid #ddd',
    display: 'flex',
    alignItems: 'center',
  },
  tableHeadCell: {
    padding: '0.5px',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold',
    borderBottom: '2px solid #ddd',
    height: '24px',
    minWidth: '150px',
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
  },
  stickyColumnHeader: {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
    boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
  },
  scrollableMiddle: {
    overflowX: 'auto',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    overflowY: 'auto',
  },
  lastColumn: {
    position: 'sticky',
    right: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
    boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.1)',
  },
  assigneeColumnHeader: {
    minWidth: '450px',
  },
  lastColumnHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 2,
    width: '150px',
    minWidth: '150px',
    boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.1)',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface ITableProps<T> {
  columns: IColumn<T>[];
  data: T[];
  handlePagination?: (event: any, page: number) => void;
  limit: number;
  page: number;
  pagination?: boolean;
  downSortIcon?: any;
  upSortIcon?: any;
  sorting?: boolean;
  loading: boolean;
  type?: 'rounded' | 'normal' | 'gray';
  count?: number;
  EmptyState?: string | ReactElement;
  stateText?: string;
  textDetails?: string;
  className?: string;
  fullData?: boolean;
  isFreezed?: boolean;
  onRowClick?: (row: T) => void;
  onMouseEnterRow?: (row: T) => void;
  onMouseLeaveRow?: (row: T) => void;
}

export default function CustomizedTables<T>({
  columns,
  data,
  handlePagination,
  downSortIcon,
  upSortIcon,
  limit,
  page,
  sorting = false,
  pagination = false,
  loading = false,
  type = 'rounded',
  count,
  EmptyState,
  stateText,
  textDetails,
  fullData = true,
  className,
  onRowClick,
  isFreezed = false,
  onMouseEnterRow,
  onMouseLeaveRow,
}: ITableProps<T>): ReactElement {
  const classes = useStyles();
  const [tableData, setTableData] = useState<T[]>(data);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const limitedData = getLimitData<T>(page, limit, tableData, fullData);

  if (data?.length === 0 && !loading) return <>{EmptyState}</>;
  if (isFreezed)
    return (
      <FreezedTables
        columns={columns}
        data={data}
        handlePagination={handlePagination}
        downSortIcon={downSortIcon}
        upSortIcon={upSortIcon}
        limit={limit}
        page={page}
        sorting={sorting}
        pagination={pagination}
        loading={loading}
        type={type}
        count={count}
        EmptyState={EmptyState}
        stateText={stateText}
        textDetails={textDetails}
        fullData={fullData}
        className={className}
        onRowClick={onRowClick}
        isFreezed={true}
        onMouseEnterRow={onMouseEnterRow}
        onMouseLeaveRow={onMouseLeaveRow}
      />
    );
  return (
    <>
      <Box>
        <TableContainer data-cy="table-container">
          <Table
            classes={{ root: classes.table }}
            aria-label="customized table"
          >
            <TableHeadRow<T>
              type={type}
              columns={columns}
              tableData={tableData}
              isFreezed={isFreezed}
              initialData={data}
              setTableData={setTableData}
              DownSortIcon={downSortIcon}
              UpSortIcon={upSortIcon}
              loading={loading}
              sorting={sorting}
            />
            <TableBodyRow<T>
              type={type}
              loading={loading}
              columns={columns}
              isFreezed={isFreezed}
              sorting={sorting}
              data={limitedData}
              stateText={stateText}
              textDetails={textDetails}
              className={className}
              onClick={onRowClick}
              onMouseEnterRow={onMouseEnterRow}
              onMouseLeaveRow={onMouseLeaveRow}
            />
          </Table>
        </TableContainer>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={1.5}
        >
          {pagination && (
            <Pagination
              count={calculatePaginationTabs<T>(
                limit,
                count || data?.length ? data?.length : 0
              )}
              onChange={handlePagination}
              page={page}
              variant="outlined"
              shape="rounded"
            />
          )}
        </Box>
      </Box>
    </>
  );
}
