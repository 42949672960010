import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useProvider as useMeetingProvider } from 'contexts/MeetingContext';
import useNavigatorOnLine from 'hooks/useNavigatorOnLine';
import { toast } from 'react-toastify';
import { ReactComponent as FullScreenIcon } from 'assets/FullScreen.svg';
import { ReactComponent as CloseIcon } from 'assets/CloseJitsi.svg';

import { JitsiMeeting } from '@jitsi/react-sdk';
import { gql, useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    backgroundColor: '#101828',
    borderRadius: ({ height }: any) => (height === '100vh' ? '0' : '12px'),
    position: 'relative',
    maxHeight: ({ height }: any) => height || '90vh',
    maxWidth: ({ width }: any) => width || '100%',
    height: ({ height }: any) => height || '90vh',
    width: ({ width }: any) => width || '100%',
    overflow: 'hidden',
  },
  fullscreenIcon: {
    position: 'absolute',
    top: '1vh',
    right: ({ width }: any) => '3.5rem',
    color: 'white',
    fontSize: '2vw',
    zIndex: 10000000,
    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '1vh',
    right: ({ width }: any) => '1rem',
    color: 'white',
    fontSize: '2vw',
    zIndex: 10000000,
    '& svg': {
      fontSize: '2rem',
      cursor: 'pointer',
    },
  },
  sessionTitle: {
    position: 'absolute',
    top: '2.1vh',
    left: '15px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    color: '#EAECF0',
  },
}));

interface IMeetingRoomPage {
  endCallAction?: () => void;
  height?: string | number;
  width?: string | number;
  isWide?: boolean;
  handleFullScreen?: () => void;
  roomId?: string;
}
export default function JitsiMeetingRoomPage({
  endCallAction,
  height,
  width,
  isWide = true,
  handleFullScreen,
  roomId,
}: IMeetingRoomPage): JSX.Element {
  const { data } = useQuery(
    gql`
      query getJwtTokenForJitsi($roomId: String!) {
        getJwtTokenForJitsi(roomId: $roomId) {
          token
          roomName
        }
      }
    `,
    {
      variables: {
        roomId,
      },
    }
  );
  const classes = useStyles({ height, width, isWide });

  const { appointment } = useMeetingProvider();

  const network = useRef<{
    status: boolean;
  }>({
    status: true,
  });

  const networkStatus = useNavigatorOnLine();

  useEffect(() => {
    if (!networkStatus) {
      network.current.status = false;
      toast.warn('Connection lost.', {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (!network.current.status) {
        toast.success('Connection restored.', {
          position: 'top-right',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        network.current.status = true;
        window.location.reload();
      }
    }
  }, [networkStatus]);

  const history = useHistory();

  const handleEndCall = useCallback(() => {
    if (isWide) {
      history.push({
        pathname: `/meeting/end/${roomId}`,
        state: { isDoctor: false },
      });
    } else {
      endCallAction();
    }
  }, [roomId]);
  return (
    <div>
      <div className={clsx(classes.videoContainer)}>
        {!isWide && (
          <Box display="flex" alignItems="center">
            <div onClick={handleFullScreen} className={classes.fullscreenIcon}>
              <FullScreenIcon />
            </div>
            <div onClick={() => handleEndCall()} className={classes.closeIcon}>
              <CloseIcon />
            </div>
          </Box>
        )}
        {!isWide && (
          <div className={classes.sessionTitle}>
            {appointment?.appointment?.sessionType.duration}min session
          </div>
        )}

        {data?.getJwtTokenForJitsi?.token && (
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <JitsiMeeting
              domain={process.env.REACT_APP_MEETING_DOMAIN || 'localhost:8443'}
              jwt={data?.getJwtTokenForJitsi?.token}
              roomName={data?.getJwtTokenForJitsi?.roomName}
              configOverwrite={{
                disableThirdPartyRequests: true,
                disableLocalVideoFlip: true,
                backgroundAlpha: 0.5,
                defaultRemoteDisplayName: '',
                startWithVideoMuted: false,
                startWithAudioMuted: false,
                fileRecordingsEnabled: true,
                fileRecordingsServiceEnabled: true,
              }}
              onReadyToClose={() => {
                handleEndCall();
              }}
              interfaceConfigOverwrite={{
                SHOW_ROOM_NAME: false,
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,
                VERTICAL_FILMSTRIP: true,
                FILM_STRIP_MAX_HEIGHT: '100%',
                JITSI_WATERMARK_LINK: '',
                SHOW_JITSI_WATERMARK: false,
                SHOW_BRAND_WATERMARK: true,
                BRAND_WATERMARK_URL:
                  //TODO: Add the Eggmed logo here
                  'https://cdn.prod.website-files.com/66f45efaab376d486995a796/66ffc8477cb672eb1dc21b76_Eggmed%20all-in-one%20EHR%20software%20white%20logo-p-500.png',
                DEFAULT_LOGO_URL:
                  'https://cdn.prod.website-files.com/66f45efaab376d486995a796/66ffc8477cb672eb1dc21b76_Eggmed%20all-in-one%20EHR%20software%20white%20logo-p-500.png',
              }}
              onApiReady={(externalApi) => {
             
                externalApi.addListener(
                  'participantRoleChanged',
                  ({ role }) => {
                    if (role === 'moderator') {
                   
                      externalApi.executeCommand('startRecording', {
                        mode: 'file',
                      });
                    }
                  }
                );
              }}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = '100%';
                iframeRef.style.width = '100%';
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
