import React from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Text from 'Text';
import { ReactComponent as EditIcon } from 'assets/templateSettings/editIcon.svg';
import { ReactComponent as MoreIcon } from 'assets/patientActivities/more.svg';
import { ReactComponent as Eye } from 'assets/templateSettings/eye.svg';
import { useStyles } from './styles';
import TagsCell from './TagsCell';
import Switch from 'components/ui/SwitchNew';
import useEditDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useEditDoctorTemplate';
import { DOCTOR_TEMPLATES_BY_FORM } from '../ManageTemplates';
import { useSnackbar } from 'hooks/useSnackbar';
import { AUTO_SEND_TEMPLATES } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGetAutoSendTemplates';
import { SEARCH_TEMPLATES } from '@eggmed/graphql-client/operations/patientActivitiesOperations/useGetSearchTemplates';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { toDayjs } from 'utils';

dayjs.extend(relativeTime);

export enum TemplateType {
  all = 'All',
  notes = 'Note-taking',
  intake = 'Intake',
  assessment = 'Assessment',
  other = 'Other',
}

interface IProps {
  hoveredRowIndex: string | null;
  handleOpenDropDown: (e: React.MouseEvent<HTMLElement>, row: any) => void;
  handleEditFields: (row: any) => void;
  handleViewFields: (row: any) => void;
  type: TemplateType;
  tagOpen: boolean;
  currentRow: any;
  setTagOpen: (value: boolean) => void;
  data?: any;
}

function Columns({
  hoveredRowIndex,
  handleEditFields,
  handleViewFields,
  handleOpenDropDown,
  type,
  tagOpen,
  setTagOpen,
  currentRow,
  data,
}: IProps) {
  const [handleEditDoctorTemplate] = useEditDoctorTemplate();
  const { triggerSnack } = useSnackbar();
  const { t } = useTranslation();
  const getRelativeTime = (timestamp: number): string => {
    return `${t('il y a')} ${toDayjs(new Date()).from(
      toDayjs(new Date(timestamp)),
      true
    )} ${t('ago')}`;
  };

  const classes = useStyles();
  const columns = [
    {
      id: 'title',
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="templateName" style={{ whiteSpace: 'noWrap' }}>
            Name
          </Text>
        </Box>
      ),
      freeze: true,
      align: 'left',
      minWidth: 500,
      format: (
        value,
        { name, title, isDefault, isAutoSent, isScored, type }
      ) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            style={{
              cursor: 'pointer',
              maxWidth: '400px', // Set max width here
            }}
          >
            <Typography
              style={{
                fontWeight: 500,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {title || name}
            </Typography>
            <Box pl={1} display="flex" alignItems="center">
              {isDefault && (
                <Tooltip title="Default Template">
                  <Text
                    i18nKey="Default"
                    variant="caption"
                    className={classes.type}
                  >
                    Default
                  </Text>
                </Tooltip>
              )}

              {isAutoSent && (
                <Tooltip title="Auto-sent">
                  <Text
                    i18nKey="Auto-send"
                    variant="caption"
                    className={classes.type}
                  >
                    Auto-send
                  </Text>
                </Tooltip>
              )}
              {isScored && (
                <Tooltip title="Scored template">
                  <Text
                    i18nKey="Scored"
                    variant="caption"
                    className={classes.type}
                  >
                    Scored
                  </Text>
                </Tooltip>
              )}
            </Box>
          </Box>
        );
      },
    },

    {
      id: 'tags',
      minWidth: 300,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Tags" style={{ whiteSpace: 'noWrap' }}>
            Tags
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value, { _id, tags }) => {
        return (
          <TagsCell
            editMode={tagOpen && _id === currentRow?._id}
            tags={tags}
            width={300}
            isSelected={tagOpen && _id === currentRow?._id}
            setEditMode={setTagOpen}
            templateId={_id}
            data={data}
          />
        );
      },
    },
    {
      id: 'lastOpenedAt',
      minWidth: 5,
      maxWidth: 10,
      flexGrow: 1,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="lastUsed" style={{ whiteSpace: 'noWrap' }}>
            Last used
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {getRelativeTime(value) ?? '-'}
          </Typography>
        );
      },
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      maxWidth: 5,
      flexShrink: 0,
      freeze: true,
      format: (value, row) => (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={`${classes.actions} ${
              hoveredRowIndex === row?._id ? classes.actionsVisible : ''
            }`}
          >
            <Eye
              data-cy="view-action"
              onClick={(e) => {
                e.stopPropagation();
                handleViewFields(row);
              }}
              style={{ cursor: 'pointer', marginRight: '0.8rem' }}
            />
            <EditIcon
              data-cy="edit-action"
              onClick={(e) => {
                e.stopPropagation();
                handleEditFields(row);
              }}
              style={{ cursor: 'pointer', marginRight: '0.8rem' }}
            />
          </Box>
          <Box
            onClick={(e) => handleOpenDropDown(e, row)}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ cursor: 'pointer', width: '18px' }}
          >
            <MoreIcon data-cy="more-action" />
          </Box>
        </Box>
      ),
    },
  ];
  const allColumns = [
    ...columns.slice(0, 1),
    {
      id: 'formType',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="type" style={{ whiteSpace: 'noWrap' }}>
            Type
          </Text>
        </Box>
      ),
      align: 'left',
      format: (value) => {
        return (
          <Typography
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {t(convertTemplatesType(value))}
          </Typography>
        );
      },
    },
    ...columns.slice(1),
  ];
  const intakeColumn = [
    ...columns.slice(0, 1),
    {
      id: 'isAutoSent',
      minWidth: 10,
      label: (
        <Box fontSize="1rem">
          <Text i18nKey="Auto-send" style={{ whiteSpace: 'noWrap' }}>
            Auto-send
          </Text>
        </Box>
      ),
      align: 'center',
      format: (value, { _id }) => {
        return (
          <Switch
            key={_id}
            checked={value}
            onClick={(e) => e.stopPropagation()}
            onChange={async (e, valor) => {
              await handleEditDoctorTemplate({
                variables: {
                  id: _id,
                  doctorTemplateInput: {
                    isAutoSent: valor,
                  },
                },
                refetchQueries: [
                  DOCTOR_TEMPLATES_BY_FORM,
                  AUTO_SEND_TEMPLATES,
                  SEARCH_TEMPLATES,
                ],
              });
              triggerSnack(
                t(
                  `Template has been successfully ${
                    value ? 'disabled' : 'enabled'
                  } for auto-sending`
                )
              );
            }}
          />
        );
      },
    },
    ...columns.slice(1),
  ];
  const getColumnType = () => {
    switch (type) {
      case TemplateType.all:
        return allColumns;
      case TemplateType.intake:
        return intakeColumn;
      default:
        return columns;
    }
  };

  return getColumnType();
}

const convertTemplatesType = (type) => {
  const formsType = {
    'assessment-form': 'Assessment',
    'intake-form': 'Intake',
    'note-taking': 'Note-taking',
    other: 'other',
  };
  return formsType[type];
};

export default Columns;
