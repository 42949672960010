import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  Paper,
  Popper,
} from '@material-ui/core';
import { useStyles } from '../Insurance';
import React from 'react';
import PrimaryInsurance from 'assets/PrimaryInsurance';
import SecondaryInsurance from 'assets/SecondaryInsurance';
import OtherInsurance from 'assets/OtherInsurance';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { Controller } from 'react-hook-form';
import Text from 'Text';
import { gql, useLazyQuery } from '@apollo/client';
import AutoCompleteAddNew from 'components/ui/Inputs/AutoCompleteAddnew';
import { useTranslation } from 'react-i18next';

const InsuranceInfo = (props) => {
  const classes = useStyles();
  const insurance = [
    {
      name: 'Primary',
      icon: (
        <PrimaryInsurance
          color={
            props.watch()?.insuranceType === 'Primary' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Secondary',
      icon: (
        <SecondaryInsurance
          color={
            props.watch()?.insuranceType === 'Secondary' ? 'white' : '#182230'
          }
        />
      ),
    },
    {
      name: 'Other',
      icon: (
        <OtherInsurance
          color={props.watch()?.insuranceType === 'Other' ? 'white' : '#182230'}
        />
      ),
    },
  ];
  const [page, setPage] = React.useState(1);
  const LIMIT = 10;
  const [getPayers, { data, loading }] = useLazyQuery(INSURANCE_LIST);
  const [payers, setPayers] = React.useState([]);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [isLoad, setIsLoad] = React.useState(true);
  const [search, setSearch] = React.useState('');
  const handleSearch = (value) => {
    setIsLoad(true);
    setPayers([]);
    setHasNextPage(true);
    getPayers({
      variables: { page: 1, limit: 18, search: value },
    });
  };
  React.useEffect(() => {
    getPayers({
      variables: {
        page: 1,
        limit: 18,
        search: '',
      },
    });
  }, []);
  React.useEffect(() => {
    if (data) {
      if (data?.payersList?.page === 1) {
        setPayers(data?.payersList?.data);
      } else {
        setPayers((prev) => [...prev, ...data?.payersList?.data]);
      }
      setHasNextPage(data?.payersList?.hasNextPage);
    }
  }, [data]);
  const loadMore = () => {
    setIsLoad(false);
    const nextPage = Math.floor(payers.length / 18) + 1;
    if (hasNextPage && !loading) {
      getPayers({
        variables: { page: nextPage, limit: 18, search },
      });
      setPage(nextPage);
    }
  };
  const { t } = useTranslation();
  const CustomPaper = (props) => (
    <Paper {...props} style={{ width: '420px' }} />
  );
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text i18nKey="Insurance type" className={classes.relationShipText}>
          Insurance type
        </Text>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Controller
            name="insuranceType"
            control={props.control}
            defaultValue="Primary"
            render={({ value, onChange }) => (
              <>
                {insurance?.map((insurance) => (
                  <Box
                    key={insurance.name}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.relationShipBox}
                    ml={2}
                    style={{
                      border: `1px solid ${
                        value === insurance?.name ? '#0265DC' : '#E0E0E0'
                      }`,
                      backgroundColor:
                        value === insurance?.name ? '#0265DC' : '#fff',
                    }}
                    onClick={() => onChange(insurance?.name)}
                  >
                    {insurance.icon}
                    <Text
                      i18nKey={insurance.name}
                      className={classes.relationShipLabel}
                      style={{
                        color: value === insurance?.name ? '#fff' : '#182230',
                      }}
                    >
                      {insurance.name}
                    </Text>
                  </Box>
                ))}
              </>
            )}
          />
        </Box>
      </Box>
      <Grid container spacing={2} style={{ marginBlock: '16px' }}>
        <Grid item xl={6} lg={6}>
          <Text
            i18nKey={'Payer'}
            style={{
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
              fontWeight: 500,
              color: '#182230',
              marginBottom: '6px',
            }}
          >
            Payer
          </Text>
          <Controller
            name={'payer'}
            control={props.control}
            render={({ onChange, value }) => (
              <AutoCompleteAddNew
                noOptionsText={
                  loading && isLoad ? t('Loading...') : t('No options')
                }
                value={value}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setSearch(newInputValue);
                  handleSearch(newInputValue);
                }}
                options={convertObjectsFormat(payers)}
                getOptionLabel={(option) => {
                  if (option) {
                    return option?.name;
                  }
                  return '';
                }}
                PaperComponent={CustomPaper}
                ListboxProps={{
                  onScroll: (event) => {
                    const listboxNode = event.currentTarget;

                    if (
                      listboxNode.scrollHeight - listboxNode.scrollTop ===
                      listboxNode.clientHeight
                    ) {
                      loadMore();
                    }
                  },
                }}
                disablePortal={false}
              />
            )}
          />
          {/* <InputBlock
            label={'Payer'}
            {...props}
            name="payer"
            type="select"
            options={['payer 1', 'payer 2']}
          /> */}
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Member ID'}
            {...props}
            name="memberID"
            type="text"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Group ID'}
            {...props}
            name="groupID"
            type="text"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock label={'Plan ID'} {...props} name="planID" type="text" />
        </Grid>

        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Copay/Coinsurance'}
            {...props}
            name="copay"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Deductible'}
            {...props}
            name="deductible"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Effective start date'}
            {...props}
            name="startDate"
            type="date"
          />
        </Grid>
        <Grid item xl={6} lg={6}>
          <InputBlock
            label={'Effective end date'}
            {...props}
            name="endDate"
            type="date"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InsuranceInfo;
export const INSURANCE_LIST = gql`
  query payersList($page: Int, $limit: Int, $search: String) {
    payersList(page: $page, limit: $limit, search: $search) {
      page
      hasNextPage
      data {
        _id
        payer_name
        payer_state
        auto
        payer_type
        payerid
        claims_1500
        eligibility
        era
        isAdded
      }
    }
  }
`;
export const convertObjectsFormat = (array) => {
  if (array) {
    return array?.map((element) => ({
      ...element,
      name: `${element?.payer_name}`,
      id: element?._id,
    }));
  }
  return [];
};
