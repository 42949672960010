import React, { ReactElement } from 'react';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import TrashIcon from 'assets/TrashIcon';
import { Box } from '@material-ui/core';
import ModalDelete from 'components/DeleteHandler/ModalDelete';

interface IDeleteHandlerProps extends IconButtonProps {
  value?: any;
  iconButton?: boolean;
  inputLine?: any;
  handleDelete: (index: number | string) => void;
  DeleteIcon?: any;
  handleAction: (_id: string, value: any, handleClose?: () => void) => void;
  text?: string;
  isDelete?: boolean;
  loadingDelete?: boolean;
  isAllowed?: boolean;
  name?: string;
}

const DeleteHandler = ({
  value,
  iconButton = true,
  inputLine,
  handleDelete,
  handleAction,
  text = 'appointment',
  isAllowed = false,
  isDelete = false,
  DeleteIcon = (
    <TrashIcon
      fill="#B42318"
      width="18px"
      height="18px"
      style={{ marginRight: '10px' }}
    />
  ),
  loadingDelete,
  name,
  ...props
}: IDeleteHandlerProps): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { _id: ID } = value;
  async function handleDeleteItem() {
    if (ID && !isDelete) {
      await handleAction(ID, value, handleClose);
    } else {
      await handleAction(ID, true);
    }
    handleDelete(inputLine);
  }
  return (
    <>
      {iconButton ? (
        <Box
          {...props}
          // style={{ cursor: 'pointer' }}
          // onClick={handleClick}
        >
          <Box
            data-cy="delete-handler"
            display="flex"
            alignItems="center"
            justifyContent="center"
            alignSelf="self"
            alignContent="center"
            onClick={isAllowed ? handleClick : undefined}
          >
            {DeleteIcon}
          </Box>
        </Box>
      ) : (
        <Box onClick={handleClick} {...props}>
          {DeleteIcon}
        </Box>
      )}

      <ModalDelete
        text={text}
        open={open}
        name={name}
        onClose={handleClose}
        onDelete={handleDeleteItem}
        loading={loadingDelete}
      />
    </>
  );
};

export default DeleteHandler;
DeleteHandler.defaultProps = {
  DeleteIcon: (
    <TrashIcon
      fill="#B42318"
      width="18px"
      height="18px"
      style={{ marginRight: '10px' }}
    />
  ),
  inputLine: {},
  iconButton: true,
  value: {},
};
