import { createContext } from 'react';
import dayjs from 'dayjs';
import {
  DATE_ACTION,
  VIEW_ACTION,
  EVENT_ACTION,
  EDIT_MODE_ACTION,
  MODAL_ADD_ACTION,
  DATE_RANGE_ACTION,
  CURRENT_DATE_ACTION,
  CLOSE_NOT_USED,
  RESET_CURRENT_EVENT,
  SET_BACK,
  OPEN_PATIENT_MODAL,
  CLOSE_PATIENT_MODAL,
  CHANGE_TAGS,
  ADD_PATIENT,
  Manage_CONFLICT,
  SET_FEED_MODE,
} from './actions';
import { IInitialState, ISchedulePageContext } from './types';
import { toDayjs } from 'utils';

export * from './actions';
export const initialState: IInitialState = {
  date: new Date(),
  view: 'timeGridWeek',
  notUsed: true,
  currentEvent: {},
  editMode: false,
  feedMode: false,
  modalAddOpen: false,
  currentDate: new Date(),
  dateRange: [
    toDayjs(new Date()).startOf('week').valueOf(),
    toDayjs(new Date()).endOf('week').valueOf(),
  ],
  back: false,
  openPatientModal: false,
  selectedColorId: null,
  patientAdded: null,
  isFromConflict: false,
};

export const Context = createContext<ISchedulePageContext | undefined>(
  undefined
);

interface IAction {
  type:
    | typeof SET_BACK
    | typeof RESET_CURRENT_EVENT
    | typeof DATE_ACTION
    | typeof VIEW_ACTION
    | typeof EVENT_ACTION
    | typeof EDIT_MODE_ACTION
    | typeof MODAL_ADD_ACTION
    | typeof DATE_RANGE_ACTION
    | typeof CURRENT_DATE_ACTION
    | typeof CLOSE_NOT_USED
    | typeof OPEN_PATIENT_MODAL
    | typeof CLOSE_PATIENT_MODAL
    | typeof ADD_PATIENT
    | typeof CHANGE_TAGS
    | typeof SET_FEED_MODE
    | typeof Manage_CONFLICT;

  payload?: any;
}

export default function Reducer(state: IInitialState, action: IAction) {
  switch (action.type) {
    case DATE_ACTION:
      return { ...state, date: action.payload };
    case VIEW_ACTION:
      return { ...state, view: action.payload };
    case EVENT_ACTION:
      return { ...state, currentEvent: action.payload };
    case EDIT_MODE_ACTION:
      return { ...state, editMode: action.payload };
    case SET_FEED_MODE:
      return { ...state, feedMode: action.payload };
    case MODAL_ADD_ACTION:
      return { ...state, modalAddOpen: action.payload };
    case DATE_RANGE_ACTION:
      return { ...state, dateRange: action.payload };
    case CURRENT_DATE_ACTION:
      return { ...state, currentDate: action.payload };
    case CLOSE_NOT_USED:
      return { ...state, notUsed: false };
    case RESET_CURRENT_EVENT:
      return { ...state, currentEvent: {} };
    case SET_BACK:
      return { ...state, back: action.payload };
    case OPEN_PATIENT_MODAL:
      return { ...state, openPatientModal: true };
    case CLOSE_PATIENT_MODAL:
      return { ...state, openPatientModal: false };
    case CHANGE_TAGS:
      return { ...state, selectedColorId: action.payload };
    case ADD_PATIENT:
      return { ...state, patientAdded: action.payload };
    case Manage_CONFLICT:
      return { ...state, isFromConflict: action.payload };
    default:
      throw new Error('No action found');
  }
}
