import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as Add } from 'assets/add-icon.svg';
import { ReactComponent as Down } from 'assets/down-icon.svg';
import { ReactComponent as Up } from 'assets/up-icon.svg';
import { ReactComponent as Select } from 'assets/select-icon.svg';
import { ReactComponent as Remove } from 'assets/remove-icon.svg';
import useStyles from './styles';
import Input from 'components/ui/Inputs';
import Button from 'components/ui/Button';
import Text from 'Text';
import { capitalizeFirstLetter } from 'pages/Taskpage/TaskAdd/utils';
import useAddLocation from '@eggmed/graphql-client/operations/locationOperation/useAddLocation';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useTranslation } from 'react-i18next';

interface Location {
  name?: string;
  _id?: string;
}

interface SearchLocationProps {
  locationsData?: any;
  commonProps?: any;
  value?: Location;
  feedMode?: boolean;
}

const SearchLocation = React.memo(
  ({ commonProps, value, feedMode, locationsData }: SearchLocationProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { user } = useAuth();
    const isAdmin = ['Admin', 'owner']?.includes(user?.role);
    const [inputValue, setInputValue] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (value?.name) {
        setInputValue(value.name);
      } else {
        setInputValue('');
      }
    }, [value]);
    const { setValue, register, control } = commonProps;
    const { addLocation, loading: loadingAdd } = useAddLocation();

    const locationsFromQuery =
      locationsData && locationsData?.locationsByDoctor;

    const physicalLocationsList = useMemo(() => {
      return locationsFromQuery?.map((location) => ({
        ...location,
        name: capitalizeFirstLetter(location?.name),
        seletectedLocation: location?._id === value?._id,
      }));
    }, [locationsFromQuery, value]);

    const [physicalLocations, setPhysicalLocations] = useState(
      physicalLocationsList
    );

    useEffect(() => {
      setPhysicalLocations(physicalLocationsList);
    }, [physicalLocationsList]);

    useEffect(() => {
      if (value?.name) {
        setInputValue(value?.name || '');
      }
    }, [value]);

    const handleClickAway = (event: MouseEvent | null) => {
      if (ref.current && !ref.current.contains(event?.target as Node)) {
        setDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickAway);
      return () => document.removeEventListener('mousedown', handleClickAway);
    }, []);

    const physicalLocationsNames = useMemo(() => {
      return physicalLocations?.map((el) => el.name.toLowerCase());
    }, [physicalLocations]);

    const handleCreateLocation = useCallback(
      async (e) => {
        e.preventDefault();
        if (!isAdmin) return;
        const trimmedInputValue = inputValue.trim();
        if (trimmedInputValue.length === 0) return;

        if (physicalLocationsNames.includes(trimmedInputValue.toLowerCase())) {
          setDropdownOpen(false);
          return;
        }

        try {
          const response = await addLocation({
            variables: {
              locationInput: { name: trimmedInputValue },
            },
          });

          const newLocation = response?.data?.addLocation;
          const { __typename, ...restLocattion } = newLocation;
          if (newLocation) {
            const newLocationFormatted = {
              name: capitalizeFirstLetter(newLocation.name),
              _id: newLocation?._id,
              seletectedLocation: true,
            };
            setPhysicalLocations((prev) => [...prev, newLocationFormatted]);
            setValue('locationInPerson', restLocattion);
            setInputValue(newLocationFormatted.name);
            setDropdownOpen(false);
          }
        } catch (error) {
          console.error('Failed to create location:', error);
          setDropdownOpen(false);
        }
      },
      [addLocation, inputValue, physicalLocationsNames, setValue]
    );

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      setDropdownOpen(true);
    };

    const handleRemoveLocation = (locationId: string) => {
      const updatedLocations = physicalLocations?.map((location) => {
        if (location._id === locationId) {
          return { ...location, seletectedLocation: false };
        }
        return location;
      });

      setPhysicalLocations(updatedLocations);
      setValue('locationInPerson', null);
      setInputValue('');
    };

    const handleSelectLocation = (locationId: string) => {
      const updatedLocations = physicalLocations.map((location) => {
        if (location._id === locationId) {
          return { ...location, seletectedLocation: true };
        }
        return { ...location, seletectedLocation: false };
      });

      const selectedLocation = updatedLocations.find(
        (loc) => loc._id === locationId
      );
      setPhysicalLocations(updatedLocations);
      if (selectedLocation) {
        setValue('locationInPerson', {
          name: selectedLocation.name,
          _id: selectedLocation._id,
        });
        setInputValue(selectedLocation.name);
      }
      setDropdownOpen(false);
    };

    const filteredLocations = useMemo(() => {
      return physicalLocations?.filter((location) =>
        location?.name?.toLowerCase().includes(inputValue?.toLowerCase())
      );
    }, [physicalLocations, inputValue]);

    const trimmedQuery = inputValue?.trim().toLowerCase();
    const isQueryIncluded = filteredLocations?.some(
      (location) => location.name.toLowerCase() === trimmedQuery
    );
    const newLocations = (() => {
      if (filteredLocations && trimmedQuery.length > 0) {
        if (
          !isQueryIncluded &&
          !filteredLocations.includes(inputValue?.trim()?.toLowerCase())
        ) {
          return [{ name: inputValue?.trim() }, ...filteredLocations];
        }
      }

      return physicalLocations || [];
    })();

    const highlightText = (text: string, query: string) => {
      if (!text) return '';
      if (!query) return text;

      const parts = text.split(new RegExp(`(${query})`, 'gi')) || [];
      return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: '#3892F3',
              borderRadius: '2px',
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'center',
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    const RenderCreatetRow = ({ index, input, handleCreateLocation }) => {
      return (
        <Box
          key={index}
          style={{
            cursor: 'pointer',
          }}
        >
          <Box
            className={classes.locationItemCreate}
            onClick={async (e) => {
              e.preventDefault();
              await handleCreateLocation(e);
            }}
          >
            <Typography className={classes.locationTitle}>{input}</Typography>
            <Button className={classes.assignButton}>
              <Add />
              <Text i18nKey="Create" className={classes.assign}>
                Create
              </Text>
            </Button>
          </Box>
        </Box>
      );
    };
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          opacity: feedMode ? 0.4 : 1,
        }}
        ref={ref}
      >
        <Input
          type="text"
          placeholder={isAdmin ? t('Search or create new') : t('Search')}
          variant="filled"
          disabled={feedMode}
          style={{
            maxHeight: '40px',
            marginBottom: '0.3rem',
            width: '100%',
          }}
          value={inputValue}
          onChange={handleSearch}
          onClick={() =>
            !feedMode ? setDropdownOpen(!dropdownOpen) : undefined
          }
          InputProps={{
            endAdornment: dropdownOpen ? <Up /> : <Down />,
          }}
        />

        {dropdownOpen && (
          <Box
            className={
              locationsFromQuery?.length !== 0
                ? classes.dropdown
                : classes.emptyLocation
            }
            style={{
              position: 'absolute',
              top: 45,
              left: 0,
              zIndex: 1000,
              maxHeight: '120px',
              overflowY: 'auto',
            }}
          >
            <>
              {locationsFromQuery?.length === 0 &&
              inputValue?.trim().length === 0 ? (
                <Text
                  i18nKey={
                    isAdmin ? 'Type to create a new location' : 'No location.'
                  }
                  className={classes.emptyLocationText}
                >
                  {isAdmin ? 'Type to create a new location' : 'No location.'}
                </Text>
              ) : locationsFromQuery?.length === 0 &&
                inputValue?.trim().length !== 0 ? (
                isAdmin ? (
                  <RenderCreatetRow
                    key={0}
                    index={0}
                    input={inputValue?.trim()}
                    handleCreateLocation={handleCreateLocation}
                  />
                ) : (
                  <Text
                    i18nKey="No matching location."
                    className={classes.emptyLocationText}
                  >
                    No matching location.
                  </Text>
                )
              ) : (
                newLocations?.map((location, index) =>
                  index === 0 &&
                  inputValue?.trim() !== '' &&
                  !isQueryIncluded ? (
                    isAdmin ? (
                      <RenderCreatetRow
                        key={index}
                        index={index}
                        input={location?.name}
                        handleCreateLocation={handleCreateLocation}
                      />
                    ) : (
                      <Text
                        i18nKey="No matching location."
                        className={classes.emptyLocationText}
                      >
                        No matching location.
                      </Text>
                    )
                  ) : (
                    <Box className={`${classes.locationItem}`} key={index}>
                      <Box
                        className={classes.templateItem}
                        onClick={(e) => {
                          e.preventDefault();
                          location?.seletectedLocation
                            ? handleRemoveLocation(location._id)
                            : handleSelectLocation(location?._id);
                        }}
                      >
                        <Typography className={classes.locationTitle}>
                          {highlightText(location?.name?.trim(), inputValue)}
                        </Typography>
                        {location?.seletectedLocation ? (
                          <Button
                            className={`${classes.unassignButton} ${classes.hoverButton}`}
                          >
                            <Remove />
                            <Text i18nKey="Remove" className={classes.assign}>
                              Remove
                            </Text>
                          </Button>
                        ) : (
                          <Button
                            className={`${classes.assignButton} ${classes.hoverButton}`}
                          >
                            <Select />
                            <Text i18nKey="Select" className={classes.assign}>
                              Select
                            </Text>
                          </Button>
                        )}
                      </Box>
                    </Box>
                  )
                )
              )}
            </>
          </Box>
        )}
      </div>
    );
  }
);

export default SearchLocation;
