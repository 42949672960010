import React from 'react';
import DasboardPage from './DashboardPage';
import { gql } from '@apollo/client';
import InboxPageProvider from 'pages/InboxPage/state/Provider';

const Dashboard = () => {
  return (
    <InboxPageProvider isOnly={false}>
      <DasboardPage />
    </InboxPageProvider>
  );
};

export default Dashboard;

export const GET_DOCTOR_PAST_OR_UPCOMING_APPOINTMENTS = gql`
  query doctorPastOrUpcomingAppointment($isUpcoming: Boolean, $filter: Filter) {
    doctorUpcomingAppointments(isUpcoming: $isUpcoming, filter: $filter) {
      result {
        startDate
        endDate
        isEnded
        description
        title
        location
        locationLink
        conditionType {
          tag
          color
        }
        members
        invitation {
          invitation_id
        }
        patient {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        doctor {
          _id
          firstname
          middlename
          lastname
          picture
          email
          phone
          timeZone
        }
        doctorGoing
        patientGoing
        _id
        sessionType {
          duration
          session
          currency
          rate
        }
        isPaid
      }
      count
    }
  }
`;
export const GET_PATIENT_BY_AGE = gql`
  query patientsByAge {
    patientsByAge {
      patients {
        _id
        count
      }
    }
  }
`;
