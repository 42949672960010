import React, { useEffect } from 'react';
import InputBlock from 'components/PatientProfile/PatientNewTabs/InputBlock';
import { Box, Grid, Typography } from '@material-ui/core';
import useStyles from '../styles';
import SessionSelect from 'pages/SchedulePage/AddEventPage/EventForm/SessionSelect';
import { currencySymbols } from './Steps';
import { useTranslation } from 'react-i18next';
import Text from 'Text';

export const InvoiceDetails = ({ methods }) => {
  const classes = useStyles();
  const { register, control, errors, watch, reset } = methods;
  const commonProps = { register, control, errors };
  const selectedSession = watch('sessionType');
  const issueDate = watch('issueDate');
  const { t } = useTranslation();
  useEffect(() => {
    reset({
      ...watch(),
      amount: selectedSession?.rate,
      amountPaid: 0,
    });
  }, [selectedSession]);

  const plus30Days = 30 * 24 * 60 * 60 * 1000;

  useEffect(() => {
    reset({
      ...watch(),
      dueDate: issueDate && new Date(issueDate.getTime() + plus30Days),
    });
  }, [issueDate]);

  const balanceDue: number | undefined =
    Number(watch('amount')) - Number(watch('amountPaid'));

  return (
    <Grid container spacing={2}>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Issue date')}
          {...commonProps}
          name="issueDate"
          type="date"
          defaultValue={new Date()}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Due date')}
          {...commonProps}
          name="dueDate"
          type="date"
          defaultValue={
            new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
          }
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <SessionSelect
          control={control}
          register={register}
          errors={errors}
          label={t('Service')}
          hasMeetingOptions={false}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Session date')}
          {...commonProps}
          name="sessionDate"
          type="date"
          showTimeSelect={true}
          defaultValue={new Date()}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Amount')}
          {...commonProps}
          name="amount"
          type="number"
          defaultValue={''}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <InputBlock
          label={t('Amount paid')}
          {...commonProps}
          name="amountPaid"
          type="number"
          defaultValue={0}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          className={classes.balance}
        >
          <Text i18nKey="balanceDue" className={classes.balance}>
            Balance due:
          </Text>{' '}
          {currencySymbols[watch('sessionType')?.currency]}
          {balanceDue ? balanceDue : 'xxx'}
        </Box>
      </Grid>
    </Grid>
  );
};
