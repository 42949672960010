import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ReactComponent as AssignedActivityIcon } from 'assets/patientActivities/assigned-act.svg';
import { AssignedCard, AssignedCardLoading } from '../AssignedCard';
import Text from 'Text';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyAssigned } from 'assets/patientActivities/assigned-illus.svg';
import { Empty } from '../Empty';
import { IActivitySource } from '..';
import { ActivitiesTypes } from 'pages/PatientActivities/RecentActivityCard';
import ScrollBar from 'components/ui/ScrollBar';

export const AssignedActivities = ({
  assignedAct,
  loadingAssigned,
  handleOpenCreateEdit,
  setActivityToOpen,
  handleScroll,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box display="flex" alignItems="center" gridGap="1rem">
        <AssignedActivityIcon />
        <Text i18nKey="assignedActivities" className={classes.title}>
          Assigned activities
        </Text>
      </Box>
      <ScrollBar
        display="flex"
        flexDirection="column"
        gridGap="1rem"
        mt={3}
        height={'calc(100% - 3rem)'}
        overflow={'scroll'}
        className={classes.scrollbar}
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
        onScroll={handleScroll}
      >
        {loadingAssigned ? (
          <>
            {[1, 2, 3, 4].map(() => (
              <AssignedCardLoading />
            ))}
          </>
        ) : assignedAct && assignedAct?.patientActivityByFilter.length > 0 ? (
          assignedAct?.patientActivityByFilter?.map((el) => (
            <AssignedCard
              activityType={el?.type}
              assignedAt={el?.assignedAt}
              formTitle={el?.title}
              openComplete={() => {
                setActivityToOpen({
                  source: IActivitySource.ASSIGN,
                  type: el?.type ?? ActivitiesTypes.FORM,
                  assignedAt: el?.assignedAt,
                  activity: el,
                });
                handleOpenCreateEdit();
              }}
            />
          ))
        ) : (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Empty
              icon={<EmptyAssigned />}
              title={t('No assigned activity yet')}
              description={t(
                'Here you will view the activities assigned by your provider'
              )}
            />
          </Box>
        )}
      </ScrollBar>
    </>
  );
};
