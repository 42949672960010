import React, { useEffect } from 'react';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import useStyles from './styles';
import { RegisteredSelect } from 'pages/Taskpage/components/CustomSession';
import { useQuery, gql } from '@apollo/client';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import {
  Control,
  DeepMap,
  FieldValues,
  FieldError,
  useFormContext,
  Controller,
} from 'react-hook-form';
import AddDefaultSession from './AddDefaultSession';
import clsx from 'clsx';
import ErrorIcon from '@material-ui/icons/Error';
import { ReactComponent as OnlineIcon } from 'assets/Schedule/new/online.svg';
import { ReactComponent as InPersonIcon } from 'assets/Schedule/new/in-person.svg';
import Text from 'Text';
import { useTranslation } from 'react-i18next';
import FreeTag from 'components/ui/FreeTag';

interface IPatientSelectProps {
  isPatient?: boolean;
  register: (name: string) => void;
  control: Control;
  errors: DeepMap<FieldValues, FieldError>;
  label?: string;
  placeholder?: string;
  loading?: boolean;
  doctorId?: string;
  activeMeeting?: boolean;
  setActiveMeeting?: React.Dispatch<React.SetStateAction<boolean>>;
  hasMeetingOptions?: boolean;
  feedMode?: boolean;
}
export interface ISelectProps {
  duration: string;
  session: string;
  rate: string;
  currency: string;
  color: string;
  location: string;
  isPrepayment: boolean;
  isFree: boolean;
  _id: string;
  cptCode: {
    code: string;
    description: string;
  };
  locationPlace: string;
  locationId: { _id: string; name: string };
  externalUrl: string;
  assignedRoles: { _id: string; name: string }[];
  assignedProviders: { _id: string; firstname: string; lastname: string }[];
}

export default function SessionSelect({
  isPatient,
  errors,
  control,
  register,
  doctorId,
  activeMeeting,
  setActiveMeeting,
  hasMeetingOptions = true,
  label = 'Service',
  feedMode = false,
  ...props
}: IPatientSelectProps) {
  const { doctor } = useAuth();
  const classes = useStyles();
  const { setValue } = useFormContext();
  const [display, setDisplay] = React.useState(true);
  const { t } = useTranslation();

  const { data } = useQuery(SELECTED_RATE, {
    variables: {
      doctor: doctor?._id || doctorId,
    },
  });
  const { selectedRate } = data || {};
  const noRate = selectedRate?.length === 1 && selectedRate[0].rate === '0';
  const displayAlert = (selectedRate?.length === 0 || noRate) && display;
  const options =
    selectedRate?.length > 0
      ? selectedRate?.map((el: ISelectProps) => {
          return {
            _id: el?._id,
            session: el?.session,
            duration: el?.duration,
            rate: el?.rate,
            currency: el?.currency,
            color: el?.color,
            location: el?.location,
            isPrepayment: el?.isPrepayment,
            isFree: el?.isFree,
            cptCode: el?.cptCode,
            locationPlace: el?.locationPlace,
            externalUrl: el?.externalUrl,
            assignedRoles: el?.assignedRoles,
            assignedProviders: el?.assignedProviders,
            locationInPerson: {
              _id: el?.locationId?._id,
              name: el?.locationId?.name || '',
            },
          };
        })
      : [{ session: 'session', duration: '45', rate: '0', currency: 'USD' }];
  const setSession = (data: ISelectProps) => {
    setValue('sessionType', data, { shouldValidate: true });
  };

  return (
    <Box mb={hasMeetingOptions && 2}>
      <Typography className={classes.label}>{label}</Typography>
      {!isPatient && displayAlert && (
        <Box width="100%">
          <AddDefaultSession
            doctorId={doctor?._id || doctorId}
            setDisplay={setDisplay}
            setSession={setSession}
          />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        width="100"
        height="100%"
        sx={{ gridGap: '1.5rem' }}
      >
        <RegisteredSelect
          data-cy="Session-Type"
          renderValue={(value: ISelectProps) => (
            <Box
              display="flex"
              justifyContent="space-between"
              height="100%"
              alignItems="center"
              width="100%"
            >
              <Typography>{`${value?.duration} min, ${value?.session}`}</Typography>
              {Number(value?.rate) !== 0 ? (
                <Typography>${value?.rate}</Typography>
              ) : (
                <FreeTag isMarginLeft={false} />
              )}
            </Box>
          )}
          name="sessionType"
          options={options}
          control={control}
          register={register}
          errors={errors?.sessionType}
          style={{ width: '100%', flex: 1 }}
          feedMode={feedMode}
          {...props}
        />

        {hasMeetingOptions && (
          <Controller
            name="activeMeeting"
            control={control}
            render={({ onChange, value }) => (
              <Box className={classes.eventType}>
                <Box
                  className={clsx(
                    classes.oneEvent,
                    classes.onlineEvent,
                    value && classes.activeEvent // `value` is true for online
                  )}
                  onClick={() => onChange(true)} // Set activeMeeting to true
                >
                  <OnlineIcon />
                  <Text i18nKey="online">Online</Text>
                </Box>
                <Box
                  className={clsx(
                    classes.oneEvent,
                    !value && classes.activeEvent // `value` is false for in-person
                  )}
                  onClick={() => onChange(false)} // Set activeMeeting to false
                >
                  <InPersonIcon />
                  <Text i18nKey="inPerson">In-person</Text>
                </Box>
              </Box>
            )}
          />
        )}
      </Box>

      <Box alignSelf="flex-start">
        {errors && errors.sessionType && (
          <FormHelperText data-cy="error-message" className={classes.error}>
            <ErrorIcon className={classes.errorIcon} />
            {t(errors?.sessionType?.message)}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
}

export const SELECTED_RATE = gql`
  query selectedRate($doctor: ID) {
    selectedRate(doctor: $doctor) {
      _id
      session
      duration
      rate
      isFree
      locationId {
        _id
        name
      }
      currency
      color
      location
      isPrepayment
      cptCode {
        code
        description
      }
      locationPlace
      externalUrl
      assignedRoles {
        _id
        name
      }
      assignedProviders {
        _id
        firstname
        lastname
      }
    }
  }
`;
