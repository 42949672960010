import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  lableClass: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'left',
    marginBottom: '0.5rem',
  },
  chip: {
    color: '#067647',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    textAlign: 'center',
    backgroundColor: '#ECFDF3',
    border: '1px solid #ABEFC6',
    width: '70px',
    height: '22px',
    // padding: '1px',
    gap: '0px',
    // borderRadius: '16px' ,
  },
  alert: {
    padding: '0 0 0 15px',
    gap: '10px',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    borderRadius: '16px',
  },
  titleContainer: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#344054',
    fontFamily: 'Inter',
  },
  h3: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#344054',
  },
  h2: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#101828',
  },
  subtitle: {
    marginTop: '0.5rem',
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#667085',
    textAlign: 'left',
  },
  divider: {
    marginTop: '-15px',
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  longInput: {
    width: '100%',
    height: '52px',
    background: '#F8FAFB',
    borderRadius: '8px',
    marginTop: '12.04px',
  },
  label: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '1rem',
    lineHeight: '23px',
    color: '#425364',
  },
  description: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.only('md')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  checkBox: {
    marginLeft: '10px',
    [theme.breakpoints.only('lg')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '0px',
      display: 'flex',
    },
  },
  alignCheck: {
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },

  boxCheck: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '20px',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  PhoneInput: {
    width: '100%',
    height: '52px',
    background: '#F8FAFB',
    borderRadius: '4px',
    marginTop: '12.04px',
  },
  labelPhone: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '23px',
    color: '#425364',
    marginTop: '5px',
  },
  input: {
    width: '100%',
    borderRadius: '8px',
    '& .MuiFormHelperText-contained': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
    },
  },
  marginTop: { marginTop: '12.04px' },
  marginTop2: {
    marginTop: '16px',
  },
  error: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    gap: '6px'
  },
  button: {
    width: '139px',
    height: '36px',
    borderRadius: '5px',
  },
  cancel: {
    marginRight: theme.spacing(2),
    backgroundColor: 'transparent',
    border: '1px solid rgba(130, 130, 130, 1)',
    color: '#425364',
    textTransform: 'capitalize',
    fontSize: '18px',
    lineHeight: '21px',
    minWidth: '94px',
    fontWeight: 400,
    borderRadius: '5px',
    [theme.breakpoints.only('xl')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      backgroundColor: 'rgba(195, 207, 221, 1)',
      border: '1px solid rgba(130, 130, 130, 1)',
    },
  },
  save: {
    backgroundColor: '#1F61DC',
    color: 'white',
    textTransform: 'none',
    fontSize: '18px',
    lineHeight: '21px',
    fontWeight: 600,
    minWidth: '94px',
    border: '1px solid #1F61DC',
    borderRadius: '5px',
    offset: '1px 3px rgba(153, 153, 153, 0.1)',
    [theme.breakpoints.only('xl')]: {
      width: '5vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '5vw',
    },
    '&:hover': {
      backgroundColor: '#1D31AA',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
  },
  p: {
    width: '316px',
    fontFamily: 'Roboto',
    fontStyle: 'normale',
    fontSize: '20px',
    lineHeight: '39px',
    color: '#425364',
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridContainerBilling: {
    padding: '0px 0px',
    position: 'relative',
  },
  title: {
    textTransform: 'none',
  },
  rootRadio: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconRadio: {
    borderRadius: '50%',
    width: 22,
    height: 22,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: 'white',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIconRadio: {
    backgroundColor: 'white',
    border: '1px solid #39B54A',
    color: 'green',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 22,
      height: 22,
      backgroundImage: 'radial-gradient(#39B54A,#39B54A 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#f5f8fa',
    },
  },
  dayName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#425364',
  },
  firstDay: {
    maxWidth: '100%',
    height: '48px',
    background: '#F8FAFB',
    borderRadius: '8px 8px 0 0',
    borderInline: '0.5px solid #1F61DC',
    borderBlock: '0.5px solid #1F61DC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: '25px',
  },
  day: {
    maxWidth: '100%',
    height: '48px',
    background: '#F8FAFB',
    borderInline: '0.5px solid #1F61DC',
    borderBottom: '0.5px solid #1F61DC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: '25px',
  },
  lastDay: {
    maxWidth: '100%',
    height: '48px',
    background: '#F8FAFB',
    borderRadius: '0 0 8px 8px',
    borderInline: '0.5px solid #1F61DC',
    borderBottom: '0.5px solid #1F61DC',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: '25px',
  },
  WorkingDays: {
    marginTop: '36px',
    maxWidth: theme.typography.pxToRem(536),
  },
  Session: {
    marginTop: '36px',
    maxWidth: theme.typography.pxToRem(728),
  },
  Buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '36px',
    width: '100%',
    alignItems: 'center',
    gap: '10px',
  },
  saveButton: {
    minWidth: '81px',
    padddingBlock: '10px',
    paddingInline: '1rem',
    borderRadius: '5px',
    backgroundColor: '#1F61DC',
    height: '36px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1C31AC',
    },
  },
  cancelButton: {
    padddingBlock: '10px',
    paddingInline: '1rem',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    border: '0.5px solid #425364',
    height: '36px',
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#425364',
    '&:hover': {
      backgroundColor: '#F8FAFB',
    },
  },
  modal: {
    width: '30%',
    [theme.breakpoints.only('lg')]: {
      width: '35%',
    },
  },
  deleteModal: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  draftNotes: {
    height: theme.typography.pxToRem(210),
    maxHeight: theme.typography.pxToRem(210),
    minHeight: theme.typography.pxToRem(210),
  },
  requiredField: {
    width: '100%',
    height: '0px',
    border: '1px solid #BCC6D3',
  },
  snackBar: {
    minWidth: 'auto',
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#147AF3',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '17px',
    lineHeight: '36px',
    padding: '16px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  timeBox: {
    width: '100%',
    fontSize: '8px',
    marginLeft: '2px',
    [theme.breakpoints.up('md')]: {
      width: '300px',
      fontSize: '10px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '14px',
    },
  },
  alertText: {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'italic',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19.36px',
    color: '#B42318',
    textAlign: 'left',
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
    color: '#4A5578',
    backgroundColor: '#F9F9FB',
    border: '1px solid #7D89B0',
    borderRadius: '4px',
    padding: '20px',
    gap: '10px',
  },
  infoboxTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    paddingBottom: '0.2rem',
  },
  infoTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.36px',
  },
  calendarUrl: {
    margin: '1rem 0 1rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4vh',
    padding: '12px',
    gap: '12px',
    borderRadius: '4px',
    border: '1px solid #D0D5DD',
  },
  inputStyle: {
    width: '70rem',
    height: '1rem',
    padding: '10px 14px 10px 14px',
    gap: '0px',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    justifyContent: 'space-between',
  },
  calendarText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    textAlign: 'left',
    paddingLeft: '0.5rem',
  },
  calenderChoice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '30rem',
    border: '1px solid #D0D5DD',
    borderRadius: '4px',
    padding: '8px',
    marginTop: '1rem',
    gap: '12px',
  },
  disconnectBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#D92D20',
    border: '1px solid #D92D20',
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '8px',
    textTransform: 'none',
    width: '9rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#D92D20',
    },
  },
  connectBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0265DC',
    border: '1px solid #0265DC',
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '8px',
    textTransform: 'none',
    width: '9rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#0265DC',
    },
  },
  copyBtn: {
    display: 'flex',
    padding: '2px 8px 2px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EAECF0',
    border: '1px solid #EAECF0',
    color: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    borderRadius: '4px',
    textTransform: 'none',
    gap: '4px',
    width: '6.25rem',
    height: '2rem',
    '&:hover': {
      backgroundColor: '#EAECF0',
    },
  },
  copyText: {
    color: '#475467',
    marginLeft: '2px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
  },
  calSync: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: '30px',
  },
  wrapper: {
    alignItems: 'center',
    padding: '20px 18px 20px 18px',
    gap: '40px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    marginTop: '1rem',
    width: '48vw',
    [theme.breakpoints.between('md', 'lg')]: {
      width: '55vw',
    },
  },
  containerCal: {
    top: '1.875rem',
    left: '1.875rem',
    bottom: '1.875rem',
    gap: '1.875rem',
  },
  inputBlock: {
    height: '1rem',
    padding: '10px 14px 10px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '45vw',
  },
}));
export default useStyles;
