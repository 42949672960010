import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import React from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    borderRadius: '0.5rem',
    border: '1px solid #e5e7eb',
    padding: '1rem',
    minHeight: '100px',
    cursor: 'pointer',
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4,0,0.2,1)',
    transitionDuration: '0.3s',
    '&:hover': {
      border: '1px solid #425364',
    },
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
    color: '#494B5A',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'blue',
  },
  circleRed: {
    width: 10,
    height: 10,
    borderRadius: '100%',
    backgroundColor: 'red',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#494B5A',
  },
  date: {
    fontSize: '14px',
    color: '#8E96A4',
  },
  historytitle: {
    fontSize: '0.8125rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
    color: '#494B5A',
  },
}));

const History = () => {
  const classes = useStyles();
  const PSHX = [
    {
      name: 'Coronary bypass surgery',
    },
  ];
  const PMHX = [
    {
      name: 'Abdominal pain',
    },
  ];
  const FHX = [
    {
      name: 'Brain tumors',
    },
    {
      name: 'Blindness',
    },
    {
      name: 'Cva (Celebral infraction)',
    },
  ];
  const SHx = [
    {
      name: 'College',
    },
    {
      name: 'Divorced',
    },
    {
      name: 'Npo caffeine use',
    },
    {
      name: 'Married',
    },
  ];
  return (
    <Box className={classes.root} style={{ cursor: 'not-allowed' }}>
      <Typography className={classes.title}>Past history</Typography>
      <Box mt={2}>
        <Box mb={4}>
          <Typography className={classes.historytitle}>PSHX</Typography>
          {PSHX?.map((medication) => (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
              mb={2}
            >
              <Box className={classes.circle} />
              <Typography className={classes.text}>
                {medication?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box mb={4}>
          <Typography className={classes.historytitle}>PMHX</Typography>
          {PMHX?.map((medication) => (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
              mb={2}
            >
              <Box className={classes.circle} />
              <Typography className={classes.text}>
                {medication?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box mb={4}>
          <Typography className={classes.historytitle}>FHx</Typography>
          {FHX?.map((medication) => (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
              mb={0.5}
            >
              <Typography className={classes.text}>
                {medication?.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography className={classes.historytitle}>SHx</Typography>
          {SHx?.map((medication) => (
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '10px' }}
              mb={2}
            >
              <Box className={classes.circle} />
              <Typography className={classes.text}>
                {medication?.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default History;
