import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ReactComponent as AddIcon } from 'assets/ConsultationPage/btnAddIcon.svg';
import clsx from 'clsx';
import Button from '../ui/Button';
import ProgressLoader from '../ui/ProgressLoader';
import Text from 'Text';

interface IwidthProps {
  widthResponsive?: boolean;
  isPatient?: boolean;
}
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '10px 14px',
    boxSizing: 'border-box',
    border: '1px solid #D0D5DD',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#344054',
    textTransform: 'capitalize',
    width: 'unset',
    height: 'unset',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #425364',
    },
  },
  saveButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: '#2F80ED',
    border: '1px solid #2F80ED',
    padding: '10px 14px',
    boxSizing: 'border-box',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: 'white',
    textTransform: 'capitalize',
    marginRight: '0.5rem',
    '&:hover': {
      backgroundColor: '#1E70DC',
    },
  },
  buttonAdd: {
    padding: '10px 14px',
    boxSizing: 'border-box',
    fontFamily: 'Inter',
    width: 'unset',
    height: '100%',
    color: '#0054B6',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textTransform: 'none',
    border: '1px solid #59A7F6',
    backgroundColor: '#F5FAFF',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    minWidth: '80px',
    paddingBlock: '10px',
    borderRadius: '8px',
    '&:hover': {
      background: '#E5EAEE',
    },
  },
  container: {
    padding: '0px 2rem',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  rightBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
  },
  leftBlock: {
    width: '100%',
  },
}));
interface IProps {
  editable: boolean;
  handleOpen?: (e: any) => void;
  handleEdit?: (e: any) => void;
  loading?: boolean;
  type?: string;
  handleAdd: () => void;
  buttonLabel: any;
  className?: any;
  classNameIcon: any;
  widthResponsive?: boolean;
  addButton?: boolean;
  isPatient?: boolean;
}

export default function EditButtons({
  editable,
  handleOpen,
  handleEdit,
  handleAdd,
  buttonLabel,
  className,
  classNameIcon,
  type,
  widthResponsive,
  addButton = true,
  loading = false,
  isPatient = false,
}: IProps) {
  const classes = useStyles({ widthResponsive, isPatient });
  if (editable) {
    return (
      <Box className={classes.container}>
        {addButton && (
          <Box className={classes.leftBlock}>
            <Button
              className={clsx(className, classes.buttonAdd)}
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                handleAdd();
              }}
              data-cy="add-button"
            >
              <AddIcon className={classNameIcon} />
              {buttonLabel}
            </Button>
          </Box>
        )}
        <Box className={classes.rightBlock}>
          <Button
            data-cy="cancel"
            className={classes.button}
            onClick={handleOpen}
            buttonType="reset"
          >
            <Text i18nKey="cancel">Cancel</Text>
          </Button>

          <Button
            variant="contained"
            className={classes.saveButton}
            onClick={(e) => {
              e.preventDefault();
              handleEdit(e);
            }}
            buttonType={type}
            data-cy="submit"
          >
            {loading ? (
              <ProgressLoader width={20} height={20} />
            ) : (
              <Text i18nKey="save">Save</Text>
            )}
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <Button data-cy="edit" buttonType={type} type="edit" onClick={handleOpen}>
      <Text i18nKey="edit">Edit</Text>
    </Button>
  );
}
EditButtons.defaultProps = {
  handleOpen: () => {},
  handleEdit: () => {},
  loading: false,
  type: '',
};
