import React, { ReactElement, useEffect } from 'react';
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField, { FilledTextFieldProps } from '@material-ui/core/TextField';

import Autocomplete, {
  AutocompleteCloseReason,
  createFilterOptions,
  AutocompleteRenderOptionState,
  AutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import { InputAdornment } from '@material-ui/core';
import { emailReggex } from 'utils';
import { FormInput } from './FormInput';
interface StyleProps {
  isSearch: boolean;
}
export const FormInputs = withStyles((theme) => ({
  root: {
    width: '100%',
    // height: '50px',
    '& .MuiFilledInput-root': {
      backgroundColor: 'white',
      borderRadius: ({ isSearch }: StyleProps) => (isSearch ? '10px' : '5px'),
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel)':
      {
        marginTop: '0px',
      },
    '& .MuiFormHelperText-root': {
      border: '0px !important',
    },
    '& .Mui-focused': {
      border: '1px solid #082CAF',
      borderRadius: ({ isSearch }: StyleProps) => (isSearch ? '10px' : '5px'),
    },
    '& .Mui-error': {
      border: '1px solid red',
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input':
      {
        padding: '8px 8px 16px 12px',
      },
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: '8px',
    },
    '& .MuiFilledInput-input': {
      padding: theme.spacing(2),
      borderRadius: 'inherit',
      height: ({ isSearch }: StyleProps) => (isSearch ? '7px' : 'auto'),
    },
    '& .MuiFilledInput-underline:after': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '1px solid #3f50b5',
    },
    '& .MuiFilledInput-underline:before': {
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      transform: 'scaleX(0)',
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      border: '0px solid #3f50b5',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: '2px solid #B3BFD0',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid white',
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: theme.spacing(0),
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}))(TextField);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 221,
      fontSize: 13,
    },
    button: {
      fontSize: 13,
      width: '100%',
      textAlign: 'left',
      paddingBottom: 8,
      color: '#586069',
      fontWeight: 600,
      '&:hover,&:focus': {
        color: '#0366d6',
      },
      '& span': {
        width: '100%',
      },
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: '.15em 4px',
      fontWeight: 600,
      lineHeight: '15px',
      borderRadius: 2,
    },
    popper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 'fit-content',
      zIndex: 9999999999999999999999999999999999999,
      fontSize: 13,
      color: '#586069',
      backgroundColor: '#f6f8fa',
    },
    header: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    paper: {
      boxShadow: 'none',
      margin: 0,
      color: '#586069',
      fontSize: 13,
    },
    option: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: 'relative',
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  })
);

export interface Option {
  name: string;
  description?: string;
  color?: string;
  picture?: string;
  id: string | number;
  type?: string;
  email?: string;
  _id?: string;
  firstname?: string;
  lastname?: string;
}
export interface Value {
  name?: string;
  description?: string;
  color?: string;
  picture?: string;
  id?: string | number;
  type?: string;
  email?: string;
}

export interface IAutoCompteAddProps {
  values: Option[];
  setValues: any;
  options: Option[];
  renderPreview?: (option: Option) => ReactElement;
  renderOption?: (
    option: Option,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  renderPreviewWrapper?: (content: any) => ReactElement;
  groupBy?: any;
  filterOptions?: AutocompleteProps<any, true, true, true>['filterOptions'];
  placeholder?: string;
  multiple?: boolean;
  icon?: ReactElement;
  noOptionsText?: string;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  menuClassName?: string;
  isSearch?: boolean;
}
// Get option label
const filter = createFilterOptions<any>();
export default function AutoCompleteAdd({
  values,
  setValues,
  options,
  renderOption,
  renderPreview,
  renderPreviewWrapper,
  filterOptions,
  placeholder,
  multiple = true,
  icon,
  noOptionsText = 'No labels',
  onFocus,
  onBlur,
  menuClassName,
  ...props
}: IAutoCompteAddProps): ReactElement {
  const classes = useStyles({ isSearch: props?.isSearch });
  const [pendingValue, setPendingValue] = React.useState<any[]>(values);
  const [key, setValueKey] = React.useState('autocomplete');
  const handleClose = (
    event: React.ChangeEvent<any>,
    reason: AutocompleteCloseReason
  ) => {
    if (reason === 'toggleInput') {
      return;
    }
    setValues(pendingValue);
  };

  useEffect(() => {
    setPendingValue(values);
    setValueKey(pendingValue && pendingValue[0]);
  }, [values]);
  return (
    <>
      <Autocomplete
        id="autocomplete-id"
        disableClearable
        onFocus={onFocus}
        onBlur={onBlur}
        onClose={handleClose}
        multiple={multiple}
        classes={{
          paper: classes.paper,
          option: classes.option,
          popperDisablePortal: classes.popperDisablePortal,
          popper: menuClassName ? menuClassName : classes.popper,
        }}
        value={pendingValue}
        key={key}
        onChange={(event, newValue) => {
          setPendingValue(newValue);
        }}
        disableCloseOnSelect
        renderTags={() => null}
        noOptionsText={noOptionsText}
        getOptionSelected={(option: Option, value: Option) =>
          option.id === value.id
        }
        filterOptions={
          filterOptions
            ? filterOptions
            : (argumentsOptions, params) => {
                const filtered = filter(argumentsOptions, params);
                // Suggest the creation of a new value
                if (
                  params.inputValue !== '' &&
                  emailReggex.test(params.inputValue)
                ) {
                  filtered.push({
                    label: params.inputValue,
                    email: params.inputValue,
                    type: 'Guest',
                    name: `Add guest "${params.inputValue}"`,
                  });
                }
                return filtered;
              }
        }
        renderOption={renderOption}
        options={options}
        getOptionLabel={(option) => option.name}
        renderInput={(params) =>
          icon ? (
            <FormInputs
              ref={params.InputProps.ref}
              variant="filled"
              {...params}
              isSearch={props?.isSearch}
              InputProps={{
                placeholder: placeholder,
                startAdornment: (
                  <InputAdornment position="start">{icon}</InputAdornment>
                ),
              }}
            />
          ) : (
            <FormInput
              ref={params.InputProps.ref}
              variant="filled"
              {...params}
            />
          )
        }
        {...props}
      />
      {renderPreviewWrapper &&
        renderPreview &&
        renderPreviewWrapper(
          multiple
            ? values?.map(renderPreview)
            : [values as Value].map(
                renderPreview as (option: Option) => ReactElement
              )
        )}
    </>
  );
}

interface LabelType {
  name: string;
  color: string;
  description?: string;
}
