import React, { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import useStyles from '../styles';
import EditButtons from 'components/EditButtons';
import General from './GeneralInformation';
import Address from './Address';
import { IDoctor } from '../PersonalInformation';
import { Controller, FieldErrors, Control, Ref } from 'react-hook-form';

interface IpersonalInfoProps {
  register?: (ref?: Ref | null) => void;
  control?: Control<any>;
  errors?: FieldErrors<any>;
  handleClose: () => void;
  handleEditDoctor: () => void;
  doctor: IDoctor;
  loading?: boolean;
  error?: string;
}

const EditPersonalInfo = ({
  register,
  control,
  errors,
  handleClose,
  handleEditDoctor,
  doctor,
  loading,
  error,
}: IpersonalInfoProps): ReactElement => {
  const classes = useStyles({ isPatient: false });
  const commonProps = { register, control, errors };
  return (
    <Box className={classes.scroll} data-cy="presonal-modal">
      <Address doctor={doctor} error={error} {...commonProps} />
      <Box
        height="76px"
        display="flex"
        alignItems="center"
        width="100%"
        borderTop="1px solid #EAECF0"
        style={{
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <EditButtons
          handleOpen={handleClose}
          editable
          handleEdit={handleEditDoctor}
          loading={loading}
        />
      </Box>
    </Box>
  );
};
export default EditPersonalInfo;
