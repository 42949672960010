import React from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import InputBlock from 'pages/AdminPage/Payment/InputBlock';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'shared/interfaces/i18';
import clsx from 'clsx';
import SignatureResponse from 'components/Editorjs/Responses/Signature';
import { useStyles } from './index';
import CustomRadioGroup from './CustomRadioGroup';
import Text from 'Text';

const ClaimFinal = ({ commonProps }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid item xl={3} lg={3}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="25. Federal tax ID number" className={classes.label}>
            25. Federal tax ID number
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Federal tax ID number'}
              {...commonProps}
              name="federalTax"
              type="number"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <Box mb={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classes.margin}
              >
                <Typography className={clsx(classes.textTitle)}>
                  ID Type
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <CustomRadioGroup
                  name={'idType'}
                  control={commonProps.control}
                  options={[
                    { name: 'SSN', value: 0 },
                    { name: 'EIN', value: 1 },
                  ]}
                  ariaLabel={'idType'}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={3} lg={3}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="26. Patient account NO." className={classes.label}>
            26. Patient account NO.{' '}
          </Text>
        </Box>
        <Box alignItems="center" bgcolor={'#F9FAFB'} width="100%" height="45%">
          <InputBlock
            label={''}
            {...commonProps}
            name="patientAccount"
            type="number"
          />{' '}
        </Box>
      </Grid>
      <Grid item xl={2} lg={2}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="27. Accept assignment?" className={classes.label}>
            27. Accept assignment?
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <CustomRadioGroup
            name={'isAccept'}
            control={commonProps.control}
            options={[
              { name: 'Yes', value: 0 },
              { name: 'No', value: 1 },
            ]}
            ariaLabel={'isAccept'}
          />
        </Box>
      </Grid>
      <Grid item xl={2} lg={2}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="28. Total charge" className={classes.label}>
            28. Total charge
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={''}
              {...commonProps}
              name="totalCharge"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={2} lg={2}>
        <Box className={classes.labelRoot} p={1}>
          <Text i18nKey="29. Reserved for NUCC use" className={classes.label}>
            29. Reserved for NUCC use
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor={'#F9FAFB'}
          height="45%"
        >
          <Text i18nKey="Reserved for NUCC">Reserved for NUCC</Text>
        </Box>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="30. signature of physician or supplier including degrees or credentials"
            className={classes.label}
          >
            30. signature of physician or supplier including degrees or
            credentials
          </Text>
        </Box>

        <Box>
          <Text
            i18nKey="I certify that the statements on the reverse apply to this bill and are made a part thereof."
            className={classes.paragraph}
          >
            I certify that the statements on the reverse apply to this bill and
            are made a part thereof.
          </Text>
        </Box>
        <Box mt={'35px'} display="flex" justifyContent="flex-end">
          <Text i18nKey="ELECTRONICALLY SIGNED" className={classes.signLabel}>
            ELECTRONICALLY SIGNED
          </Text>
          <InputBlock
            label={'Date'}
            {...commonProps}
            name="dateSignaturePhy"
            type="date"
            disabled
          />
        </Box>
      </Grid>{' '}
      <Grid item xl={4} lg={4}>
        <Box
          style={{ whiteSpace: 'nowrap' }}
          className={classes.labelRoot}
          p={1}
        >
          <Text
            i18nKey="31. Service facility location information"
            className={classes.label}
          >
            31. Service facility location information
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Facility name'}
              {...commonProps}
              name="facilityname"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 1'}
              {...commonProps}
              name="address1Facility"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 2'}
              {...commonProps}
              name="address2Facility"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'City'}
              {...commonProps}
              name="cityFacility"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'State'}
              {...commonProps}
              name="stateFacility"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'ZIP code'}
              {...commonProps}
              name="zipcodeFacility"
              type="text"
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Box className={classes.labelRoot} p={1}>
            <Text
              i18nKey="31. a Service facility NPI"
              className={classes.label}
            >
              31. a Service facility NPI
            </Text>
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12}>
            <InputBlock
              label={'Service facility NPI'}
              {...commonProps}
              name="npiFacility"
              type="text"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={4} lg={4}>
        <Box className={classes.labelRoot} p={1}>
          <Text
            i18nKey="32. Billing provider info & PH #"
            className={classes.label}
          >
            32. Billing provider info & PH #
          </Text>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Phone number'}
              {...commonProps}
              name="phoneBilling"
              type="phone"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'First name'}
              {...commonProps}
              name="firstBilling"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Middle name'}
              {...commonProps}
              name="MiddleBilling"
              type="text"
            />
          </Grid>
          <Grid item xl={3} lg={3}>
            <InputBlock
              label={'Last name'}
              {...commonProps}
              name="LastBilling"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 1'}
              {...commonProps}
              name="line1Billing"
              type="text"
            />
          </Grid>
          <Grid item xl={6} lg={6}>
            <InputBlock
              label={'Address line 2'}
              {...commonProps}
              name="line2Billing"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'City'}
              {...commonProps}
              name="cityBilling"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'State'}
              {...commonProps}
              name="stateBilling"
              type="text"
            />
          </Grid>
          <Grid item xl={4} lg={4}>
            <InputBlock
              label={'Zip code'}
              {...commonProps}
              name="ZipBilling"
              type="text"
            />
          </Grid>
        </Grid>
        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6}>
              <Box className={classes.labelRoot} p={1}>
                <Text
                  i18nKey="32. a. Billing provider NPI"
                  className={classes.label}
                >
                  32. a. Billing provider NPI
                </Text>
              </Box>
              <InputBlock
                label={'Billing provider NPI'}
                {...commonProps}
                name="billingnpi"
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={6}>
              <Box className={classes.labelRoot} p={1}>
                <Text i18nKey="32. b. Taxonomy code" className={classes.label}>
                  32. b. Taxonomy code
                </Text>
              </Box>
              <InputBlock
                label={'Taxonomy code'}
                {...commonProps}
                name="taxonomyBilling"
                type="text"
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ClaimFinal;
