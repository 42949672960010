import React from 'react';
import { createRoot } from 'react-dom/client';
import FormGenerator from 'pages/FormsPage/FormGenerator';

interface TimelineData {
  events?: Array<any>;
  questions: Array<any>;
  question?: any;
}

interface TimelineConfig {
  edit?: boolean;
}

interface TimelineConstructorProps {
  data: TimelineData;
  config?: TimelineConfig;
  api: any;
  readOnly: boolean;
  edit: boolean;
}

export default class ShortText {
  private api: any;
  private readOnly: boolean;
  private data: TimelineData;
  private CSS: { wrapper: string };
  private nodes: { holder: HTMLElement | null };
  private edit: boolean;
  private root: any | null;

  static get toolbox() {
    return {
      icon: `<svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.5C0 0.947715 0.447715 0.5 1 0.5H16C16.5523 0.5 17 0.947715 17 1.5C17 2.05228 16.5523 2.5 16 2.5H1C0.447715 2.5 0 2.05228 0 1.5Z" fill="#475467"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.5C0 3.94772 0.447715 3.5 1 3.5H16C16.5523 3.5 17 3.94772 17 4.5C17 5.05228 16.5523 5.5 16 5.5H1C0.447715 5.5 0 5.05228 0 4.5Z" fill="#475467"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 6.94772 0.447715 6.5 1 6.5H16C16.5523 6.5 17 6.94772 17 7.5C17 8.05228 16.5523 8.5 16 8.5H1C0.447715 8.5 0 8.05228 0 7.5Z" fill="#475467"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.5C0 9.94772 0.447715 9.5 1 9.5H9C9.55229 9.5 10 9.94772 10 10.5C10 11.0523 9.55229 11.5 9 11.5H1C0.447715 11.5 0 11.0523 0 10.5Z" fill="#475467"/>
      </svg>           
      `,
      title: 'Long text',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }: TimelineConstructorProps) {
    this.api = api;
    this.readOnly = readOnly;
    this.edit = config?.edit ?? false;
    this.root = null;

    const defaultQuestion = {
      question: 'Custom question',
      type: 'Long answer',
      options: ['Option 1', 'Option 2'],
      description: '',
      required: false,
      isDescription: false,
      range: [1, 3],
    };

    this.data = {
      questions: data.questions || [defaultQuestion],
    };

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    };

    this.nodes = {
      holder: null,
    };
  }
  // private setQuestions = (newQuestions: Array<any>) => {
  //   console.log(newQuestions, 'newQuestions');
  //   this.data = {
  //     ...this.data,
  //     questions: newQuestions,
  //   };
  // };

  private setQuestions = (
    newQuestionsOrUpdater: Array<any> | ((questions: Array<any>) => Array<any>)
  ) => {
    if (typeof newQuestionsOrUpdater === 'function') {
      // Handle functional update
      const updatedQuestions = (
        newQuestionsOrUpdater as (questions: Array<any>) => Array<any>
      )(this.data.questions);
      this.data = {
        ...this.data,
        questions: updatedQuestions,
      };
    } else {
      // Handle direct array update
      this.data = {
        ...this.data,
        questions: newQuestionsOrUpdater,
      };
    }
    this.updateDom();
  };
  private deleteBlock = (index: number) => {
    this.api.blocks.delete(index);
  };
  private getBlockIndex = () => {
    return this.api.blocks.getCurrentBlockIndex();
  };

  private updateDom() {
    if (this.nodes.holder) {
      if (!this.root) {
        this.root = createRoot(this.nodes.holder);
      }
      this.root.render(
        <FormGenerator
          questions={this.data.questions}
          setQuestions={this.setQuestions}
          question={this.data.question}
          isNotes={false}
          api={this.api} // Pass the Editor.js API
          deleteBlock={this.deleteBlock}
          changeQuestion={this.changeQuestion.bind(this)}
          getBlockIndex={this.getBlockIndex}
          edit={this.edit}
        />
      );
    }
  }

  private changeQuestion = (addedQuestion: string) => {
    this.data = {
      ...this.data,
      question: { ...this.data.question, question: addedQuestion },
    };
  };

  render(): HTMLElement {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    this.root = createRoot(rootNode);
    this.root.render(
      <FormGenerator
        questions={this.data.questions}
        api={this.api} // Pass the Editor.js API
        setQuestions={this.setQuestions}
        changeQuestion={this.changeQuestion.bind(this)}
        question={this.data.question}
        isNotes={false}
        deleteBlock={this.deleteBlock}
        getBlockIndex={this.getBlockIndex}
        edit={this.edit}
      />
    );

    return this.nodes.holder;
  }

  save(): TimelineData {
    return this.data;
  }
  destroy() {
    if (this.root) {
      this.root.unmount();
      this.root = null;
    }
  }
}
