import { newDate } from 'utils/dateUtils';

export const colorsMapping = {
  Red: '#EB5757',
  Blue: '#2F80ED',
  Pink: 'Pink',
  Yellow: '#F2C94C',
  Green: '#27AE60',
  Purple: '#BB6BD9',
};
export function convertToEventFormat({
  endDate,
  startDate,
  conditionType,
  ...appointment
}: any) {
  const start = newDate(startDate);

  const end = newDate(endDate);

  const newStart = start.getTime();
  const newEnd = end.getTime();

  const conditionFilter = conditionType;
  const color = appointment?.sessionType
    ? colorsMapping[appointment?.sessionType?.color]
    : '';
  const firstColor = conditionFilter
    ? colorsMapping[conditionFilter.firstColor]
    : '';
  const event = {
    ...appointment,
    end: newEnd,
    start: newStart,
    color,
    firstColor,
    conditionType: conditionType ?? null,
    endDate: newEnd,
    startDate: newStart,
    id: appointment._id,
  };
  return event;
}

export function findMembers({ appointments, doctors, patients }: any) {
  if (!appointments || !doctors || !patients) return;
  return appointments.map((appointment: any) => {
    const members = appointment?.members;
    if (!members) return appointments;
    const newMembers = [...members].map((member) => {
      const doctorIndex = doctors.findIndex(
        (doctor: any) => doctor.email === member
      );
      if (doctorIndex !== -1) return doctors[doctorIndex];
      const patientIndex = patients.findIndex(
        (patient: any) => patient.email === member
      );
      if (patientIndex !== -1) return patients[patientIndex];
      return { email: member, picture: '' };
    });
    return { ...appointment, members: newMembers };
  });
}
