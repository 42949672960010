import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    height: ({ isPatientTab }: { isPatientTab?: boolean }) =>
      isPatientTab ? '65vh' : 'calc(100vh - 71px)',
    // Both (70, 128) are header sizes on different used viewports
    maxHeight: 'calc(100vh - 71px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 128px)',
      maxheight: 'calc(100vh - 128px)',
    },
  },
  containerPatient: {
    backgroundColor: 'white',
    borderRadius: '20px',
    marginBottom: '1rem',
    height: 'calc(100vh - 87px - 1rem)',
    maxHeight: 'calc(100vh - 87px - 1rem)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 132px - 1rem)',
      maxHeight: 'calc(100vh - 132px - 1rem)',
      borderRadius: '0px',
    },
  },
  fullHeight: {
    height: '100%',
    maxHeight: '100%',
  },
  widthBox: {
    [theme.breakpoints.up('xl')]: {
      width: '25% !important',
    },
    [theme.breakpoints.only('lg')]: {
      width: ({ isPatientTab }: { isPatientTab?: boolean }) =>
        isPatientTab ? '35% !important' : '30% !important',
    },
  },
  hidden: {
    height: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
