import { useQuery, useLazyQuery, gql } from '@apollo/client';
import React, { useContext, createContext, useState, useEffect } from 'react';
import { IDoctorTemplates } from './FormModal';
import { DOCTOR_TEMPLATES } from './Questionaires';
import { templateType } from 'pages/AppointmentPage/AppointmentPage';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { useToast } from 'hooks/useToast';
import useDeleteDoctorTemplate from '@eggmed/graphql-client/operations/patientActivitiesOperations/useDeleteDoctorTemplate';
import { IDoctor } from 'pages/PatientDashboard/LatestContactedDoctors';

interface IFormProvider {
  openForm: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  assignOpen: boolean;
  setAssignOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openAssignModal: (form: IDoctorTemplates) => void;
  handleDeleteModal: (form: IDoctorTemplates) => void;
  openDeleteModal: boolean;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  formToAssign: IDoctorTemplates;
  setFormToAssign: React.Dispatch<React.SetStateAction<IDoctorTemplates>>;
  data: IDoctorTemplates[];
  refetch: () => void;
  doctor: IDoctor;
  triggerToast: (x: number, y: number) => void;
  handleDelete: () => void;
  loading: boolean;
  setFormType?: React.Dispatch<React.SetStateAction<FormTypeEnum>>;
  setPage: (page: number) => void;
  count: number;
  page: number;
  setType: any;
  deleteId: string;
  setDeleteId: (arg: string) => void;
}

export enum FormTypeEnum {
  noteTaking = 'note-taking',
  assessmentForm = 'assessment-form',
  intakeForm = 'intake-form',
  other = 'other',
}

const Context = createContext<IFormProvider>(undefined);

export default function Provider({ children }: { children: React.ReactNode }) {
  const { doctor } = useAuth();
  const [openForm, setOpenForm] = useState(false);
  const [assignOpen, setAssignOpen] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [formToAssign, setFormToAssign] =
    React.useState<IDoctorTemplates>(null);
  const [formType, setFormType] = React.useState<FormTypeEnum>(
    FormTypeEnum.intakeForm
  );
  const [deleteId, setDeleteId] = React.useState(null);

  const { triggerToast } = useToast();
  const handleClose = () => {
    setOpenForm(false);
  };

  const openAssignModal = (form: IDoctorTemplates) => {
    setAssignOpen(true);
    setOpenForm(false);
    setFormToAssign(form);
  };

  const handleDeleteModal = (form: IDoctorTemplates) => {
    setOpenDeleteModal(true);
    setFormToAssign(form);
  };

  const [handleDeleteDoctorTemplate] = useDeleteDoctorTemplate();
  const handleDelete = () => {
    handleDeleteDoctorTemplate({
      variables: { id: formToAssign._id },
      refetchQueries: [DOCTOR_TEMPLATES],
    });
    setOpenDeleteModal(false);
  };
  const [type, setType] = React.useState(templateType.form);
  const [page, setPage] = React.useState(1);
  const limit = 10;
  const { data, refetch, loading } = useQuery(DOCTOR_TEMPLATES, {
    variables: { type, formType, page, limit, setPage },
  });
  return (
    <Context.Provider
      value={{
        openForm,
        setOpenForm,
        handleClose,
        assignOpen,
        setAssignOpen,
        openAssignModal,
        handleDeleteModal,
        openDeleteModal,
        setOpenDeleteModal,
        formToAssign,
        setFormToAssign,
        data: data?.doctorTemplateByTypeForm?.result || [],
        count: data?.doctorTemplateByTypeForm?.count,
        loading,
        refetch,
        doctor,
        triggerToast,
        handleDelete,
        setFormType,
        setPage,
        page,
        setType,
        deleteId,
        setDeleteId,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useProvider() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useFormProvider must be used within a formProvider');
  }

  return context;
}
