import { Box, makeStyles, Theme, Button } from '@material-ui/core';
import React from 'react';
import Tabs from 'components/ui/Tabs';
import ActiveMed from './ActiveMed';
import { ReactComponent as AddTaskIcon } from 'assets/AddTaskIcon.svg';
import Text from 'Text';

const useStyles = makeStyles<Theme>((theme) => ({
  addTask: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#1F61DC',
    borderRadius: '10px',
    textTransform: 'capitalize',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '36px',
    padding: '16px',
    height: '43px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#082CAF',
    },
  },
  tabForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));
const MedicationModal = ({ handleExpand }) => {
  const classes = useStyles();
  const [customValue, setCustomValue] = React.useState(0);
  const medicationsArray = [
    {
      _id: 0,
      name: 'Ezy dose cold seal crd 62 dose miscellaneous',
      date: new Date(),
      type: 'active',
      diagnosis: 'Acne vulgaris',
    },
    {
      _id: 1,

      name: 'PredniSONE oral tablet 5mg',
      date: new Date(),
      type: 'active',
      diagnosis: 'Hypothyroidism',
    },
    {
      _id: 2,
      name: 'AmLODIPine besylate oral tablet 10 mg',
      date: new Date(),
      type: 'discontinued',
    },
  ];
  return (
    <Box px="2rem" mt="1rem">
      <Tabs
        tabsMode="simple"
        tabClassHeader={classes.tabForm}
        tabsBar={['Active', 'Discontinued', 'Error']}
        componetAlignedRightWithTabs={
          <Button
            className={classes.addTask}
            onClick={() => handleExpand(true)}
            data-cy="add-drug"
          >
            <AddTaskIcon style={{ paddingRight: '6px', width: '1.3rem' }} />
            <Text i18nKey="add">Add</Text>
          </Button>
        }
        tabsContent={[
          <Box mt={4} ml="2rem">
            <ActiveMed
              data={medicationsArray?.filter((el) => el?.type === 'active')}
            />
          </Box>,
          <Box mt={4} ml="2rem">
            <ActiveMed
              data={medicationsArray?.filter(
                (el) => el?.type === 'discontinued'
              )}
            />
          </Box>,
          <Box mt={4} ml="2rem">
            <ActiveMed
              data={medicationsArray?.filter((el) => el?.type === 'error')}
            />
          </Box>,
        ]}
        customValue={customValue}
        setCustomValue={setCustomValue}
      />
    </Box>
  );
};

export default MedicationModal;
