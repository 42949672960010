import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  equal: {
    flex: 1,
  },
  label: {
    color: '#425364',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(21),
  },
  labelSpacing: {
    marginTop: theme.spacing(4),
    marginBottom: '10px',
    marginRight: theme.spacing(2),
  },
  chip: {
    height: '24px',
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  closeIcon: {
    fontSize: '44px',
    color: '#425364',
  },
  deleteIcon: {
    backgroundColor: 'red',
    textTransform: 'capitalize',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
      color: 'white',
      '& svg': {
        '& path': {
          stroke: 'white',
        },
      },
    },
    '&:active': {
      backgroundColor: 'red',
      color: 'white',
    },
    minWidth: '120px',
  },
  responsiveMobile: {
    backgroundColor: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess ? '#EFF6FF' : 'white',
    filter: ({ openSuccess }: { openSuccess: boolean }) =>
      openSuccess && 'blur(5px)',
    [theme.breakpoints.only('md')]: {
      width: '50vw',
    },
    [theme.breakpoints.only('xl')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('lg')]: {
      width: '35vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '90vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '90vw',
    },
    height: 'auto',
  },
  succesfullIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '30px',
  },
  dateChip: {
    color: 'black',
    //marginLeft: 'auto',
    fontSize: '15px',
    height: '24px',
    fontWeight: 500,
    cursor: 'pointer',
    backgroundColor: 'transparent',
  },
  labelSpacingDate: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  labelSpacingAssigned: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  sideInformation: {
    borderLeft: '1px solid #BCC6D3',
    height: '65vh',
    paddingLeft: `${theme.spacing(4)}px !important`,
    paddingTop: `${theme.spacing(9.5)}px !important`,
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.only('sm')]: {
      borderLeft: '0px solid #BCC6D3',
      paddingLeft: `${theme.spacing(3.5)}px !important`,
      paddingTop: `${theme.spacing(2)}px !important`,
      height: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      borderLeft: '0px solid #BCC6D3',
      paddingLeft: `${theme.spacing(3.5)}px !important`,
      paddingTop: `${theme.spacing(2)}px !important`,
      height: '100%',
    },
  },
  sideMargin: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },
  mainInformation: {
    height: '65vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.only('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      height: '100%',
    },
  },
  scroll: {
    maxHeight: 'calc(92vh - 57px)',
    height: 'calc(92vh - 57px)',
  },
  scrollView: {
    maxHeight: 'calc(92vh - 65px)',
    height: 'calc(92vh - 65px)',
  },
  scrollSide: {
    maxHeight: '60vh',
    overflowX: 'hidden',
    [theme.breakpoints.only('xs')]: {
      maxHeight: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      maxHeight: '100%',
    },
  },
  marginMain: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  title: {
    color: '#1F61DC',
    fontWeight: 700,
    fontSize: '43px',
  },
  labelMargin: {
    marginLeft: theme.spacing(-0.5),
  },
  input: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
  },
  inputDate: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    // marginTop: theme.spacing(0.2),
  },
  marginRight: {
    marginRight: theme.spacing(2.2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  inputFile: {},
  iconButton: {
    position: 'absolute',
    top: '3vh',
    right: '2vw',
    [theme.breakpoints.only('xs')]: {
      top: '1.5vh',
    },
  },
  draftjsInput: {
    maxHeight: '230px',
    height: '230px',
    overflowY: 'auto',
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.only('sm')]: {
      width: '99%',
    },
    [theme.breakpoints.only('xs')]: {
      width: '99%',
    },
  },
  spacingIcon: {
    paddingTop: '3px',
  },
  modalContent: {
    backgroundColor: 'white',
    width: '55vw',
    maxWidth: '55vw',
    height: '70vh',
    maxHeight: '70vh',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('lg')]: {
      width: '78vw',
      maxWidth: '78vw',
    },
    [theme.breakpoints.only('md')]: {
      width: '80vw',
      maxWidth: '80vw',
    },
    [theme.breakpoints.only('sm')]: {
      width: '93vw',
      maxWidth: '93vw',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100vw',
      maxWidth: '100vw',
      height: '100vh',
      maxHeight: '100vh',
      borderRadius: '0px',
    },
  },
  inputWrapperCustom: {
    cursor: 'pointer',
    height: '44px',
    position: 'relative',
    backgroundColor: ' #FFFFFF',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Inter, sans-serif',
    '&:focus': {
      borderRadius: '8px',
    },
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  dateLabel: {
    // marginRight: theme.spacing(1),
    marginBottom: '-5px',
    marginLeft: theme.spacing(-2),
  },
  fileIcon: {
    // marginRight: theme.spacing(1),
    color: '#BCC6D3',
    paddingRight: theme.spacing(1),
  },
  btnBox: {
    [theme.breakpoints.only('xs')]: {
      position: 'absolute',
      right: '4rem',
      bottom: '-35rem',
    },
  },
  error: {
    color: 'red',
    marginLeft: '3px',
    '&.Mui-error': {
      color: 'red',
      marginLeft: '3px',
    },
  },
  errorIcon: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
    right: '4px',
  },
  noDate: {
    color: 'white',
    backgroundColor: 'white',
    height: 0,
  },
}));

export default useStyles;
