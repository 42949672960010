import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modalBox: {
    outline: 0,
    overflow: 'hidden',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    backgroundColor: 'white',
    borderRadius: '24px',
    filter: ({
      openSuccess,
    }: {
      openSuccess: boolean;
      disablePadding: boolean;
    }) => openSuccess && 'blur(5px)',
    position: 'relative',
  },
  ScrollContainer: {
    minWidth: '100%',
    overflowX: 'hidden',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  title: {
    textTransform: 'none',
    color: ' #1F61DC',
    fontSize: theme.typography.pxToRem(30),
    lineHeight: '38px',
    fontWeight: 'bold',
    paddingTop: '1.5rem',
    marginBottom: '1rem',
  },
  cancelIcon: {
    position: 'absolute',
    top: '1.2rem',
    right: '1.2rem',
    cursor: 'pointer',
    zIndex: 10,
    width: '20px',
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingInline: '2rem',
    height: '8vh',
  },
  slideBox: {
    outline: 0,
    overflow: 'hidden',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    backgroundColor: 'white',
    filter: ({
      openSuccess,
    }: {
      openSuccess: boolean;
      disablePadding: boolean;
    }) => openSuccess && 'blur(5px)',
    position: 'relative',
    height: '100vh',
  },
  notTaking: {
    boxShadow: 'none',
    outline: 0,
    overflow: 'hidden',
    backgroundColor: 'white',
    filter: ({
      openSuccess,
    }: {
      openSuccess: boolean;
      disablePadding: boolean;
    }) => (openSuccess ? 'blur(5px)' : 'none'),
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  slideForm: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 'calc(100vh - 59px)',
  },
  slideTitle: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '20px',
    fontWeight: 600,
    color: '#101828',
    marginTop: '1rem',
    marginBottom: '0.7rem',
  },
  cancelIconSlide: {
    position: 'absolute',
    top: '1.3rem',
    right: '2.2rem',
    cursor: 'pointer',
    zIndex: 10,
    width: '20px',
  },
  editIconSlide: {
    position: 'absolute',
    top: '1.3rem',
    right: '4.5rem',
    cursor: 'pointer',
    zIndex: 10,
    width: '20px',
  },
  newRegularModal: {
    outline: 0,
    overflow: 'hidden',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
    backgroundColor: 'white',
    borderRadius: '12px',
    filter: ({
      openSuccess,
    }: {
      openSuccess: boolean;
      disablePadding: boolean;
    }) => openSuccess && 'blur(5px)',
    position: 'relative',
  },
  slide: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  iconClose: {
    justifyContent: 'flex-end',
    display: 'flex',
    padding: '1rem',
  },
}));

export default useStyles;
