import { Box, Typography, makeStyles, Theme, Avatar } from '@material-ui/core';
import clsx from 'clsx';
import ScrollBar from 'components/ui/ScrollBar';
import React from 'react';
import EditButtons from 'components/EditButtons';
import Dayjs from 'dayjs';
import { ReactComponent as PaymentTime } from 'assets/PaymentTime.svg';
import { ReactComponent as PaymentHour } from 'assets/PaymentHour.svg';
import { useQuery, gql } from '@apollo/client';
import usePaymentIntent from '@eggmed/graphql-client/operations/appointmentsOperations/usePaymentIntent';
import useAuth from 'graphql/operations/doctorOperations/useAuth';
import { GET_CREDIT_CARD } from 'pages/AdminPage/index';
import { useHistory } from 'react-router-dom';
import { APPOINTMENT } from './PaymentModal';
import { ReactComponent as AddCardPay } from 'assets/AddCardPay.svg';
import { ReactComponent as MasterCardIcon } from 'assets/MasterCardIcon.svg';
import { ReactComponent as SelectedCardPay } from 'assets/SelectedCardPay.svg';
import { ReactComponent as UnselectedCardPay } from 'assets/UnselectedCardPay.svg';
import Text from 'Text';
import { dayjsFormat, toDayjs } from 'utils';

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    paddingInline: '3rem',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '25vw',
  },
  appointmentDetails: {
    border: '0.5px solid #BCC6D3',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '5px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.14)',
  },
  totalLabel: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16px',
    color: '#425364',
  },
  sessionDetailsTitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#344054',
  },
  cardPay: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    color: '#101828',
  },
  avatar: {
    width: '50px',
    height: '50px',
    textTransform: 'capitalize',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#425364',
  },
  name: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    color: '#475467',
    textTransform: 'capitalize',
  },
  specialty: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    color: '#475467',
    textTransform: 'capitalize',
  },
  email: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#425364',
  },
  date: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    color: '#475467',
  },
  prices: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#425364',
  },
  tax: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#425364',
  },
  total: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18px',
    color: '#1F61DC',
  },
  creditBtn: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#1F61DC',
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

export const MakePayment = ({
  doctor,
  closeModal,
  startDate,
  isPending,
  appointmentId,
  setStep,
  endDate,
}) => {
  const classes = useStyles();
  const { firstname, lastname, picture, email, specialty } = doctor || {};
  const {
    user: { user: userAuth },
  } = useAuth();
  const history = useHistory();
  const { _id: userId } = userAuth || {};
  const { data: creditCards } = useQuery(GET_CREDIT_CARD, {
    variables: { userid: userId },
  });
  const { data } = useQuery(APPOINTMENT, {
    variables: {
      appointmentId,
    },
  });
  const { sessionType } = data?.appointment || {};
  const cardsInformation = creditCards?.userCreditCard;
  const [_card, setCard] = React.useState(null);
  const [selected, setSelected] = React.useState('');
  const AMOUNT = Number(sessionType?.rate) * 100;
  const { paymentIntent, loading: loadingPaymentIntent } = usePaymentIntent();

  const { _id: paymentMethodId } = _card || {};
  const paymentInput = {
    amount: AMOUNT,
    appointmentId,
    paymentMethodId: paymentMethodId,
  };
  const handleEdit = async () => {
    try {
      await paymentIntent({
        variables: {
          paymentInput,
        },
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };
  const handleSubmit = async (e) => {
    if (_card) {
      await handleEdit();
      closeModal();
      history.push({ search: '?redirect_status=succeeded' });
    }
  };

  function handleClick() {
    setSelected(null);
    setStep(2);
  }
  function handleSelect(card, index) {
    setCard(card);
    setSelected(index);
    if (selected === index) {
      setSelected(null);
      setCard(null);
    }
  }

  return (
    <ScrollBar maxHeight="65vh">
      <Box className={classes.modal}>
        <Box className={classes.session}>
          <Text
            i18nKey="sessionDetails"
            className={classes.sessionDetailsTitle}
          >
            Session details
          </Text>
          <Box
            display="flex"
            py={4}
            alignItems="center"
            borderBottom="1px solid #BCC6D3"
          >
            <Avatar className={classes.avatar} src={picture}>
              {firstname && firstname[0]} {lastname && lastname[0]}
            </Avatar>
            <Box ml={2}>
              <Typography
                className={classes.name}
                style={{ marginBottom: '5px' }}
              >
                {firstname} {lastname}
              </Typography>
              <Typography className={classes.specialty}>
                {specialty || '-'}
              </Typography>{' '}
            </Box>

            <Box display="flex" ml={6} flexDirection="column">
              <Box
                mr={2}
                display="flex"
                alignItems="center"
                style={{ marginBottom: '5px' }}
              >
                <PaymentTime style={{ marginRight: '5px' }} />
                <Typography className={classes.date}>
                  {toDayjs(startDate).format('MMM D')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <PaymentHour style={{ marginRight: '5px' }} />
                <Typography className={classes.date}>
                  {dayjsFormat(startDate, 'h:mm A')}-{' '}
                  {dayjsFormat(endDate, 'h:mm A')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={clsx(classes.margin)} mt={2}>
          <Box pt={2}>
            <Text
              i18nKey="paymentDetails"
              className={classes.sessionDetailsTitle}
            >
              Payment details
            </Text>
          </Box>
          <Box pb={4} borderBottom="1px solid #BCC6D3">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
              mb={0.5}
            >
              <Text i18nKey="consultationCost" className={classes.email}>
                Consultation cost
              </Text>
              <Typography className={classes.prices}>
                ${Number(sessionType?.rate)}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text i18nKey="taxes" className={classes.email}>
                Taxes
              </Text>
              <Text i18nKey="$5" className={classes.tax}>
                $5
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Text i18nKey="total" className={classes.totalLabel}>
                Total
              </Text>
              <Typography className={classes.total}>
                ${Number(sessionType?.rate) + 5}
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            px={'4px'}
            maxHeight="150px"
            style={{ overflowY: 'auto', overflowX: 'hidden' }}
          >
            {cardsInformation?.map((card, index) => (
              <Box
                py={2}
                display="flex"
                alignItems="center"
                style={{ cursor: 'pointer', gap: '10px' }}
                onClick={() => handleSelect(card, index)}
                justifyContent="flex-end"
                flexDirection="row-reverse"
              >
                <Box display="flex" alignItems="center">
                  <MasterCardIcon style={{ marginRight: '10px' }} />
                  <Typography
                    className={classes.cardPay}
                  >{`xxx xxxxx ${card?.cardNumber?.substr(
                    card?.cardNumber?.length - 4
                  )}`}</Typography>
                </Box>
                <>
                  {selected === index ? (
                    <SelectedCardPay />
                  ) : (
                    <UnselectedCardPay />
                  )}
                </>
              </Box>
            ))}
          </Box>
          <Box
            mt={1}
            display="flex"
            alignItems="center"
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
          >
            <AddCardPay style={{ marginRight: '10px' }} />
            <Text i18nKey="Add New Card" className={classes.creditBtn}>
              Add New Card
            </Text>
          </Box>
          <Box mb={1} position="absolute" bottom="0rem" right="3rem">
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              my={2}
              style={{
                marginInline: '3rem',
              }}
            >
              <EditButtons
                type="submit"
                submitText="Pay"
                submitWidth="3vw"
                handleOpen={() => !isPending && closeModal()}
                editable
                loading={loadingPaymentIntent}
                handleEdit={(e) => handleSubmit(e)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ScrollBar>
  );
};
